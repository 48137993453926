.page {
	display: flex;
	padding-top: 48px;
}

.main {
	padding: 40px 24px 40px 40px;
	flex-grow: 1;
}

.title {
	display: inline-block;
	font: var(--gilroy-Medium-24-140p);
	letter-spacing: 1px;
	text-transform: lowercase;
}

.table {
	margin-top: 16px;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 30px;
	grid-row-gap: 16px;
}

.input {
	position: relative;
	display: flex;
	align-items: flex-end;
}

.input > :first-child {
	width: 100%;
}

.input input {
	max-width: 100% !important;
}

.title__artists {
	margin-top: 47px;
}

.title__id {
	margin-top: 30px;
}

.flex {
	display: flex;
	flex-direction: column;
	margin-top: 16px;
}

.item {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 24px;
}

.item:not(:last-child) {
	margin-bottom: 24px;
}

.button__wrapper {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.prev {
	display: inline-block;
	padding: 18px 65px 18.43px 0px;
	font: var(--gilroy-Medium-18-22);
	text-decoration: underline;
	cursor: pointer;
}

.next {
	width: 328px;
	height: 60px;
	margin-left: auto;
	margin-top: 48px;
	background: var(--color-dark-grey);
	border: none;
	color: var(--color-white);
	letter-spacing: 1px;
	font: var(--gilroy-Medium-18-140);
	cursor: pointer;
}

.helper {
	position: relative;
	width: 368px;
	padding: 40px;
	background: var(--color-light-green);
}

.helper__wrapper {
	position: sticky;
	top: 112px;
}

.helper__title {
	display: inline-block;
	font: var(--gilroy-Medium-24-140p);
	margin-bottom: 16px;
}

.helper__main {
	font: var(--gilroy-Medium-16-140);
}

.date :global(.react-calendar) {
	width: 100%;
}

.date :global(.react-calendar__month-view__days) {
	display: grid !important;
	grid-template-columns: repeat(7, 1fr);
	padding-left: 9px;
}

.date :global(.react-calendar__tile) {
	margin: 0px;
}

.Helper,
.noticeHelper {
	position: absolute;
	left: 0px;
	color: var(--color-red);
	font: var(--gilroy-Medium-12-12);
	padding: 0 4px;
}

.Helper,
.noticeHelper {
	bottom: -16px;
}

.noticeHelper {
	bottom: -64px;
	text-transform: lowercase;
}

.plineHelper,
.plineDateHelper {
	position: absolute;
	left: 0px;
	color: var(--color-red);
	font: var(--gilroy-Medium-12-12);
	padding: 0 4px;
}

.plineHelper {
	bottom: -36px;
}

.plineDateHelper {
	bottom: -26px;
}

.select {
	width: 100%;
}

.inputGenres {
	position: relative;
}

.inputGenres > :first-child {
	width: 100%;
}

.inputGenres > :first-child > :first-child > :last-child {
	padding-bottom: 1px !important;
}

.info {
	position: absolute;
	top: 32px;
	left: 65px;
	transition-duration: 0.1s;
	transform: translate(0px, 0px);
}

.infoMts {
	position: absolute;
	top: 30px;
	left: 124px;
	transition-duration: 0.1s;
	transform: translate(0px, 0px);
}

.info svg,
.infoMts svg,
.infoActive svg,
.infoActiveMts svg {
	width: 16px;
	height: 16px;
}

.infoActive {
	position: absolute;
	top: 8px;
	left: 55px;
	transition-duration: 0.1s;
	transform: translate(-5px, 0px);
}

.infoActiveMts {
	position: absolute;
	top: 6px;
	left: 100px;
	transition-duration: 0.1s;
	transform: translate(-5px, 0px);
}

.infoActive svg circle,
.infoActiveMts svg circle {
	stroke: var(--color-grey100) !important;
}

.infoActive svg path,
.infoActiveMts svg path {
	fill: var(--color-grey100) !important;
}

.disabled,
.disabled input {
	opacity: 0.6;
	cursor: not-allowed;
}

.artistsBlock {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 426px;
	margin-top: 12px;
}

.helpContainer {
	position: relative;
}

.gridForLines {
	display: grid;
	grid-template-columns: 2fr 3fr;
	gap: 12px;
}

.gridForLines > div > div {
	width: 100%;
}

.variousArtist {
	margin-top: 12px;
}

.variousArtistHelper {
	color: var(--color-red);
	font: var(--gilroy-Medium-12-12);
	padding: 0 4px;
}

@media screen and (min-width: 587px) {
	.noticeHelper {
		bottom: -48px;
	}
}

@media screen and (min-width: 620px) {
	.noticeHelper {
		bottom: -36px;
	}
}

@media screen and (min-width: 748px) {
	.noticeHelper {
		bottom: -24px;
	}
}

@media screen and (min-width: 1262px) {
	.noticeHelper {
		bottom: -18px;
	}
}

@media screen and (min-width: 839px) {
	.plineHelper {
		bottom: -26px;
	}
}

@media screen and (min-width: 951px) {
	.plineDateHelper {
		bottom: -16px;
	}
}

@media screen and (min-width: 1024px) {
	.plineHelper {
		bottom: -26px;
	}

	.plineDateHelper {
		bottom: -26px;
	}
}

@media screen and (min-width: 1113px) {
	.plineDateHelper {
		bottom: -16px;
	}
}

@media screen and (min-width: 1165px) {
	.plineHelper {
		bottom: -18px;
	}
}
