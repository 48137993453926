.Label {
	font: var(--gilroy-Medium-16);
	font-size: inherit;
	line-height: inherit;
	text-decoration: none;
	color: inherit;
	background-color: transparent;
}

.Label:global.black {
	color: var(--color-dark-grey);
}
