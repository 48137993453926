/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/11/2020, 15:02
 */

.Form {
	width: 100%;
}

.Form :global .title {
	font: var(--gilroy-Medium-24-140p);
	line-height: 28px;
	margin-bottom: 24px;
}

.Form :global .controls {
	font: var(--gilroy-Medium-18);
	display: flex;
	align-items: center;
	flex-direction: column;
	margin-top: 32px;
	text-align: center;
}

.Form :global .links {
	font: var(--gilroy-Medium-18);
	line-height: 24px;
	letter-spacing: 0.72px;
	display: flex;
	align-items: center;
	flex-direction: column;
	margin-top: 26px;
	text-align: center;
}

@media screen and (min-width: 768px) {
	.Form {
		max-width: 416px;
	}

	.Form :global .title {
		margin-bottom: 40px;
		font: var(--gilroy-Medium-32);
		line-height: 40px;
	}
	.Form :global .controls {
		margin-top: 40px;
	}
}

@media screen and (min-width: 3840px) {
	.Form {
		margin-top: 120px;
		margin-bottom: 160px;
		padding-right: 60px;
		padding-left: 60px;
	}
}
