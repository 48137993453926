.modal {
	width: 0;
	height: 100%;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 140000000;
	display: flex;
	justify-content: flex-end;
	visibility: hidden;
	overflow: hidden !important;
	overflow-y: hidden !important;
	opacity: 0;
	transition: width 300ms ease-in;
}

.modal.active {
	width: 100%;
	visibility: visible;
	opacity: 1;
}

.container {
	position: relative;
	width: 416px;
	min-height: 600px;
	height: 100vh;
	background-color: var(--color-white);
	overflow: hidden !important;
	overflow-y: hidden !important;
	color: var(--color-dark-grey);
	display: flex;
	flex-direction: column;
	padding: 40px;
}

.modalFooter__title,
.modalHeader__title {
	font: var(--gilroy-Medium-30);
	font-weight: 500;
	line-height: 40px;
	letter-spacing: 1px;
	text-transform: lowercase;
}

.modalHeader_clear {
	font: var(--gilroy-Medium-18);
	font-weight: 500;
	letter-spacing: 1px;
	text-transform: lowercase;
	text-decoration: underline;
	cursor: pointer;
	color: var(--color-grey);
	margin: 0 0 0 24px;
}

.modalHeader_clear:hover {
	color: var(--color-dark-grey);
}

.modal__icon {
	margin-right: 16px;
}

.modalHeader {
	display: flex;
	flex-direction: column;
	padding-bottom: 40px;
}

.modalHeader__titleWrapper {
	display: flex;
	align-items: center;
}

.modalHeader svg {
	font-size: 36px;
}

.modalBody {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	text-transform: lowercase;
}

.modalBody * {
	letter-spacing: 1px;
}

.setting__title {
	margin-bottom: 15px;
	font: var(--gilroy-Medium-24);
	line-height: 34px;
}

.setting {
	margin-bottom: 40px;
}

.setting__list {
	height: 58vh;
	overflow-y: scroll;
}

.setting__item {
	display: flex;
	align-items: center;
	font: var(--gilroy-Medium-16-140);
	margin-bottom: 15px;
}

.setting__item:hover {
	cursor: pointer;
	text-decoration: line-through;
}

.setting__checkbox {
	margin-right: 16px;
}

.button__select {
	margin-top: auto;
	text-transform: lowercase;
}

.button__select:hover {
	background: #e5e5e5;
	border: 2px #ffffff solid;
}

.Overlay {
	position: absolute;
	opacity: 0;
	top: 0;
	left: 0;
	width: 0;
	height: 0;
	background-color: var(--color-dark-grey);
	transition: opacity 300ms ease-in;
}

.OverlayActive {
	z-index: 130000000;
	width: 100vw;
	height: 100vh;
	opacity: 0.5;
}

.modalHeader__icon {
	margin: 0 0 0 auto;
}

@media screen and (min-width: 795px) {
	.setting__list {
		overflow-y: none;
	}
}
