.loader {
fill: var(--color-light-green);
}







