.Form {
	width: 100%;
	max-width: 876px;
}

.Title {
	margin-bottom: 24px;
}

.Button {
	margin-top: 20px;
	text-transform: lowercase;
}

.Fields {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
}

.Terms {
	font: var(--gilroy-Medium-16);
	margin: 0;
	padding: 0;
	text-decoration: underline;
	border: none;
	background-color: transparent;
}

.Controls {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	justify-content: space-between;
	margin-top: 23px;
	margin-bottom: 60px;
}

.header {
	font: var(--gilroy-Medium-24-140p);
	margin-bottom: 20px;
}

.subheader {
	font: var(--gilroy-Medium-16-140p);
}

.Checkbox a {
	text-decoration: none;
	font-weight: 700;
	cursor: pointer;
	color: inherit;
}

@media screen and (min-width: 768px) {
	.header {
		font: var(--gilroy-Medium-40);
	}

	.subheader {
		font: var(--gilroy-Medium-24);
	}
}
