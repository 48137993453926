/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 02/10/2020, 16:10
 */

.Token {
	display: flex;
	align-items: center;
	padding: 5px 8px;
	user-select: none;
	color: var(--color-dark-grey);
	background-color: var(--color-grey100);
}

.Token :global .remove {
	margin-top: 3px;
	margin-left: 5px;
	padding: 0;
	cursor: pointer;
	border: none;
	outline: none;
	background-color: transparent;
}
