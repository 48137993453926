.wrapper {
	display: grid;
	grid-template-columns: 9fr 3fr;
	margin-bottom: 32px;
	gap: 24px;
}

.artistInfo {
	display: flex;
	align-items: center;
	padding: 8px;
	background-color: var(--color-white);
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
}

.photo {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 16px;
	width: 100px;
	height: 100px;
	flex-shrink: 0;
	background-color: var(--color-white100);
	overflow: hidden;
}

.cover {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
}

.artistPhotoMain {
	border-radius: 50%;
}

.wrapperName {
	display: flex;
	gap: 12px;
}

.name {
	margin-bottom: 7px;
	font: var(--gilroy-Medium-24);
}

.releaseType {
	padding: 4px 8px;
	display: inline-flex;
	align-items: center;
	height: 32px;
	text-align: center;
	justify-content: center;
	transform: translateY(-4px);
	font: var(--gilroy-Medium-16-24);
	background-color: var(--color-light-grey);
}

.wrapperIsrc {
	display: flex;
	align-items: center;
	margin-bottom: 12px;
}

.wrapperIsrcBtn {
	display: flex;
	align-items: center;
	margin-bottom: 12px;
	padding: 0;
	background: transparent;
	outline: none;
	border: none;
	cursor: pointer;
}

.wrapperIsrcBtn img {
	margin-left: 8px;
}

.isrcLabel {
	margin-right: 8px;
	font: var(--gilroy-Medium-16-24);
	color: var(--color-grey);
}

.isrcValue {
	display: flex;
	align-items: center;
	gap: 9px;
	font: var(--gilroy-Medium-16-24);
	color: var(--color-dark-grey);
}

.artistCardTitle {
	font: var(--gilroy-Medium-16-24);
	color: var(--color-dark-grey);
	text-decoration: underline;
}

.listArtists {
	margin: 0;
	padding: 0;
	display: flex;
	gap: 12px;
	flex-wrap: wrap;
	list-style: none;
}

.streamsWrapper {
	padding: 16px 8px 20px;
	background-color: var(--color-white);
	text-align: center;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
}

.artist {
	display: flex;
	align-items: center;
	gap: 8px;
}

.artistPhoto {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 24px;
	height: 24px;
	border-radius: 50%;
	flex-shrink: 0;
	background-color: var(--color-white100);
	overflow: hidden;
}

.artistName {
	font: var(--gilroy-Medium-16-24);
	color: var(--color-dark-grey);
}

.disabled .artistName {
	color: var(--color-grey);
	pointer-events: none;
}

.artistName:hover {
	text-decoration: underline;
}
.streamsTitle {
	margin-bottom: 8px;
	font: var(--gilroy-Medium-16-24);
	color: var(--color-grey100);
}

.streamsValue {
	margin-bottom: 4px;
	font: var(--gilroy-Medium-24);
}

.progress {
	position: relative;
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	gap: 5px;
	font: var(--gilroy-Medium-12-12);
}

.progressValue {
	font: var(--gilroy-Medium-14);
}

.wrapperSelectPeriod {
	cursor: pointer;
}

.selectPeriod {
	border: none;
	outline: none;
	margin-right: 7px;
	background-color: transparent;
	font: var(--gilroy-Medium-12-12);
	color: inherit;
	cursor: pointer;
}

.chevron {
	transform: rotate(0deg);
}

.wrapperSelectPeriod:hover .selectPeriod {
	text-decoration: underline;
}

.active .chevron {
	transform: rotate(180deg);
}

.progressValue svg {
	display: none;
	margin-right: 3px;
	margin-bottom: -1px;
}

.upStreams {
	color: #6c9400;
}

.downStreams {
	color: var(--color-red);
}

.upStreams .arrow {
	display: inline-block;
}

.downStreams .arrow {
	display: inline-block;
	transform: rotate(180deg);
}

.listPeriod {
	padding: 0;
	margin: 0;
	list-style: none;
	position: absolute;
	top: 20px;
	left: 50%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	min-width: 130px;
	transform: translateX(-50%);
	background-color: var(--color-white);
	border: 1px solid var(--color-grey100);
	z-index: 2;
}

.periodElement {
	padding: 8px;
	width: 100%;
	text-align: left;
	font: var(--gilroy-Medium-14);
	color: var(--color-dark-grey);
	cursor: pointer;
}

.periodElement::first-letter {
	text-transform: uppercase;
}

.periodElement:hover {
	background-color: var(--color-light-grey);
}

.periodActive {
	color: var(--color-white) !important;
	background-color: var(--color-green) !important;
}

.copyButton {
	position: relative;
	margin: 0;
	padding: 0;
	background: transparent;
	outline: none;
	border: none;
	cursor: pointer;
}

.copyButton svg {
	transition: opacity 0.2s linear;
}

.copyButton:hover svg {
	opacity: 0.7;
}

.copiedTooltip {
	position: absolute;
	top: -30px;
	left: 50%;
	padding: 6px;
	transform: translateX(-50%);
	font: var(--gilroy-Medium-12);
	color: var(--color-dark-grey);
	background-color: var(--color-white);
	box-shadow: 0 1px 10px rgba(0, 0, 0, 0.08);
	animation: showTooltip 0.2s linear alternate;
}

.normalPercent {
	color: var(--color-dark-grey);
	background-color: transparent;
}

.normalPercent .arrow {
	display: none;
}

@keyframes showTooltip {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}
