.page {
	display: flex;
	min-height: 85vh;
	padding-top: 48px;
}

.main {
	padding: 40px 24px 40px 40px;
	flex-grow: 1;
}

.loader {
	padding: 40px 24px 40px 40px;
	flex-grow: 1;
	width: auto;
}

.form_container {
	padding: 41px 64px 80px;
	overflow-y: scroll;
}

.title {
	font: var(--gilroy-Medium-24-140p);
	letter-spacing: 1px;
	color: var(--color-dark-grey);
	margin-bottom: 8px;
	text-transform: lowercase;
}

.description {
	display: block;
	font: var(--gilroy-Medium-14-20);
	letter-spacing: 1px;
	color: var(--color-dark-grey);
	margin-bottom: 16px;
	width: 50vw;
}

.main {
	flex-grow: 1;
	padding: 40px 24px 40px 40px;
}

.main__notForm {
	padding-right: 40px;
}

.main__wrapper {
	display: flex;
}

.info button {
	display: flex;
	width: 24px;
	margin-left: 0;
}

.title__info {
	display: flex;
	align-items: flex-end;
	text-transform: lowercase;
}

.title__info.info button {
	margin: 4px 0px 0px 16px;
}

.title {
	display: flex;
	flex-direction: column;
	margin-right: 16px;
	font: var(--gilroy-Medium-24-140p);
}

.table {
	margin-top: 24px;
}

.table__item {
	display: flex;
	position: relative;
	padding: 0 8px 0 50px;
	align-items: flex-start;
	border: 1px solid transparent;
	font: var(--gilroy-Medium-14-17);
}

.table__item.table__itemSel {
	border-color: var(--color-light-green);
}

.table__item:not(:last-child) {
	margin-bottom: 2px;
}

.table__item > *:nth-child(2) {
	width: 15px;
	margin-right: 16px;
	padding: 22px 0 21px;
	text-align: right;
}

.table__item > *:nth-child(3) {
	width: 11.172vw;
	flex-grow: 1;
	margin-right: 16px;
	padding: 22px 0 21px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.table__item > *:nth-child(4) {
	margin-right: 8px;
	padding: 19px 0;
	margin-left: auto;
	cursor: pointer;
	width: 24px;
}

.table__item > *:nth-child(5) {
	padding: 19px 0;
}

.tableItem__sel {
	position: absolute;
	left: 16px;
	top: 50%;
	transform: translateY(-50%);
}

.tableItem__inselected {
	width: 24px;
	height: 24px;
	border: 2px solid var(--color-light-grey);
	border-radius: 50%;
	background: transparent;
}

.main__notForm .table__item {
	padding: 0 16px 0 66px;
}

.main__notForm .table__item > *:nth-child(2) {
	margin-right: 24px;
}

.toogleMenu {
	cursor: pointer;
}

.form {
	position: relative;
	padding: 56px 40px 60px 0px;
}

.lastSave {
	display: flex;
	align-items: center;
	margin-bottom: 40px;
	background: var(--color-light-grey);
}

.lastSave__switch {
	padding: 0 7px;
}

.lastSave > :last-child {
	padding: 19px;
	padding-left: 0;
	font: var(--gilroy-Medium-16-140);
}

.form__title {
	display: inline-block;
	margin-bottom: 16px;
	font: var(--gilroy-Medium-16-150);
	color: var(--color-dark-grey);
	letter-spacing: 0.15px;
}

.form__section {
	display: flex;
	flex-direction: column;
}

.form__section > :not(:last-child) {
	margin-bottom: 24px;
}

.form__section > :last-child {
	margin-bottom: 60px;
}

.addPublisher {
	display: flex;
}

.button__addPublisher {
	display: flex;
	align-items: center;
	margin-right: 15px;
	cursor: pointer;
	font: var(--gilroy-Medium-14-17);
	text-decoration: underline;
}

.button__addPublisher img {
	margin-right: 8px;
}

.button__close {
	margin-left: 16px;
	width: 48px;
	height: 48px;
	padding: 16px;
}

.button__close img {
	width: 100%;
	height: 100%;
}

.form__close {
	position: absolute;
	top: 18px;
	right: 30px;
	width: 24.5px;
	cursor: pointer;
}

.button__wrapper {
	display: flex;
	justify-content: space-between;
	width: 100%;
	/* padding: 60px 40px 80px; */
	padding: 0px 40px;
}

.prev {
	display: inline-block;
	padding: 18px 65px 18.43px 0px;
	font: var(--gilroy-Medium-18-22);
	text-decoration: underline;
	cursor: pointer;
}

.next {
	width: 328px;
	/* width: 25.625vw; */
	height: 60px;
	background: var(--color-dark-grey);
	border: none;
	color: var(--color-white);
	letter-spacing: 1px;
	font: var(--gilroy-Medium-18-140);
	cursor: pointer;
}

.next.disabled {
	background: var(--color-grey50);
	cursor: inherit;
}

.date :global(.react-calendar) {
	width: 100%;
}

.date :global(.react-calendar__month-view__days) {
	display: grid !important;
	grid-template-columns: repeat(7, 1fr);
	padding-left: 9px;
}

.date :global(.react-calendar__tile) {
	margin: 0px;
}

.grid {
	position: relative;
	display: grid;
	grid-template-columns: 2fr 2fr 1fr;
	grid-column-gap: 24px;
}

.input {
	position: relative;
}

.input > :first-child {
	width: 100%;
}

.Helper {
	position: absolute;
	top: calc(65px + 6px);
	left: 0px;
	padding-left: 4px;
	padding-right: 4px;
	color: var(--color-red);
	font: var(--gilroy-Medium-12);
	font-size: smaller;
}

.author__item {
	padding-bottom: 24px;
	margin-bottom: 40px;
	border-bottom: 2px dashed rgba(0, 0, 0, 0.15);
}

.author__item > :not(:last-child) {
	margin-bottom: 24px;
}

.authorData {
	position: relative;
	display: flex;
	width: 126px;
}

.authorClose {
	position: absolute;
	right: 20px;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;
}

.publisher_item {
	display: flex;
	align-items: flex-end;
	padding-top: 10px;
	padding-bottom: 10px;
}

.publisher_author {
	position: relative;
	width: 350px;
	margin-right: 16px;
}

.publisher_author > div {
	margin: 0;
	width: 350px;
}

.publisher_slider {
	position: relative;
	display: flex;
	align-items: center;
}

.author__button {
	display: flex;
	align-items: center;
}

.addExecutor {
	display: flex;
	align-items: center;
	text-decoration: underline;
	font: var(--gilroy-Medium-14-17);
}

.addExecutor img {
	margin: 0 8px 0 16px;
}

.info button {
	display: flex;
	width: 24px;
	margin-left: 0;
}

.form__buttons {
	display: flex;
}

.form__buttons > :not(:last-child) {
	margin-right: 40px;
}

.author__delete {
	display: inline-block;
	font: var(--gilroy-Medium-14-17);
	text-decoration: underline;
	cursor: pointer;
}

.form__buttonWrapper {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.form__buttonWrapper span {
	display: inline-block;
	margin-left: 8px;
	text-decoration: underline;
	font: var(--gilroy-Medium-14-17);
	pointer-events: none;
}

.form__submitWrapper {
	position: relative;
}

.form__submit {
	width: 328px;
	height: 47px;
	margin-top: 48px;
	border: none;
	background: var(--color-dark-grey);
	font: var(--gilroy-Medium-18-140);
	color: var(--color-white100);
	cursor: pointer;
}

.submit_helper {
	position: absolute;
	top: calc(100% + 5px);
	left: 0px;
	color: var(--color-red);
	font: var(--gilroy-Medium-12);
	font-size: smaller;
	padding: 0 5px;
}

.bottom_container {
	position: relative;
	width: 100%;
}

.error_container {
	position: absolute;
	top: 0;
	right: 85px;
}

.error {
	color: var(--color-red);
	font: var(--gilroy-Medium-12);
	font-size: smaller;
}

.ownershipContainer {
	max-height: 66px;
}
