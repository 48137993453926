.download {
	text-decoration: underline;
	cursor: pointer;
}

.error {
	color: var(--color-red);
}

.preLoader {
	width: 30px;
	height: 30px;
	margin: 0 auto;
	animation: loader 1s linear infinite;
}

.text {
	text-align: center;
	font: var(--gilroy-Medium-12);
}

@keyframes loader {
	100% {
		transform: rotate(360deg);
		transform-origin: center;
	}
}
