.info {
	display: grid;
	grid-template-columns: 2fr 1fr;
	gap: 30px;
	margin-bottom: 40px;
}

.title {
	display: inline-block;
	margin-bottom: 24px;
	letter-spacing: 1px;
	font: var(--gilroy-Medium-24-140p);
}

@media screen and (min-width: 1024px) {
	.info {
		grid-template-columns: 70% 30%;
	}
}

@media screen and (min-width: 1280px) {
	.info {
		grid-template-columns: 75% 25%;
	}
}

@media screen and (min-width: 1440px) {
	.info {
		grid-template-columns: 80% 20%;
	}
}
