.wrapper {
  display: flex;
  flex-direction: column;
	width: 100%;
	padding: 16px;
	background-color: var(--color-white);
  height: 100%;
  min-height: 303px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
}

.headingGraphic {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.title {
  max-width: 270px;
  width: 100%;
	font: var(--gilroy-Medium-24-28);
}

.tabs {
  display: flex;
  width: 100%;
  max-width: 216px;
  
}

.tabType {
  outline: none;
  border: none;
  padding: 8px;
  flex-grow: 1;
  background-color: var(--color-white);
  font: var(--gilroy-Medium-14);
  color: var(--color-grey);
  cursor: pointer;
}

.activeTabType {
  background-color: var(--color-light-grey);
  color: var(--color-dark-grey);
}

.graph {
	display: flex;
	align-items: center;
  flex: 1;
  column-gap: 44px;
  height: 100%;
}

.graph path {
  cursor: pointer;
}

.labels {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.labelListening,
.labelDownloads {
	background-color: var(--color-light-grey);
	display: flex;
	align-items: center;
	font: var(--gilroy-Medium-12);
	min-width: 173px;
}

.labelText {
	display: inline-block;
  margin-left: auto;
  text-align: right;
	font: var(--gilroy-Medium-14);
	color: var(--color-grey);
  white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.labelPercent {
  flex-shrink: 0;
	font: var(--gilroy-Bold-14-17);
}

.labelColorLine {
  flex-shrink: 0;
	width: 8px;
	height: 32px;
	background-color: green;
}

.textContent {
	display: flex;
	gap: 8px;
}

.leftSideLabel {
	display: flex;
	align-items: center;
	gap: 12px;
}

.label {
  position: relative;
	display: flex;
	align-items: center;
	gap: 8px;
  padding-right: 10px;
	background-color: var(--color-light-grey);
	min-width: 216px;
	max-width: 240px;
  width: 100%;
}


.customClass {
  fill: green !important;
  stroke: green !important;
}

.tooltipGraphicWrapper {
  padding: 6px;
  width: 100%;
  min-width: 160px;
  background-color: var(--color-white);
  box-shadow: 0 1px 10px rgba(0,0,0,0.08);
}


.tooltipGraphicWrapperAbsolute {
  display: none;
  position: absolute;
  right: 225px;
  bottom: 0;
}


.label:hover .tooltipGraphicWrapperAbsolute {
  display: block;
}

.tooltipGraphic {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}

.tooltipGraphicOthers {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0;
}

.tooltipGraphicHeading {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 12px;
  gap: 10px;
}

.tooltipGraphicOutlet {
  font: var(--gilroy-Medium-12);
  color: var(--color-dark-grey);
}

.tooltipGraphicOutletOthers{
  color: var(--color-grey);
}

.tooltipListOutletOthers {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  list-style: none;
}

.tooltipElementOutletOthers {
  width: 100%;
  display: flex;
  gap: 5px;
  justify-content: space-between;

}

.tooltipGraphicPercentage {
  font: var(--gilroy-Medium-12);
  color: var(--color-grey);
  text-align: right;
}