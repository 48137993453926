.item {
	position: relative;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 30px;
	margin-bottom: 48px;
}

.helper {
	position: absolute;
	bottom: -24px;
	left: 0px;
	color: var(--color-red);
	font: var(--gilroy-Medium-12);
	font-size: smaller;
	padding: 0 5px;
}

.info {
	margin-left: -20px;
	margin-top: 20px;
}
