/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 12/02/2021, 18:24
 */

.Label {
	margin-bottom: 34px;
}

.Controls {
	display: flex;
	margin-top: 40px;
}

.Controls button {
	margin-right: 30px;
}

.Selected {
	display: flex;
	justify-content: space-between;
	max-width: 178px;
	margin: 5px;
	padding: 10px;
	text-transform: capitalize;
	background-color: var(--color-white);
}

.Selected button {
	border: none;
	background-color: transparent;
}

.SelectedContainer {
	display: flex;
	flex-wrap: wrap;
}
