.text_editor {
	margin: 24px 0;
	width: 100% !important;
	border: 0 solid transparent !important;
	cursor: text !important;
	overflow: auto;
	min-height: 200px;
	max-height: 390px;
	font-family: Gilroy-Medium, serif !important;
  user-select: text; /* Standard user-select property */
  -webkit-user-select: text; /* For Safari */
  -moz-user-select: text; /* For Firefox */
  -ms-user-select: text; /* For IE/Edge */
}

.error {
	margin-top: 6px;
	display: block;
	color: var(--color-red);
	font: var(--gilroy-Medium-12-12);
	font-size: smaller;
	padding: 0 4px;
}

/* Specific adjustments for Safari */
@media not all and (min-resolution: 0.001dpcm) {
	@supports (-webkit-appearance: none) {
		.text_editor {
			-webkit-user-select: text;
			-webkit-appearance: none;
		}
	}
}
