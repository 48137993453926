.banner {
	position: relative;
	width: 100%;
	padding: 24px;
	margin-bottom: 24px;
	background: var(--color-light-green);
	font: var(--gilroy-Medium-16-140);
	letter-spacing: 1px;
}

.banner img {
	position: absolute;
	width: 16px;
	height: 16px;
	top: 12px;
	right: 12px;
	cursor: pointer;
}

.banner h4 {
	font: var(--gilroy-Medium-24);
	letter-spacing: 0.96px;
}
