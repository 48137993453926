/* paggination */

.repertoire__songItemsPaggination {
	width: 100%;
	margin-top: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.repertoire__songItemsPaggination button {
	cursor: pointer;
}

.repertoire__songItemsPaggination button:first-child {
	margin-right: 32px;
	width: auto;
}
.repertoire__songItemsPaggination button:last-child {
	width: auto;
	margin-left: 32px;
}
.repertoire__songItemsPaggination > * {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: transparent;
	border: none;
	min-width: 24px;
	margin: 0 5px;
	height: 24px;
	color: var(--color-dark-grey);
	font: var(--gilroy-Medium-14);
}
.repertoire__songItemsPaggination button.pagginationActive {
	color: var(--color-light-green);
}

.selectPage {
	background: transparent;
	border: none;
	border-bottom: 2px solid transparent;
	padding-top: 2px;
	color: var(--color-dark-grey);
	text-align: center;
	font: var(--gilroy-Medium-14);
}
.selectPage:global(.form__focus) {
	outline: none;
	border-color: var(--color-light-green);
}

.selectPage input {
	width: 0;
	height: 0;
	border: none;
}
