.statistic {
	width: 100%;
	top: 56px;
	letter-spacing: 1px;
	background: var(--color-white100);
	margin-top: 74px;
}

.statistic__header {
	display: flex;
	height: 48px;
	background-color: var(--color-light-grey);
	font: var(--gilroy-Bold-16-19);
}

.statistic__headerButton {
	padding: 16px 0;
	border: none;
	text-align: center;
	cursor: pointer;
}

.statistic__main {
	padding: 14px;
}

@media screen and (min-width: 768px) {
	.Input input {
		font: var(--gilroy-Medium-18);
	}

	.Input {
		max-width: 416px;
		height: 60px;
	}

	.statistic {
		top: 0;
		margin-top: 0;
	}
}

@media screen and (min-width: 1280px) {
	.statistic__main {
		padding: 40px;
	}
}
