.Page {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	justify-content: center;
	margin-bottom: 100px;
	padding-top: 50px;
}

.Page section {
	width: 100%;
}

.HeaderLine {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin-top: 40px;
	margin-bottom: 24px;
}

.Checkbox {
	margin: 0;
}

.Page__title {
	display: flex;
	justify-content: space-between;
}

.Title {
	margin-bottom: 20px;
}

.Field {
	margin-bottom: 20px;
}

.Field :global .title {
	margin-bottom: 10px;
}

.Roles ul {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}

.Roles li {
	margin-right: 40px;
	margin-bottom: 20px;
}

.Table {
	font: var(--gilroy-Medium-16);
	overflow: auto;
	width: 100%;
	max-height: 100%;
	border-spacing: 0px;
}

.Table td {
	height: 50px;
	padding: 5px;
	border-top: 1px solid #f4f4f4;
	border-left: 1px solid #f4f4f4;
}

.Table th {
	padding: 5px;
	text-transform: uppercase;
	border-top: 1px solid var(--color-grey50);
	border-left: 1px solid var(--color-grey50);
	background-color: #d9d9d9;
}

.Table tr:hover td,
.Table tr:nth-child(odd):hover td {
	background-color: var(--color-light-grey);
}

.Table tr:nth-child(odd) td {
	background-color: var(--color-white100);
}

.Table td:global.cb {
	text-align: center;
}

.Table td:global.cb > * {
	display: inline-block;
}

.Button {
	width: 160px;
	height: 40px;
	min-height: 40px;
	margin-top: 40px;
	margin-left: auto;
}

.Wizard {
	position: fixed;
	top: 64px;
	width: 100%;
	padding: 0 115px;
	background: var(--color-white);
	z-index: 1;
	border-radius: 2px;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
}

.Wizard * {
	font: var(--gilroy-Bold-14-17);
	letter-spacing: 1px;
}
.Wizard > div {
	margin: 0;
	padding: 28px 0 27px;
	display: inline-block;
	width: auto;
}

.grid {
	margin-top: 80px;
}

.tabs {
	width: 100%;
	top: 56px;
	letter-spacing: 1px;
	background: var(--color-white100);
}

.tabs__header {
	display: flex;
	height: 48px;
	background-color: var(--color-light-grey);
}

.tabs__header > button {
	font: var(--gilroy-Bold-16-19);
}

.tabs__main {
	padding: 30px;
}

.tabs__statistic {
	padding-top: 5px;
}

.headerButton {
	cursor: pointer;
}

@media screen and (min-width: 768px) {
	.tabs {
		top: 0;
	}
}
