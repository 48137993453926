/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 12/02/2021, 18:24
 */

.Page {
	display: flex;
	/*align-items: center;*/
	justify-content: center;
	height: 100%;
	background-color: var(--color-white100);

}

.Form {
	width: 100%;
	margin-top: 140px;
	padding: 0 134px;
}


.Content {
	margin-top: 30px;
}

.Content .Input {
	max-width: 100%;
	}

.Content .Input::first-letter {
	
	text-transform: capitalize;
}

.Controls {
	display: flex;
	justify-content: flex-end;
	margin-top: 50px;
}