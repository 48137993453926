/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/11/2020, 15:02
 */

 .Lang {
	font: inherit;
	font-weight: bold;
	cursor: pointer;
	user-select: none;
	letter-spacing: 1px;
}

.List {
	display: flex;
	flex-direction: row;
	font: var(--gilroy-Medium-18);
}

.List li + li {
	margin-left: 10px;
}

.List :global .current-lang {

}

.List :global .another-lang {
	/*display: none;*/
	/*width: 0;*/
	transition: all 0.4s ease-out;
	opacity: 0.5;
	color: var(--color-grey);
}

.List:hover :global .another-lang {
	/*display: block;*/
	/*width: auto;*/
	opacity: 1;
}
