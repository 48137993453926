.wrapperFilters {
  display: grid;
  grid-template-columns: repeat(3,1fr);
  margin-bottom: 32px;
  gap: 24px;
}

.datePicker {
  width: 100%;
}

.datePicker div:first-child{
  width: 100%;
  max-width: 100% !important;
}

.datePicker div div div:first-child{
  width: 100%;
  max-width: 100% !important;
}

.disabled {
  opacity: 0.6;
  pointer-events: none;
}



