.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
}

@media (max-width: 1024px) {
  .wrapper {
    grid-template-columns: 1fr;
  }
}