.wrapper {
	background-color: var(--color-white);
	margin: 40px 30px;
}

.header {
  margin-bottom: 24px;
	padding: 16px 0 0 12px;
}

.title {
	margin: 0;
  margin-bottom: 20px;
  font: var(--gilroy-Medium-24);
}

.wrapperFilers {
  display: flex;
  gap: 15px;
}


.headerInfo {
	display: grid;
	grid-template-columns: 8fr 3fr 2fr;
	align-items: center;
	gap: 12px;
	font: var(--gilroy-Medium-14);
	color: var(--color-grey);
	margin-bottom: 12px;
}
.headerInfoName {
	padding-left: 45px;
}

.headerInfoLink {
	padding-right: 8px;
	justify-self: end;
}
.headerInfoLinkEn {
	padding-right: 22px;
	justify-self: end;
}

.skeletonWrapper {
	height: 548px;
	width: 100%;
	padding: 0 14px 14px;
}

.paginationWrapper {
	margin-bottom: 30px;
}

.wrapperInputFilter {
  width: 420px;
}
