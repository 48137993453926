.Modal {
	position: fixed;
	width: 100%;
	height: 100%;
}

.Overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: var(--color-dark-grey);
}

.LeftSide {
	overflow: auto;
	height: 100vh;
	width: 368px;
	padding: 36px !important;
	color: var(--color-white100);
	background-color: var(--color-dark-grey);
}

.LeftSideWhite {
	overflow-x: hidden;
	height: 100vh;
	width: 100%;
	color: var(--color-dark-grey);
	background-color: var(--color-white100);
}

.ContentWhite {
	display: flex;
	overflow: auto;
	flex-direction: column;
	justify-content: flex-start;
	padding: 0 40px 0 40px;
}

.ContentWhite button {
	max-width: 100%;
}

.ContentWhite a {
	max-width: 100%;
}

.containerWhite {
	padding-right: 0px !important;
	padding-left: 0px !important;
}

.titleWhite {
	margin-top: 150px;
	margin-bottom: 16px;
}

.confirmBtn {
	margin-top: 238px;
	margin-bottom: 16px;
}

.CloseButton {
	position: fixed;
	top: 24px;
	right: 24px;
	cursor: pointer;
}

.Content {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.Title {
	font: var(--gilroy-Medium-56);
	margin-bottom: 28px;
}

.errorWrapper {
	position: relative;
}

.deleteWrapper {
	position: relative;
}

.Helper {
	position: absolute;
	bottom: 0px;
	left: 0px;
	color: var(--color-red);
	font: var(--gilroy-Medium-12);
	font-size: smaller;
	padding: 0 5px;
}

.errorHelper,
.errorNotifyHelper {
	position: absolute;
	left: 18px;
	color: var(--color-red);
	font: var(--gilroy-Medium-12);
	font-size: smaller;
	padding: 0 5px;
}

.errorHelper {
	bottom: 100px;
}

.errorNotifyHelper {
	bottom: 240px;
}

.confirmBtnHelper {
	display: block;
	margin-bottom: 5px;
	color: var(--color-red);
	font: var(--gilroy-Medium-12);
	font-size: smaller;
}

/* ---NOTIFICATION MODAL---*/

.Tray {
	display: flex;
	margin: 0;
}
.Tray > * + * {
	margin-left: 10px;
}

.Lang {
	/* margin: 0 18px 0 10px; */
	margin-right: 18px;
	grid-area: lang;
}

.notify__wrapper :global .another-lang {
	opacity: 0.5 !important;
}

.notify__lang {
	margin-bottom: 20px;
}

.notify {
	overflow: auto;
	height: 100vh;
	/* width: 408px; */
	/* margin-left: auto; */
	/* max-height: 100vh; */
	color: var(--color-dark-grey);
	background-color: var(--color-white);
}

.notify__wrapper {
	display: flex;
	flex-direction: column;
	position: relative;
	padding: 24px;
	height: 100%;
}

.input,
.textArea {
	width: 100%;
	padding: 10px 8px;
	line-height: 21.2px;
	background-color: var(--color-white100);
}

.input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
	color: #d7d7d7;
}

.add_service__title {
	font: var(--gilroy-Medium-32);
	line-height: 40px;
	margin-top: 40px;
}

.CloseButtonAddService {
	position: fixed;
	top: 32px;
	right: 30px;
}

.servicesWrapper {
	margin-top: 40px;
}

.servicesField {
	margin-bottom: 24px !important;
}

.servicesField > label > div {
	padding-right: 44px !important;
}

.servicesField > label:last-child {
	font: var(--gilroy-Medium-18);
	line-height: 24px;
}

.notify__title {
	font: var(--gilroy-Bold-20);
	line-height: 28px;
}

.notify__top_wrapper {
	display: flex;
	justify-content: space-between;
	margin-bottom: 40px;
}

.reports__top_wrapper {
	display: flex;
	justify-content: space-between;
	margin-bottom: 30px;
}

.rubric_modal_wrapper {
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	margin-bottom: 40px;
}

.notify__body {
	z-index: 0;
	margin-top: 20px;
}

.textArea {
	border: none;
	resize: vertical;
	min-height: 150px;
	max-height: 400px;
	font: var(--gilroy-Medium-18);
	border-bottom: 2px grey solid;
}

.input:focus {
	border-color: var(--color-white100);
	border: none !important;
	border-bottom: 2px var(--color-white100) solid !important;
}

.textArea:focus {
	border-color: var(--color-white100);
	border: none !important;
	border-bottom: 2px var(--color-white100) solid !important;
	outline: none !important;
}

.notify__recepients {
	position: relative;
	z-index: 999999999 !important;
}

.notify__sendAllMark {
	position: initial !important;
	margin-top: 8px;
}

.notify__confirm {
	display: flex;
	flex-grow: 1;
}

.activeBtn,
.inactiveBtn {
	margin-top: auto;
	max-width: 100%;
}

.activeBtnConfirmQuestion {
	margin-top: 20px;
	max-width: 100%;
}

.activeBtnConfirmQuestion:not(:last-child) {
	margin-right: 8px;
}

.notify__confirm_question_wrapper {
	max-width: 100%;
	margin-top: auto;
	padding-bottom: 24px;
}

.inactiveBtn,
.inactiveBtn:hover {
	color: var(--color-white) !important;
	background-color: var(--color-grey100) !important;
	border: none !important;
}

.disable {
	pointer-events: none;
	opacity: 0.5;
}

.readAdminModalItemTitle {
	margin-right: 8px;
	font: var(--gilroy-Bold-16-140);
	color: var(--color-dark-grey);
}

.readAdminModalRubricTitleWrapper {
	position: relative;
	margin-right: 8px;
	font: var(--gilroy-Bold-16-140);
	color: var(--color-dark-grey);
}

.readAdminModalListItem {
	margin-bottom: 16px;
}

.readAdminModalListItem:not(:first-child) {
	display: flex;
}

.readAdminModalTitle {
	font: var(--gilroy-Bold-20);
	color: var(--color-dark-grey);
	margin-bottom: 36px;
}

.notify__header {
	position: relative;
}

.notify__header > div {
	max-width: 100%;
}

.Helper {
	position: absolute;
	bottom: -16px;
	left: 0px;
}

.checkbox_wrapper {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	margin-bottom: 8px;
	cursor: pointer;
}

.hint {
	margin-top: 6px;
	font: var(--gilroy-Medium-12-15);
	color: var(--color-dark-grey);
}

.notify__dropZone {
	margin-top: 20px;
}

.checked {
	width: 24px;
	height: 24px;
	background-image: url('images/checked_full.svg');
	background-repeat: no-repeat;
	background-size: auto;
}

.notChecked {
	background-image: url('images/checked_empty.svg');
	width: 24px;
	height: 24px;
	background-repeat: no-repeat;
	background-size: auto;
}

.checkbox_label {
	font-weight: 500;
	font: var(--gilroy-Medium-16-140);
	line-height: 16px;
	margin-left: 10px;
	padding-top: 3px;
}

.readAdminModalItemText ul {
	padding-left: 18px;
	list-style: disc !important;
}

.readAdminModalItemText ol {
	padding-left: 18px;
}

.textEditor {
	height: 150px;
	font: var(--gilroy-Medium-18) !important;
}

.input::placeholder {
	color: #9197a3 !important;
}

.btnConfirmRubric {
	max-width: 100%;
	margin-top: auto;
}

.readAdminRubricLang {
	margin-bottom: 20px;
}

.Helper_rubric {
	position: absolute;
	bottom: -15px;
	left: 0px;
	padding: 0 40px 0 40px;
	color: var(--color-red);
	font: var(--gilroy-Medium-12);
	font-size: smaller;
}

.reportsDateWrapper {
	position: relative;
	margin: 37px 0px;
	min-width: 100%;
}

.reportsDateWrapper > div {
	max-width: 100%;
}

.reportsDateWrapper > div > div {
	max-width: 100%;
}

.reportsDateWrapper input {
	/* background-color: var(--color-white100); */
	border-bottom-color: unset !important;
	padding-left: 4px;
}

.reportsDateWrapper input::placeholder {
	color: var(--color-grey);
}

.reportsDateWrapper > :first-child {
	width: 100%;
}

.reportsDateWrapper input:focus {
	background-color: var(--color-white100);
}

.DateTimeTitle {
	font: var(--gilroy-Medium-18);
	line-height: 24px;
}

/*=======NextDateModal-in-Admin-Reports=======*/
.nextReportsDateTitle {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
}

.nextReportsDateTimeWrapper {
	padding: 16px 0px;
}

.nextReportsDateTimeWrapper {
	padding: 16px 0px;
}

.horizontalFlex {
	display: flex;
	justify-content: space-between;
}

.horizontalFlex > div {
	max-width: 164px;
	min-width: 164px;
	width: 164px;
}

.nextReportsDateWrapper {
	position: relative;
	/* margin: 37px 0px; */
	min-width: 100%;
}

.nextReportsDateWrapper > div {
	max-width: 100%;
}

.nextReportsDateWrapper > div > div {
	max-width: 100%;
	margin-bottom: 5px !important;
}

.nextReportsDateWrapper input {
	/* background-color: var(--color-white100); */
	border-bottom-color: unset !important;
	padding-left: 4px;
}

.nextReportsDateWrapper input::placeholder {
	color: var(--color-grey);
}

.nextReportsDateWrapper > :first-child {
	width: 100%;
}

.nextReportsDateWrapper input:focus {
	background-color: var(--color-white100);
}

/*=====================================*/

.distributionsDatesWrapper {
	display: flex;
	justify-content: space-between;
	align-items: baseline;
}

/** Mozilla Firefox */
@-moz-document url-prefix() {
	.distributionsDatesWrapper {
		align-items: last baseline;
	}
}

.reportsDistributionDate {
	position: relative;
	margin: 8px 0px;
	min-width: calc(100% / 2 - 12px);
	/* background-color: var(--color-white100); */
}

.distributionsDatesWrapper > div:first-child ::placeholder {
	color: var(--color-grey100) !important;
}

.distributionsDatesTimeTitle {
	margin-top: 32px;
	font: var(--gilroy-Medium-18);
	line-height: 24px;
	color: var(--color-grey);
}

/* .reportsDistributionDate > div > div {
	padding:8px 0px !important;
} */

.reportsDistributionDate > div {
	max-width: auto;
	width: auto;
}

.reportsDreportsDistributionDateateWrapper > div > div {
	max-width: 100%;
}

.reportsDistributionDate input {
	/* background-color: var(--color-white100); */
	border-bottom-color: unset !important;
	padding-left: 4px;
}

.reportsDistributionDate input::placeholder {
	color: var(--color-grey);
}

.reportsDistributionDate > :first-child {
	width: 100%;
}

.reportsDistributionDate input:focus {
	background-color: var(--color-white100);
}

.reports__title input {
	padding: 8px 4px;

	min-width: 100%;
	/* background-color: var(--color-white100); */
}

.reports__title input:focus {
	/* background-color: var(--color-light-grey); */
	border-bottom: 2px solid var(--color-dark-grey) !important;
}

.reports__title input:not(:placeholder-shown) {
	/* background-color: var(--color-light-grey); */
	border-bottom: 2px solid var(--color-dark-grey) !important;
}

.reports__title input::placeholder {
	color: var(--color-grey);
}

.reportsDateWrapper > :first-child {
	width: 100%;
}

.reportsDateWrapper input:focus {
	background-color: var(--color-white100);
}

.DropZone__wrapper {
	margin-top: 24px;
}

.DropZone__title {
	margin-bottom: 8px;
	font: var(--gilroy-Medium-14);
	line-height: 16px;
}

.DropZone {
	max-width: 100% !important;
	cursor: pointer;
}

.delete {
	margin-top: 20px;
}

.reports__confirm {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	flex-grow: 1;
}

.activeReportsBtn,
.inactiveReportsBtn {
	margin-top: 10px;
	max-width: 100%;
}

.error {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 64px;
	margin-top: 70px;
}

.sumWrapper {
	display: flex;
}

.sum input {
	position: relative;
	width: calc((400px - 48px) * 0.7);
}
.currencies {
	position: relative;
	margin-left: 24px;
	width: calc(100% / 3 - 24px);
	z-index: 1;
}

.accId div {
	width: 467px;
	margin-top: 48px;
	margin-bottom: 40px;
}

.reportsDateTimeWrapper {
	padding: 16px 0px;
}

.reportsDateWrapper > div > div {
	margin-bottom: 15px;
}

/*=======reportsModal==========*/

.ReportsModalTitle {
	display: flex;
	justify-content: space-between;
	margin-bottom: 24px;
}

.ReportsModalRadioBtn {
	display: flex;
	justify-content: flex-start;
	margin-top: 6px;
	margin-bottom: 32px;
}

.ReportsModalRadioBtn div {
	display: flex;
	align-items: center;
}

.ReportsModalRadioBtn > div:first-child {
	margin-right: 24px;
}

.ReportsModalRadioBtn input {
	width: 24px;
	height: 24px;
	margin-right: 16px;
	accent-color: #232323;
}

.ReportsModalRadioBtn label {
	font: var(--gilroy-Medium-14);
	line-height: 16px;
}

.ReportsModalLang {
	margin: 6px 0px 40px;
}

.ReportsModalLang > ul > li > div:first-child {
	margin: 0px;
}

.ReportsModalName {
	margin-bottom: 32px;
}

.ReportsModalAccId {
	width: 100%;
	margin-bottom: 32px;
}

.ReportsModalAccId > div {
	width: 100%;
}

.RoyaltyWrapper {
	display: flex;
	justify-content: space-between;
	margin: 0px 0px 24px;
}

.Royalty input {
	position: relative;
	width: 208px;
}

.RoyaltyCurrencies {
	position: relative;
	margin-left: 24px;
	width: 100%;
	z-index: 1;
}

/*===============TEXT FORMAT==================== */

.Paragraph {
	font: var(--gilroy-Medium-16);
	line-height: 22px;
	color: var(--color-light-grey);
	letter-spacing: 1px;
}

.Paragraph ul {
	padding-left: 10px;
	list-style: none;
	margin-bottom: 28px;
}

.Paragraph ul > li::before {
	content: '\2022';
	color: var(--color-light-green);
	font-weight: bold;
	font-family: system-ui;
	display: inline-block;
	width: 10px;
	margin-left: -10px;
}

.Paragraph ol {
	counter-reset: item;
	margin-bottom: 28px;
}

.Paragraph ol li {
	display: block;
}

.Paragraph ol li:before {
	content: counter(item) '. ';
	counter-increment: item;
	color: var(--color-light-green);
}

.Paragraph li {
	margin-bottom: 12px;
	font: var(--gilroy-Medium-16);
	line-height: 22px;
}

.Paragraph a {
	text-decoration: underline;
	color: var(--color-light-green);
}

.Paragraph p {
	padding: 0;
}

.Paragraph h1 {
	display: block;
	position: fixed;
	width: 100%;
	margin: 0;
	padding: 0;
	margin-top: -180px !important;
	margin-left: -48px;
	margin-bottom: 28px;
	padding-top: 64px;
	padding-left: 48px;
	padding-right: 48px;
	padding-bottom: 20px;
	background-color: var(--color-dark-grey);
	font: var(--gilroy-Medium-56);
	line-height: 76px;
}

.Paragraph h2 {
	display: block;
	padding: 0;
	margin: 0px 0px 28px;
	font: var(--gilroy-Medium-36);
	line-height: 48px;
}

.Paragraph h3 {
	display: block;
	padding: 0;
	margin: 0px 0px 24px;
	font: var(--gilroy-Medium-28);
	line-height: 36px;
}

.Paragraph h4 {
	display: block;
	padding: 0;
	margin: 0px 0px 14px;
	font: var(--gilroy-Medium-20);
	line-height: 32px;
}

.errors {
	margin-bottom: 14px;
}

/*===============TEXT FORMAT==================== */

@media screen and (min-width: 768px) {
	.LeftSideWhite {
		width: 408px;
		margin-left: auto;
		padding-left: unset !important;
	}

	.containerWhite {
		padding-left: unset !important;
	}

	.ContentWhite button {
		max-width: 416px;
	}

	.ContentWhite a {
		max-width: 416px;
	}
}

@media screen and (min-width: 1024px) {
	.Paragraph h1 {
		width: 33.3333vw;
	}
}

@media screen and (min-width: 1440px) {
	.Title {
		font: var(--gilroy-Medium-64);
		margin-bottom: 40px;
	}

	.LeftSide {
		width: 416px;
		padding: 40px !important;
	}

	.Paragraph p {
		padding: 0;
	}

	.Paragraph ul {
		padding-left: 10px;
		margin-bottom: 40px;
	}

	.Paragraph ol {
		margin-bottom: 40px;
		padding: 0;
	}

	.Paragraph h1 {
		margin-top: -180px !important;
		margin-left: -64px;
		margin-bottom: 40px;
		padding-left: 64px;
		font: var(--gilroy-Medium-64);
		line-height: 76px;
	}

	.Paragraph h2 {
		display: block;
		padding: 0;
		margin: 0px 0px 32px;
		font: var(--gilroy-Medium-40);
		line-height: 52px;
	}

	.Paragraph h3 {
		display: block;
		padding: 0;
		margin: 0px 0px 24px;
		font: var(--gilroy-Medium-30);
		line-height: 40px;
	}

	.Paragraph h4 {
		display: block;
		padding: 0;
		margin: 0px 0px 16px;
		font: var(--gilroy-Medium-22-26p);
		line-height: 32px;
	}
}

.ZeroMarginBottom {
	margin-bottom: 0px;
}

.ZeroPaddingBottom {
	padding-bottom: 0px;
}

.InactiveBlock {
	opacity: 0.5;
	pointer-events: none;
}

.invoiceInputTitle {
	margin-top: 40px;
	font: var(--gilroy-Medium-14);
	font-style: normal;
	font-weight: 500;
	color: var(--color-grey);
}

.invoiceModalText {
	margin-top: 16px;
	font: var(--gilroy-Medium-18-140);
	font-weight: 500;
	letter-spacing: 0.72px;
}

.numberInput {
	position: relative;
}

.numberInput input[type='number']::-webkit-outer-spin-button,
.numberInput input[type='number']::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

.numberInput input[type='number'],
.numberInput input[type='number']:hover,
.numberInput input[type='number']:focus {
	appearance: none;
	-moz-appearance: textfield;
}
