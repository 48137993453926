.useType {
	margin-bottom: 56px;
	border-bottom: 1px solid var(--color-grey100);
}

.content {
	padding: 0 50px;
}

.Sub {
	margin-bottom: 40px;
}

.checkbox {
	font: var(--gilroy-Bold-24);
	margin-bottom: 56px;
}

.checkGreen {
	margin-bottom: 56px;
	margin-right: 20px;
}

.infoButton svg {
	vertical-align: top;
	margin-bottom: 54px;
}
