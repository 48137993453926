.repertoire__songItemsWrapper {
	display: flex;
	flex-direction: column;
}
.repertoire__songItemsWrapper a {
	color: unset;
	text-decoration: none;
	margin-bottom: 2px;
}

.song__table {
	display: flex;
	align-items: center;
	padding: 14.5px 15px 14.5px 72px;
}

.song__table > * {
	position: relative;
}

.song__table > * > :first-child {
	display: inline-block;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.song__table > * > :nth-child(2) {
	position: absolute;
	left: 0;
	top: 100%;
	padding: 3px;
	background: var(--color-dark-grey);
	color: var(--color-grey100);
	opacity: 0;
	visibility: hidden;
	z-index: 1;
	transition: 0.1s ease;
}

.song__table > *:hover > :nth-child(2) {
	opacity: 1;
	visibility: visible;
}

.song__table > *:first-child {
	max-width: 40%;
	min-width: 40%;
	margin-right: 20px;
}
.song__table > *:nth-child(2) {
	max-width: 40%;
	min-width: 40%;
	margin-right: 20px;
}

.song__table > *:nth-child(3) {
	color: transparent;
	max-width: 10%;
	min-width: 10%;
}

.song__table > *:nth-child(4) {
	min-width: 10%;
}

.repertoire__songItemShare {
	text-align: right;
}

.repertoire__songItemsWrapper li {
	position: relative;
	align-items: center;
	font: var(--gilroy-Medium-16-19);
	background-color: var(--color-white);
	margin-bottom: 2px;
}

.repertoire__songItemsWrapper li:last-child {
	margin-bottom: 0;
}

.repertoire__songTitle {
	font: var(--gilroy-Medium-18);
	color: var(--color-grey100);
	text-transform: lowercase;
	margin-bottom: 16px;
	padding-top: 0;
	padding-bottom: 0;
}

.loader {
	margin-top: 30px;
}

.repertoire__songItemLogo {
	width: 22px;
	position: absolute;
	top: 50%;
	transform: translate(0, -50%);
	left: 25px;
}

.repertoire__songItemError {
	color: var(--color-red);
}

.repertoire__songItemError .repertoire__songItemLogo path {
	fill: var(--color-red);
}

.repertoire__mainContent {
	margin-top: 30px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 575px;
}

.repertoire__mainContentEmpty {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 215px;
}

.repertoire__mainContentEmpty > *:first-child {
	margin-bottom: 32px;
}

.repertoire__mainContentEmpty > *:last-child {
	color: var(--color-dark-grey);
	font: var(--gilroy-Medium-24-140p);
	letter-spacing: 1px;
	text-align: center;
}

.repertoire__mainContentEmpty > *:last-child a {
	color: var(--color-dark-grey);
	text-decoration: underline;
	cursor: pointer;
}

.filter {
	margin-top: 8px;
	display: flex;
	flex-wrap: wrap;
}

.filter__item {
	padding: 6.5px 30px 6.5px 8px;
	background: var(--color-grey100);
	position: relative;
	display: inline-block;
	font: var(--gilroy-Medium-16-19);
	letter-spacing: initial;
	margin-right: 8px;
	margin-top: 8px;
	cursor: pointer;
}

.filter__item img {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 8px;
}

.filter__itemButton {
	display: flex;
	cursor: pointer;
}

.filter a {
	font: var(--gilroy-Medium-18);
	text-decoration: underline;
	margin-left: 8px;
	margin-top: 13px;
}

.btnAsLink {
	background: none !important;
	border: none;
	padding-top: 10px !important;
	font: var(--gilroy-Medium-16-19);
	text-decoration: underline;
	cursor: pointer;
}

.adaptive__songTitle,
.adaptive__songTable {
	display: none;
}

.adaptive__item {
	display: block;
	width: 100%;
	margin: 16px 0;
}

.adaptive__songItemLogo {
	width: 22px;
	height: 22px;
}

.adaptive__itemTitle {
	height: 27px;
	color: var(--color-grey100);
	font: var(--gilroy-Medium-14-20);
}

.itemInactive {
	color: var(--color-grey100);
}

.adaptive__itemText {
	position: relative;
	height: 27px;
	font: var(--gilroy-Medium-14-20);
}

.adaptive__itemText :first-child {
	display: inline-block;
	max-width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.adaptive__itemText :nth-child(2) {
	position: absolute;
	left: 0;
	top: 100%;
	padding: 3px;
	background: var(--color-dark-grey);
	color: var(--color-grey100);
	opacity: 0;
	visibility: hidden;
	z-index: 1;
	transition: 0.1s ease;
}

.adaptive__itemText:hover > :nth-child(2) {
	opacity: 1;
	visibility: visible;
}

.adaptive__menuMoreWrapper {
	display: flex;
	width: 48px;
	height: 48px;
}

.itemInactive {
	color: var(--color-grey100);
	pointer-events: none;
}

.editIcon {
	margin-left: auto;
	margin-right: 20px;
	background: transparent;
	border: none;
	cursor: pointer;
}

.editIcon img {
	width: 25px !important;
}

@media screen and (min-width: 768px) {
	.adaptive__songTitle,
	.adaptive__songTable {
		display: flex;
	}

	.adaptive__item {
		display: none;
	}

	.adaptive__menuMoreWrapper {
		display: none;
	}

	.menuMoreWrapper {
		display: flex;
		align-items: center;
		height: 48px;
		cursor: pointer;
	}

	.menuMoreWrapperActive {
		position: relative;
		display: flex;
		align-items: center;
		height: 48px;
		background-color: var(--color-black);
	}

	.song__table > *:first-child {
		max-width: 33%;
		min-width: 33%;
	}

	.song__table > *:first-child,
	.song__table > *:nth-child(2) {
		max-width: 25%;
		min-width: 25%;
	}
}

@media screen and (min-width: 880px) {
	.song__table > *:first-child,
	.song__table > *:nth-child(2) {
		max-width: 30%;
		min-width: 30%;
	}
}

@media screen and (min-width: 1500px) {
	.song__table > *:first-child {
		max-width: 37%;
		min-width: 37%;
	}

	.song__table > *:nth-child(2) {
		max-width: 36%;
		min-width: 36%;
	}
}

.Inactive {
	fill: var(--color-grey100);
	color: var(--color-grey100);
}

.Inactive path {
	fill: var(--color-grey100);
}

.noData {
	font: var(--gilroy-Medium-24-140p);
}
