.lastSave {
	display: flex;
	align-items: center;
	margin-bottom: 40px;
	background: var(--color-light-grey);
}

.lastSave__switch {
	padding: 0 7px;
}

.lastSave > :last-child {
	padding: 19px;
	padding-left: 0;
	font: var(--gilroy-Medium-16-140);
}
