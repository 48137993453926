/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/11/2020, 15:02
 */

.CloseButton {
	padding: 0;
	cursor: pointer;
	border: 0;
	background-color: transparent;
}

.CloseButton:global.white svg path {
	fill: var(--color-white);
}

.CloseButton:global.black svg path {
	fill: var(--color-dark-grey);
}
