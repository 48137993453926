.page {
	display: flex;
	justify-content: center;
	height: 100%;
	background-color: var(--color-white100);
}

@media screen and (min-width: 768px) {
	.page {
		align-items: center;
	}

	.page form {
		max-width: 500px;
	}
}

@media screen and (min-width: 1024px) {
	.page {
		align-items: center;
		justify-content: flex-start;
	}

	.page form {
		margin: 0 auto;
		padding: 0;
	}
}
