.Page {
	display: grid;
	width: 100%;
	height: 100%;
	grid-template-areas: 'wiz' 'form';
	grid-template-rows: 60px auto;
	grid-template-columns: auto;
}

.Wizard {
	grid-area: wiz;
}

.Form {
	overflow: auto;
	overflow-x: hidden;
	height: 100%;
	padding-top: 50px;
	padding-bottom: 50px;
	grid-area: form;
}

.Header {
	margin-bottom: 43px;
}

.Controls {
	margin-top: 50px;
}

.CountryInput input,
.CountryInput {
	max-width: 100%;
}

.CountryInput {
	margin-bottom: 44px;
}

.street {
	margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
	.CountryInput input,
	.CountryInput {
		max-width: 416px;
	}
	.street {
		margin-bottom: 0px;
	}
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
	.Controls {
		margin-left: 44px !important;
	}
}
