.songPhonogram__empty {
	padding-top: 256px;
	padding-bottom: 272px;
	display: flex;
	flex-direction: column;
	align-items: center;
	letter-spacing: 1px;
}

.songPhonogram__empty span {
	font: var(--gilroy-Medium-24-140p);
	margin-top: 32px;
}

.songPhonogram__empty a {
	text-decoration: underline;
}

.song__createNewWrapper {
	display: flex;
	justify-content: flex-end;
}

.song__createNew {
	display: flex;
	align-items: center;
	font: var(--gilroy-Medium-18);
	text-decoration: underline;
	letter-spacing: inherit;
}

.song__createNew > img {
	margin-right: 8px;
}

.songPhonogram__body {
	letter-spacing: 1px;
}

div.songPhonogramBody__tableHead {
	padding-top: 24px;
	padding-bottom: 16px;
	color: var(--color-grey100);
	font: var(--gilroy-Medium-18);
}

.songTableHead__ISRC {
	text-align: right;
}

.song__table {
	text-decoration: none;
	color: var(--color-black);
	display: flex;
	padding: 14.5px 137px 14.5px 72px;
}

.song__table > *:first-child {
	width: 460px;
	margin-right: 11px;
}

.song__table > *:nth-child(2) {
	width: 255px;
	margin-right: 11px;
}

.song__table > *:nth-child(3) {
	width: 155px;
	margin-right: 11px;
}

.song__table > *:nth-child(4) {
	width: 115px;
}

.song__tableItem {
	position: relative;
}

.song__tableItem img {
	position: absolute;
	top: 50%;
	transform: translate(0, -50%);
}

.song__tableItem .dynamic {
	top: 25px;
	left: 25px;
}

.song__tableItem .play {
	right: 24px;
}

.song__tableItem {
	height: 52px;
	background: var(--color-white);
	margin-bottom: 4px;
	font: var(--gilroy-Medium-16-19);
}

.song__tableName,
.song__tableExecutor {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.song__tableName:hover {
	text-decoration: line-through;
}

.mm {
	position: absolute;
	top: 20px;
	left: 50vw;
	z-index: 111111111;
	border: none;
	background: darkblue;
	color: white;
	padding: 5px 10px;
}

.song__tableGenre {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.noData {
	text-align: center;
	font: var(--gilroy-Medium-24);
}

.eye {
	top: 50%;
	transform: translateY(-50%);
	right: 24px;
}
