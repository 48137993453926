/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/11/2020, 15:02
 */

.InfoButton {
	display: flex;
	align-items: center;
}

.InfoButton svg {
	vertical-align: bottom;
}

.Button {
	margin-left: 16px;
	cursor: pointer;
	border: 0;
	background-color: transparent;
}

.Button circle,
.Button path {
	margin-left: 16px;
	cursor: pointer;
	border: 0;
	background-color: transparent;
}

.Button:hover circle {
	stroke: var(--color-dark-grey);
}

.Button:hover path {
	fill: var(--color-dark-grey);
}
