preview img.loader {
	width: 0;
	height: 0;
}

.preview {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	right: 0;
	width: 240px;
	height: 240px;
	border: 1px solid var(--color-black);
}

.preview img {
	max-height: 100%;
	max-width: 100%;
}

@keyframes loader {
	100% {
		transform: rotate(360deg);
	}
}
