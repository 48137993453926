.mainWrapper {
	/* overflow: hidden; */
	/* margin-bottom: 30px; */
	/* height: calc(100% - 35px); */
}

.noData {
	margin-top: 20vw;

	text-align: center;
	font: var(--gilroy-Medium-24);
}

.uploadReportBtn {
	cursor: pointer;
	text-decoration: none;
	color: var(--color-dark-grey);
	width: 400px;
	margin: 0px auto;
}

.noReports {
	width: 50px;

	margin-bottom: 30px;
}

.loader {
	position: absolute;
	top: -5px;
	left: 12px;
}

.sectionTitleWrapper {
	display: flex;
	margin: 24px 40px 16px;
	justify-content: space-between;
	align-items: flex-end;
}

.topBtnsWrapper {
	display: flex;
	align-items: center;
}

.createArticleBtn {
	display: flex;
	justify-content: center;
	flex-wrap: nowrap;
	align-items: center;
	padding: 10px 24px;
	text-decoration: none;
	color: var(--color-dark-grey);
	font: var(--gilroy-Medium-16);
	border: none;
	border-radius: 20px;
	background-color: var(--color-light-green);

	cursor: pointer;
}

.iconPlus {
	width: 16px;

	margin-right: 8px;
}

.filterWrapper {
	display: flex;
	align-items: inherit;
	/* margin: 0px 40px 30px; */
	/* margin-bottom: 30px; */
	width: 504px;
}

.filterIcon {
	margin-left: 8px;
	width: 24px;
	cursor: pointer;
}

.mainInfoTable {
	letter-spacing: 1px;

	margin-left: 40px;
	margin-right: 2vw;
	margin-bottom: 10px;
}

.row_header {
	padding: 11px 15px 11px 0px !important;
	/* padding: 0px !important; */
	margin: 0px 0px 10px !important;
	flex-wrap: nowrap !important;
	justify-content: space-between !important;
	color: var(--color-grey100);
	font: var(--gilroy-Medium-14);
	line-height: 16px;
	text-transform: lowercase;
	/* width: 95%; */
	width: 100%;
	/* min-width: max-content; */
}

.arrow_down {
	margin-left: 7px;
}

.MainInfoTable__body {
	font: var(--gilroy-Medium-16-19);
}

.MainInfoTable__body li {
	background-color: var(--color-white);
	margin: 4px 0px;
	border: 3px solid white;
	transition: 0.2s;
}

.MainInfoTable__body ul {
	background-color: var(--color-white100);
	padding: 0;
}

.reportsListItem {
	display: flex;
	justify-content: space-between !important;
	min-width: max-content;
	color: var(--color-dark-grey);
}

.row_data {
	display: block !important;

	margin: 2px 0px !important;
	font: var(--gilroy-Medium-16);

	padding: 17px 15px 7px 0px;
	/* width: 95%; */
	width: 100%;
}

.row_title {
	margin: 0px !important;
	font: var(--gilroy-Medium-16);
	padding: 0px 0px 6px;
	height: 20px !important;
	justify-content: space-between !important;
}

.shell {
	display: flex;
	align-items: center;
}

.shell a {
	width: 100%;
}

.actionTitle {
	text-transform: capitalize;
	text-decoration: underline;
	margin-left: 8px;
	padding-bottom: 3px;
	padding-top: 12px;
	cursor: pointer;
}

.menuMoreWrapper {
	/* height: 48px; */
	margin-left: auto;
	display: flex;
	/* align-self: flex-start; */
	align-items: center;
	min-width: fit-content;
	height: 49px;
	cursor: pointer;
	margin: 0px 0px 0px auto !important;
	padding: 0px 10px;
}

.menuMoreWrapperActive {
	position: relative;
	margin-left: auto;
	display: flex;
	/* align-self: flex-start; */
	align-items: center;
	height: 49px;
	min-width: fit-content;
	margin: 0px 0px 0px auto;
	background-color: var(--color-black) !important;
	padding: 0px 10px;
}

.menuMore {
	position: relative;
	flex-grow: 1;
	/* padding: 0px 11px 40px 11px; */
}

.menuMoreList {
	position: absolute;
	bottom: -80px;
	right: 0;
	background-color: var(--color-black) !important;
	color: var(--color-white);
	z-index: 99999;
	white-space: nowrap;
}

.menuMoreListItem {
	padding: 9px 24px;
	border: none !important;
	background-color: var(--color-black) !important;
	cursor: pointer;
}

@media screen and (min-width: 1024px) {
	.mainWrapper {
		width: calc(100vw - 169px);
	}
}
