.save_btn {
	margin-top: 38px;
}

.country {
	max-width: 416px;
	margin-bottom: 43px;
}

.subTitle {
	margin-top: 10px;
}

.payment {
	margin-bottom: 48px;
	max-width: 416px;
}

.countrySelect {
	position: relative;
}

.error {
	position: absolute;
	top: 50px;
	letter-spacing: 0.4px;
	color: var(--color-red);
	font: var(--gilroy-Medium-12-12);
	padding: 0 4px;
}
