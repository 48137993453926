.save_btn {
	margin-top: 20px;
}

.country {
	max-width: 416px;
	margin-bottom: 43px;
}

.subTitle {
	margin-top: 10px;
}

.residentialAddress {
	max-width: 416px;
}

.editText {
	display: block;
	margin-left: 8px;
	padding-top: -12px;
	font: var(--gilroy-Medium-18);
}

.webSite,
.email {
	max-width: 100%;
}

.dropDownPhone {
	max-width: 100%;
}