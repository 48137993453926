.loginBtn {
	margin-top: 40px !important;
}

.page {
	height: 100%;
	background-color: var(--color-white100);
}

.login {
	display: none;
	align-items: center;
	justify-content: center;
	max-width: 312px;
	background-color: var(--color-light-green);
}

.login :global .title {
	font: var(--gilroy-Medium-40);
}

.login :global .controls {
	font: var(--gilroy-Medium-18);
}

.register {
	display: flex;
	justify-content: center;
	overflow: visible;
}

.login > div {
	width: 312px;
}

.login button {
	width: 312px;
	margin-top: 48px;
}

@media screen and (min-width: 768px) {
	.register {
		align-items: center;
	}

	.login {
		display: flex;
	}
}

@media screen and (max-height: 950px) {
	.register {
		align-items: start;
	}
}
