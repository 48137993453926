.songPhonogram__empty {
	padding-top: 106px;
	padding-bottom: 272px;
	display: flex;
	flex-direction: column;
	align-items: center;
	letter-spacing: 1px;
}

.songPhonogram__empty span {
	font: var(--gilroy-Medium-24-140p);
	margin-top: 32px;
}

.songPhonogram__empty a {
	text-decoration: underline;
}

.song__createNewWrapper {
	display: flex;
	justify-content: flex-end;
}

.song__createNew {
	display: flex;
	align-items: center;
	font: var(--gilroy-Medium-18);
	text-decoration: underline;
	letter-spacing: inherit;
}

.song__createNew > img {
	margin-right: 8px;
}

.songPhonogram__body {
	letter-spacing: 1px;
}

.songPhonogramBody__tableHead {
	display: grid;
	grid-template-columns: 3fr 2fr 1fr 1fr 1fr;
	grid-column-gap: 10px;
	align-items: center;
	width: 100%;
	height: 32px;
	padding: 0 24px;
	margin-bottom: 4px;
	color: var(--color-grey100);
	font: var(--gilroy-Medium-14);
	font-weight: 500;
	line-height: 16px;
}

.song__table {
	display: flex;
	align-items: center;
	min-height: 52px;
	padding: 0px 24px;
}

.song__tableExecutor {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.song__tableItem {
	position: relative;
	display: grid;
	grid-template-columns: 3fr 2fr 1fr 1fr 1fr;
	grid-column-gap: 10px;
	width: 100%;
	background: var(--color-white);
	margin-bottom: 4px;
	font: var(--gilroy-Medium-16-19);
}

.song__tableItem .play {
	right: 24px;
}

.song__tableName {
	display: flex;
	align-items: center;
	transition: 0.2s;
}

.song__tableName img {
	width: 24px;
	height: 24px;
	margin-right: 24px;
}

.song__tableName:hover {
	text-decoration: line-through;
}

.noData {
	text-align: center;
	font: var(--gilroy-Medium-24);
}

.song__link {
	text-decoration: none;
	text-transform: none;
	color: var(--color-dark-grey);
}
