/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/11/2020, 15:02
 */

.Input {
	font-size: 5rem;
	width: 100%;
	margin: 20px auto;
	text-align: center;
	letter-spacing: 20px;
	border: none;
	outline: none;
}