.addBtn,
.addBtnDisabled {
	margin-top: 10px;
	display: flex;
	align-items: center;
	background: transparent;
	border: none;
}

.addBtn {
	cursor: pointer;
}

.addBtnDisabled {
	cursor: not-allowed;
}

.addBtn span,
.addBtnDisabled span {
	display: inline-block;
	margin-left: 8px;
	text-decoration: underline;
	font: var(--gilroy-Medium-14-17);
	pointer-events: none;
}

.imageDisabled {
	opacity: 0.5;
}
