.tabs__header {
	width: 100%;
	height: 48px;
	display: flex;
}

.tabs__header button {
	flex: 1;
	cursor: pointer;
	padding: 13px 36px;
	font: var(--gilroy-Bold-16-19);
}

