.preLoader {
	background-image: url('images/loading_circle.svg');
	background-size: 70%;
	background-position: center;
	background-repeat: no-repeat;
	width: 70%;
	height: 70%;
	object-fit: cover;
	animation: loader 1s linear infinite;
}
