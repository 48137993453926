/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 03/03/2021, 16:02
 */

.Page {
	height: 100%;
	padding: 30px;
	background-color: var(--color-white100);
}

.Banner {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	min-height: 136px;
	padding: 24px 74px;
	background-color: var(--color-light-green);
}

.Banner :global .header {
	margin-bottom: 8px;
}

.Banner :global .body {
	max-width: calc(100% - 234px);
}

.Banner :global .upload-btn {
	width: 214px;
}

.CloseButton {
	position: absolute;
	top: 10px;
	right: 10px;
}

.Content {
}

.Example {
}

.File {
	font-size: 18px;
	display: flex;
	align-items: center;
	width: fit-content;
	max-width: 80%;
	padding: 15px 0;
}

.File i {
	font-size: 24px;
	margin-right: 10px;
}

.File a {
	display: flex;
	align-items: flex-start;
	color: var(--color-dark-grey);
}

.File a img {
	margin-right: 5px;
}

.File:hover span {
	text-decoration: line-through;
}

.Page {
	display: grid;
	width: 100%;
	height: 100%;
	grid-template-areas: 'wiz' 'container';
	grid-template-rows: 60px auto;
	grid-template-columns: 100%;
}

.Wizard {
	grid-area: wiz;
}

.Container {
	grid-area: container;
}

.Right {
	padding-top: 80px;
	padding-bottom: 50px;
	background-color: var(--color-light-green);
}

.Right label {
	font: var(--gilroy-Medium-24);
}

.Right > div {
	padding-left: 70px;
}

.Right ul {
	margin-left: 32px;
}

.Right li + li {
	margin-top: 16px;
}

.Left {
	overflow: auto;
}

.Form {
	min-height: 100%;
	padding-top: 80px;
	padding-right: 40px;
	padding-bottom: 50px;
}

.Header {
	margin-bottom: 24px;
}

.FormLine {
	margin-bottom: 32px;
}

.Subheader {
	margin-bottom: 32px;
}

.Controls {
	margin-top: 80px;
	max-width: 416px;
}

.Radio {
	margin-top: 32px;
	margin-bottom: 24px;
}

.Fields {
	/*display: flex;*/
	/*flex-direction: column;*/
	/*flex-wrap: wrap;*/
	/*align-items: flex-start;*/
	/*width: 100%;*/
	/*height: 75vh;*/
}

.Field {
}

.Fields > div {
	/*max-width: 50%;*/
}

.Fields input {
	/*width: 100%;*/
}

.Country {
	/*display: flex;*/
}

.Country div:first-child {
	/*width: 48%;*/
	/*margin-right: 30px;*/
}

.Country div:last-child {
	/*width: 20%;*/
}

@media screen and (min-width: 320px) {
}

@media screen and (min-width: 768px) {
}

@media screen and (min-width: 1440px) {
	.Form {
		/*margin: 0;*/
	}

	.FieldsCol {
		/*padding-right: 30px;*/
	}

	.Controls {
	}
}

@media screen and (min-width: 1920px) {
}

@media screen and (min-width: 3840px) {
}
