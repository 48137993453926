.bannerWrapper {
  display: flex;
  align-items: center;
  background-color: var(--color-light-green);
  padding: 24px;
  /* margin-bottom: 40px; */
}

.bannerText {
flex-grow: 1;
}

.bannerTitle {
  font: var(--gilroy-Medium-24);
  color: var(--color-dark-grey);
  margin-bottom: 8px;
}

.bannerContent {
  font: var(--gilroy-Medium-16-150);
  color: var(--color-dark-grey);
}

.bannerBtn {
  margin-left: 24px;
  min-width: 282px;
  height: 60px;
  padding-top: 18px;
  padding-bottom: 18px;
  text-align: center;
  background-color: var(--color-white);
  font: var(--gilroy-Medium-18-140);
  color: var(--color-dark-grey);
  cursor: pointer;
  white-space: nowrap;
}