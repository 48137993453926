.page {
	display: flex;
	min-height: 85vh;
	padding-top: 48px;
}

.main {
	padding: 40px 24px 40px 40px;
	flex-grow: 1;
}

.title {
	display: inline-block;
	font: var(--gilroy-Medium-24-140p);
	letter-spacing: 1px;
	text-transform: lowercase;
}

.body {
	margin-top: 24px;
	display: flex;
	align-items: flex-start;
}

.loaderWrapper {
	position: absolute;
	bottom: 50%;
	right: 42%;
}

.DropZone__wrapper {
	width: 240px;
	height: 240px;
	position: relative;
}

.DropZone {
	width: 240px;
	height: 240px;
	margin: 0;
	padding: 0;
	border: none;
	background-image: url("data:image/svg+xml;utf8,<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' style='fill: none; stroke: black; stroke-width: 1; stroke-dasharray: 8 8'/></svg>");
}

.DropZone span {
	border-bottom: none;
}

.DropZone > div {
	width: 100%;
}

.DropZone > div > div {
	margin: 0 15px 0 19px;
	display: flex;
	flex-direction: column;
	text-align: center;
	font: var(--gilroy-Medium-18-22);
}

.DropZone > div > div > img {
	width: 54.47px;
	margin-bottom: 33.06px;
	margin-right: 0;
}

.DropZone span {
	display: block;
}

.preLoader {
	background-image: url('images/loading_circle.svg');
	background-size: 70%;
	background-position: center;
	background-repeat: no-repeat;
	width: 70%;
	height: 70%;
	object-fit: cover;
	animation: loader 1s linear infinite;
}

.Preview img.loader {
	width: 0;
	height: 0;
}

.Preview {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	right: 0;
	width: 240px;
	height: 240px;
	border: 1px solid var(--color-black);
}

.Preview img {
	width: 100%;
	object-fit: cover;
}

@keyframes loader {
	100% {
		transform: rotate(360deg);
	}
}

.delete {
	margin-left: 24px;
	text-decoration: underline;
	text-transform: lowercase;
	font: var(--gilroy-Medium-18-22);
	cursor: pointer;
}

.button__wrapper {
	margin-top: 76px;
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.prev {
	display: inline-block;
	padding: 18px 65px 18.43px 0px;
	font: var(--gilroy-Medium-18-22);
	text-decoration: underline;
	cursor: pointer;
}

.next {
	width: 328px;
	height: 60px;
	background: var(--color-dark-grey);
	border: none;
	color: var(--color-white);
	letter-spacing: 1px;
	font: var(--gilroy-Medium-18-140);
	cursor: pointer;
}

.next.disabled {
	background: var(--color-grey50);
	cursor: inherit;
}

.helper {
	position: relative;
	width: 368px;
	padding: 40px;
	background: var(--color-light-green);
	min-height: calc(100vh - 152px);
}

.helper__wrapper {
	position: sticky;
	top: 112px;
}

.helper__title {
	display: inline-block;
	font: var(--gilroy-Medium-24-140p);
	margin-bottom: 16px;
}

.helper__main {
	font: var(--gilroy-Medium-16-140);
}

.date :global(.react-calendar) {
	width: 100%;
}

.date :global(.react-calendar__month-view__days) {
	display: grid !important;
	grid-template-columns: repeat(7, 1fr);
	padding-left: 9px;
}

.date :global(.react-calendar__tile) {
	margin: 0px;
}

.input {
	position: relative;
}

.errorHelper {
	color: var(--color-red);
	font: var(--gilroy-Medium-12);
	font-size: smaller;
	padding: 10px 5px;
}
