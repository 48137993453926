.RepertoireMainInfoTable__bodyTitle {
	margin: 16px 0px 15px 15px;
	display: block;
	font: var(--gilroy-Medium-18);
}

.RepertoireMainInfoTable__body {
	font: var(--gilroy-Medium-16-19);
}

.RepertoireMainInfoTable__body li {
	background-color: var(--color-white);
	margin: 2px 0px;
	border: 3px solid white;
	transition: 0.2s;
}

.RepertoireMainInfoTable__body li:hover {
	border: 3px solid var(--color-light-green);
}

.RepertoireMainInfoTable__body ul {
	background-color: var(--color-white100);
	padding: 0;
}

.row_data {
	margin: 2px 0px !important;
	font: var(--gilroy-Medium-16-24);
	padding: 11px 0px;
	padding-top: 14px;
}

.row_title {
	margin: 0px !important;
	font: var(--gilroy-Medium-16-24);
	padding: 0px 0px 6px;
}

.row_titles {
	margin: 0px !important;
	font: var(--gilroy-Medium-16);
	padding: 0px 0px 6px;
}
