.page {
	min-height: 85vh;
	padding-top: 48px;
}

.main {
	padding: 0px 8px 0 40px;
}

.main__notForm {
	width: 416px;
	padding-right: 40px;
}

.main__wrapper {
	display: flex;
	align-items: flex-start;
}

.main__wrapper_whithoutForm {
	display: block;
}

.info button {
	display: flex;
	width: 24px;
	margin-left: 0;
}

.title__info {
	display: flex;
	align-items: center;
	height: 72px;
	text-transform: lowercase;
}

.title__info.info button {
	margin-bottom: 4px;
}

.title {
	display: flex;
	flex-direction: column;
	margin-right: 16px;
	font: var(--gilroy-Medium-24-140p);
}

.table__item {
	display: flex;
	position: relative;
	padding: 0 8px 0 50px;
	align-items: flex-start;
	background-color: var(--color-white);
	border: 1px solid transparent;
	font: var(--gilroy-Medium-14-17);
}

.table__item.table__itemSel {
	border-color: var(--color-green);
}

.table__item:not(:last-child) {
	margin-bottom: 2px;
}

.table__item > *:nth-child(2) {
	width: 15px;
	margin-right: 16px;
	padding: 22px 0 21px;
	text-align: right;
}

.table__item > *:nth-child(3) {
	width: 11.172vw;
	flex-grow: 1;
	margin-right: 16px;
	padding: 22px 0 21px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.table__item > *:nth-child(4) {
	margin-right: 8px;
	padding: 19px 0;
	margin-left: auto;
	width: 24px;
	cursor: pointer;
}

.table__item > *:nth-child(5) {
	padding: 19px 0;
}

.tableItem__sel {
	position: absolute;
	left: 16px;
	top: 50%;
	fill: var(--color-light-green);
	transform: translateY(-50%);
}

.tableItem__inselected {
	width: 24px;
	height: 24px;
	border: 2px solid var(--color-light-grey);
	border-radius: 50%;
	background: transparent;
}

.main__notForm .table__item {
	padding: 0 16px 0 66px;
}

.main__notForm .table__item > *:nth-child(2) {
	margin-right: 24px;
}

.toogleMenu {
	cursor: pointer;
}

.button__wrapper {
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding: 48px 40px 80px;
}

.prev {
	display: inline-block;
	padding: 18px 65px 18.43px 0px;
	font: var(--gilroy-Medium-18-22);
	text-decoration: underline;
	cursor: pointer;
}

.next {
	max-width: 328px;
	width: 25.625vw;
	height: 60px;
	background: var(--color-dark-grey);
	border: none;
	color: var(--color-white);
	letter-spacing: 1px;
	font: var(--gilroy-Medium-18-140);
	cursor: pointer;
}

.next.disabled {
	background: var(--color-grey50);
	cursor: inherit;
}
