.RepertoireSongInfo__mainInfoTable {
	letter-spacing: 1px;
	margin-left: 40px;
	margin-right: 2vw;
	margin-bottom: 10px;
}

.banner__wrapper {
	margin: 30px 2vw 30px 40px;
}

.banner__wrapper a {
	text-decoration: underline;
}

.banner__wrapper a,
.banner__wrapper a:visited {
	color: unset;
}

.helper {
	position: relative;
	margin: 30px 2vw 30px 40px;
	padding: 24px;

	background: var(--color-light-green);
	font: var(--gilroy-Medium-16-140);
	letter-spacing: 1px;
}

.row_header {
	padding: 0px !important;
	margin: 0px 0px 10px !important;
	flex-wrap: nowrap !important;
	color: var(--color-grey);
	font: var(--gilroy-Medium-14);
	text-transform: lowercase;
}

.RepertoireMainInfoTable__body {
	font: var(--gilroy-Medium-16-19);
}

.RepertoireMainInfoTable__body li {
	background-color: var(--color-white);
	margin: 4px 0px;
	border: 3px solid white;
	transition: 0.2s;
}

.RepertoireMainInfoTable__body ul {
	background-color: var(--color-white100);
	padding: 0;
}

.row_data {
	display: block !important;
	margin: 2px 0px !important;
	font: var(--gilroy-Medium-18);
	padding: 11px 0px 9px 0px;
}

.row_title {
	margin: 0px !important;
	font: var(--gilroy-Medium-16);
}

.row_title_number {
	display: flex;
}

.sectionTitleWrapper {
	display: flex;
	margin: 24px 40px;
	justify-content: space-between;
}

.sectionTitle {
	font: var(--gilroy-Medium-24);
}

.modalRecipientsWrapper {
	position: relative;
}

.modalRecipientsAll {
	position: absolute;
	top: 100%;
	left: 0;
	background-color: var(--color-dark-grey);
	color: var(--color-white);
}

.modalRecipientsItemWrapper {
	display: flex;
}

.modalRecipientsItem {
	margin-right: 20px;
}

.action {
	display: flex;
	cursor: pointer;
	align-items: center;
}

.actionTitle {
	text-decoration: underline;
	margin-left: 8px;
}

.noData {
	margin-top: 150px;
	align-items: center;
	text-align: center;
	font: var(--gilroy-Medium-24);
}

.shell {
	display: flex;
	align-items: center;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.ellipsisContainer {
	width: 100%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.rejected {
	color: var(--color-red);
}

.heading {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden !important;
	overflow-wrap: break-word;
}
