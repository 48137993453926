/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 12/02/2021, 11:27
 */


.Page {
	display: grid;
	width: 100%;
	height: 100%;
	grid-template-areas: "wiz" "form";
	grid-template-rows: 60px auto;
	grid-template-columns: auto;
}

.Wizard {
	grid-area: wiz;
}

.Form {
	/*margin: 0 30px;*/
	/* overflow: auto; */
	height: 100%;
	padding-top: 50px;
	padding-bottom: 50px;
	grid-area: form;
}

.Header {
	margin-bottom: 43px;
}

.Header :global .sub {
	margin-top: 10px;
}

.List {
	margin: 32px 0 0;
}

.Controls {
	margin-top: 50px;
}

.List li {
	margin-bottom: 20px;
}

.List li :global .status {
	color: var(--color-light-green);

}