/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/11/2020, 15:02
 */

.Form button {
	margin-top: 20px;
}

.Title {
	font: var(--form-title-font);
}

.Fields {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	max-height: 60vh;
}

.Controls {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	max-height: 10vh;
}

.Controls a {
	height: 60px;
	margin-top: 20px;
}
