.page {
	display: flex;
	min-height: 85vh;
	padding-top: 48px;
}

.main {
	padding: 40px 24px 40px 40px;
	flex-grow: 1;
}

.form_container {
	padding: 41px 64px 80px;
	overflow-y: scroll;
	width: 100%;
}

.input {
	position: relative;
}

.Helper {
	position: absolute;
	bottom: -18px;
	left: 0px;
	color: var(--color-red);
	font: var(--gilroy-Medium-12-12);
	padding: 0 4px;
}

.btn_next {
	border: none;
	background-color: var(--color-btn-grey);
	color: var(--color-white100);
	width: 328px;
	height: 60px;
	font: var(--gilroy-Medium-18-140);
	letter-spacing: 1px;
	margin-left: auto;
	margin-top: 48px;
	cursor: pointer;
}

.title {
	font: var(--gilroy-Medium-24-140p);
	letter-spacing: 1px;
	color: var(--color-dark-grey);
	text-transform: lowercase;
}

.required_text {
	font: var(--gilroy-Medium-14-20);
	letter-spacing: 1px;
	color: var(--color-dark-grey);
	margin-bottom: 24px;
}

.list {
	display: grid;
	grid-template-columns: repeat(2, calc(50% - 12px));
	grid-column-gap: 24px;
	grid-row-gap: 24px;
}

.list_item > div {
	margin: 0;
	width: 100%;
}

.list_item {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

.description {
	padding: 6px 4px 0px;
	font: var(--gilroy-Medium-12-20);
	letter-spacing: 0.4px;
	color: rgba(0, 0, 0, 0.54);
}

.helper {
	position: relative;
	width: 368px;
	padding: 40px;
	background: var(--color-light-green);
	min-height: calc(100vh - 153px);
}

.helper__wrapper {
	position: sticky;
	top: 112px;
}

.helper__title {
	display: inline-block;
	font: var(--gilroy-Medium-24-140p);
	margin-bottom: 16px;
}

.helper__main {
	font: var(--gilroy-Medium-16-140);
}

.button__wrapper {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.input_leftCol {
	position: relative;
	min-width: 412px;
}
