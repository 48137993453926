/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 07/05/2020, 19:38
 */

.Header {
	max-width: 415px;
}

.Label {
	/*margin-bottom: 85px;*/
	/*font: var(--gilroy-Medium-40-140);*/
	max-width: 415px;
}

.Button {
	max-width: 415px;
	margin-top: 50px;
}


@media screen  and (min-width: 320px) {

}

@media screen  and (min-width: 768px) {

}

@media screen  and (min-width: 1440px) {

}

@media screen  and (min-width: 1920px) {

}

@media screen  and (min-width: 3840px) {

}

