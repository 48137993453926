.btnContainer {
	position: relative;
}

.download {
	position: relative;
	margin-left: 25px;
	background-position: center;
	background-size: auto;
	background-repeat: no-repeat;
  flex-shrink: 0;
}

.button {
	display: flex;
	flex-direction: row;
	align-items: center;
	border: none;
	background-color: transparent;
	font: var(--gilroy-Medium-16-140);
}

.downloadBtnTitle {
	margin-left: 8px;
	border-bottom: 1px solid var(--color-dark-grey);
  flex-shrink: 0;
}

.disabled {
	composes: download;
	pointer-events: none;
	opacity: 0.5;
}

.enabled {
	composes: download;
	cursor: pointer;
}

.downloadStatus {
	position: absolute;
	text-align: right;
	opacity: 1 !important;
	width: 110px;
	bottom: -25px;
	right: 0px;
}

.menuContainer {
	position: absolute;
	right: 0;
	z-index: 11;
	padding-top: 8px;
	width: 254px;
	height: 100px;
}

.menuContainer ul {
	width: 100%;
	font: var(--gilroy-Medium-16-140);
	color: var(--color-dark-grey);
}

.listItem {
	height: 50px;
	padding: 13px 20px 13px 20px;
	background: var(--color-light-grey);
}

.listItem:hover {
	cursor: pointer;
	color: var(--color-white);
	background: var(--color-dark-grey100);
}
