.RadioButton {
	font: var(--gilroy-Medium-16-140);
	display: flex;
	align-items: center;
	box-sizing: border-box;
	max-width: 416px;
	margin-bottom: 10px;
	user-select: none;
	color: inherit;
	border: none;
	outline: none;
	background-color: transparent;
}

.RadioButton input[type='radio'] {
	display: none;
}

.RadioButton > label {
	position: relative;
	display: flex;
	align-items: center;
	max-width: 416px;
	min-height: 24px;
	padding-left: 34px;
	cursor: pointer;
}

.RadioButton > label::before,
.RadioButton > label::after {
	position: absolute;
	display: inline-block;
	content: '';
}

.RadioButton > label::before {
	top: 0;
	left: 0;
	width: 24px;
	height: 24px;
	border: 1px solid var(--color-dark-grey);
	border-radius: 50%;
	background-color: transparent;
}

.RadioButton > label::after {
	top: 5px;
	left: 5px;
	width: 14px;
	height: 14px;
	border-radius: 50%;
	background-color: #000;
}

.RadioButton input[type='radio'] + label::after {
	content: none;
}

.RadioButton input[type='radio']:checked + label::before {
	background-color: transparent;
}

.RadioButton input[type='radio']:checked + label::after {
	content: '';
	color: var(--color-white);
}

.RadioButton input[type='radio']:focus + label::before {
	outline: rgb(59, 153, 252) auto 5px;
}

.RadioButton:global.disabled > label {
	cursor: not-allowed;
	color: var(--color-grey);
}
