.loader {
	margin-top: 30px;
}

.helpInfo {
	margin-bottom: 32px;
}

.bankData {
	margin-top: 32px;
}

.Form {
	height: 100%;
	padding: 30px 64px 80px;
	grid-area: form;
	background-color: var(--color-white);
	box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
		0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
	border-radius: 4px;
}

.livingPlace,
.pasportIssuer {
	max-width: 100%;
}

.middleRow {
	padding: 25px 0px;
}

.save_btn {
	margin-top: 20px;
}

.RadioBtn {
	padding-top: 17px;
}

.subTitle {
	margin-top: 10px;
}

.status {
	width: 100%;
	text-align: right;
	font: var(--gilroy-Bold-16-140);
}

.editBtn {
	text-align: right;
	cursor: pointer;
	margin-right: 5px !important;
}

.editBtn > button {
	display: flex;
	height: 20px;
	border: none;
	background-color: unset;
	cursor: pointer;
	padding-top: 20px;
}

.editText {
	display: block;
	margin-left: 8px;
	padding-top: -12px;
	font: var(--gilroy-Medium-18);
}
