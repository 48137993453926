.text_editor {
	margin-top: 14px;
	border: 0 solid transparent !important;
	cursor: text !important;
	overflow: scroll;
	font-family: Gilroy-Medium, serif !important;
	word-break: break-all;
	min-height: 160px;
	max-height: 160px;
}

