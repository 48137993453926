.title {
	font: var(--gilroy-Medium-40);
}

@media screen and (max-width: 1302px) {
	.title {
		display: inline-block;
		width: 850px;
	}
}

@media screen and (max-width: 1250px) {
	.title {
		display: inline;
	}
}
