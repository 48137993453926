.recaptcha-container {
	margin: 24px 0 0;
}

.recaptcha-privacy {
	margin: 12px 0;
	font: var(--gilroy-Medium-12);
	color: var(--color-dark-grey);
	opacity: 0.4;
}

.Button {
	font: var(--gilroy-Medium-14);
	padding: 0;
	text-decoration: underline;
	border: none;
	outline: none;
	background-color: transparent;
}

.form_wrapper {
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	padding: 0 16px;
}

.title {
	padding-top: 40px;
}

.Form input,
.Form button,
.Checkbox {
	width: 100%;
	max-width: 100%;
}

.Checkbox label div {
	font: var(--gilroy-Medium-16);
}

.Checkbox a {
	text-decoration: none;
	font-weight: 700;
	cursor: pointer;
	color: inherit;
}

.register_header {
	margin-bottom: 8px;
	font: var(--gilroy-Medium-28);
	line-height: 36px;
}

.register_subheader {
	margin-bottom: 40px;
	font: var(--gilroy-Medium-18);
	line-height: 22px;
	letter-spacing: 0.72px;
}

.Forgot {
	margin-top: 20px;
	text-align: center;
}

.Form button {
	margin-top: 44px;
}

.login_wrapper {
	margin-top: 40px;
	padding: 24px;
	background-color: var(--color-light-green);
}

.login_title_header {
	margin-bottom: 8px;
	line-height: 28px;
}

.login_title_body {
	margin-bottom: 24px;
	line-height: 22px;
	letter-spacing: 0.72px;
}

.login_button_wrapper {
	margin-bottom: 0px;
}

.phone > div:nth-child(2) > span:last-child {
	bottom: 0px !important;
	height: 2px !important;
}

.phone > div:nth-child(2) > span:last-child:before {
	bottom: 0px !important;
	height: 2px !important;
	transition: 0s ease all;
	background: var(--color-dark-grey) !important;
}

.phone > div:nth-child(2) > span:last-child:after {
	bottom: 0px !important;
	height: 2px !important;
	transition: 0s ease all;
	background: var(--color-dark-grey) !important;
}

.Register_wrapper {
	width: 100%;
	padding-top: 48px;
	padding-bottom: 72px;
}

.Register_Input {
	margin-bottom: 28px !important;
}

.Register_Phone_Input {
	margin-bottom: 8px !important;
}

.Register_Dropdown_Input {
	margin-bottom: 4px !important;
}

.emailContainer {
	position: relative;
}

.preLoader {
	position: absolute;
	top: 0;
	right: 0;
	width: 30px;
	height: 30px;
	margin: 0 auto;
	animation: loader 1s linear infinite;
}

.captchaContainer {
	position: relative;
}

.helper {
	position: absolute;
	bottom: 0px;
	color: var(--color-red);
	font: var(--gilroy-Medium-12);
}

.captchaErrorHelper {
	position: absolute;
	color: var(--color-red);
	font: var(--gilroy-Medium-12);
}

.phoneСonfirmation {
	position: absolute;
	left: -10000px;
}

@keyframes loader {
	100% {
		transform: rotate(360deg);
		transform-origin: center;
	}
}

@media screen and (max-width: 767px) {
	.login_button {
		max-width: 100%;
	}
}

@media screen and (min-width: 768px) {
	.register_header {
		font: var(--gilroy-Medium-40);
		margin-bottom: 16px;
	}

	.register_subheader {
		font: var(--gilroy-Medium-24);
		margin-bottom: 45px;
	}

	.title {
		margin-top: 0;
		padding: 0;
	}

	.login_wrapper {
		margin-top: 20px;
	}

	.login_title_header {
		margin-bottom: 20px;
	}

	.login_title_body {
		margin-bottom: 20px;
	}

	.login_button_wrapper {
		margin-bottom: 20px;
	}
}
