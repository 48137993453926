.wrapperTrackPage {
  padding: 40px 30px;
}

.contentTrackPage {
  background-color: #fff;
}

.trackHeading {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  padding: 12px 16px 0;
  gap: 8px;
}

.trackTitle {
  margin: 0;  
  margin-bottom: 2px;
  font: var(--gilroy-Medium-24);
  color: var(--color-dark-grey);
}

.trackPerformer {
  margin: 0;
  font: var(--gilroy-Medium-14);
  color: #888888;
}

.trackMainPhoto {
  width: 48px;
  height: 48px;
  object-fit: cover;
  object-position: center;
}

.headerInfo {
	display: grid;
	grid-template-columns: 8fr 2fr 2fr 2fr;
	align-items: center;
	gap: 12px;
	font: var(--gilroy-Medium-14);
	color: var(--color-grey);
	margin-bottom: 12px;
}

.spotify {
  grid-template-columns: 8fr 2fr  2fr 2fr;
}

.apple {
  grid-template-columns: 8fr 2fr 2fr;
}

.headerInfoName {
	padding-left: 35px;
}

.headerInfoLink {
	padding-right: 8px;
	justify-self: end;
}
.headerInfoLinkEn {
	padding-right: 22px;
	justify-self: end;
}


.wrapperEmpty {
  margin: 0 14px 14px;
  background-color: var(--color-white100);
}

.listPlaylists {
  min-height: 633px;
}

.wrapperFilters {
  display: flex;
  margin-bottom: 24px;
  padding: 0 12px;
  gap: 24px;
}

.filterCountries {
  width: 100%;
  min-width: 290px;
  max-width: 18vw;
}

.loader {
	margin-top: 30px;
}

.skeletonWrapper {
  height: 548px;
	width: 100%;
	padding: 0 14px 14px;
}