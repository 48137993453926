.Wizard {
	position: fixed;
	padding: 0 40px;
	top: 64px;
	width: 100%;
	height: 48px;
	padding: 0 40px;
	background: var(--color-white);
	z-index: 1;
	border-radius: 2px;
	visibility: hidden;
}

.Wizard * {
	font: var(--gilroy-Bold-16-19);
	letter-spacing: 1px;
}

.Wizard > div {
	margin: 0;
	padding: 0;
	display: inline-block;
	width: auto;
}

@media screen and (min-width: 768px) {
	.Wizard {
		visibility: visible;
	}
}

@media screen and (min-width: 1024px) {
	.Wizard {
		width: calc(100vw - 162px);
	}
}
