/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/11/2020, 15:02
 */

.Logo {
	position: fixed;
	/*margin-right: auto;*/
	z-index: 99999;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	height: 51px;
	margin-bottom: auto;
	padding-left: 16px;
	background-color: var(--color-logo);
	grid-area: logo;
}

.Commit {
	color: var(--color-grey);
}

.Image {
	width: 70px;
	height: 23px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

@media screen and (min-width: 320px) {
}

@media screen and (min-width: 768px) {
	.Image {
		width: 108px;
		height: 33px;
	}

	.Logo {
		height: 80px;
		padding-left: 30px;
	}
}

@media screen and (min-width: 768px) {
	.Logo {
		padding-left: 20px;
	}
}

@media screen and (min-width: 1024px) {
	.Logo {
			position: unset;
			padding-left: 0;
			height: 101%;
			width: 162px;
		}
}

@media screen and (min-width: 1920px) {
}

@media screen and (min-width: 3840px) {
	.Image {
		width: 216px;
		height: 66px;
	}
}

/*@media only screen and (max-width: 320px) {*/
/*    .Image {*/
/*        */
/*    }*/
/*}*/
