/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 02/10/2020, 16:10
 */

.Page {
	display: grid;
	width: 100%;
	height: 100%;
	grid-template-areas: 'wiz' 'form';
	grid-template-rows: 60px auto;
	grid-template-columns: auto;
}

.Wizard {
	grid-area: wiz;
}

.Form {
	overflow: auto;
	overflow-x: hidden;
	height: 100%;
	padding-top: 50px;
	padding-bottom: 50px;
	grid-area: form;
}

.Header {
	margin-bottom: 64px;
}

.Header :global .sub {
	margin-top: 10px;
}

.Controls {
	/* margin-top: 50px; */
}

.Roles {
	margin-bottom: 64px;
}

.Roles ul {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}

.Roles li {
	margin-right: 40px;
	/* margin-bottom: 20px; */
}

.BulletSelected > div {
	width: 10px;
	height: 10px;
	/* margin-right: 10px; */
	background-color: var(--color-grey);
}

.BulletSelected > span > div {
	color: var(--color-grey) !important;
}

.Field {
	margin-top: 20px;
}

.Field :global .header {
	margin-bottom: 24px;
}

.Field :global .input {
	max-width: 312px;
}

.errMessage {
	margin-top: 5px;
	color: red;
}

.infoButton {
	align-items: normal;
	margin-bottom: 24px;
}

.infoButtonPeriod {
}

.periodWrapper {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}

.periodWrapper > div + div {
	padding-top: 10px;
}
