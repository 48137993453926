.filter__container {
	margin-top: 24px;
	padding: 24px 30px;
	background: var(--color-light-grey);
}

.filter__button {
	font: var(--gilroy-Medium-18);
	margin-bottom: 0;
	margin-top: 24px;
	text-align: center;
	text-decoration: underline;
}

.filterButton__active {
	margin-top: -8px;
}

.filter__hide {
	visibility: hidden;
	height: 0;
	opacity: 0;
	transition: 0.2s;
}

.filter__active {
	visibility: visible;
	height: auto;
	opacity: 1;
}

.filter__hide > * {
	margin-bottom: 16px;
}

.filter__hide > * > span:first-child {
	padding-left: 8px;
}

.header__info {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-column-gap: 30px;
	grid-row-gap: 24px;
}

.headerInfo__item {
	display: flex;
	flex-direction: column;
	font: var(--gilroy-Medium-18);
}

.headerInfoItem__title,
.headerInfoItemFilter__title {
	color: var(--color-grey100);
	font: var(--gilroy-Medium-14);
}

.headerInfo__item > span:first-child {
	margin-bottom: 6px;
}

.headerInfo__item > .headerInfoItemFilter__title {
	margin-top: 10px;
	margin-bottom: -15px !important;
}

.headerInfo__item input {
	font: var(--gilroy-Medium-18) !important;
}

.headerInfoItemFilter__title > span:first-child {
	margin-bottom: 0;
}

.headerInfoItemFilter__countryTitle {
	color: var(--color-grey100);
	font: var(--gilroy-Medium-14);
}
