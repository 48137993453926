.container {
	margin-top: 24px;
}

.title {
	letter-spacing: 1px;
	font: var(--gilroy-Medium-24-140p);
}

.lyricsContainer {
	margin-top: 16px;
	padding: 14px 24px;
	background: var(--color-white);
}

.lyrics {
	position: relative;
	width: 100%;
	color: var(--color-dark-grey);
	font: var(--gilroy-Medium-16-140);
	overflow: hidden;
}

.lyricsText {
	width: 398px;
	word-break: break-word;
}

.ellipsis {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 13;
	overflow: hidden;
	text-overflow: ellipsis;
}

.showMoreBtn,
.hideBtn {
	margin-bottom: 18px;
	color: var(--color-dark-grey);
	font: var(--gilroy-Medium-18-140);
	border: none;
	border-bottom: 1px solid var(--color-dark-grey);
	background: transparent;
	cursor: pointer;
}

.showMoreBtn {
	margin-top: 4px;
}

.hideBtn {
	margin-top: 18px;
}
