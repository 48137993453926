.card {
	display: flex;
	flex-direction: column;
	width: 260px;
}

.image {
	width: 260px;
	height: 260px;
	margin-bottom: 12px;
	background: linear-gradient(
			100deg,
			rgba(255, 255, 255, 0.04) 30.37%,
			#fff 56.5%,
			rgba(255, 255, 255, 0) 91.46%
		),
		var(--color-light-grey);
		animation: fadeIn 1s ease-in-out infinite alternate;
}

.album {
	width: 105px;
	height: 32px;
	margin-bottom: 8px;
	background: var(--color-light-grey);
}

.title {
	width: 252px;
	height: 48px;
	margin-bottom: 8px;
	background: var(--color-light-grey);
}

.streams {
	width: 137px;
	height: 24px;
	background: var(--color-light-grey);
}

@keyframes fadeIn {
	from {
		opacity: 0.5;
	}
	to {
		opacity: 1;
	}
}
