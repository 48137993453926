.performerItem {
	position: relative;
	display: flex;
	align-items: baseline;
}

.autosuggestItem {
	width: 386px;
}

.autosuggestButton {
	border: none;
	background: transparent;
	margin-left: 21px;
	cursor: pointer;
}

.helper {
	position: absolute;
	bottom: 0px;
	left: 0px;
	color: var(--color-red);
	font: var(--gilroy-Medium-12-12);
	padding: 0 4px;
}

.performerItemForSafari {
	align-items: flex-start;
}

.autosuggestButtonForSafari {
	align-items: flex-start;
	margin-top: 24px;
}

@media screen and (min-width: 1100px) {
	.helper {
		bottom: -8px;
	}
}

@media screen and (min-width: 1489px) {
	.helper {
		bottom: 0px;
	}
}
