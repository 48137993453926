.noData {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-top: 223px;
}

.noData__title {
	width: 100%;
	text-align: center;
	font: var(--gilroy-Medium-32);
	margin-bottom: 8px;
}

.noData__text {
	width: 100%;
	text-align: center;
	font: var(--gilroy-Medium-18-140);
	margin-bottom: 32px;
}

.noData__search {
	font: var(--gilroy-Medium-24);
}
