.form__section {
	display: grid;
	grid-template-columns: repeat(2, calc(50% - 12px));
	grid-column-gap: 24px;
	grid-row-gap: 24px;
}

.third__section {
	display: flex;
	width: 328px;
}

.input {
	position: relative;
}

.helper {
	position: absolute;
	bottom: -32px;
	left: 0px;
	color: var(--color-red);
	font: var(--gilroy-Medium-12);
	font-size: smaller;
	padding: 0 5px;
}

.snippetError {
	margin-top: 12px;
	color: var(--color-red);
	font: var(--gilroy-Medium-12);
	font-size: smaller;
	padding: 0 5px;
}
