.container {
	padding: 40px 30px 68px 30px;
}

.title {
	font: var(--gilroy-Medium-24-28);
	margin-bottom: 16px;
}

.radioGroup {
	margin-top: 7px;
	margin-bottom: 31px;
}

.releases {
	min-height: 392px;
}

.disabled label {
	opacity: 0.5;
}

.noData {
	display: flex;
	justify-content: center;
	font: var(--gilroy-Medium-16-24);
}
