.noData {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 50vh;
	justify-content: center;
	font: var(--gilroy-Medium-18);
	line-height: 24px;
	color: var(--color-dark-grey);
	text-transform: lowercase;
}

.noData_title {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 8px;
}

.noData_title h2 {
	font: var(--gilroy-Medium-32);
}

.createArticleBtn {
	margin: 32px 0 0;
	display: flex;
	justify-content: center;
	flex-wrap: nowrap;
	align-items: center;
	padding: 10px 24px;
	text-decoration: none;
	color: var(--color-dark-grey);
	font: var(--gilroy-Medium-16);
	border: none;
	border-radius: 20px;
	background-color: var(--color-light-green);
	cursor: pointer;
}

.createArticleBtnLink {
	display: flex;
	align-items: center;
}

.iconPlus {
	width: 16px;
	margin-right: 8px;
}
