.issue_wrapper {
	list-style: none;
}

.issue_element {
	display: flex;
	/* width: 100vw; */
	justify-content: flex-start;
	align-content: center;
	font: var(--gilroy-Medium-16);
	margin-bottom: 30px;

	/* padding: 5px 0px 5px 10px; */
}

.issue_marker {
	display: flex;
	align-items: center;
	margin-right: 8px;
}
.text_left {
	text-align: left;
	padding-left: 20px !important;
}

ul,
li,
span {
	padding: 0px;
	margin: 0px;
}
