.switcher {
	display: flex;
	align-items: center;
}

.switcher__toggler span {
	opacity: 1;
}

.switcher__title {
	font: var(--gilroy-Medium-18-140);
}
