/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 22/01/2021, 16:03
 */

.Button {
	font: var(--gilroy-Medium-18);
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	max-width: 416px;
	height: 60px;
	min-height: 60px;
	max-height: 60px;
	cursor: pointer;
	user-select: none;
	text-align: center;
	letter-spacing: 1px;
	color: var(--color-dark-grey);
	border: none;
	outline: none;
	background-color: var(--color-white);
}

.Button:hover {
	color: var(--color-dark-grey);
	border: 2px solid var(--color-white);
	background-color: transparent;
}

.Button:global.primary {
	color: var(--color-white);
	background-color: var(--color-btn-grey);
}

.Button:global.primary:hover {
	color: var(--color-dark-grey);
	border: 2px solid var(--color-btn-grey);
	background-color: transparent;
}

.Button:global.icon {
	width: unset;
	height: unset;
	background-color: transparent;
}

.Button:global.icon:hover {
	width: unset;
	height: unset;
	border: none;
	background-color: transparent;
}

.Button:global.disabled {
	cursor: not-allowed;
	color: var(--color-white);
	border: none;
	background-color: var(--color-grey100);
}

.Button:global.link {
	text-decoration: underline;
	color: var(--color-dark-grey);
	border: none;
	background-color: transparent;
}

.Button:global.link:hover {
	text-decoration: line-through;
	background-color: transparent;
}

.Button:global.warn {
	color: var(--color-white);
	border: none;
	background-color: var(--color-red);
}

.Upload input {
	display: none;
}
