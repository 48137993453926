.ModalWrapper {
	position: absolute;
	color: var(--color-light-grey);
	/*opacity: 0.5;*/
	top: 0;
	left: 0;
	width: 100vw;
	min-height: 100vh;

	background-color: var(--color-dark-grey);
	z-index: 9999999;
}

.Image_broma {
	width: 74px;
	height: 22px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}
.Image_partner {
  width: 48px;
  height: 48px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

.ModalHeader {
	display: flex;
	justify-content: space-between;
	padding-top: 21px;
	padding-bottom: 18px;
}

.closeWrapper {
	display: flex;
	align-items: center;
	margin-right: 5px;
}

.CloseTitle {
	margin-right: 24px;
}
