.page {
	display: flex;
}

.main {
	/* padding: 40px 30px 68px 30px; */
	flex-grow: 1;
}

.title {
	display: block;
	font: var(--gilroy-Medium-24-140p);
	letter-spacing: 1px;
	text-transform: lowercase;
}

.body {
	margin-top: 16px;
}

.Input__radio {
	font: var(--gilroy-Medium-16-150);
	letter-spacing: 0.15px;
}

.Input__title {
	color: var(--color-dark-grey);
	margin-bottom: 24px;
}

.Input__title::first-letter {
	text-transform: uppercase;
}

.RadioGroup_date {
	display: grid;
	grid-column-gap: 24px;
	padding: 0;
}

.RadioGroup_date > div {
	padding: 0;
}

.RadioGroup_date > :nth-child(1) {
	margin-bottom: 24px;
}

.RadioGroup_date > div > div > div {
	margin: 0;
}

.RadioGroup_date > div > div > input[type='radio']:checked + label::before {
	border-color: var(--color-dark-grey100);
}

.RadioGroup_date > div > div > label::before {
	border: 2px solid var(--color-grey100);
}
.RadioGroup > div > div > label::before {
	border: 2px solid var(--color-grey100);
}

.RadioGroup_date > div > div > label::after {
	width: 10px;
	height: 10px;
	left: 7px;
	top: 7px;
	border-color: var(--color-dark-grey100);
	background-color: var(--color-dark-grey100);
}

.RadioGroup {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 24px;
	padding: 0;
}

.RadioGroup > div {
	padding: 0;
}

.RadioGroup > div > div {
	margin: 0;
}

.RadioGroup > div > div > input[type='radio']:checked + label::before,
.ReasonsRadioGroup > div > div > input[type='radio']:checked + label::before {
	border-color: var(--color-dark-grey100);
}

.RadioGroup > div > div > label::after,
.ReasonsRadioGroup > div > div > label::after {
	width: 10px;
	height: 10px;
	left: 7px;
	top: 7px;
	border-color: var(--color-dark-grey100);
	background-color: var(--color-dark-grey100);
}

.loader {
	margin-top: 20px;
}

.Outlets {
	margin-top: 24px;
	padding: 20px 30px 48px 24px;
}

.outlets__title {
	font: var(--gilroy-Medium-16-150);
	letter-spacing: 0.15px;
	color: var(--color-dark-grey);
	margin-left: 3px;
	margin-bottom: 20px;
}

.table {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-column-gap: 40px;
	font: var(--gilroy-Medium-16-20);
	color: var(--color-dark-grey);
}

.button__wrapper {
	margin-top: 48px;
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.input {
	position: relative;
	margin-top: 15px;
}

.next {
	width: 400px;
	height: 60px;
	background: var(--color-dark-grey);
	border: none;
	color: var(--color-white);
	letter-spacing: 1px;
	font: var(--gilroy-Medium-18-140);
	cursor: pointer;
}

.HelperBtn {
	position: absolute;
	bottom: 0px;
	left: 34px;
	color: var(--color-red);
	font: var(--gilroy-Medium-12);
	font-size: smaller;
}

.ReasonInput {
	margin-top: -15px !important;
}

.ReasonInput > label {
	max-width: 420px !important;
	color: var(--color-grey) !important;
}

.ReasonsRadioGroup .ReasonInput div {
	margin-top: -10px !important;
	width: 342px;
}

.RadioGroup > div > div > input[type='radio']:checked + label::before {
	border-color: var(--color-light-green);
}

.RadioGroup > div > div > label::after {
	width: 10px;
	height: 10px;
	left: 7px;
	top: 7px;
	border-color: var(--color-light-green);
	background-color: var(--color-light-green);
}

.ReasonsRadioGroup > div:last-child {
	margin-top: 20px;
}

.submitDisabled {
	width: 400px;
	height: 60px;
	background: var(--color-grey);
	border: none;
	color: var(--color-white);
	letter-spacing: 1px;
	font: var(--gilroy-Medium-18-140);
	cursor: not-allowed;
}
