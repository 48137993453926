.TableItemWrapper {
	cursor: pointer;
}

.TableWrapper {
	letter-spacing: 1px;
	margin-left: 40px;
	margin-right: 2vw;
}

.row_header {
	padding: 0px !important;
	margin: 0px 0px 10px !important;
	flex-wrap: nowrap !important;
	color: var(--color-grey100);
	font: var(--gilroy-Medium-18);
}

.TableBody {
	font: var(--gilroy-Medium-16-19);
}

.TableBody li {
	height: 52px;
	background-color: var(--color-white);
	margin: 2px 0px;
	transition: 0.2s;
}

.TableBody ul {
	background-color: var(--color-white100);
	padding: 0;
}

.row_data {
	display: block !important;
	margin-left: 14px !important;
	margin-right: 14px !important;
	font: var(--gilroy-Medium-18);
	padding: 14px 0px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.row_title {
	margin: 0px !important;
	font: var(--gilroy-Medium-18);
	padding: 0px 0px 6px;
}

.item_title,
.item_performer,
.item_type,
.item_status,
.item_date {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.row_title_number {
	display: flex;
}

.search {
	margin-left: 40px;
	margin-right: 40px;
}

.sectionTitleWrapper {
	display: flex;
	height: 36px;
	margin: 24px 40px;
	justify-content: space-between;
}

.sectionTitle {
	font: var(--gilroy-Medium-24);
}

.sectionDeleteBtn {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 148px;
	height: 36px;
	border: none;
	border-radius: 50px;
	background: var(--color-light-green);
	font: var(--gilroy-Medium-16);
	cursor: pointer;
}

.sectionDeleteBtn img {
	margin-right: 4px;
}

.disabled {
	opacity: 0.5;
	cursor: not-allowed;
}

.notifyBtn {
	display: flex;
	align-items: center;
	padding: 10px 24px;
	font: var(--gilroy-Medium-16);
	border: none;
	border-radius: 20px;
	background-color: var(--color-light-green);
}

.DeleteBtn {
	cursor: pointer;
}

.iconPlus {
	width: 16px;
	margin-right: 8px;
}

.modalRecipientsWrapper {
	position: relative;
}

.modalRecipientsAll {
	position: absolute;
	top: 100%;
	left: 0;
	background-color: var(--color-dark-grey);
	color: var(--color-white);
}

.modalRecipientsItemWrapper {
	display: flex;
}

.modalRecipientsItem {
	margin-right: 20px;
}

.itemText {
	display: -webkit-box;
	max-width: 40vw;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.itemText ul {
	list-style: disc !important;
	padding-left: 20px;
}

.itemText ol {
	padding-left: 20px;
}

.mainWrapper {
	margin-bottom: 30px;
}

.noData {
	margin-top: 150px;
	text-align: center;
	font: var(--gilroy-Medium-24);
}

.nota {
	width: 22px;
	height: 22px;
}
