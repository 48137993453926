.statistics_filter__item {
	display: flex;
	align-items: center;
	gap: 8px;
	cursor: pointer;
  margin: 0 0 0 8px;
}

.statistics_filter__item span {
  border-bottom: 1px solid var(--color-dark-grey);
	font: var(--gilroy-Medium-16);
  line-height: 140%;
}
