.CodeInput {
	position: relative;
	max-width: 416px;
	margin-bottom: 25px;
}

.Input {
	height: unset;
	margin-bottom: 0;
}

.Helper {
	font: var(--gilroy-Medium-12);
	font-size: smaller;
	padding: 5px 5px 0;
	color: var(--color-grey);
}

.resendBtn {
	position: absolute;
	top: 0;
	right: 0;
	cursor: pointer;
	background-color: transparent;
	font: var(--gilroy-Medium-18-140);
	border: none;
	border-bottom: 2px solid var(--color-grey);
}
