.wrapper {
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: var(--color-white);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
}

.headerInfo {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
	margin-bottom: 16px;
}

.headerInfoTitle {
	font: var(--gilroy-Medium-24-140p);
}

.buttonInfo {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  color: var(--color-grey);
}

.buttonInfo span {
  font: var(--gilroy-Medium-16-24);
  text-transform: capitalize;
  text-decoration: underline;
}

.wrapperInformer {
  position: absolute;
  top: 30px;
  right: 0;
  padding: 10px;
  min-width: 170px;
  background-color: var(--color-white);
  box-shadow: 0 1px 10px rgba(0,0,0,0.08);
  z-index: 2;
}

.wrapperInformerHeading {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  font: var(--gilroy-Medium-12);
  color: var(--color-grey);
}

.listSources {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  list-style: none;
}

.source {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  font: var(--gilroy-Medium-14);
}

@media (max-width: 1024px) {
  .wrapper {
    min-height: 360px;
  }
}