/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 02/03/2021, 20:39
 */

.Form {
	margin-top: 60px;
	margin-bottom: 100px;
}

.Controls {
	margin-top: 50px;
}

.Checkbox {
	margin-bottom: 40px;
}

.Table {
	margin-top: 40px;
}

.Table :global .title {
	width: 40%;
	min-width: 40%;
	max-width: 40%;
}

.Table :global .value {
	max-width: 500px;
}

.Roles {
	display: flex;
	flex-wrap: wrap;
	max-width: 500px;
}

.Bullet {
	font-size: 12px;
}

.Roles li {
	margin-right: 40px;
	margin-bottom: 0px;
}
