/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 17/05/2020, 23:43
 */


.Form {
	width: 100%;
	max-width: 416px;
}

.Title {
	margin-bottom: 24px;

}

.Button {
	margin-top: 20px;
}


.Fields {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	max-height: 35vh;
}

.Controls {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	max-height: 10vh;
	margin-top: 23px;
}


@media screen  and (min-width: 320px) {

}

@media screen  and (min-width: 768px) {
	.Fields, .Controls {
		/*max-width: 50%;*/
	}
}

@media screen  and (min-width: 1440px) {
}

@media screen  and (min-width: 1920px) {

}

@media screen  and (min-width: 3840px) {

}

