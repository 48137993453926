.graphicsWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 32px;
  gap: 23px;
}

@media (max-width: 1100px) {
  .graphicsWrapper {
    grid-template-columns: 1fr;
  }
}