.search {
	max-width: 504px;
	text-transform: lowercase !important;
}

.search::placeholder {
	text-transform: lowercase !important;
}

@media screen and (min-width: 1280px) {
	.search {
		width: 504px;
	}
}
