.Field {
	font: var(--gilroy-Medium-16);
	position: relative;
	display: flex;
	align-items: flex-end;
	width: 100%;
	margin-top: 10px;
	margin-bottom: 25px;
	padding: 4px;
	text-transform: inherit;
	color: var(--color-grey);
	border: none;
	border-bottom: 2px solid var(--color-grey);
}

.Content {
	display: flex;
	align-items: center;
	width: 100%;
	text-transform: inherit;
}

.Selected {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	margin: 0 20px 0 0;
	padding: 0;
	text-transform: inherit;
}

.SelectedItem {
	display: flex;
	align-items: center;
	margin-right: 8px;
	padding: 4px 8px;
	user-select: none;
	text-transform: inherit;
	color: var(--color-dark-grey);
	background-color: var(--color-light-grey);
}

.SelectedItem :global .remove {
	margin-top: 5px;
	margin-left: 10px;
	padding: 0;
	cursor: pointer;
	border: none;
	outline: none;
	background-color: transparent;
}

.Input {
	font: var(--gilroy-Medium-18);
	min-width: 50px;
	height: 29px;
	padding: 0;
	border: none;
	outline: none;
	background-color: transparent;
}

.Input:global.empty {
	margin-left: 0;
}

.List {
	position: absolute;
	top: calc(100% + 10px);
	left: 0;
	overflow-x: hidden;
	overflow-y: auto;
	width: 400px;
	max-height: 192px;
	text-transform: inherit;
}

/* Hide scrollbar */
.List::-webkit-scrollbar {
	width: 0; /* This will hide the scrollbar */
}

/* Optional: If you want to style the track */
.List::-webkit-scrollbar-track {
	background: transparent;
}

/* Optional: If you want to style the thumb */
.List::-webkit-scrollbar-thumb {
	background: transparent;
}

.Item {
	font: var(--gilroy-Medium-16);
	display: flex;
	align-items: center;
	min-width: 418px;
	min-height: 42px;
	padding: 0 8px;
	cursor: pointer;
	user-select: none;
	text-transform: inherit;
	color: var(--color-dark-grey);
	background-color: var(--color-white);
}

.Item:hover,
.CountryItem:hover {
	background-color: var(--color-light-grey);
}

.CountryItem {
	width: 100%;
	height: 48px;
	padding: 8px 12px;
	cursor: pointer;
	user-select: none;
	color: var(--color-dark-grey);
	background-color: var(--color-white);
	display: grid;
	align-items: center;
	grid-column-gap: 8px;
}

.Placeholder {
	font: var(--gilroy-Medium-18);
	position: absolute;
	width: 100%;
	color: var(--color-grey);
}
