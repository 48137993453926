.page {
	display: flex;
	justify-content: space-between;
	min-height: 85vh;
	padding-top: 48px;
}

.title {
	display: block;
	font: var(--gilroy-Medium-24-140p);
	letter-spacing: 1px;
	margin-bottom: 24px;
}

.main {
	display: flex;
	flex-direction: column;
	padding: 40px 24px 40px 40px;
	flex-grow: 1;
}

.table {
	display: flex;
	flex-direction: column;
}

.table__item {
	padding: 0 24px;
	margin-bottom: 2px;
	width: 100%;
	height: 64px;
	background: var(--color-white);
	display: flex;
	align-items: center;
	font: var(--gilroy-Medium-14-17);
	cursor: grab;
}

.table__item > *:nth-child(1) {
	padding: 24px 0 23px;
	width: 1.641vw;
	margin-right: 24px;
	text-align: left;
	pointer-events: none;
}

.table__item > *:nth-child(2) {
	padding: 24px 0 23px;
	width: 32.344vw;
	margin-right: 16px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	pointer-events: none;
}

.table__item > *:nth-child(3) {
	padding: 24px 0 23px;
	width: 3.516vw;
	margin-right: 16px;
	text-align: right;
	opacity: 0;
	visibility: hidden;
	pointer-events: none;
}

.table__item > *:nth-child(4) {
	padding: 20px 0;
	width: 24px;
	margin-right: 16px;
	cursor: pointer;
}

.table__item > *:nth-child(5) {
	padding: 20px 0;
	margin-right: 16px;
	cursor: pointer;
}

.table__item > *:nth-child(6) {
	padding: 20px 0;
	cursor: pointer;
}

.loader {
	margin: -4px 0 0 auto !important;
	padding: 0 0 !important;
}

.dots {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.dots__active path {
	fill: var(--color-white);
}

.menu {
	position: relative;
	display: flex;
}

.menuWrapper,
.menuWrapperEdit {
	display: flex;
}

.menuWrapper {
	margin: 0 80px 0 auto !important;
}

.menuWrapperEdit {
	margin-left: auto;
	margin-right: 0 !important;
}

.play {
	display: flex;
	align-items: center;
	margin-right: 16px;
	width: 24px;
	background: transparent;
	border: none;
	cursor: pointer;
}

.replace {
	margin-top: 5px;
	margin-right: 16px;
	width: 24px;
	cursor: pointer;
}

.sort {
	cursor: grab;
}

.positionUp {
	margin-right: 5px;
}

.board {
	position: absolute;
	top: 100%;
	right: 0;
	padding: 10px 15px 10px 0;
	background: var(--color-dark-grey);
	color: var(--color-white);
	font: var(--gilroy-Medium-14-140p);
	z-index: 1;
}

.board > div {
	margin-bottom: 20px;
}

.board > div:last-child {
	margin-bottom: 0px;
}

.board__item {
	position: relative;
	padding-left: 33px;
}

.board img {
	position: absolute;
	left: 17px;
	top: 50%;
}

.arrow__up {
	transform: translateY(-50%);
}

.arrow__down {
	transform: translateY(-50%) rotate(180deg);
}

.board span {
	white-space: nowrap;
}

.board__delete {
	padding-left: 16px;
}

.DropZone {
	background: transparent;
	max-width: 100%;
	height: 80px;
	padding: 0;
	font: var(--gilroy-Medium-18);
	border: none;
	background-image: url("data:image/svg+xml;utf8,<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' style='fill: none; stroke: black; stroke-width: 1; stroke-dasharray: 8 8'/></svg>");
	cursor: pointer;
}

.DropZone > div {
	width: 100%;
	text-transform: lowercase;
}

.DropZoneError {
	background: transparent;
	max-width: 100%;
	height: 80px;
	padding: 0;
	font: var(--gilroy-Medium-18);
	border: none;
	background-color: rgb(231, 231, 231);
	background-image: url("data:image/svg+xml;utf8,<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' style='fill: none; stroke: black; stroke-width: 1; stroke-dasharray: 8 8'/></svg>");
}

.DropZoneError > div {
	width: 100%;
}

.DropZoneError ::before {
	content: '';
	width: 26px;
	height: 26px;
	background-image: url('../../../../images/error.svg');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	fill: red;
}

.modal__open {
	font: var(--gilroy-Medium-18);
	display: flex;
	justify-content: center;
	margin-bottom: 42px;
}

.modal__open a {
	text-decoration: underline;
	cursor: pointer;
}

.button__wrapper {
	display: flex;
	justify-content: space-between;
	margin-top: 40px;
	width: 100%;
}

.prev {
	display: inline-block;
	padding: 18px 65px 18.43px 0px;
	font: var(--gilroy-Medium-18-22);
	text-decoration: underline;
	cursor: pointer;
}

.next {
	margin-left: auto;
	display: block;
	max-width: 328px;
	width: 25.625vw;
	height: 60px;
	background: var(--color-btn-grey);
	border: none;
	color: var(--color-white);
	letter-spacing: 1px;
	font: var(--gilroy-Medium-18-140);
	cursor: pointer;
}

.next.disabled {
	background: var(--color-grey50);
	cursor: initial;
}

.helper {
	position: relative;
	width: 368px;
	padding: 40px;
	background: var(--color-light-green);
	min-height: calc(100vh - 152px);
}

.helper__wrapper {
	position: sticky;
	top: 112px;
}

.helper__title {
	display: inline-block;
	font: var(--gilroy-Medium-24-140p);
	margin-bottom: 16px;
}

.helper__main {
	font: var(--gilroy-Medium-16-140);
}

.input {
	position: relative;
}

.disabled {
	opacity: 0.5;
	cursor: not-allowed !important;
}

.ErrorHelper {
	position: absolute;
	left: 0px;
	color: var(--color-red);
	font: var(--gilroy-Medium-12);
	font-size: smaller;
	padding: 8px 5px;
}

.table__item .spinner {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 20px;
	margin-top: 0;
	animation: spin 2s linear infinite;
	overflow: visible;
}

.deleteButton,
.deleteErrorButton {
	display: flex;
	align-items: center;
	background: transparent;
	border: none;
	cursor: pointer;
}

.deleteButton {
	margin-right: 16px;
}

.deleteErrorButton {
	position: absolute;
	right: 24px;
	margin-right: 0 !important;
}

.deleteErrorButton img {
	margin-right: 0;
}

.errorsBlock {
	display: flex;
	flex-direction: column;
	gap: 6px;
}

.errorBlockHelper {
	color: var(--color-red);
	font: var(--gilroy-Medium-12);
	font-size: smaller;
	padding: 8px 5px;
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
