.royalty__wrapper {
	position: relative;
	display: flex;
	margin: 40px 2vw 30px 40px;
	padding: 16px 48px 16px;
	background-color: var(--color-white);
	align-items: center;
}

.royalty__title {
	display: flex;
	flex-direction: column;
	width: 70%;
	font: var(--gilroy-Medium-16);
	color: var(--color-grey);
}

.royalty__title_amount {
	font: var(--gilroy-Medium-48);
	color: var(--color-dark-grey);
	margin: 8px 0 24px;
}

.royalty__title span {
	color: var(--color-dark-grey);
}

.disabled {
	color: var(--color-grey);
}

.royalty__wrapper button {
	max-width: 320px;
}

.royalty__help {
	position: absolute;
	top: 16px;
	right: 12px;
}
