.btnOnly {
	z-index: 999999999999999;
}

.formControl {
	margin: 0px !important;
	width: 100%;
}

.menuItem {
	position: relative;
  padding: 8px 12px !important;
}


.active {
  color: var(--color-white) !important;
  background-color: var(--color-green) !important;
}

.noData {
	text-align: center;
	pointer-events: none;
}



@keyframes btnOnlyAppearence {
	0% {
		opacity: 0;
	}

	25% {
		opacity: 0;
	}

	50% {
		opacity: 0;
	}

	75% {
		opacity: 0.5;
	}

	100% {
		opacity: 1;
	}
}

.MuiPaper-root.MuiPopover-paper {
  height: auto;
}

.wrapper {
  height: auto;
}

.search {
	padding-left: 13px !important;
	padding-right: 13px !important;
	width: 100%;
}

.search label {
	padding-left: 13px;
}

.help_btn button {
	margin-top: -5px;
	margin-left: 5px;
}

.help_btn svg {
	width: 14px;
}

.search {
	padding-left: 20px !important;
	padding-right: 20px !important;
	width: 100%;
	position: relative;
}

.search::before {
	content: '';
	position: absolute;
	left: 20px;
	top: 70%;
	transform: translateY(-50%);
	width: 24px;
	height: 24px;
	background-image: url('images/loop.svg');
	background-size: cover;
}

.search input {
	padding: 0 0 0 40px !important;
}

.search label {
	padding-left: 60px !important;
}



