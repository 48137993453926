.infoBlock,
.titleWrapper {
	display: grid;
	grid-template-columns: 2fr 1fr;
	grid-column-gap: 30px;
}

.titleWrapper {
	margin-bottom: 24px;
}

.title {
	letter-spacing: 1px;
	font: var(--gilroy-Medium-24-140p);
}

.RepertoireSongInfo__edit {
	font: var(--gilroy-Medium-18);
	text-decoration: underline;
	display: flex;
}
.RepertoireSongInfo__edit img {
	margin-right: 8px;
	width: 24px;
	height: 24px;
}

.titleInfo {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-column-gap: 30px;
	grid-row-gap: 24px;
	margin-top: 24px;
	background: var(--color-white);
	padding: 20px 24px;
}

.titleInfoItem {
	display: flex;
	flex-direction: column;
	font: var(--gilroy-Medium-18);
}
.RepertoireInfoItem__title {
	color: var(--color-grey100);
	font: var(--gilroy-Medium-14);
}

.titleInfoItem > span:first-child {
	margin-bottom: 6px;
}

.RepertoireInfoItem__flagContainer {
	display: flex;
	align-items: flex-end;
}

.RepertoireInfoItem__flagContainer > *:first-child {
	margin-right: 8px;
}

.RepertoireSongInfo__main {
	display: grid;
	margin-top: 40px;
}

.RepertoireSongInfo__mainInfoTable {
	letter-spacing: 1px;
}

.song__table {
	display: flex;
	padding: 11px 18.25px 11px 24px;
}

.song__table > *:first-child {
	width: 390px;
	margin-right: 13px;
}

.song__table > *:nth-child(2) {
	width: 200px;
	margin-right: 43px;
}

.song__table > *:nth-child(3) {
	width: 40px;
	margin-right: 48px;
}

.song__table > *:nth-child(4) {
	width: 40px;
	margin-right: 31px;
}

.RepertoireMainInfoTable__header {
	color: var(--color-grey100);
	font: var(--gilroy-Medium-18);
	padding-bottom: 16px;
	padding-top: 0;
}

.RepertoireMainInfoTable__body {
	font: var(--gilroy-Medium-16-19);
}

.RepertoireMainInfoTable__body li {
	background-color: var(--color-white);
	margin: 2px 0px;
	border: 3px solid white;
	transition: 0.2s;
}

.RepertoireMainInfoTable__body li:hover {
	border: 3px solid var(--color-light-green);
}

.RepertoireMainInfoTable__body ul {
	background-color: var(--color-white100);
	padding: 0;
}

.RepertoireMainInfoTable__bodyTitle {
	margin: 16px 0px 15px 15px;
	display: block;
	font: var(--gilroy-Medium-18);
}
.RepertoireMainInfoTable__bodyTitleLast {
	margin-top: 24px;
}

.RepertoireMainInfoTable__body > * {
	background-color: var(--color-white);
	align-items: center;
	padding: 11px 18.25px 11px 24px;
	margin-bottom: 2px;
}

.RepertoireMainInfoTable__body > *.RepertoireMainInfoTable__select {
	padding: 11px 18.25px 11px 24px;
	background-color: var(--color-light-green);
	border: 2px solid var(--color-light-green);
	transition: 0.2s;
}

.RepertoireMainInfoTable__body > *.RepertoireMainInfoTable__select:hover {
	background-color: var(--color-white);
}

.RepertoireMainInfoTable__owner {
	text-decoration: underline;
}

.RepertoireSongInfo__mainFilter {
	width: 19.583vw;
}

.RepertoireSongInfo__mainFilter > * {
	margin-bottom: 16px;
}

.RepertoireSongInfo__mainFilter > * > span:first-child {
	padding-left: 8px;
}

.RepertoireMainFilter__button {
	font: var(--gilroy-Medium-18);
	margin-bottom: 0;
	margin-top: 24px;
	text-align: center;
	text-decoration: underline;
}

.RepertoireMainFilter__buttonActive {
	margin-top: -8px;
}

.RepertoireMainFilter__hideContainer {
	visibility: hidden;
	height: 0;
	opacity: 0;
	transition: 0.2s;
}

.RepertoireMainFilter__hideContainerActive {
	visibility: visible;
	height: auto;
	opacity: 1;
}

.RepertoireMainFilter__hideContainer > * {
	margin-bottom: 16px;
}

.RepertoireMainFilter__hideContainer > * > span:first-child {
	padding-left: 8px;
}

.filter__container > * {
	margin-bottom: 16px;
}

.filter__button {
	font: var(--gilroy-Medium-18);
	margin-bottom: 0;
	margin-top: 24px;
	text-align: center;
	text-decoration: underline;
}

.filterButton__active {
	margin-top: -8px;
}

.filter__hide {
	visibility: hidden;
	height: 0;
	opacity: 0;
	transition: 0.2s;
}

.filter__active {
	visibility: visible;
	height: auto;
	opacity: 1;
}

.filter__hide > * {
	margin-bottom: 16px;
}

.filter__hide > * > span:first-child {
	padding-left: 8px;
}

.header__info {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-column-gap: 30px;
	grid-row-gap: 24px;
}

.headerInfo__item {
	display: flex;
	flex-direction: column;
	font: var(--gilroy-Medium-18);
}

.headerInfoItem__title,
.headerInfoItemFilter__title {
	color: var(--color-grey100);
	font: var(--gilroy-Medium-14);
}

.headerInfo__item > span:first-child {
	margin-bottom: 6px;
}

.headerInfo__item > .headerInfoItemFilter__title {
	margin-top: 10px;
	margin-bottom: -15px !important;
}

.headerInfo__item input {
	font: var(--gilroy-Medium-18) !important;
}

.headerInfoItemFilter__title > span:first-child {
	margin-bottom: 0;
}

.row_header {
	padding: 0px !important;
	margin: 0px 0px 10px !important;
	flex-wrap: nowrap !important;
	color: var(--color-grey100);
	font: var(--gilroy-Medium-18);
	align-items: center !important;
}

.row_data {
	margin: 2px 0px !important;
	font: var(--gilroy-Medium-18);
	padding: 11px 0px;
}

.row_title {
	margin: 0px !important;
	font: var(--gilroy-Medium-18);
	padding: 0px 0px 6px;
}

.error {
	display: flex;
	min-height: 100%;
	padding: 40px;
	align-items: center;
	text-align: center;
	color: var(--color-red);
}

.incompatibilityError {
	text-align: center;
	color: var(--color-red);
}

.detailsWrapper {
	display: flex;
	align-items: baseline;
	cursor: pointer;
}

.detailsHeader {
	margin-right: 10px;
}

.detailsText {
	margin-top: 20px;
	max-width: 50vw;
	word-wrap: break-word;
}

.Page {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	background-color: var(--color-white100);
}

.Form {
	width: fit-content;
	max-width: 70%;
}

.Title {
	margin-bottom: 24px;
}

.DisputeErrorComment {
	margin-top: 24px;
}

@media screen and (min-width: 768px) {
	.RepertoireSongInfo__main {
		grid-template-columns: 2fr 1fr;
	}
}
