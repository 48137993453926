.modal {
	width: 0;
	height: 100%;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 140000000;
	display: flex;
	justify-content: flex-end;
	visibility: hidden;
	overflow: hidden !important;
	overflow-y: hidden !important;
	opacity: 0;
	transition: width 300ms ease-in;
}

.modal.active {
	width: 100%;
	visibility: visible;
	opacity: 1;
}

.container {
	position: relative;
	width: 416px;
	min-height: 600px;
	height: 100vh;
	background-color: var(--color-dark-grey);
	overflow: hidden !important;
	overflow-y: hidden !important;
	color: var(--color-white);
	display: flex;
	flex-direction: column;
	padding: 40px;
}

.container.white_theme {
	position: relative;
	width: 416px;
	min-height: 600px;
	height: 100vh;
	background-color: var(--color-white);
	overflow: hidden !important;
	overflow-y: hidden !important;
	color: var(--color-dark-grey);
	display: flex;
	flex-direction: column;
	padding: 40px;
}

.container.white_theme li img {
	filter: invert(1)
} 

.modalFooter__title,
.modalHeader__title {
	font: var(--gilroy-Medium-30);
	font-weight: 500;
	line-height: 40px;
	letter-spacing: 1px;
}

.modal__icon {
	margin-right: 16px;
}

.modalHeader {
	display: flex;
	flex-direction: column;
	padding-bottom: 40px;
}

.modalHeader__titleWrapper {
	display: flex;
	justify-content: space-between;
}

.modalHeader svg {
	font-size: 36px;
}

.modalBody {
	flex-grow: 1;
	text-transform: lowercase;
}

.modalBody * {
	letter-spacing: 1px;
}

.setting__title {
	margin-bottom: 15px;
	font: var(--gilroy-Medium-24);
	line-height: 34px;
}

.setting {
	margin-bottom: 40px;
}

.setting__list {
	height: 58vh;
	overflow-y: scroll;
}

.setting__item {
	display: flex;
	align-items: center;
	font: var(--gilroy-Medium-16-140);
	margin-bottom: 15px;
}

.setting__item:hover {
	cursor: pointer;
	text-decoration: line-through;
}

.setting__checkbox {
	margin-right: 16px;
}

.button__select {
	position: absolute;
	bottom: 40px;
	left: 40px;
	height: 60px;
	margin-top: 8px;
	width: calc(416px - 80px);
	background: var(--color-white);
	border: none;
	font: var(--gilroy-Medium-18-140);
	text-transform: capitalize;
	cursor: pointer;
}

.button__select:hover {
	background: #e5e5e5;
	border: 2px #ffffff solid;
}

.Overlay {
	position: absolute;
	opacity: 0;
	top: 0;
	left: 0;
	width: 0;
	height: 0;
	background-color: var(--color-dark-grey);
	transition: opacity 300ms ease-in;
}

.OverlayActive {
	z-index: 130000000;
	width: 100vw;
	height: 100vh;
	opacity: 0.5;
}

.modalHeader__icon {
	position: absolute;
	top: 24px;
	right: 24px;
}

@media screen and (min-width: 795px) {
	.setting__list {
		overflow-y: none;
	}
}
