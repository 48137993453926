/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 23/09/2020, 15:24
 */

@import-normalize; /* bring in normalize.css styles */

body {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

div .notification-container {
	width: unset;
}

.notification-container .notification {
	margin-top: 37px;
	box-shadow: 0 5px 8px rgba(196, 196, 196, 0.6);
}

.notification-container .notification-error {
	font: var(--gilroy-Medium-14);
	font-weight: 500;
	padding: 24px 42px;
	color: var(--color-dark-grey);
	background-color: var(--color-white);
}

.notification-container .notification-error:before {
	content: none;
}

.widget-with-audio {
	margin-bottom: 60px;
}

.widget-with-navi {
	margin-bottom: 60px;
}

.grecaptcha-badge {
	visibility: hidden;
}

.text_editor-main .public-DraftEditor-content {
	min-height: 140px;
}