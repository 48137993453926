.createArticle__header {
	display: flex;
	justify-content: space-between;
	font: var(--gilroy-Medium-24);
	color: var(--color-dark-grey);
	font-weight: 500;
}

.deleteBtn {
	display: flex;
	align-items: center;
	font: var(--gilroy-Medium-18-140);
	cursor: pointer;
	background: transparent;
	border: none;
}

.deleteBtn span {
	border-bottom: 2px solid;
}

.deleteBtn img {
	margin-right: 8px;
}
