.backdrop {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 10000000;
	background-color: rgba(30, 30, 30, 0.8);
	visibility: hidden;
	opacity: 0;
}

.backdrop.active {
	visibility: visible;
	opacity: 1;
}

.container {
	position: absolute;
	right: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: var(--color-dark-grey);
	padding: 82px 24px 37px 24px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.close__btn {
	position: absolute;
	top: 20px;
	right: 29px;
	background-color: inherit;
	border: none;
}

.btn__container {
	display: flex;
	flex-direction: column;
}

.btn__add {
	background-color: inherit;
	border: none;
	display: flex;
	align-items: center;
	letter-spacing: 1px;
	font: var(--gilroy-Medium-16-140);
	padding: 18px 0;
	color: var(--color-white100);
	cursor: pointer;
}

.disabled__container {
	margin-bottom: 16px;
}

.btn__disabled {
	background-color: inherit;
	border: none;
	display: flex;
	align-items: center;
	letter-spacing: 1px;
	font: var(--gilroy-Medium-16-140);
	padding: 18px 0;
	color: #777673;
	margin-bottom: 16px;
}

.alert {
	font: var(--gilroy-Medium-14-17);
	color: #f75e63;
}

.alert span {
	text-decoration: underline;
}

.btn__add:not(:last-child) {
	margin-bottom: 16px;
}

.img__svg {
	width: 24px;
	height: 24px;
	margin-right: 10px;
}

.btn__addAll {
	background-color: inherit;
	border: none;
	font: var(--gilroy-Medium-14-20);
	letter-spacing: 1px;
	text-decoration-line: underline;
	color: var(--color-light-grey);
}

.link {
	text-decoration: none;
	margin-bottom: 16px;
	cursor: pointer;
}

.Modal {
	position: fixed;
	width: 100%;
	height: 100%;
}

.Overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: var(--color-dark-grey);
}

.LeftSide {
	overflow: auto;
	z-index: 1100;
	height: 100vh;
	width: 400px !important;
	color: var(--color-white100);
	background-color: var(--color-dark-grey);
}
