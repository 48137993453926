.modal {
	position: fixed;
	width: 100%;
	height: 100%;
}

.overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: var(--color-dark-grey);
}

.container {
	width: 512px;
	margin-right: 0 !important;
	padding-right: 0 !important;
}

.bodyContainer {
	width: 512px;
	height: 100vh;
	padding: 24px;
	padding-right: 36px;
	overflow: auto;
	color: var(--color-dark-grey);
	background-color: var(--color-white);
}

.bodyWrapper :global .another-lang {
	opacity: 0.5 !important;
}

.bodyWrapper {
	display: flex;
	flex-direction: column;
	position: relative;
	height: 100%;
}

.bodyHeader {
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
}

.bodyHeader button {
	border: none;
	background: transparent;
	cursor: pointer;
}

.bodyTitle {
	font: var(--gilroy-Medium-24-28);
	color: var(--color-dark-grey);
}

.bodyTopWrapper {
	display: flex;
	justify-content: space-between;
	margin-bottom: 40px;
}

.cardsContainer {
	display: flex;
	flex-direction: column;
	gap: 12px;
	margin-bottom: 24px;
}

.saveBtn {
	position: sticky;
	bottom: 0;
	margin: auto 0 0;
	width: 100%;
	max-width: 100%;
	height: 60px;
}

.saveBtn:hover {
	background-color: var(--color-white) !important;
}

.backBtn {
	font: var(--gilroy-Medium-18-24);
	background: transparent;
	border: none;
	border-bottom: 1px solid var(--color-dark-grey);
	cursor: pointer;
}

.headerContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.switcher {
	margin-right: -12px;
}

.notificationBlockTitle {
	font: var(--gilroy-Medium-16-24);
	color: var(--color-grey);
}

.notificationBlockBody {
	margin-top: 8px;
}

.checkbox {
	font: var(--gilroy-Medium-18-24);
	text-transform: lowercase;
	color: var(--color-dark-grey);
}
