.end {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 328px;
	margin: 134px auto;
}

.title {
	font: var(--gilroy-Medium-24-140p);
	margin-bottom: 16px;
}
.text {
	font: var(--gilroy-Medium-16-150);
	margin-bottom: 48px;
}
.button {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 60px;
	background: var(--color-dark-grey);
	border: none;
	color: var(--color-white);
	cursor: pointer;
	text-decoration: none;
	font: var(--gilroy-Medium-18-140);
}
