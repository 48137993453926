/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/11/2020, 15:02
 */

.Accordion {
	margin-bottom: 10px;
}

.Accordion:global.open :local .Header {
	font: var(--gilroy-Medium-24);
	font-weight: bold;
	background-color: var(--color-light-green);
}

.Header {
	font: var(--gilroy-Medium-24);
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 60px;
	padding: 0 30px;
	background-color: var(--color-grey);
}

.Header :global .title img {
	margin-left: 20px;
}

.Body {
	display: none;
	padding: 30px;
	background-color: var(--color-white);
}

.Accordion:global.open :local .Body {
	display: block;
}
