.notify {
	width: 100vw;
	height: 100vh;
	background: rgba(30, 30, 30, 0.8);
	position: fixed;
	z-index: 140000000;
	display: flex;
	justify-content: flex-end;
	visibility: hidden;
	opacity: 0;
}

.notify.active {
	visibility: visible;
	opacity: 1;
}

.notifyWrapper {
	position: relative;
	min-height: 100vh;
	width: 100%;
	max-width: 400px;
	background-color: var(--color-dark-grey);
	overflow-y: auto;
}

.notifyWrapper * {
	color: var(--color-white);
}

.notifyHeader {
	padding: 15px 30px 24px 25px;
}

.notifyTitle__titleWrapper {
	display: flex;
	align-items: center;
}

.notifyTitle {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.notifyTitle svg {
	font-size: 42px;
}

.notifyTitle__back {
	display: flex;
	align-items: center;
	padding-top: 15px;
	font: var(--gilroy-Medium-18);
	text-transform: lowercase;
	cursor: pointer;
}

.notifyTitle__title {
	margin-right: 8px;
	font: var(--gilroy-Medium-32-140p);
	text-transform: lowercase;
}

.notifyTitle__notesCount {
	margin-right: 8px;
	background: var(--color-red);
	border-radius: 50%;
	padding: 0.278vw 0.576vw;
	font: var(--gilroy-Medium-14-20);
	height: 1.667vw;
	width: 1.667vw;
	display: flex;
	align-items: center;
	justify-content: center;
}

.notifyHeader__flagAll {
	width: 100%;
	padding: 30px 0px 10px 0px;
	display: block;
	text-decoration: underline;
	text-align: right;
	font: var(--gilroy-Medium-16);
	cursor: pointer;
	background: transparent;
	border: none;
	outline: none;
	color: var(--color-white100);
}

.notifyHeader__flagOne {
	composes: notifyHeader__flagAll;
	padding: 8px 0px 20px 0px;
}

.NotifyItem {
	position: relative;
	padding: 16px 50px 16px 73px; /*  */
	border-bottom: 1px solid #888888;
	cursor: pointer;
}

.NotifyItem:last-child {
	border: none;
}

.NotifyItem p {
	margin: 0;
}

.NotifyItem p.NotifyItem__text {
	font: var(--gilroy-Bold-16-20);
}

.NotifyItem p.NotifyItem__date {
	margin-top: 0.556vw;
	font: var(--gilroy-Medium-16-20);
	color: var(--color-grey);
}

.NotifyItem img {
	position: absolute;
	top: 1.389vw;
	left: 1.736vw;
}

.NotifyItem span {
	position: absolute;
	top: 1.597vw;
	right: 2.083vw;
}

.NotifyItem__dots {
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: var(--color-red);
}

.notifyEmpty {
	position: absolute;
	display: flex;
	margin-right: -50%;
	transform: translate(-50%, -50%);
	flex-direction: column;
	align-items: center;
	top: 50%;
	left: 50%;
}

.notifyEmpty__img {
	width: 56px;
}

.notifyEmpty__text {
	margin-top: 32px;
	padding: 0 30px;
	font: var(--gilroy-Medium-16-140);
	text-align: center;
}

.close__btn {
	position: absolute;
	top: 20px;
	right: 29px;
	background-color: inherit;
	border: none;
}

.Modal {
	position: fixed;
	width: 100%;
	height: 100%;
}

/* ==================MODAL CSS===================== */

.Overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: var(--color-dark-grey);
}

.RightSide {
	flex: 0 !important;
}

.LeftSide {
	overflow: auto;
	z-index: 1100;
	height: 100vh;
	width: 100% !important;
	max-width: 100% !important;
	color: var(--color-white100);
	background-color: var(--color-dark-grey);
}

.LeftSideWhite {
	overflow: auto;
	height: 100vh;
	width: 408px;
	margin-left: auto;
	color: var(--color-dark-grey);
	background-color: var(--color-white100);
}

.ContentWhite {
	display: flex;
	overflow: auto;
	flex-direction: column;
	justify-content: flex-start;
	padding: 0 40px 0 40px;
}

.containerWhite {
	padding-right: 0px !important;
}

.titleWhite {
	margin-top: 150px;
	margin-bottom: 16px;
}

.confirmBtn {
	margin-top: 238px;
	margin-bottom: 16px;
}

.CloseButton {
	position: fixed;
	top: 40px;
	right: 40px;
}

.Content {
	display: flex;
	overflow: auto;
	flex-direction: column;
	justify-content: flex-start;
	margin: 117px 104px;
}

.Title {
	font: var(--gilroy-Medium-32);
	margin-bottom: 50px;
}

.Paragraph {
	font: var(--gilroy-Medium-18);
}

.Paragraph > ul {
	padding-left: 40px;
	list-style: disc;
}

.errorWrapper {
	position: relative;
}

.Helper {
	position: absolute;
	bottom: 0px;
	left: 0px;
	color: var(--color-red);
	font: var(--gilroy-Medium-12);
	font-size: smaller;
	padding: 0 5px;
}

.itemHeading {
	font: var(--gilroy-Bold-20);
	line-height: 28px;
	word-wrap: break-word;
}

.itemText {
	font: var(--gilroy-Medium-16-20);
	line-height: 24px;
}

.itemText a {
	color: var(--color-white);
	cursor: pointer;
	text-decoration: underline;
}

.itemText ul {
	list-style: disc !important;
	padding-left: 18px;
}

.itemText ol {
	padding-left: 18px;
}

.itemDate {
	margin-top: 8px;
	font: var(--gilroy-Medium-14-20);
	color: var(--color-grey);
}

.notifyMain {
	padding: 0px 24px 24px;
}

.settingsBtn {
	display: flex;
	align-items: center;
	border: none;
	background: transparent;
	cursor: pointer;
}

@media screen and (min-width: 1024px) {
	.RightSide {
		flex: 1 0 0px !important;
	}

	.LeftSide {
		width: 498px !important;
		max-width: 498px !important;
	}

	.notifyTitle__title {
		font: var(--gilroy-Medium-20);
	}

	.notifyHeader {
		padding: 24px;
	}

	.close__btn {
		top: 22px;
	}
}

@media screen and (min-width: 1280px) {
	.notifyTitle__title {
		font: var(--gilroy-Medium-32-140p);
	}

	.notifyHeader {
		padding: 15px 30px 24px 25px;
	}

	.close__btn {
		top: 20px;
	}
}
