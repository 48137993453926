.delete__button {
	display: flex;
	width: fit-content;
	margin-top: 24px;
	padding: 8px 0;
	align-items: center;
	background: transparent;
	font: var(--gilroy-Medium-18-140);
	letter-spacing: 0.72px;
	color: var(--color-btn-grey);
	cursor: pointer;
	border: none;
	text-decoration-line: underline;
}

.delete__button img {
	margin-right: 10px;
}

.disabled {
	opacity: 0.3;
	pointer-events: none;
}
