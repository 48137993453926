.modal {
	position: fixed;
	width: 100%;
	height: 100%;
}

.overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: var(--color-dark-grey);
}

.container {
	width: 512px;
	margin-right: 0 !important;
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.bodyContainer {
	width: 512px;
	height: 100vh;
	padding: 24px;
	overflow: auto;
	color: var(--color-dark-grey);
	background-color: var(--color-white);
}

.bodyWrapper :global .another-lang {
	opacity: 0.5 !important;
}

.bodyWrapper {
	display: flex;
	flex-direction: column;
	position: relative;
	height: 100%;
}

.bodyHeader {
	display: flex;
	flex-direction: column;
	margin-bottom: 32px;
}

.bodyHeader button {
	border: none;
	background: transparent;
	cursor: pointer;
}

.bodyTitle {
	font: var(--gilroy-Medium-32);
	color: var(--color-dark-grey);
}

.bodySubtitle {
	margin-top: 4px;
	font: var(--gilroy-Medium-18-24);
	color: var(--color-grey);
}

.bodyTopWrapper {
	display: flex;
	justify-content: space-between;
	margin-bottom: 16px;
}

.saveBtn {
	width: 100%;
	max-width: 100%;
	height: 60px;
	margin-top: auto;
}