.repertoire__mainFilter {
  display: flex;
  flex-grow: 1;
}

.repertoire__mainFilterInput {
  display: flex;

  position: relative;
  /* margin-right: 21px; */
  width: 100%;
}

.repertoire__mainFilterInput svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.repertoire__mainFilterInput input {
  width: 100%;
  padding: 8px 20px 8px 39px;
  border: none;
  border-bottom: 2px solid var(--color-grey);
  font: var(--gilroy-Medium-16);
  line-height: 24px;
  background: transparent;
  transition: 0.2s;
}

.repertoire__mainFilterInput input:hover {
  border-color: var(--color-black);
}

.repertoire__mainFilterInput input:hover~svg path {
  fill: #000;
}

.repertoire__mainFilterInput input:focus {
  /* border-color: #000; */
  border-bottom: 2px solid var(--color-dark-grey);
  
}

.repertoire__mainFilterInput input:focus~svg path {
  fill: var(--color-black);
}

.repertoire__mainFilterInput input:focus-visible {
  outline: none;
  
}

.repertoire__mainFilterInput input::placeholder {
  /* color: #d7d7d7; */
  color: var(--color-grey);

}

.repertoire__filterImg {
  cursor: pointer;
}

.inputLowercasePlaceholder::placeholder {
text-transform: lowercase;
}


.inputFilled input
{
  border-bottom: 2px solid var(--color-dark-grey) !important;
}

.inputFilled svg path {
    fill: var(--color-black);
}

.removeIcon {
  position: absolute;
  top: 3px;
  right: -5px;
  width: 36px;
  cursor: pointer;
}