.Review__Wrapper {
	min-height: 85vh;
	padding-top: 48px;
}

.Review__Main_wrapper {
	padding: 40px 30px;
	flex-grow: 1;
}

.Review__Control_wrapper {
	width: 53.125vw !important;
	display: flex;
	margin-top: 27px;
	justify-content: space-between;
	width: 100%;
	padding: 0px 0px 20px 30px;
}

.addRoleContractsBanner {
	margin-bottom: 40px;
	margin-top: 40px;
}

.Review__Prev {
	display: inline-block;
	padding: 18px 65px 18.43px 0px;
	font: var(--gilroy-Medium-18-22);
	text-decoration: underline;
	cursor: pointer;
}

.Review__Btn_next {
	border: none;
	background-color: var(--color-btn-grey);
	color: var(--color-white100);
	width: 282px;
	min-width: 282px;
	height: 60px;
	font: var(--gilroy-Medium-18-140);
	letter-spacing: 1px;
	text-transform: lowercase;
	cursor: pointer;
}

.Review__Btn_next.disabled {
	background: var(--color-grey50);
	cursor: not-allowed;
}

.Review__Btn_sign {
	composes: Review__Btn_next;
	width: 252px;
	min-width: 252px;
}

.Review__Sign_agreement_wrapper {
	display: flex;
	justify-content: space-between;
	padding: 16px 24px;
	background-color: var(--color-light-grey);
}

.Review__Sign_agreement_wrapper_text {
	margin-right: 24px;
}

.Review__Sign_agreement_wrapper_text > span:first-child {
	display: block;
	font: var(--gilroy-Medium-18);
	line-height: 24px;
	letter-spacing: 0.72px;
}

.Review__Sign_agreement_wrapper_text > span:nth-child(2) {
	display: inline-block;
	font: var(--gilroy-Medium-14);
	line-height: 16px;
}

.Review__Information_wrapper {
	margin-top: 24px;
	margin-bottom: 40px;
	padding: 20px 24px;
	background-color: var(--color-white);
}

.Review__Section_title {
	display: inline-block;
	font: var(--gilroy-Medium-24);
	line-height: 28px;
	letter-spacing: 0.96px;
}

.Review__Information_table_row {
	padding: 0;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-column-gap: 30px;
	margin-bottom: 16px;
}

.Review__Information_table_cell {
	word-break: break-word;
}

.Review__Information_table_cell span {
	display: block;
}

.Review__Information_table_cell > span:first-child {
	font: var(--gilroy-Medium-12);
	line-height: 12px;
	color: var(--color-grey100);
	margin-bottom: 8px;
}

.Review__Information_table_cell > span:nth-child(2) {
	font: var(--gilroy-Medium-16);
	line-height: 24px;
	letter-spacing: 0.16px;
}

.Background_white {
	background-color: var(--color-white);
}

.Text_uppercase {
	text-transform: uppercase;
}

.bottomContainer {
	position: relative;
	width: 100%;
}

.Helper {
	position: absolute;
	top: -25px;
	right: 80px;
	color: var(--color-red);
	font: var(--gilroy-Medium-12);
	font-size: smaller;
	padding: 0 5px;
}

@media screen and (min-width: 768px) {
	.Review__Information_table_row {
		grid-template-columns: repeat(4, 1fr);
	}
}
