.page {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	margin-bottom: 30px;
}

.Table {
	font: var(--gilroy-Medium-16);
	overflow: auto;
	width: 100%;
	max-height: 100%;
	border-spacing: 0px;
}

.Table td {
	height: 50px;
	padding: 5px;
	border-top: 1px solid #f4f4f4;
	border-left: 1px solid #f4f4f4;
}

.Table th {
	padding: 5px;
	border-top: 1px solid var(--color-grey50);
	border-left: 1px solid var(--color-grey50);
	background-color: #d9d9d9;
}

.Table tr:global.second th {
	font-size: 14px;
	line-height: 100%;
	width: 50px;
	min-width: 50px;
	max-width: 50px;
	height: 160px;
	padding-top: 30px;
	text-align: start;
}

.Table :global .title {
	width: 100%;
	cursor: auto;
}

.Table :global .mark {
	width: 24px;
	text-align: center;
}

.Table :global .date {
	width: 200px;
}

.Table :global .firstCol {
	min-width: 190px;
}

.Table :global .rotate-270 {
	transform: rotate(-90deg) translateX(-25px);
	transform-origin: 50% 50%;
}

.Table tr:hover td,
.Table tr:nth-child(odd):hover td {
	background-color: var(--color-light-grey);
}

.Table tr:nth-child(odd) td {
	background-color: var(--color-white100);
}

.page__searchAndFilterWrapper {
	display: flex;
}

.inputWrapper {
	padding: 5px 0px 5px 5px;
	flex-grow: 1;
}

.page__input {
	display: block;
	width: 100%;
}

.page__checkbox {
	margin: 10px;
}

.loaderWrapper {
	position: relative;
}

.loader {
	margin-top: 30px;
}

.noData {
	text-align: center;
	font: var(--gilroy-Medium-24);
}

.signUpTitle {
	cursor: pointer;
	overflow-wrap: break-word;
	max-width: 40vw;
}

.signUpTitle :hover {
	text-decoration: underline;
}

.filterIcon {
	margin: 0 55px 0 8px;
	width: 24px;
	cursor: pointer;
}

.sectionDeleteBtn,
.disabledSectionDeleteBtn {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 132px;
	height: 36px;
	margin-top: 8px;
	border: none;
	border-radius: 50px;
	font: var(--gilroy-Medium-16);
}

.sectionDeleteBtn {
	background: var(--color-light-green);
	cursor: pointer;
}

.disabledSectionDeleteBtn {
	background: var(--color-light-grey);
	cursor: not-allowed;
}

.sectionDeleteBtn img,
.disabledSectionDeleteBtn img {
	margin-left: 6px;
}

@media screen and (min-width: 1024px) {
	.filterIcon {
		margin-right: 48px;
	}
}
