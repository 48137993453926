.topTen {
	display: flex;
	flex-direction: column;
	min-width: 210px;
  max-width: 224px;
  width: 100%;
	flex-wrap: nowrap;
	margin-bottom: 20px;
	padding: 12px 10px 0;
  background-color: var(--color-white100);
}

.topTenTitle {
	font: var(--gilroy-Bold-16-20);
	margin-bottom: 8px;
}

.tenTopTableHeading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  gap: 10px;
}

.tenTopTableHeading span {
  font: var(--gilroy-Medium-14);
  color: var(--color-grey);
  letter-spacing: 0.15px;
}

.topTenData li:not(:last-child) {
	margin-bottom: 8px;
}

.topTenData li {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 0;
  font: var(--gilroy-Medium-14);
  color: var(--color-dark-grey);
  border-bottom: 1px solid var(--color-light-grey);
}

.topTenData li:hover .countryNameTooltip {
  display: block;
}
.wrapperCountry {
  display: flex;
  align-items: center;
  gap: 5px;
}

.countryPlace {
  margin-right: 5px;
  max-width: 18px;
  width: 100%;
  color: var(--color-grey);
}

.countryName {
  margin-right: 5px;
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.countryNameTooltip {
  display: none;
  position: absolute;
  left: 28px;
  bottom: -20px;
  padding: 6px;
  font: var(--gilroy-Medium-14);
  color: var(--color-dark-grey);
  background-color: var(--color-white);
  box-shadow: 0 1px 10px rgba(0, 0, 0, .08);
}

.countryValue {
  margin-left: auto;
}