.Input {
	position: relative;
	height: 54px;
}

.Input:not(:last-child) {
	margin-bottom: 20px;
}

.Input input {
	font: var(--gilroy-Medium-16);
	line-height: 24px;
	display: block;
	width: 100%;
	padding: 8px 4px;
	border: none;
	color: var(--color-grey) !important;
	border-bottom: 2px solid var(--color-grey);
}

.inputPlaceholder::placeholder {
	color: var(--color-grey);
	opacity: 1;
}

.Input .inputPassword {
	padding: 8px 45px 8px 4px;
}

.Input .inputPassword::placeholder {
	color: var(--color-grey);
	opacity: 1;
}

.Input input:not(:placeholder-shown) {
	color: var(--color-dark-grey) !important;
	border-bottom: 2px solid var(--color-dark-grey);
	outline: none;
}

.Input input:focus {
	color: var(--color-dark-grey) !important;
	border-bottom: 2px solid var(--color-dark-grey);
	outline: none;
}

.Input input:placeholder-shown {
	color: var(--color-grey) !important;
}

/* LABEL ======================================= */
.Label {
	font-size: 18px;
	font-weight: normal;
	position: absolute;
	top: 10px;
	left: 5px;
	transition: 0.2s ease all;
	pointer-events: none;
	color: var(--color-grey100);
}

/* active state */
.Input input:focus ~ .Label,
.Input input:valid ~ .Label {
	font: var(--gilroy-Medium-12);
	top: -20px;
}

.ShowPassword {
	position: absolute;
	top: 7px;
	right: 4px;
	height: 47px;
	border: none;
	outline: none;
	background-color: transparent;
}

/* BOTTOM BARS ================================= */
.Bar {
	position: relative;
	display: block;
	width: 100%;
	margin-bottom: 6px;
	height: 2px;
	bottom: 1px;
}

.Bar:before,
.Bar:after {
	position: absolute;
	bottom: 1px;
	width: 0;
	height: 2px;
	content: '';
	transition: 0.2s ease all;
	background: var(--color-dark-grey);
}

.Bar:before {
	left: 50%;
}

.Bar:after {
	right: 50%;
}

/* active state */
.Input input:focus ~ .Bar:before,
.Input input:focus ~ .Bar:after {
	width: 50%;
}

/* HIGHLIGHTER ================================== */
.Highlight {
	position: absolute;
	top: 25%;
	left: 0;
	width: 100px;
	height: 60%;
	pointer-events: none;
	opacity: 0.5;
}

.Helper {
	font: var(--gilroy-Medium-12-12);
	color: var(--color-grey);
	padding: 0 4px;
}

.Error .Bar:before,
.Error .Bar:after {
	background-color: var(--color-red);
}

.Error input,
.Error input:not(:placeholder-shown) {
	color: var(--color-red) !important;
	border-bottom-color: var(--color-red);
	border-bottom: 2px solid var(--color-red);
}

.Error input::placeholder {
	color: var(--color-red) !important;
}

.Error span {
	display: inherit;
	letter-spacing: 0.4px;
	font: var(--gilroy-Medium-12-12);
	padding: 0 4px;
}

.Tip {
	z-index: 100;
	font: var(--gilroy-Medium-12);
	position: absolute;
	top: 50%;
	right: 105%;
	width: 221px;
	padding: 16px;
	transform: translateY(-50%);
	color: var(--color-white);
	background-color: var(--color-dark-grey);
}

.Tip:after {
	z-index: 1000000;
	position: absolute;
	top: 41%;
	right: -12px;
	width: 0;
	height: 0;
	content: '';
	transform: rotate(270deg) translateY(50%);
	border-width: 20px 20px 0 20px;
	border-style: solid;
	border-color: var(--color-dark-grey) transparent transparent transparent;
}

.Error {
	font: var(--gilroy-Medium-12-12);
	color: var(--color-red);
}

.icon {
	position: absolute;
	top: 14px;
	right: 0;
}

/* ANIMATIONS ================ */
@keyframes inputHighlighter {
	from {
		background: var(--color-dark-grey);
	}
	to {
		width: 0;
		background: transparent;
	}
}

@media screen and (min-width: 768px) {
	.Input input {
		font: var(--gilroy-Medium-18);
		line-height: 24px;
	}

	.Input {
		max-width: 416px;
		height: 60px;
	}

	.Input:not(:last-child) {
		margin-bottom: 25px;
	}

	.ShowPassword {
		top: 10px;
	}
}
