.Page {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	justify-content: center;
	margin-bottom: 100px;
}

.Page section {
	width: 100%;
}

.HeaderLine {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin-top: 40px;
	margin-bottom: 24px;
}

.Checkbox {
	margin: 0;
	position: relative;
}

.disabled {
	opacity: 0.6;
	pointer-events: none;
}

.Page__title {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 24px;
}

.title {
	margin-bottom: 20px;
	word-break: break-word;
	font: var(--gilroy-Medium-40);
}

.status {
	display: flex;
	justify-content: end;
	font: var(--gilroy-Bold-24);
}

.Field {
	overflow-wrap: break-word;
	margin-bottom: 20px;
}

.Field :global .title {
	margin-bottom: 10px;
}

.Roles ul {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}

.Roles li {
	margin-right: 40px;
	margin-bottom: 20px;
}

.Table {
	font: var(--gilroy-Medium-16);
	overflow: auto;
	width: 100%;
	max-height: 100%;
	border-spacing: 0;
}

.Table td {
	height: 50px;
	padding: 5px;
	border-top: 1px solid #f4f4f4;
	border-left: 1px solid #f4f4f4;
}

.Table th {
	padding: 5px;
	text-transform: uppercase;
	border-top: 1px solid var(--color-grey50);
	border-left: 1px solid var(--color-grey50);
	background-color: #d9d9d9;
}

.Table tr:hover td,
.Table tr:nth-child(odd):hover td {
	background-color: var(--color-light-grey);
}

.Table tr:nth-child(odd) td {
	background-color: var(--color-white100);
}

.Table td:global.cb {
	text-align: center;
}

.Table td:global.cb > * {
	display: inline-block;
}

.Button {
	width: 160px;
	height: 40px;
	min-height: 40px;
	margin-top: 40px;
	margin-left: auto;
}

.deleteButton {
	width: 250px;
	height: 40px;
	min-height: 40px;
}

.grid {
	margin-top: 40px;
}

.warningBox {
	z-index: 1;
	position: absolute;
	width: 50%;
	background-color: white;
	color: black;
	padding: 5px;
	border: 1px var(--color-grey) solid;
}

.paymentsStatus {
	margin-top: 16px;
}

.fileCell {
	display: flex;
	align-items: center;
}

.paymentTitle:hover {
	opacity: 0.8;
	cursor: pointer;
}

.invoiceDetails {
	position: absolute;
	left: -5px;
	display: flex;
	flex-direction: column;
	gap: 16px;
	z-index: 2;
	width: 100%;
	padding: 16px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.invoiceName {
	display: flex;
	align-items: center;
}

.invoiceLink {
	color: var(--color-dark-grey);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.invoiceLink:hover {
	background-color: var(--color-light-grey);
	opacity: 0.8;
}

.paymentsTable td {
	padding: 0;
	padding-top: 16px;
	padding-right: 5px;
	padding-left: 5px;
}

@media screen and (max-width: 768px) {
	.container {
		margin-left: 0 !important;
	}
}
