.repertoire__mainFilter {
	display: flex;
  flex-grow: 1;
}
.repertoire__mainFilterInput {
	display: flex;
  
	position: relative;
	/* margin-right: 21px; */
	width: 100%;
}



.repertoire__mainFilterInput svg {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	pointer-events: none;
}

.repertoire__mainFilterInput input {
	width: 100%;
	padding: 8px 4px;
	border: none;
	border-bottom: 2px solid #888;
	font: var(--gilroy-Medium-18);
	background: transparent;
	transition: 0.2s;
}

.repertoire__mainFilterInput input:hover {
	border-color: #000;
}
.repertoire__mainFilterInput input:hover ~ svg path {
	fill: #000;
}

.repertoire__mainFilterInput input:focus {
	/* border-color: #000; */
	border-bottom: 2px solid var(--color-dark-grey);
}
.repertoire__mainFilterInput input:focus ~ svg path {
	fill: #000;
}

.repertoire__mainFilterInput input:focus-visible {
	outline: none;
}

.repertoire__mainFilterInput input::placeholder {
	color: #d7d7d7;
}

.repertoire__filterImg {
	cursor: pointer;
}

.save {
	margin-left: 15px;
}

.repertoire__mainFilterInputFilled input  {
		border-bottom: 2px solid var(--color-dark-grey);
}