.Page {
	display: grid;
	width: 100%;
	height: 100%;
	grid-template-areas: 'wiz' 'container';
	grid-template-rows: 60px auto;
	grid-template-columns: 100%;
}

.Wizard {
	grid-area: wiz;
}

.Container {
	overflow-x: hidden;
	padding-top: 50px;
	padding-bottom: 50px;
	grid-area: container;
}

.Section {
	margin-top: 40px;
}

.HeaderLine {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 8px;
}

.SubHeader {
	margin-top: 30px;
	margin-bottom: 10px;
}

.Checkbox {
	margin: 0;
}

.Input {
	width: 100%;
	max-width: unset;
	margin: 0;
}

.FormLine {
	position: relative;
	display: flex;
	justify-content: space-between;
	margin-top: 32px;
}

.FormLine > div {
	width: 100%;
	margin: 0;
}

.FormLine > div + div {
	margin-left: 40px;
}

.FormLine > button {
	margin-left: 20px;
}

.Controls {
	margin-top: 50px;
}

.helper {
	position: absolute;
	top: 20px;
	left: 10px;
	color: var(--color-red);
	font: var(--gilroy-Medium-12);
	font-size: smaller;
	padding: 0 5px;
}

.error {
	position: absolute;
	bottom: 15px;
	color: var(--color-red);
	font: var(--gilroy-Medium-12);
	font-size: smaller;
	padding: 0 5px;
}

.disabled {
	opacity: 0.5;
}

.removeDisabled {
	opacity: 0.5;
	cursor: not-allowed;
	background-color: transparent !important;
}
