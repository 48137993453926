/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/11/2020, 15:02
 */

.Select {
	font: var(--gilroy-Medium-16);
	height: 60px;
}

.Select :global .select__indicator-separator {
	display: none;
}

.Select :global .select__indicator {
	color: var(--color-dark-grey);
}

.Select :global .select__value-container {
	padding: 0;
}

.Select :global .select__menu {
	top: unset;
	margin-bottom: 0;
	border-radius: unset;
	box-shadow: none;
}

.Select :global .select__placeholder {
	color: var(--color-grey100);
}

.Select :global .select__control {
	border: none;
	border-bottom: 1px solid;
	border-radius: 0;
	background-color: transparent;
	box-shadow: none;
}

.Select :global .select__control:hover {
	border-color: var(--color-dark-grey);
	box-shadow: none;
}

.Helper {
	font: var(--gilroy-Medium-16);
}
