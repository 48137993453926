.helper {
	position: relative;
	padding: 24px;
	margin-bottom: 24px;
	background: var(--color-light-green);
	font: var(--gilroy-Medium-16-140);
	letter-spacing: 1px;
}

.helper img {
	position: absolute;
	width: 16px;
	height: 16px;
	top: 8px;
	right: 8px;
}

.error {
	position: relative;
	color: var(--color-red100);
	padding: 0px 5px;
}

.errorText {
	position: absolute;
	font: var(--gilroy-Medium-12);
	font-size: smaller;
}

.loader {
	margin-top: 30px;
}

.statisticHeader {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 16px;
}

.btnsContainer {
	display: flex;
}

.statistic__chartModeBtn {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 164px;
	height: 40px;
	cursor: pointer;
	font: var(--gilroy-Medium-18-140);
	color: var(--color-grey);
	border: none;
}

.statistic__chartModeBtnActive {
	background-color: var(--color-dark-grey100);
	color: var(--color-white);
}

.statisticByDate__mainContent {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.statisticByDate__mainContentEmpty {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 215px;
}

.inputsWrapper {
	display: flex;
	align-items: flex-end;
  gap: 24px;
}

.filtersButton {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;

}

.filtersButton span {
  font: var(--gilroy-Medium-16);
  line-height: 140%;
  border-bottom: 1px solid var(--color-dark-grey);
}

.inputTrack {
	width: 100%;
	max-width: 150px;
	margin: auto 12px 0px !important;
}

.inputOutlets {
	width: 100%;
	max-width: 150px;
	margin-top: auto;
}

.inputDate {
	min-width: 150px;
}

.inputStartDate {
	composes: inputDate;
	margin: 0 12px 0 0;
}

.topTracksTable {
	width: 100%;
	margin: 0 0 24px 0;
}

.topTracksTable td {
	text-align: left !important;
}

.topTracksTable__itemNumber {
	color: var(--color-grey);
}

.topTracksTable__body {
	background-color: white;
}

.topTracksTable__header {
	font: var(--gilroy-Medium-14);
	color: var(--color-grey100);
	text-transform: lowercase;
	margin-bottom: 16px;
	padding: 8px;
}

.topTracksTable__body>tr>td,
.topTracksTable__body>tr div td {
	font: var(--gilroy-Medium-16-19);
	padding: 15px 10px;
}

.topTracksTable__body>tr>*:first-child {
	width: 40 px;
}

.topTracksTable__item {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	position: relative;
}

.topTracksTable__fullName {
	position: absolute;
	left: 0px;
	top: 30px;
	padding: 3px;
	background: var(--color-dark-grey);
	color: var(--color-grey100);
	opacity: 0;
	display: none;
	z-index: 1;
	transition: 0.1s ease;
}

.topTracksTable__itemLink:hover .topTracksTable__fullName {
	border: 5px red solid;
	color: red;
	background-color: aquamarine;
	opacity: 1;
	display: block;
}

.topTracksTable {
	border-spacing: 0px;
}

.topTracksTable td {
	border-bottom: 2px solid var(--color-white100);
	border-spacing: 0px 0px;
}

.topTracksTable__body>tr>td:first-child,
.topTracksTable__body>tr div div *:first-child {
	max-width: 24px !important;
	overflow: visible;
}

.topTracksTable__body>tr>*:nth-child(2),
.topTracksTable__body>tr div *:nth-child(2) {
	width: 40px;
}

.topTracksTable__body>tr>*:nth-child(3),
.topTracksTable__body>tr div *:nth-child(3) {
	max-width: 25vw;

}

.topTracksTable__body>tr>*:nth-child(4),
.topTracksTable__body>tr div *:nth-child(4) {
	max-width: 20vw;

}

.topTracksTable__body>tr>*:nth-child(5),
.topTracksTable__body>tr div *:nth-child(5) {
	max-width: 25vw;
}

.topTracksTable__body>tr>*:nth-child(6),
.topTracksTable__body>tr div *:nth-child(6) {
	max-width: 28vw;

}

.topTracksTable__body>tr>*:nth-child(7),
.topTracksTable__body>tr div *:nth-child(7) {

	max-width: 8vw;
}


.topTracksTitle,
.topTracksTitleMain {
	margin: 40px 0 16px 0;
}

.topTracksTitle {
	font: var(--gilroy-Medium-24-140p);
}

.topTracksTitleMain {
	font: var(--gilroy-Medium-32);
}

.tracksCheckmarks {
	height: 300px;
}

.tableRow:hover {
	cursor: pointer;
}

tr.noSeparate:not(.lastRow) td {
	border-bottom: 0px solid transparent;
}

.moreIcon {
	max-width: 60px !important;
	cursor: pointer;
	text-overflow: unset;
}

.moreIcon .loader {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 20px;
	margin-top: 0;
	animation: spin 2s linear infinite;
	overflow: visible;
}

.moreIcon svg {
	max-width: 20px;
}

.moreIcon img {
	transform: rotate(0deg);
	margin-top: 6px;
	transition: transform .4s ease;
}

.moreIcon.active img {
	transform: rotate(180deg);
	transition: transform .4s ease;
}

.outletItem {
	width: 16px;
	height: 16px;
	border-radius: 50%;
}

td.outletInfo {
	display: flex;
	gap: 8px;
	align-items: center;
}

.accordionRow {
	overflow: hidden;
	display: none;
	transition: max-height 0.15s ease-out;
}

.rowOpen {
	transition: max-height 0.15s ease-out;
	display: table-row;
}

.statistic__noData {
	margin: 150px 0px;
	text-align: center;
	font: var(--gilroy-Medium-24);
	color: var(---color-dark-grey100);
}

.noReleaseData {
	margin: 319px auto 0;
}

.noReleaseData h4 {
	font: var(--gilroy-Medium-32);
	font-weight: 500;
	line-height: 40px;
}


.btnContainer {
  margin-left: auto;
}

@media screen and (min-width: 800px) {
	.inputTrack {
		max-width: 180px;
	}

	.inputOutlets {
		max-width: 180px;
	}
}

@media screen and (min-width: 900px) {
	.inputTrack {
		max-width: 200px;
	}

	.inputOutlets {
		max-width: 200px;
	}
}

@media screen and (min-width: 1024px) {
	.inputTrack {
		max-width: 220px;
	}

	.inputOutlets {
		max-width: 220px;
	}
}

@media screen and (min-width: 1280px) {
	.inputTrack {
		max-width: 25vw;
	}

	.inputOutlets {
		max-width: 15.5vw;
	}
}

@keyframes spin {
	from {
			transform: rotate(0deg);
	}
	to {
			transform: rotate(360deg);
	}
}