.wrapper {
  flex: 1;
	width: 100%;
	height: 100%;
	background: linear-gradient(
			91deg,
			rgba(255, 255, 255, 0.04) 38.5%,
			#fff 50.11%,
			rgba(255, 255, 255, 0) 61.49%
		),
		var(--color-light-grey);
	animation: fadeIn 1s ease-in-out infinite alternate;
}

@keyframes fadeIn {
	from {
		opacity: 0.5;
	}
	to {
		opacity: 1;
	}
}
