/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 10/11/2020, 15:28
 */

.Page {
 
	display: flex;
	align-items: center;
  
  
}

.titleWrapper {
	margin-top: 60px;
	margin-bottom: 10px;
}

.Content {
	min-height: 60vh;
}

.SubHeader {
	font: var(--gilroy-Medium-24);
	margin-bottom: 85px;
	color: var(--color-grey);
}

.List {
	display: flex;
	flex-wrap: wrap;
	/* max-height: 65vh; */
	margin-top: 32px;
}




.Account {
	font: var(--gilroy-Medium-24);
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 282px;
	height: 282px;
	/*margin-right: 42px;*/
	margin: 15px;
	margin-bottom: 42px;
	cursor: pointer;
	color: var(--color-white100);
}

.Account:before {
	position: absolute;
	top: 0;
	left: 0;
	width: calc(100% - 12px);
	height: 51%;
	content: '';
	background-color: var(--color-dark-grey);
}

.Account:after {
	position: absolute;
	bottom: 0;
	left: 12px;
	width: calc(100% - 12px);
	height: 50%;
	content: '';
	background-color: var(--color-dark-grey);
}

.Create {
	margin: 15px;
}

.Account span {
	z-index: 1;
	padding: 30px;
	text-align: center;
	word-break: break-word;
}

.svg_branding > path {
	fill: var(--color-light-green);
}
.svg_branding > rect {
	stroke: var(--color-light-green);
}


@media screen and (min-width:768px) {
	.titleWrapper {
		margin-top: 10px;
	}
}

/* @media screen and (min-width:1024px) {
	.titleWrapper {
		margin-top: 160px;
	}
} */
