.helper {
	position: relative;
	padding: 24px;
	margin-bottom: 24px;
	background: var(--color-light-green);
	font: var(--gilroy-Medium-16-140);
	letter-spacing: 1px;
}

.helper img {
	position: absolute;
	width: 16px;
	height: 16px;
	top: 8px;
	right: 8px;
}

.repertoire__songItemsWrapper {
	display: flex;
	flex-direction: column;
}

.repertoire__songItemsWrapper a {
	color: unset;
	text-decoration: none;
	margin-bottom: 2px;
}

.draft_moderation {
	color: var(--color-grey100);
}

.song__table {
	display: flex;
	padding: 14.5px 15px 14.5px 64px;
}

.song__table > * {
	position: relative;
}

.song__table > * > :first-child {
	display: inline-block;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.song__table > * > :nth-child(2) {
	position: absolute;
	left: 0;
	top: 100%;
	padding: 3px;
	background: var(--color-dark-grey);
	color: var(--color-grey100);
	opacity: 0;
	visibility: hidden;
	z-index: 1;
	transition: 0.1s ease;
}

.song__table > *:hover > :nth-child(2) {
	opacity: 1;
	visibility: visible;
}

.song__table > *:first-child {
	width: 14vw;
	margin-right: 2.344vw;
}

.song__table > *:nth-child(2) {
	width: 14vw;
	margin-right: 2.344vw;
}

.song__table > *:nth-child(3) {
	width: 16vw;
	margin-right: 2.344vw;
}

.song__table > *:nth-child(4) {
	width: 12vw;
	margin-right: 2.344vw;
}

.repertoire__songItemsWrapper .itemWrapper {
	position: relative;
	align-items: center;
	font: var(--gilroy-Medium-16-19);
	background-color: var(--color-white);
}

.repertoire__songItemsWrapper li:last-child {
	margin-bottom: 0;
}

.repertoire__songTitle {
	font: var(--gilroy-Medium-18);
	color: var(--color-grey100);
	text-transform: lowercase;
	margin-bottom: 16px;
	padding-top: 0;
	padding-bottom: 0;
}

.loader {
	margin-top: 30px;
}

.repertoire__songItemLogo {
	position: absolute;
	top: 16px;
	left: 16px;
	width: 22px;
	height: 22px;
}

.repertoire__songItemError {
	color: var(--color-red);
}

.repertoire__songItemError .repertoire__songItemLogo path {
	fill: var(--color-red);
}

.repertoire__mainContent {
	margin-top: 30px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.repertoire__mainContentEmpty {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 215px;
}

.repertoire__mainContentEmpty > *:first-child {
	margin-bottom: 32px;
}

.repertoire__mainContentEmpty > *:last-child {
	color: var(--color-dark-grey);
	font: var(--gilroy-Medium-24-140p);
	letter-spacing: 1px;
}

.repertoire__mainContentEmpty > *:last-child a {
	color: var(--color-dark-grey);
	text-decoration: underline;
	cursor: pointer;
}

.itemWrapper {
	display: flex;
	justify-content: space-between;
}

.itemLink {
	flex-grow: 1;
}

.menuMore {
	position: relative;
	padding: 0px 14px 0px 14px;
}

.menuMoreList {
	position: absolute;
	bottom: -37px;
	right: 0;
	background-color: var(--color-black);
	color: var(--color-white);
	z-index: 99999;
}

.menuMoreListItem {
	padding: 9px 24px;
}

.menuMoreWrapper {
	display: flex;
	align-items: center;
	height: 48px;
	cursor: pointer;
}

.menuMoreWrapperActive {
	position: relative;
	display: flex;
	align-items: center;
	height: 48px;
	background-color: var(--color-black);
}

@media screen and (max-width: 1200px) {
	.song__table > *:first-child {
		width: 12vw;
	}

	.song__table > *:nth-child(2) {
		width: 12vw;
	}

	.song__table > *:nth-child(3) {
		width: 14vw;
	}

	.song__table > *:nth-child(4) {
		width: 10vw;
	}
}
