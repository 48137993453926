.recordings_title {
	display: inline-block;
	margin-bottom: 24px;
	font: var(--gilroy-Medium-24);
	letter-spacing: 0.96px;
}

.recordings_table_row {
	padding: 0px 24px;
	display: grid;
	grid-template-columns: 2fr 1fr 1fr 1fr;
	gap: 16px;
	margin-bottom: 4px;
}

.recordings_table_title_cell {
	padding: 8px 0px;
	font: var(--gilroy-Medium-14);
	line-height: 16px;
	color: var(--color-grey100);
	text-transform: lowercase;
}

.recordings_table_data_cell {
	display: flex;
	align-items: center;
	padding: 14px 0px;
	font: var(--gilroy-Medium-16);
	line-height: 20px;
	letter-spacing: 1px;
	word-break: break-word;
}

.recordings_table_data_cell img {
	margin-right: 24px;
}

.text_uppercase {
	text-transform: uppercase;
}

.background_white {
	background-color: var(--color-white);
}
