/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/11/2020, 15:02
 */

.Page {
	margin-top: 71px;
	margin-right: 181px;
	margin-left: 134px;
}

.Page > section {
	margin-bottom: 40px;
}

.Wizard {
	background-color: var(--color-white);
}

.Header {
	margin-bottom: 56px;
}

.Header :global .sub {
	margin-top: 16px;
}

.Preferences {
	align-items: baseline;
}

.Preferences ul {
	list-style: none;
}

.Preferences li {
	display: flex;
}

.Preferences img {
	margin-right: 20px;
}

.Roles {
	display: flex;
	justify-content: space-around;
	padding: 20px 0;
}

.Roles > * {
	margin-right: 35px;
}

.Roles > *:nth-child(even) {
	margin-right: 0;
}

.Sub {
	margin-bottom: 40px;
}

.Controls {
	display: flex;
	margin-top: 40px;
}

.Controls button {
	margin-right: 30px;
}
