.item {
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
	font: var(--gilroy-Medium-20-28);
	width: 100%;
	color: var(--color-white100);
}

.item_wrapper {
	display: flex;
	padding: 10px 16px;
}

.item_wrapper img {
	margin-right: 7px;
}

.item a {
	font: inherit;
	text-decoration: none;
	color: inherit;
}

.item:hover {
	text-decoration: line-through;
}

.item :global a.active {
	text-decoration: line-through;
}

.flexContainer {
	display: flex;
	align-items: center;
}

.flexContainer svg {
	margin-right: 7px;

}
