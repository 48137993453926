/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/11/2020, 15:02
 */

.Progress {
	overflow: hidden;
	width: 100%;
	height: 15px;
	background-color: var(--color-grey);
}

.Bar {
	height: 100%;
	background-color: var(--color-light-green);
}
