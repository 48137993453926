.Lang {
	font: inherit;
	font-weight: bold;
	cursor: pointer;
	user-select: none;
	letter-spacing: 1px;
}

.list {
	display: flex;
	flex-direction: row;
	font: var(--gilroy-Medium-18);
	line-height: 20px;
	letter-spacing: 1px;
}

.list li + li {
	margin-left: 11px;
}

.list :global .another-lang {
	transition: all 0.4s ease-out;
	opacity: 0;
	color: var(--color-grey);
}

.list:hover :global .another-lang {
	opacity: 1;
}

.anotherLangRubric {
	transition: all 0.4s ease-out;
	opacity: 0.5;
	color: var(--color-grey);
}
.anotherLangRubric:hover {
	opacity: 1;
}
