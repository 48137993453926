.info button {
	display: flex;
	width: 24px;
	flex-direction: column;
	align-self: center;

	margin-left: 16px;
}

.title__info {
	display: flex;
	align-items: flex-end;
	text-transform: lowercase;
	font: var(--gilroy-Medium-24);
}

.main_wrapper {
	margin-top: 25px;
}

.table__header {
	display: flex;
	min-width: 880px;
	flex-wrap: nowrap;
	padding: 24px 15px 8px 20px;
	align-items: flex-end;
	color: var(--color-grey100);
	padding-top: 0;
	font: var(--gilroy-Medium-18);
	text-align: center;
}

.label {
	display: flex;
	justify-content: center;
	align-items: flex-end;
	padding-left: 0px !important;
	padding-right: 0px !important;
}

.label_first {
	compose: label;
	padding-left: 15px !important;
	justify-content: left;
	align-self: flex-end;
}

.label_last {
	compose: label;
	padding-left: 50px !important;
	justify-content: left;
	align-self: flex-end;
}

.table__header > *:first-child,
.table__header > *:last-child {
	text-align: left;
}

.table__body > * {
	/* align-items: center; */
	text-align: center;
	margin-bottom: 2px;
}
.table__body > *:last-child {
	margin-bottom: 0;
}

.table__item {
	font: var(--gilroy-Medium-14-17);
	background: var(--color-white);
	position: relative;
}

.status {
	/* text-align: center; */
	/* margin-top: 176px; */
	font: var(--gilroy-Medium-24);
}
