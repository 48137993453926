.container {
	margin-top: 40px;
}

.controls {
	display: flex;
	justify-content: space-between;
}

.arrows {
	display: flex;
}

.arrowBtn {
	display: flex;
	width: 32px;
	height: 32px;
	justify-content: center;
	align-items: center;
	border: none;
	background-color: transparent;
	cursor: pointer;
	transition: transform 0.2s ease-in-out;
}

.arrowBtn:hover {
	opacity: 0.8;
}

.arrowBtn:active {
	transform: scale(0.7);
}

.arrowLeft {
	-webkit-transform: rotate(180deg); /* Safari and Chrome */
	-ms-transform: rotate(180deg); /* IE 9 */
	transform: rotate(180deg); /* Standard syntax */
}

.carousel {
	position: relative;
	width: 100%;
	overflow: hidden;
	margin: 20px auto;
}

.cardsContainer {
	display: flex;
	gap: 20px;
	transition: transform 0.5s ease-in-out;
}
