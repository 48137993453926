/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 11/12/2020, 16:05
 */

.Page {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	/* height: 100%; */
	margin-top: 50px;
	margin-bottom: 100px;
}

.Page > * {
	margin-bottom: 30px;
}