.radio_container {
	display: flex;
	align-items: center;
}

.text {
	font: var(--gilroy-Medium-18-140);
	letter-spacing: 0.15px;
	color: rgba(0, 0, 0, 0.87);
	margin-right: 35px;
}
