.page {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	background-color: var(--color-white100);
}

.form {
	width: 100%;
	max-width: 416px;
}

.title {
	margin-bottom: 24px;
}

.title div:first-child {
	line-height: 48px !important;
	margin-bottom: 10px;
}

.button {
	margin-top: 20px;
}

.controls {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	margin-top: 23px;
}
