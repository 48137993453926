.input {
	position: relative;
	display: flex;
	align-items: flex-end;
}

.input > :first-child {
	width: 100%;
}

.input input {
	max-width: 100% !important;
}

.item {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 24px;
}

.item:not(:last-child) {
	margin-bottom: 24px;
}

.helper {
	position: absolute;
	bottom: -18px;
	left: 0px;
	color: var(--color-red);
	font: var(--gilroy-Medium-12-12);
	padding: 0 4px;
}

.disabled,
.disabled input {
	opacity: 0.6;
	cursor: not-allowed;
}
