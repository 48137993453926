.container {
	display: flex;
	align-items: center;
	width: 100%;
	padding: 12px;
	font: var(--gilroy-Medium-16-24);
	color: var(--color-exclamation900);
	background: var(--color-exclamation100);
}

.container img {
	margin-right: 12px;
}
