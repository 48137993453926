.helper {
	position: relative;
	width: 368px;
	padding: 40px;
	background: var(--color-light-green);
	/* min-height: calc(100vh - 235px); */
}

.helper__wrapper {
	position: sticky;
	top: 112px;
}

.helper__title {
	display: inline-block;
	font: var(--gilroy-Medium-24-140p);
	margin-bottom: 16px;
}

.helper__main {
	font: var(--gilroy-Medium-16-140);
}
