.page {
	min-height: 85vh;
}

.main {
	padding: 0px 24px;
}

.addRoleContractsBanner {
	margin-bottom: 30px;
}

.main__title {
	display: inline-block;
	font: var(--gilroy-Medium-24-140p);
	text-transform: lowercase;
}

.title {
	display: inline-block;
	font: var(--gilroy-Medium-24-140p);
	letter-spacing: 1px;
	text-transform: lowercase;
}

.lh__title {
	font: var(--gilroy-Medium-24-29);
}

.body {
	position: relative;
	margin-top: 24px;
}

.section__information {
	display: flex;
	margin-bottom: 4px;
}

.info__table {
	flex-grow: 1;
}

.table {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-row-gap: 16px;
	margin-top: 24px;
	margin-bottom: 40px;
	padding: 20px 24px;
	background-color: var(--color-white);
}

.item {
	display: flex;
	flex-direction: column;
	max-width: 200px;
}

.item_genres {
	position: relative;
}

.item_genres:hover > :nth-child(2) {
	opacity: 1;
	visibility: visible;
}

.item_genres > :nth-child(1) {
	display: inline-block;
	width: 100%;
}

.item_genres > :nth-child(2) {
	position: absolute;
	left: 0;
	top: 100%;
	padding: 3px;
	background: var(--color-dark-grey);
	color: var(--color-grey100);
	opacity: 0;
	visibility: hidden;
	z-index: 1;
	transition: 0.1s ease;
}

.item__title {
	font: var(--gilroy-Medium-12-15);
	color: var(--color-grey100);
	margin-bottom: 8px;
}

.item__text {
	font: var(--gilroy-Medium-16-19);
	word-wrap: break-word;
}

.item__textLh {
	font: var(--gilroy-Medium-16-140);
}

.dropZone__wrapper {
	width: 200px;
	height: 200px;
	margin-top: 24px;
	margin-left: 24px;
}

.dropZone__wrapper .error {
	margin-top: 16px;
}

.DropZone {
	width: 100%;
	height: 100%;
	border: none;
	background-image: url("data:image/svg+xml;utf8,<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' style='fill: none; stroke: black; stroke-width: 1; stroke-dasharray: 8 8'/></svg>");
}

.DropZone > div > div {
	margin: 0 34.04px 0 38.3px;
	display: flex;
	flex-direction: column;
	text-align: center;
	font: var(--gilroy-Medium-18-22);
}

.DropZone > div > div > img {
	width: 54.47px;
	margin-bottom: 25px;
	margin-right: 0;
}

.DropZone span {
	display: block;
}

.Preview {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 200px;
	height: 200px;
}

.Preview img {
	max-height: 100%;
	max-width: 100%;
}

.preLoader {
	background-image: url('images/loading_circle.svg');
	background-size: 70%;
	background-position: center;
	background-repeat: no-repeat;
	width: 70%;
	height: 70%;
	object-fit: cover;
	animation: loader 1s linear infinite;
}

.Preview img.loader {
	width: 0;
	height: 0;
}

@keyframes loader {
	100% {
		transform: rotate(360deg);
	}
}

.info {
	width: 100%;
}

.recordings {
	margin: 24px 0 40px;
}

.recordings__item {
	display: flex;
	align-items: center;
	height: 64px;
	background: var(--color-white);
	padding: 0 16px;
}

.recordings__item > * {
	display: inline-block;
	font: var(--gilroy-Medium-14-17);
	letter-spacing: 1px;
}

.recordings__item > :nth-child(1) {
	width: 1.875vw;
	margin-right: 2.422vw;
	padding: 12px 0 11px;
	text-align: right;
}

.recordings__item > :nth-child(2) {
	width: 37.891vw;
	margin-right: 0.781vw;
	padding: 12px 0 11px;
}

.recordings__item > :nth-child(3) {
	width: 3.203vw;
	margin-right: 2.5vw;
	padding: 12px 0 11px;
	text-align: right;
	opacity: 0;
	visibility: hidden;
}

.recordings__item > :nth-child(4) {
	cursor: pointer;
}

.recordings > :not(:last-child) {
	margin-bottom: 2px;
}

.errorButton {
	text-decoration: underline;
	font: var(--gilroy-Medium-16-19);
}

.distributions {
	margin-top: 24px;
	display: flex;
}

.distributions > *:first-child {
	min-width: 18.75vw;
	margin-right: 24px;
}

.prev {
	display: inline-block;
	padding: 18px 65px 18.43px 0px;
	font: var(--gilroy-Medium-18-22);
	text-decoration: underline;
	cursor: pointer;
}

.next {
	width: 328px;
	height: 60px;
	background: var(--color-dark-grey);
	border: none;
	color: var(--color-white);
	letter-spacing: 1px;
	font: var(--gilroy-Medium-18-140);
	cursor: pointer;
}

.next.disabled {
	background: var(--color-grey50);
	cursor: not-allowed;
}

.helper {
	width: 28.75vw;
	padding: 40px;
	background: var(--color-light-green);
}
.helper__title {
	display: inline-block;
	font: var(--gilroy-Medium-24-140p);
	margin-bottom: 16px;
}
.helper__main {
	font: var(--gilroy-Medium-16-140);
}

.error {
	color: var(--color-red);
	font: var(--gilroy-Medium-16-19);
}

.input {
	position: relative;
}

.ErrorHelper {
	position: absolute;
	bottom: -45px;
	left: 0px;
	color: var(--color-red);
	font: var(--gilroy-Medium-12);
	font-size: smaller;
	padding: 0 5px;
}

.bottom_container {
	width: 100%;
}

.play {
	display: block;
	margin-left: auto;
	width: 20px;
	height: 20px;
}

@media screen and (min-width: 500px) {
	.item {
		max-width: 240px;
	}

	.dropZone__wrapper {
		width: 240px;
		height: 240px;
	}

	.Preview, .Preview img {
		width: 240px;
		height: 240px;
	}
}

@media screen and (min-width: 600px) {
	.item {
		max-width: 260px;
	}

	.dropZone__wrapper {
		width: 260px;
		height: 260px;
	}

	.Preview, .Preview img {
		width: 260px;
		height: 260px;
	}
}

@media screen and (min-width: 850px) {
	.table {
		grid-template-columns: repeat(2, 1fr);
		grid-column-gap: 24px;
		grid-row-gap: 16px;
		height: 300px;
	}

	.item {
		max-width: 300px;
	}

	.dropZone__wrapper {
		width: 300px;
		height: 300px;
	}

	.Preview, .Preview img {
		width: 300px;
		height: 300px;
	}
}

@media screen and (min-width: 900px) {
	.table {
		grid-template-columns: repeat(3, 1fr);
		grid-column-gap: 24px;
		grid-row-gap: 16px;
		height: 240px;
	}

	.item {
		max-width: 240px;
	}

	.dropZone__wrapper {
		width: 240px;
		height: 240px;
	}

	.Preview, .Preview img {
		width: 240px;
		height: 240px;
	}
}

@media screen and (min-width: 1200px) {
	.item {
		max-width: 200px;
	}

	.table {
		height: 200px;
	}

	.dropZone__wrapper {
		width: 200px;
		height: 200px;
	}

	.Preview, .Preview img {
		width: 200px;
		height: 200px;
	}
}
