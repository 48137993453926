.container {
	padding: 98px 40px 115px 40px;
}

.treaties__list > :not(:last-child) {
	margin-bottom: 24px;
}

.item {
	padding-bottom: 24px;
	margin-bottom: 24px;
}

.addRoleContractsBanner {
	margin-bottom: 40px;
}

.itemWrapper {
	min-width: 100%;
}

.title {
	display: inline-block;
	font: var(--gilroy-Medium-24-140p);
	color: var(--color-dark-grey);
	letter-spacing: 1px;
	padding-right: 10px;
}

.headerWrapper {
	display: flex;
	justify-content: space-between;
	margin-bottom: 16px;
}

.downloadWrapper {
	min-width: 282px;
	width: 282px;
}

.download {
	padding-top: 8px;
}

.download a {
	color: unset;
}

.item__body {
	background-color: var(--color-white);
	padding: 24px;
	margin-bottom: 2px;
}

.table__title {
	margin-bottom: 8px;
	font: var(--gilroy-Medium-16-140);
	color: var(--color-dark-grey);
	letter-spacing: 1px;
}

.table__item {
	font: var(--gilroy-Medium-16-100);
}

.item__title {
	margin: 0px 0px 8px 0px;
	font: var(--gilroy-Medium-16-140);
	display: inline-block;
	text-transform: lowercase;
	cursor: pointer;
}

.emptyItem__title {
	margin: 0px 0px 8px 0px;
	font: var(--gilroy-Medium-16-140);
	display: inline-block;
	text-transform: lowercase;
	cursor: pointer;
	color: var(--color-grey);
}

.condition__title {
	font: var(--gilroy-Medium-16-19);
	margin-bottom: 4px;
}

.territory__title {
	margin-bottom: 4px;
	font: var(--gilroy-Medium-16-19);
}

.empty {
	font: var(--gilroy-Medium-16-19);
	color: var(--color-dark-grey);
	display: inline-flex;
	justify-content: flex-start;
	align-items: center;
	margin-right: 24px;
}

.country {
	font: var(--gilroy-Medium-16-19);
	display: inline-flex;
	justify-content: flex-start;
	align-items: center;
	margin-right: 24px;
}

.country img {
	margin-left: 8px;
}

.item__main > *:not(:last-child) {
	margin-bottom: 4px;
}

.table {
	display: flex;
}
.table > div {
	width: 263px;
}

.load__btn {
	display: flex;
	align-items: center;
	align-self: flex-end;
	margin-left: auto;
	border: none;
	background: transparent;
	font: var(--gilroy-Medium-16-100);
	text-decoration: underline;
	cursor: pointer;
}

.load__btn > img {
	margin-right: 8px;
}

.btn__wrapper {
	position: relative;
	width: 416px;
	margin-top: 48px;
}

.btn__wrapper > button {
	display: inline-block;
	width: 100%;
	height: 60px;
}

.btn__newContract {
	width: 100%;
	height: 100%;
	border: 1px solid var(--color-dark-grey);
	background: var(--color-dark-grey);
	color: var(--color-white);
	font: var(--gilroy-Medium-18-140);
	cursor: pointer;
}

.disabled {
	border: 1px solid var(--color-grey100);
	background: var(--color-grey100);
	cursor: auto;
}

.btn__message {
	position: absolute;
	top: 76px;
	color: var(--color-red100);
	font: var(--gilroy-Medium-16-100);
}

.dates {
	margin-left: 40px;
	color: var(--color-grey);
}

.mainData {
	padding-right: 20px;
}

.datesSection {
	flex-wrap: nowrap !important;
	color: var(--color-grey);
}

.expires {
	color: var(--color-red);
}

.loader {
	margin-top: 30px;
}

.dateColumn {
	flex-wrap: nowrap !important;
	width: 250px !important;
	flex: 0 0 0px !important;
	max-width: 250px;
	min-width: 250px;
}

.typeId {
	margin-top: 4px;
	font: var(--gilroy-Medium-16-19);
	color: var(--color-grey);
}

.flag {
	display: inline-block;
	margin-right: 4px;
}

.flagsBox {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

@media screen and (min-width: 768px) {
	.container {
		padding-top: 24px;
	}
}
