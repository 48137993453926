.topBtnsWrapper {
	display: flex;
	align-items: center;
	margin-top: 24px;
}

.rubricSettingsLink {
	margin-right: 32px;
	font: var(--gilroy-Medium-18);
	text-decoration: underline;
	color: var(--color-dark-grey);
}

.createArticleBtn {
	display: flex;
	justify-content: center;
	flex-wrap: nowrap;
	align-items: center;
	padding: 10px 24px;
	text-decoration: none;
	color: var(--color-dark-grey);
	font: var(--gilroy-Medium-16);
	border: none;
	border-radius: 20px;
	background-color: var(--color-light-green);
	cursor: pointer;
}

.createArticleBtnLink {
	display: flex;
	align-items: center;
}

.iconPlus {
	width: 16px;
	margin-right: 8px;
}

@media screen and (min-width: 768px) {
	.topBtnsWrapper {
		margin-top: 0;
	}
}
