.Page {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	background-color: var(--color-white100);
}

.Form {
	max-width: 876px;
}

.subtitle {
	max-width: 698px;
	margin-top: 16px;
	margin-bottom: 32px;
}

.button {
	max-width: 400px;
}
