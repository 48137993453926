.page {
	padding-top: 48px;
	min-height: 85vh;
}

.main {
	padding-right: 8px;
}

.main__notForm {
	width: 416px;
	padding-right: 40px;
	margin-bottom: 24px;
}

.main__wrapper {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin-top: 16px;
	padding-left: 40px;
	padding-right: 40px;
}

.mainTitle {
	margin-top: 24px;
	margin-left: 40px;
	font: var(--gilroy-Medium-24-140p);
}

.notices__wrapper {
	padding: 8px 40px 0;
}

.info button {
	display: flex;
	width: 24px;
	margin-left: 0;
}

.title__info {
	display: flex;
	align-items: flex-end;
	text-transform: lowercase;
}

.title__info.info button {
	margin-bottom: 4px;
}

.title {
	display: flex;
	flex-direction: column;
	margin-right: 16px;
	font: var(--gilroy-Medium-24-140p);
}

.table__item {
	display: flex;
	position: relative;
	padding: 0 8px 0 50px;
	align-items: flex-start;
	background-color: var(--color-white);
	border: 1px solid transparent;
	font: var(--gilroy-Medium-14-17);
}

.table__item.table__itemSel {
	border-color: var(--color-green);
}

.table__item:not(:last-child) {
	margin-bottom: 2px;
}

.table__item > *:nth-child(2) {
	width: 15px;
	margin-right: 16px;
	padding: 22px 0 21px;
	text-align: right;
}

.table__item > *:nth-child(3) {
	width: 11.172vw;
	flex-grow: 1;
	margin-right: 16px;
	padding: 22px 0 21px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.table__item > *:nth-child(4) {
	margin-right: 8px;
	padding: 19px 0;
	margin-left: auto;
	width: 24px;
	cursor: pointer;
}

.table__item > *:nth-child(5) {
	padding: 19px 0;
}

.tableItem__sel {
	position: absolute;
	left: 16px;
	top: 50%;
	fill: var(--color-light-green);
	transform: translateY(-50%);
}

.tableItem__inselected {
	width: 24px;
	height: 24px;
	border: 2px solid var(--color-light-grey);
	border-radius: 50%;
	background: transparent;
}

.main__notForm .table__item {
	padding: 0 16px 0 66px;
}

.main__notForm .table__item > *:nth-child(2) {
	margin-right: 24px;
}

.toogleMenu {
	cursor: pointer;
}

.form {
	position: relative;
	width: 100%;
	padding: 56px 40px 60px 24px;
	background: var(--color-white);
}

.lastSave {
	display: flex;
	align-items: center;
	margin-bottom: 40px;
	background: var(--color-light-grey);
}

.lastSave__switch {
	padding: 0 7px;
}

.lastSave > :last-child {
	padding: 19px;
	padding-left: 0;
	font: var(--gilroy-Medium-16-140);
}

.form__fragment_help_btn {
	padding-bottom: 5px;
}

.form__title,
.artist_title {
	display: flex;
	justify-content: left;
	align-items: center;
	font: var(--gilroy-Medium-24-28);
	color: var(--color-dark-grey);
	letter-spacing: 0.15px;
}

.form__title {
	margin-bottom: 16px;
}

.artist_title {
	margin-bottom: 4px;
}

.form__section {
	display: grid;
	grid-column-gap: 24px;
	grid-row-gap: 24px;
}

.input {
	position: relative;
}

.form__input {
	width: 100%;
}

.input_leftCol {
	position: relative;
}

.input_rightCol {
	position: relative;
}

.input_country {
	margin-top: 20px;
}

.input_leftCol > div,
.input_rightCol > div {
	width: 100%;
}

.Helper {
	position: absolute;
	bottom: -20px;
	left: 0px;
	height: 15px;
	color: var(--color-red);
	font: var(--gilroy-Medium-12-12);
	padding: 0 5px;
}

.errorHelper {
	position: absolute;
	bottom: -10px;
	left: 0px;
	color: var(--color-red);
	font: var(--gilroy-Medium-12-12);
	padding: 0 4px;
	text-transform: lowercase;
}

.snippetError {
	margin-top: 12px;
	color: var(--color-red);
	font: var(--gilroy-Medium-12);
	font-size: smaller;
	padding: 0 5px;
	text-transform: lowercase;
}

.first__section,
.second__section {
	margin-bottom: 48px;
}

.artist__section {
	margin-bottom: 30px;
}

.third__section {
	display: flex;
	width: 328px;
}

.form__close {
	position: absolute;
	top: 20px;
	right: 30px;
	width: 24.5px;
	cursor: pointer;
}

.form__submit {
	width: 328px;
	height: 47px;
	margin-top: 48px;
	border: none;
	background: var(--color-dark-grey);
	font: var(--gilroy-Medium-18-140);
	color: var(--color-white100);
	cursor: pointer;
}

.button__wrapper {
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding: 48px 40px 80px;
}

.prev {
	display: inline-block;
	padding: 18px 65px 18.43px 0px;
	font: var(--gilroy-Medium-18-22);
	text-decoration: underline;
	cursor: pointer;
}

.next {
	max-width: 328px;
	width: 25.625vw;
	height: 60px;
	background: var(--color-dark-grey);
	border: none;
	color: var(--color-white);
	letter-spacing: 1px;
	font: var(--gilroy-Medium-18-140);
	cursor: pointer;
}

.next.disabled {
	background: var(--color-grey50);
	cursor: inherit;
}

.date :global(.react-calendar) {
	width: 100%;
}

.date :global(.react-calendar__month-view__days) {
	display: grid !important;
	grid-template-columns: repeat(7, 1fr);
	padding-left: 9px;
}

.date :global(.react-calendar__tile) {
	margin: 0px;
}

.narrative label {
	padding: 0px 4px;
}

.artistsBlock {
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 18px;
	width: 426px;
	margin-top: 12px;
}

.tip {
	z-index: 100;
	font: var(--gilroy-Medium-12);
	position: absolute;
	top: 50%;
	right: 105%;
	width: 221px !important;
	padding: 16px;
	transform: translateY(-50%);
	color: var(--color-white);
	background-color: var(--color-dark-grey);
}

.tip:after {
	z-index: 1000000;
	position: absolute;
	top: 41%;
	right: -12px;
	width: 0;
	height: 0;
	content: '';
	transform: rotate(270deg) translateY(50%);
	border-width: 20px 20px 0 20px;
	border-style: solid;
	border-color: var(--color-dark-grey) transparent transparent transparent;
}

.infoActive {
	position: absolute;
	top: 12px;
	left: 81px;
	transition-duration: 0.12s;
	transform: translate(-5px, 0px);
}

.infoActive svg {
	width: 16px;
	height: 16px;
}

.show {
	display: none;
}

@media screen and (min-width: 1100px) {
	.form__section {
		display: grid;
		grid-template-columns: repeat(2, calc(50% - 12px));
		grid-column-gap: 24px;
		grid-row-gap: 24px;
	}

	.show {
		display: block;
	}

	.hide {
		display: none;
	}
}

@media screen and (min-width: 1489px) {
	.errorHelper {
		bottom: 0px;
	}
}
