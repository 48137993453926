/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/11/2020, 15:02
 */

.Page ul {
	margin: 50px 0 0;
	padding: 0;
	list-style: none;
}

.Step {
	display: flex;
	margin: 25px 0;
}

.Step img {
	margin-right: 20px;
}

.Step a {
	font: var(--gilroy-Medium-20-140);
	color: var(--color-dark-grey);
}

.Attachment {
	display: flex;
	align-items: center;
	margin: 20px 0;
}

.DeleteFile {
	margin-left: 30px;
}

.svg_branding {
	fill: var(--color-light-green);
}
