/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/11/2020, 15:02
 */

.Dropdown {
	font: var(--gilroy-Medium-18);
	position: relative;
	width: 100%;
	height: 60px;
	margin-bottom: 25px;
}

.List {
	position: absolute;
	z-index: 1;
	bottom: auto;
	left: 0;
	overflow: auto;
	width: 100%;
	min-width: 312px;
	max-height: 220px;
	margin: 0;
}

.Item {
	display: flex;
	align-items: center;
	width: 100%;
	height: 40px;
	padding: 0 8px;
	cursor: pointer;
	background-color: var(--color-white);
}

.ItemHover:hover {
	background-color: var(--color-light-grey) !important;
}

/* .Value *,
.Item * {
	margin-right: 8px;
} */


.Item {
	padding-left: 8px;
}

.Value {
	composes: Item;
	border-bottom: 2px solid var(--color-dark-grey);
	background-color: unset;
	padding-left: 4px;
}

.Helper {
	font: var(--gilroy-Medium-12);
	/* font-size: smaller; */
}

.Error .Helper {
	color: var(--color-red);
	padding: 6px 4px;
}

.Error .Value {
	color: var(--color-red);
	border-color: var(--color-red);
	padding: 8px 4px;
}

.Placeholder {
	color: var(--color-grey100);
	composes: Value;
}

.Chevron {
	position: absolute;
	/* right: 18px; */
	right: 4px;
	bottom: 40%;
	cursor: pointer;
}

.ChevronTriangle {
	position: absolute;
	top: 2px;
	right: 0;
}
.triangle {
	margin: 10px 7px 9px;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 5px 5px 5px;
	border-color: transparent transparent rgba(0, 0, 0, 0.54) transparent;
	transition: 0.2s;
}
.triangleDown {
	transform: rotate(180deg);
}

@media screen and (min-width: 768px) {
	.Dropdown {
		max-width: 416px;
	}

	.List {
		max-width: 416px;
	}

	.Item {
		height: 42px;
	}
}
