.body {
	letter-spacing: 1px;
}

.create__container {
	margin-top: 5px;
	margin-bottom: 23px;
	display: flex;
	justify-content: flex-end;
}
.create {
	display: flex;
	align-items: center;
	font: var(--gilroy-Medium-18);
	text-decoration: underline;
	letter-spacing: inherit;
	background: none !important;
	border: none;
	padding-top: 10px !important;
	cursor: pointer;
}
.create > img {
	margin-right: 8px;
}

/*  */
/* head */

div.table__header {
	padding-top: 0px;
	padding-bottom: 16px;
	color: var(--color-grey100);
	font: var(--gilroy-Medium-18);
}

/* main */

.table {
	display: flex;
	padding: 14.6px 11px 14.4px 24px;
}

.table > *:first-child {
	width: 40.278vw;
	margin-right: 1.389vw;
}
.table > *:nth-child(2) {
	width: 20.972vw;
	margin-right: 0.694vw;
}
.table > *:nth-child(3) {
	width: 16.25vw;
}

.edit {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 1.667vw;
}

.delete {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 1.528vw;
}

.table__item {
	position: relative;
	background: var(--color-white);
	margin-bottom: 2px;
	font: var(--gilroy-Medium-16-19);
}

.table__item:last-child {
	margin-bottom: 0px;
}

.mm {
	position: absolute;
	top: 20px;
	left: 50vw;
	z-index: 111111111;
	border: none;
	background: darkblue;
	color: white;
	padding: 5px 10px;
}
