.container {
	display: flex;
	justify-content: space-between;
	width: 100%;
	max-width: 768px;
	height: 52px;
	padding: 0px 24px;
	align-items: center;
	background: var(--color-white);
}

.reportName {
	font: var(--gilroy-Medium-16-150);
}

.container button {
	display: flex;
	align-items: center;
	border: none;
	background: transparent;
	cursor: pointer;
}

.btnText {
	margin-left: 8px;
	text-transform: lowercase;
	font: var(--gilroy-Medium-18);
	border-bottom: 1px solid black ;
}
