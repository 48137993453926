.page {
	display: flex;
	min-height: 85vh;
	padding-top: 48px;
}

.main {
	padding: 40px 24px 40px 40px;
	flex-grow: 1;
}

.form_container {
	padding: 41px 64px 80px;
	overflow-y: scroll;
	width: 100%;
}

.btn_next {
	border: none;
	background-color: var(--color-btn-grey);
	color: var(--color-white100);
	width: 328px;
	height: 60px;
	font: var(--gilroy-Medium-18-140);
	letter-spacing: 1px;
	cursor: pointer;
}

.title {
	font: var(--gilroy-Medium-24-140p);
	letter-spacing: 1px;
	color: var(--color-dark-grey);
	margin-bottom: 8px;
}

.required_text {
	font: var(--gilroy-Medium-14-20);
	letter-spacing: 1px;
	color: var(--color-dark-grey);
	margin-bottom: 16px;
}

.radio:not(:last-child) {
	margin-right: 35px;
}

.add_soundtrack_container {
	display: flex;
	align-items: flex-end;
}

.autocomplete {
	width: 352px;
	margin-right: 27px;
}

.btn_add {
	border: none;
	background-color: inherit;
	display: flex;
	align-items: center;
	margin-top: 24px;
	margin-bottom: 48px;
}

.add_svg {
	width: 24px;
	height: 24px;
}

.main_add_btn_text {
	font: var(--gilroy-Medium-14-17);
	text-decoration: underline;
	color: var(--color-dark-grey);
	margin-left: 8px;
}

.button__wrapper {
	display: flex;
	margin-top: 48px;
	justify-content: space-between;
	width: 100%;
}

.prev {
	display: inline-block;
	padding: 18px 65px 18.43px 0px;
	font: var(--gilroy-Medium-18-22);
	text-decoration: underline;
	cursor: pointer;
}
