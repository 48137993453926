.Form {
	overflow: hidden !important;
}

.pseudonymTitleWrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0px 0px 8px 0px;
}

.title {
	font: var(--gilroy-Medium-24);
	text-transform: lowercase;
	line-height: 28px;
	letter-spacing: 0.96px;
	margin-right: 24px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.ipi {
	font: var(--gilroy-Medium-12-12);
	line-height: 12px;
	color: var(--color-grey100);
	margin-bottom: 8px;
}

.ipiName {
	width: 96px;
	margin-right: 24px;
}

.ipiValue {
	margin-top: 8px;
	font: var(--gilroy-Medium-16);
	line-height: 24px;
	letter-spacing: 0.16px;
}

.mainPseudonym {
	display: flex;
	align-items: center;
	text-decoration: underline;
	font: var(--gilroy-Medium-16);
	line-height: 24px;
	letter-spacing: 0.16px;
	padding: 0px 8px 3px;
	margin-top: 12px;
}

.mainPseudonymActive {
	composes: mainPseudonym;
	background-color: var(--color-light-green);
	cursor: unset;
	text-decoration: unset;
	width: fit-content;
}

.mainPseudonymToDo {
	composes: mainPseudonym;
	font: var(--gilroy-Medium-16);
	padding: 0px 0px 3px;
	background-color: unset;
	cursor: pointer;
	text-decoration: underline;
}

.ipiRow {
	margin-bottom: -20px;
}

.ipiRow > div {
	margin-bottom: 20px;
}

.pseudonymWrapper {
	padding: 24px 16px;
	background-color: var(--color-white);
}

.pseudonymWrapper:not(:last-child) {
	margin-bottom: 24px;
}

.infoItemName {
	min-width: 96px;
	width: 96px;
	margin-right: 24px;
	padding: 8px 0px;
	font: var(--gilroy-Medium-18);
	color: var(--color-grey100);
}

.outletCol {
	display: flex;
	align-items: center;
	flex-wrap: nowrap;
}

.infoItem {
	display: flex;
	justify-content: flex-start;
	width: calc(100% - 150px);
	align-items: center;
	height: 36px;
	margin: 8px 0px;
}

.infoItemValue,
.outletValue {
	font: var(--gilroy-Medium-16);
	font-weight: 500;
}
.infoItemValue {
	display: inline-block;
	width: 100%;
}

.outletValue {
	display: inline-block;
	width: calc(100% - 120px);
	overflow: hidden;
	text-overflow: ellipsis;
}

.button__addPublisher {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 48px;

	cursor: pointer;
	font: var(--gilroy-Medium-16-20);
	line-height: 24px;
	letter-spacing: 0em;
	border-radius: 50px;
	background-color: var(--color-light-green);
	padding: 6px 24px 6px 20px;
	margin: 12px 0px 24px;
}

.button__addPublisher img {
	margin-right: 8px;
}

.button__addRole {
	display: flex;
	align-items: center;
	margin-right: 15px;
	cursor: pointer;
	font: var(--gilroy-Medium-18);
	border-radius: 50px;
	font: var(--gilroy-Medium-16);
}

.button__addRole img {
	margin-right: 8px;
}

.menuMore {
	position: relative;
	padding: 0px 14px 0px 14px;
}

.menuMoreWrapper {
	display: flex;
	justify-content: flex-end;
	align-self: flex-start;
	align-items: flex-start;
	min-width: 49px;
	cursor: pointer;
}

.roleOutletLine {
	display: flex;
	align-items: center;
}

.menuMoreWrapper button {
	display: flex;
	height: 20px;
	border: none;
	background-color: unset;
	cursor: pointer;
}

.editBtn {
	font: var(--gilroy-Medium-18);
}

.deleteBtn {
	composes: editBtn;
	margin-left: 20px;
}

.titleInfoBtn button {
	margin-left: 0;
	padding-top: 5px;
}

.mainPseudonymToDo .loader {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 20px;
	margin-top: 0;
	margin-left: 10px;
	animation: spin 2s linear infinite;
	overflow: visible;
}

.titleBtnWrapper {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

@media screen and (min-width: 768px) {
	.titleWrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 0px 0px 24px 0px;
	}

	.button__addPublisher {
		height: 36px;
		margin-top: 0px;
		margin-bottom: 0px;
	}

	.titleBtnWrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.pseudonymTitleWrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 32px;
	}

	.outletValue {
		white-space: nowrap;
	}

	.mainPseudonym {
		width: unset;
		margin-top: 0px;
		margin-bottom: 0px;
	}

	.ipiRow {
		margin-bottom: 0px;
	}

	.ipiRow > div {
		margin-bottom: 0px;
	}
}
