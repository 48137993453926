.grid {
	position: relative;
	display: grid;
	grid-template-columns: 5fr 4fr 1fr;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
}

.input {
	position: relative;
}

.input > :first-child {
	width: 100%;
}

.helper,
.rolesHelper {
	position: absolute;
	left: 4px;
	color: var(--color-red);
	font: var(--gilroy-Medium-12);
	font-size: smaller;
}

.helper,
.rolesHelper {
	bottom: -30px;
}

.title__info {
	display: flex;
	align-items: flex-end;
	text-transform: lowercase;
}

.authorData {
	position: relative;
	display: flex;
	width: 146px;
}

.authorClose {
	position: absolute;
	right: 20px;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;
}

.cmoContainer {
	display: flex;
	align-items: center;
	margin-left: 16px;
}

.cmoBtn {
	font: var(--gilroy-Medium-18-140);
	color: var(--color-dark-grey);
	background: transparent;
	border: none;
	border-bottom: 1px solid var(--color-dark-grey);
	cursor: pointer;
}

.clearBtn {
	margin: 4px 0px 0px 16px;
	background: transparent;
	border: none;
	cursor: pointer;
}

.clearBtn img {
	width: 24px;
}

.ownershipContainer {
	max-height: 66px;
}

@media screen and (min-width: 732px) {
	.helper {
		bottom: -18px;
	}

	.rolesHelper {
		bottom: -32px;
	}
}

@media screen and (min-width: 768px) {
	.helper {
		bottom: -14px;
	}

	.rolesHelper {
		bottom: -25px;
	}
}

@media screen and (min-width: 782px) {
	.rolesHelper {
		bottom: -14px;
	}
}

@media screen and (min-width: 914px) {
	.helper {
		bottom: 0px;
	}
}

@media screen and (min-width: 1010px) {
	.rolesHelper {
		bottom: 0px;
	}
}

@media screen and (min-width: 1024px) {
	.helper,
	.rolesHelper {
		bottom: -12px;
	}
}

@media screen and (min-width: 1096px) {
	.helper {
		bottom: 0px;
	}
}

@media screen and (min-width: 1193px) {
	.rolesHelper {
		bottom: -14px;
	}
}

@media screen and (min-width: 1201px) {
	.rolesHelper {
		bottom: -0px;
	}
}
