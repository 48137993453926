.RadioGroup:global.horizontal {
	height: 60px;
	margin-bottom: 25px;
}

.RadioGroup {
	display: grid;
	grid-column-gap: 24px;
	padding: 9px 0;
}

.radioItem {
	display: grid;
	grid-column-gap: 24px;
	padding: 9px 0;
}

.Input__date {
	display: flex;
	justify-content: space-between;
	gap: 24px;
	padding: 12px 0;
}

.RadioGroup:global.horizontal {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	max-width: 416px;
	max-height: 60px;
}

.RadioGroup > div:last-child {
	margin-right: 0;
}

.radioBtnItem {
	margin-bottom: 0px;
	align-items: baseline;
}

.input {
	position: relative;
	margin-top: 15px;
}

.Helper,
.releaseDateBlockHelper {
	position: absolute;
	color: var(--color-red);
	font: var(--gilroy-Medium-12);
	font-size: smaller;
	padding: 0 5px;
}

.Helper {
	top: 70px;
	left: 0px;
}

.floatingInput > div > div {
	margin-bottom: 0 !important;
}

.widthCorrect {
	max-width: 264px;
}

.releaseDateBlock {
	position: relative;
	margin-left: 36px;
}

.releaseDateCheckbox {
	height: auto !important;
	margin-top: 24px;
}

.checkboxOutletsContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 24px;
}

.outletsDataBar {
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin-top: 12px;
}
