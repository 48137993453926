.detailsWrapper {
	display: flex;
	align-items: baseline;
	cursor: pointer;
}

.detailsHeader {
	margin-right: 10px;
}

.detailsText {
	margin-top: 20px;
	max-width: 50vw;
	word-wrap: break-word;
}

.page {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--color-white100);
}

.form {
	width: fit-content;
}

.title {
	margin-bottom: 24px;
}

.disputeErrorComment {
	margin-top: 24px;
}