.buttonGreen {
	display: flex;
	align-items: center;
	padding: 10px 24px;
	font: var(--gilroy-Medium-16);
	border: none;
	border-radius: 20px;
	background-color: var(--color-light-green);
	cursor: pointer;
  max-width: fit-content;
}