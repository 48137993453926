/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/11/2020, 15:02
 */

.Create {
	/*display: inline-block;*/
	/*margin-left: 42px;*/
	font: var(--gilroy-Medium-24);
	display: flex;
	align-items: center;
	justify-content: center;
	width: 282px;
	/*margin-right: 42px;*/
	height: 282px;
	margin-bottom: 42px;
	cursor: pointer;
	transition: 200ms linear;
	color: var(--color-light-green);
	border: 3px solid var(--color-light-green);
	background-color: var(--color-forms);
}

.Create span {
	position: absolute;
	opacity: 0;
	color: var(--color-forms);
}

.Create:hover {
	background-color: var(--color-light-green);
}

.Create:hover svg {
	opacity: 0;
}

.Create:hover span {
	opacity: 1;
	color: var(--color-dark-grey);
}

.svg_branding {
	fill: var(--color-light-green);
}
