/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 10/02/2021, 11:15
 */

.Document {
	display: flex;
	overflow: auto;
	flex-direction: column;
	/*max-height: calc(100vh - 460px);*/
	padding-top: 30px;
}

.DocPage {
	margin: 0 auto 30px;
	cursor: crosshair;
	background-color: var(--color-white);
	box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
}

.Content {
	overflow: auto;
	height: 83vh;
}

.Controls {
	display: flex;
	justify-content: flex-start;
	padding: 20px;
}

.Button {
	width: 150px;
	margin-left: 200px;
}

.error {
	margin-top: 20px;
	margin-left: 20px;
}
