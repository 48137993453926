.wrapper {
	display: flex;
	align-items: flex-end;
	max-height: 48px;
}

.slider {
	height: 100%;
	position: relative;
}

.slider p {
	width: 140px;
}

.value {
	display: block;
	margin-bottom: 7.5px;
	font: var(--gilroy-Medium-16-19);
	letter-spacing: 0.15px;
	color: rgba(0, 0, 0, 0.87);
}

.help_svg {
	margin-left: 8px;
	width: 24px;
	height: 24px;
}

.btn_add {
	border: none;
	background-color: inherit;
	margin-left: 16px;
	display: flex;
	align-items: center;
}

.add_svg {
	width: 24px;
	height: 24px;
}

.main_add_btn_text {
	font: var(--gilroy-Medium-14-17);
	text-decoration: underline;
	color: var(--color-dark-grey);
	margin-left: 8px;
	pointer-events: none;
}

.remove_svg {
	width: 16px;
	height: 16px;
	margin-left: 32px;
	cursor: pointer;
}
