.form {
	position: relative;
	height: 100%;
	grid-area: form;
}

.container {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 64px;
}

.subTitle {
	margin-top: 8px;
	margin-bottom: 48px;
	color: var(--color-grey);
}

.banner {
	margin-bottom: 24px;
}

.editButton {
	position: absolute;
	top: 0;
	right: 0;
	text-align: right;
	cursor: pointer;
	margin-right: 5px !important;
}

.editButton svg {
	width: 24px;
	height: 24px;
}

.editButton > button {
	display: flex;
	height: 20px;
	border: none;
	background-color: unset;
	cursor: pointer;
	padding-top: 18px;
}

@media screen and (min-width: 1280px) {
	.container {
		grid-template-columns: repeat(3, 1fr);
		gap: 48px;
	}
}

@media screen and (min-width: 1300px) {
	.container {
		gap: 64px;
	}
}
