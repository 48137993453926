.container {
	margin: 80px 30px 40px;
}

.title {
	line-height: 40px !important;
}

.subTitle {
	font: var(--gilroy-Medium-18);
	margin-top: 8px;
	line-height: 140%;
	letter-spacing: 1px;
	color: var(--color-grey);
}

.outletsTitle {
	font: var(--gilroy-Medium-24);
	line-height: 28px;
	letter-spacing: 0.96px;
}

.form {
	margin-top: 16px;
}

.form button {
	text-transform: lowercase;
	max-width: 386px;
}

.names,
.numbers {
	display: grid;
	grid-template-columns: repeat(3, auto);
	grid-column-gap: 30px;
}

.formItem,
.outletId {
	position: relative;
}

.formItem > div {
	width: 100%;
}

.numbers {
	margin-top: 16px;
	position: relative;
}

.roles {
	margin-top: 16px;
}

.outlets {
	margin: 64px 0;
}

.outletsForm {
	margin-top: 16px !important;
	display: grid;
	grid-template-columns: repeat(3, calc((100% - 48px) / 3));
	grid-column-gap: 30px;
	align-items: center;
}

.outletId > div {
	width: 100%;
}

.outletsBtns {
	width: 24px;
	cursor: pointer;
}

.deleteBtns {
	width: 32px;
	cursor: pointer;
}

.addOutlet {
	margin-top: 32px;
	max-width: 186px;
	display: flex;
	align-items: center;
	cursor: pointer;
}

.addOutletTitle {
	margin-left: 8px;
	font: var(--gilroy-Medium-18-22);
	text-decoration: underline;
}

.Helper {
	position: absolute;
	bottom: -18px;
	left: 0px;
	color: var(--color-red);
	font: var(--gilroy-Medium-12);
	font-size: smaller;
	padding: 0 5px;
}

.error {
	color: var(--color-red);
	font: var(--gilroy-Medium-12);
	font-size: smaller;
	padding: 0 5px;
}

.disabled {
	opacity: 0.5;
	pointer-events: none;
	cursor: auto;
}

.info {
	position: absolute;
	top: 35px;
	left: 100px;
	transition-duration: 0.1s;
	transform: translate(0px, 0px);
}

.infoMts {
	position: absolute;
	top: 30px;
	left: 100px;
	transition-duration: 0.1s;
	transform: translate(0px, 0px);
}

.info svg,
.infoMts svg,
.infoActive svg,
.infoActiveMts svg {
	width: 16px;
	height: 16px;
}

.infoActive {
	position: absolute;
	top: 12px;
	left: 81px;
	transition-duration: 0.12s;
	transform: translate(-5px, 0px);
}

.infoActiveMts {
	position: absolute;
	top: 7px;
	left: 81px;
	transition-duration: 0.12s;
	transform: translate(-5px, 0px);
}

.infoActive svg circle,
.infoActiveMts svg circle {
	stroke: var(--color-grey100) !important;
}

.infoActive svg path,
.infoActiveMts svg path {
	fill: var(--color-grey100) !important;
}

@media screen and (min-width: 768px) {
	.container {
		margin: 40px 30px;
	}
}
