.repertoireSong__header {
	height: 48px;
	background: var(--color-light-grey);
}

.repertoireSong__header > button {
	padding: 15px 36px;
	font: var(--gilroy-Bold-16-19);
}

.repertoireSong__titleWrapper {
	background: var(--color-white);
	padding: 12px 40px 12px 30px;
	display: flex;
	align-items: center;
	color: var(--color-dark-grey);
}

.repertoireSong__title {
	font: var(--gilroy-Medium-40);
	letter-spacing: 1px;
	margin-left: 16px;
}

.repertoireSong__isrc {
	width: 282px;
	margin-left: auto;
	display: flex;
	flex-direction: column;
	text-transform: uppercase;
	background: var(--color-white100);
}
.repertoireSong__isrc > *:last-child {
	font: var(--gilroy-Medium-18);
}

.repertoireSong__isrc > *:first-child {
	color: var(--color-grey100);
	margin-bottom: 6px;
	font: var(--gilroy-Medium-14);
}

.repertoireSong__isrc2 {
	margin-left: auto;
	text-transform: uppercase;
	font: var(--gilroy-Medium-18);
}

.repertoireSong__isrc2 > *:first-child {
	margin-right: 14px;
}

.repertoireSong__main {
	padding: 24px 30px 110px;
}

.mm {
	position: absolute;
	top: 20px;
	left: 55vw;
	z-index: 111111111;
	border: none;
	background: darkgreen;
	color: white;
	padding: 5px 10px;
}

.headerButton {
	cursor: pointer;
}
