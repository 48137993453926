a {
	text-decoration: none;
}

.container {
	margin-top: 56px;
	padding: 42px 16px 32px;
}

.titlePhone {
	display: inline-block;
	font: var(--gilroy-Medium-24-140p);
	color: var(--color-dark-grey);
}

.wrapper {
	display: flex;
	justify-content: space-between;
	padding-top: 24px;
}

.wrapper__btn {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.wrapper__btn button p {
	font: var(--gilroy-Medium-18);
	line-height: 24px;
	letter-spacing: 0.72px;
}

.btn__disabled {
	border: none;
	width: 100%;
	background-color: var(--color-grey100);
	height: 60px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
}

.img__disabled {
	width: 24px;
	height: 24px;
	fill: var(--color-white);
	margin-right: 10px;
}

.notify__text {
	color: #f75e63;
}

.notify__text span {
	text-decoration: underline;
	cursor: pointer;
}

.load__repertoryes {
	display: none;
	justify-content: center;
	margin-top: 32px;
	background-color: inherit;
	border: none;
	color: var(--color-dark-grey);
	cursor: pointer;
	font: var(--gilroy-Medium-14-20);
	text-decoration-line: underline;
	letter-spacing: 1px;
}

.link {
	text-decoration: none;
}

.Statistics > div:first-child {
	margin-top: 0px;
}

@media screen and (min-width: 768px) {
	.container {
		margin: 0;
		padding: 24px 40px 33px;
	}
	.wrapper__btn {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.titlePhone {
		display: none;
	}

	.load__repertoryes {
		display: none;
		margin-left: auto;
	}

	.btn__disabled {
		width: 30vw;
	}
}

@media screen and (min-width: 1024px) {
	.btn__disabled {
		width: 25vw;
	}
}
