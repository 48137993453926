.repertoire__phonogramItemsWrapper {
	display: flex;
	flex-direction: column;
}

.repertoire__phonogramItemsWrapper a {
	color: unset;
	text-decoration: none;
	margin-bottom: 2px;
}

.inputWrapper {
	position: relative;
	display: flex;
	flex-direction: column;
}

.result {
	position: absolute;
	top: 43px;
	width: 386px;
	background: var(--color-light-grey);
	z-index: 1;
}

.result__title {
	padding: 0 20px;
	display: inline-block;
	margin-top: 26px;
	color: var(--color-grey);
	font: var(--gilroy-Bold-16-140);
}

.result__item {
	font: var(--gilroy-Medium-16-140);
	padding: 14px 20px 12px;
}

.result__item:hover {
	background-color: var(--color-dark-grey);
	color: var(--color-white);
}

.table {
	display: flex;
	align-items: center;
	padding: 14px 14px 15px 74px;
}

.table > * {
	position: relative;
}

.table > * > :first-child {
	display: inline-block;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.table > * > :nth-child(2) {
	position: absolute;
	left: 0;
	top: 100%;
	padding: 3px;
	background: var(--color-dark-grey);
	color: var(--color-grey100);
	opacity: 0;
	visibility: hidden;
	z-index: 1;
	transition: 0.1s ease;
}

.table > *:hover > :nth-child(2) {
	opacity: 1;
	visibility: visible;
}

.table > *:first-child {
	width: 20%;
	min-width: 20%;
	max-width: 20%;
	margin-right: 20px;
}

.table > *:nth-child(2) {
	width: 15%;
	min-width: 15%;
	max-width: 15%;
	margin-right: 20px;
}

.table > *:nth-child(3) {
	width: 15%;
	min-width: 15%;
	max-width: 15%;
	margin-right: 20px;
}

.table > *:nth-child(4) {
	width: 15%;
	min-width: 15%;
	max-width: 15%;
	margin-right: 20px;
}

.table > *:nth-child(5) {
	width: 10%;
	min-width: 10%;
	max-width: 10%;
	margin-right: 20px;
}

.table > *:nth-child(6) {
	width: 15%;
	min-width: 15%;
	max-width: 15%;
	margin-right: 20px;
}

.table > *:nth-child(7) {
	margin-left: auto;
	margin-right: 20px;
	width: 30px;
}

.moderationBlock {
	display: flex;
	align-items: center;
}

.editIcon {
	margin-left: auto;
	margin-right: 20px;
	background: transparent;
	border: none;
	cursor: pointer;
}

.editIcon img {
	width: 25px !important;
}

.repertoire__phonogramItemError {
	color: var(--color-red);
}

.repertoire__phonogramItemError .repertoire__phonogramItemLogo path {
	fill: var(--color-red);
}

.repertoire__phonogramItemsWrapper li {
	position: relative;
	align-items: center;
	font: var(--gilroy-Medium-16-19);
	background-color: var(--color-white);
	margin-bottom: 2px;
}

.repertoire__phonogramItemsWrapper li:last-child {
	margin-bottom: 0;
}

.repertoire__phonogramTitle {
	font: var(--gilroy-Medium-18);
	color: var(--color-grey100);
	text-transform: lowercase;
	margin-bottom: 16px;
	padding-top: 0;
	padding-bottom: 0;
}

.loader {
	margin-top: 30px;
}

.repertoire__phonogramItemLogo {
	width: 30px;
	position: absolute;
	top: 50%;
	transform: translate(0, -50%);
	left: 24px;
}

.repertoire__phonogramItemError {
	color: var(--color-red);
}

.repertoire__phonogramItemError .repertoire__phonogramItemLogo path {
	fill: var(--color-red);
}

.repertoire__mainContent {
	margin-top: 30px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 575px;
}

.repertoire__mainContentEmpty {
	margin-top: 215px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.repertoire__mainContentEmpty > *:first-child {
	margin-bottom: 32px;
}

.repertoire__mainContentEmpty > *:last-child {
	color: var(--color-dark-grey);
	font: var(--gilroy-Medium-24-140p);
	letter-spacing: 1px;
}

.repertoire__mainContentEmpty > *:last-child a {
	color: var(--color-dark-grey);
	text-decoration: underline;
}

.btnAsLink {
	background: none !important;
	border: none;
	padding-top: 10px !important;
	font: var(--gilroy-Medium-16-19);
	text-decoration: underline;
	cursor: pointer;
}

.adaptive__songTitle,
.adaptive__songTable {
	display: none;
}

.adaptive__item {
	display: block;
	width: 100%;
	margin: 16px 0;
}

.adaptive__songItemLogo {
	width: 22px;
	height: 22px;
}

.adaptive__itemTitle {
	height: 27px;
	color: var(--color-grey100);
	font: var(--gilroy-Medium-14-20);
}

.itemInactive {
	color: var(--color-grey100);
	pointer-events: none;
}

.adaptive__itemText {
	position: relative;
	height: 27px;
	font: var(--gilroy-Medium-14-20);
}

.adaptive__itemText :first-child {
	display: inline-block;
	max-width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.adaptive__itemText :nth-child(2) {
	position: absolute;
	left: 0;
	top: 100%;
	padding: 3px;
	background: var(--color-dark-grey);
	color: var(--color-grey100);
	opacity: 0;
	visibility: hidden;
	z-index: 1;
	transition: 0.1s ease;
}

.adaptive__itemText:hover > :nth-child(2) {
	opacity: 1;
	visibility: visible;
}

.adaptive__menuMoreWrapper {
	display: flex;
	width: 48px;
	height: 48px;
}

@media screen and (min-width: 768px) {
	.adaptive__songTitle,
	.adaptive__songTable {
		display: flex;
	}

	.adaptive__item {
		display: none;
	}

	.adaptive__menuMoreWrapper {
		display: none;
	}

	.menuMoreWrapper {
		display: flex;
		align-items: center;
		height: 48px;
		cursor: pointer;
	}

	.menuMoreWrapperActive {
		position: relative;
		display: flex;
		align-items: center;
		height: 48px;
		background-color: var(--color-black);
	}

	.table > *:first-child,
	.table > *:nth-child(2) {
		min-width: 15%;
		max-width: 15%;
	}
}

@media screen and (min-width: 1024px) {
	.table > *:first-child,
	.table > *:nth-child(2) {
		min-width: 15%;
		max-width: 15%;
	}
}

@media screen and (min-width: 1500px) {
	.table > *:first-child {
		min-width: 22%;
		max-width: 22%;
	}
}

.Inactive {
	fill: var(--color-grey100);
	color: var(--color-grey100);
}

.Inactive path {
	fill: var(--color-grey100);
}
