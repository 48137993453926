.CodeInput {
	position: relative;
	max-width: 500px;
	margin-bottom: 25px;
}

.Input {
	height: unset;
	margin-bottom: 0;
	max-width: 500px !important;
}

.Helper {
	font: var(--gilroy-Medium-12);
	font-size: smaller;
	padding: 5px 5px 0;
	color: var(--color-grey);
}

.resendBtn {
	position: absolute;
	bottom: 5px;
	cursor: pointer;
	background-color: transparent;
	font: var(--gilroy-Medium-14);
	border: none;
	border-bottom: 2px solid var(--color-grey);
}
