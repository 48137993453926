.uploadBlock {
	width: 100%;
	max-width: 768px;
	margin-bottom: 48px;
}

.uploadBlock section {
	width: 100%;
	max-width: 768px;
}

.filesBlock {
	position: relative;
	margin-top: 24px;
	display: flex;
	flex-direction: column;
	gap: 16px;
}