.Auth {
	font: inherit;
	min-width: 80px;
	letter-spacing: 1px;
}

.Divider {
	margin: 0 5px;
	color: var(--color-grey);
}

.Auth a {
	text-decoration: none;
	color: inherit;
}

.User {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background-color: var(--color-light-green);
	font: var(--gilroy-Medium-18);
}

@media screen and (min-width: 768px) {
	.Divider {
		margin: 0 15px;
	}
}
