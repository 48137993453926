.end {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 594px;
  margin-top: calc(50vh - 188px);
  
  margin-left: auto;
  margin-right: auto;
}

.title {
  font: var(--gilroy-Medium-40);
  line-height: 40px;
  margin-bottom: 16px;
}

.text {
  font: var(--gilroy-Medium-24);
  line-height: 28px;
  letter-spacing: 0.96px;
  margin-bottom: 40px;
}

.text a {
  font-weight: 700;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  border: none;
  background: var(--color-dark-grey);
  color: var(--color-white);
  cursor: pointer;
  text-decoration: none;
  text-transform: lowercase;
  font: var(--gilroy-Medium-18);
  line-height: 24px;
  letter-spacing: 0.72px;
}