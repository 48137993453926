.inputWrapper {
	position: relative;
	display: flex;
	flex-direction: column;
}

.filter {
	margin-top: 8px;
	display: flex;
	flex-wrap: wrap;
}

.filter__item {
	padding: 6.5px 30px 6.5px 8px;
	background: var(--color-grey100);
	position: relative;
	display: inline-block;
	font: var(--gilroy-Medium-16-19);
	letter-spacing: initial;
	margin-right: 8px;
	margin-top: 8px;
	cursor: pointer;
	text-transform: lowercase;
}

.filter__item img {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 8px;
}

.filter__itemButton {
	display: flex;
	cursor: pointer;
}

.filter a {
	font: var(--gilroy-Medium-18);
	text-decoration: underline;
	margin-left: 8px;
	margin-top: 13px;
}

.btnAsLink {
	background: none !important;
	border: none;
	padding-top: 10px !important;
	font: var(--gilroy-Medium-16-19);
	text-decoration: underline;
	cursor: pointer;
}
