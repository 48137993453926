.file {
	display: flex;
	align-items: center;
	align-self: stretch;
	border-radius: 2px;
}

.fileName {
	position: relative;
}

.fileName span {
	position: absolute;
	bottom: 0;
}

.fileDetails span {
	font: var(--gilroy-Medium-16);
	color: var(--color-grey100);
}

.fileDelete {
	margin-left: auto;
	cursor: pointer;
}

.fileDelete img {
	width: 24px;
	height: 24px;
}
