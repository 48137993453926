/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/11/2020, 15:02
 */

.Forgot {
	font: var(--gilroy-Medium-14-140);
	margin-top: 60px;
	text-align: center;
}

.form_wrapper {
	margin-top: 56px;
	padding-top: 16px;
	width: calc(100% - 32px);
}

.submit {
	margin-top: -10px;
}

.register_wrapper {
	margin-top: 20px;
	background-color: var(--color-light-green);
	padding: 24px;
}

.register_title {
	margin-bottom: 20px;
}

.register_button {
	margin-bottom: 20px;
}

.Button {
	max-width: 100%;
}

@media screen and (min-width: 320px) {
}

@media screen and (min-width: 768px) {
	.submit {
		margin-top: 0px;
	}
	.form_wrapper {
		margin-top: 0;
		padding: 0;
	}
	/* 	.Button,
	.Input {
		min-width: 416px;
	} */

	.register_wrapper {
		display: none;
	}
}

@media screen and (min-width: 1024px) {
	.Button,
	.Input {
		min-width: 416px;
	}
}

@media screen and (min-width: 1440px) {
}

@media screen and (min-width: 1920px) {
}

@media screen and (min-width: 3840px) {
}
