.helper {
	position: relative;
	padding: 24px;
	margin-bottom: 24px;
	background: var(--color-light-green);
	font: var(--gilroy-Medium-16-140);
	letter-spacing: 1px;
}

.helper img {
	position: absolute;
	width: 16px;
	height: 16px;
	top: 8px;
	right: 8px;
}
