.Header {
  max-width: 415px;
}

.Label {
  /*margin-bottom: 85px;*/
  /*font: var(--gilroy-Medium-40-140);*/
  max-width: 415px;
}

.errorText {
  max-width: 415px;
  margin-top: 30px;

}

.errorText>span {

  font: var(--gilroy-Medium-24);

}

.Button {
  max-width: 415px;
  margin-top: 50px;
}


@media screen and (min-width: 320px) {}

@media screen and (min-width: 768px) {}

@media screen and (min-width: 1440px) {}

@media screen and (min-width: 1920px) {}

@media screen and (min-width: 3840px) {}