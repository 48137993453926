/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/11/2020, 15:02
 */


.Header {
	margin-top: 60px;
	margin-bottom: 50px;
}

.Title {
	margin-bottom: 30px;
}

.Block {
	margin-bottom: 50px;
}

.Controls {
	display: flex;
	margin-top: 50px;
	margin-bottom: 50px;
}

.infoButton{
	align-items: flex-start;
}