.cardsContainer {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.title {
	text-transform: lowercase;
	font: var(--gilroy-Medium-18-22);
	font-weight: 700;
	color: var(--color-grey);
}

.deleteBtn {
	margin-left: auto;
	margin-right: 12px;
	background: transparent;
	border: none;
	cursor: pointer;
}
