.formContainer {
	display: flex;
	flex-direction: column;
}

.title {
	margin-top: 32px;
	margin-bottom: 8px;
	color: var(--color-dark-grey);
	font: var(--gilroy-Medium-18-140);
}

.suggestInput {
	width: 100%;
	max-width: unset;
	height: 40px;
	margin: 0;
}

.dateBlock > div {
	width: 100%;
	max-width: 100%;
	height: 40px;
}

.dateBlock > div > div {
	margin-bottom: 0 !important;
}

.dateBlockInput {
	margin-top: 16px;
}

.radioGroup {
	position: relative;
}

.saveBtn {
	margin-top: 48px;
	width: 100%;
	max-width: 100%;
}
