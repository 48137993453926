.mainWrapper ul,
.mainWrapper li {
	padding: 0;
	margin: 0;
}

.title {
	font: var(--gilroy-Medium-24-140p);
	margin-bottom: 24px;
}

.title_sub {
	margin-top: 4px;
	font: var(--gilroy-Medium-16);
	line-height: 24px;
	letter-spacing: 0.16px;
	color: var(--color-grey);
}

.info {
	margin-bottom: 48px;
}

.infoNames {
	color: var(--color-grey);
	font: var(--gilroy-Medium-18-20);
	text-transform: lowercase;
}

.infoValues {
	font: var(--gilroy-Medium-16-20);
}

.infoNames li,
.infoValues li {
	padding: 8px 0;
}

.infoNames li:not(:last-child),
.infoValues li:not(:last-child) {
	margin-bottom: 8px;
}

.infoNameCol,
.infoValuesCol {
	min-width: 328px;
}

.rightsTableTitle,
.rightsTableValues {
	margin-left: 0 !important;
	margin-right: 0 !important;
	justify-content: space-between !important;
}
.rightsTable {
	padding: 14px 0;
	margin-bottom: 24px;
}

.rightsTableValues {
	background-color: var(--color-white);
	font: var(--gilroy-Medium-16-20);
}

.rightsTableTitle {
	color: var(--color-grey);
	font: var(--gilroy-Medium-18-20);
	margin-bottom: 14px;
	text-align: center;
	text-transform: lowercase;
}

.rightsTableValues li {
	height: 48px;
	padding-top: 18px;
	text-align: center;
}

.rightsTableTitle :first-child,
.rightsTableValuesLeftSide li {
	text-align: left;
	padding-left: 24px !important;
}

.expandBtn {
	width: 100%;
	height: 44px;
	text-align: center;
	background-color: var(--color-light-grey);
	margin-left: 0 !important;
	margin-right: 0 !important;
	margin-top: 20px;
	flex-grow: 1;
	justify-content: center !important;
	align-items: center !important;
	cursor: pointer;
}

.flag {
	display: inline-block;
	margin-right: 4px;
}

.country {
	display: inline-flex;
	justify-content: flex-start;
	align-items: center;
	margin-right: 24px;
}

.country img {
	margin-right: 8px;
}

.flagsBox {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}
