.downloadWrapper {
	min-width: 282px;
	width: 282px;
}

.load__btn {
	display: flex;
	align-items: center;
	align-self: flex-end;
	margin-left: auto;
	border: none;
	background: transparent;
	font: var(--gilroy-Medium-16-100);
	text-decoration: underline;
	cursor: pointer;
}

.load__btn > img {
	margin-right: 8px;
}
