.Component {
	position: relative;
}

.List {
	position: absolute;
	z-index: 99999;
	top: 10px;
	margin: 0;
	min-width: 100%;
	box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
	border-radius: 2px;
	background-color: var(--color-white);
	padding-bottom: 13px;

}

.List li {
	display: flex;
	align-items: center;
	min-height: 48px;
	padding: 0 16px;
	cursor: pointer;
}

.List li:hover {
	color: var(--color-white);
	background-color: var(--color-dark-grey);
}

.Dropdown {
	position: relative;
}

.Icon {
	position: absolute;
	top: 9px;
	right: 0;
	cursor: pointer;
	border: none;
	outline: none;
	background-color: transparent;
}

.Component > div {
	max-width: 100% !important;
}

.helpTitle {
	color: var(--color-grey100);
}