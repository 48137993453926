.repertoire__phonogramItemsWrapper {
	display: flex;
	flex-direction: column;
}

.repertoire__phonogramItemsWrapper a {
	color: unset;
	text-decoration: none;
	margin-bottom: 2px;
}

.song__table {
	display: flex;
	padding: 14.5px 39px 14.5px 72px;
}

.song__table > * {
	position: relative;
}

.song__table > * > :first-child {
	display: inline-block;
	text-align: left;
	width: 80%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.song__table > * > :nth-child(2) {
	position: absolute;
	left: 0;
	top: 100%;
	padding: 3px;
	background: var(--color-dark-grey);
	color: var(--color-grey100);
	opacity: 0;
	visibility: hidden;
	z-index: 1;
	transition: 0.1s ease;
}

.song__table > *:hover > :nth-child(2) {
	opacity: 1;
	visibility: visible;
}

.song__table > *:nth-child(2) {
	width: 35%;
	min-width: 35%;
	margin-right: 10px;
}

.song__table > *:nth-child(3) {
	width: 15%;
	min-width: 15%;
	margin-right: 10px;
}

.song__table > *:nth-child(4) {
	width: 25%;
	min-width: 25%;
	margin-right: 10px;
}

.song__table > *:nth-child(5) {
	width: 15%;
}

.song__table > *:nth-child(6) {
	width: 7%;
}

.song__table > *:nth-child(7) {
	width: 5%;
}

.repertoire__phonogramItemsWrapper li {
	position: relative;
	align-items: center;
	font: var(--gilroy-Medium-16-19);
	background-color: var(--color-white);
	margin-bottom: 2px;
}

.repertoire__phonogramItemsWrapper li:last-child {
	margin-bottom: 0;
}

.repertoire__phonogramTitle {
	font: var(--gilroy-Medium-18);
	color: var(--color-grey100);
	text-transform: lowercase;
	margin-bottom: 16px;
	padding-top: 0;
	padding-bottom: 0;
}

.loader {
	margin-top: 30px;
}

.repertoire__phonogramItemLogo {
	width: 22px !important;
	position: absolute;
	top: 50%;
	transform: translate(0, -50%);
	left: 25px;
}

.repertoire__playLogo {
	width: initial !important;
}

.repertoire__playLogo_inactive {
	width: initial !important;
	opacity: 0.3;
}

.repertoire__phonogramItemError {
	color: var(--color-red);
}

.repertoire__phonogramItemError .repertoire__phonogramItemLogo path {
	fill: var(--color-red);
}

.repertoire__mainContent {
	display: flex;
	margin-top: 30px;
	flex-direction: column;
	justify-content: space-between;
	min-height: 575px;
}

.repertoire__mainContentEmpty {
	margin-top: 215px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.repertoire__mainContentEmpty > *:first-child {
	margin-bottom: 32px;
}

.repertoire__mainContentEmpty > *:last-child {
	color: var(--color-dark-grey);
	font: var(--gilroy-Medium-24-140p);
	letter-spacing: 1px;
}

.repertoire__mainContentEmpty > *:last-child a {
	color: var(--color-dark-grey);
	text-decoration: underline;
}

.filter {
	margin-top: 8px;
	display: flex;
	flex-wrap: wrap;
}

.filter__item {
	padding: 6.5px 30px 6.5px 8px;
	background: var(--color-grey100);
	position: relative;
	display: inline-block;
	font: var(--gilroy-Medium-16-19);
	letter-spacing: initial;
	margin-right: 8px;
	margin-top: 8px;
	cursor: pointer;
}

.filter__item img {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 8px;
}

.filter__itemButton {
	display: flex;
	cursor: pointer;
}

.filter a {
	font: var(--gilroy-Medium-18);
	text-decoration: underline;
	margin-left: 8px;
	margin-top: 13px;
}

.btnAsLink {
	background: none !important;
	border: none;
	padding-top: 10px !important;
	font: var(--gilroy-Medium-16-19);
	text-decoration: underline;
	cursor: pointer;
}

.eyeBtn {
	text-overflow: initial;
}

.adaptive__songTitle,
.adaptive__songTable {
	display: none;
}

.adaptive__item {
	display: block;
	width: 100%;
	margin: 16px 0;
}

.adaptive__songItemLogo {
	width: 22px;
	height: 22px;
}

.adaptive__itemTitle {
	height: 27px;
	color: var(--color-grey100);
	font: var(--gilroy-Medium-14-20);
}

.itemInactive {
	color: var(--color-grey100);
}

.adaptive__itemText {
	position: relative;
	height: 27px;
	font: var(--gilroy-Medium-14-20);
}

.adaptive__itemText :first-child {
	display: inline-block;
	max-width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.adaptive__itemText :nth-child(2) {
	position: absolute;
	left: 0;
	top: 100%;
	padding: 3px;
	background: var(--color-dark-grey);
	color: var(--color-grey100);
	opacity: 0;
	visibility: hidden;
	z-index: 1;
	transition: 0.1s ease;
}

.adaptive__itemText:hover > :nth-child(2) {
	opacity: 1;
	visibility: visible;
}

.adaptive__menuMoreWrapper {
	display: flex;
	width: 48px;
	height: 48px;
}

.itemWrapper {
	cursor: pointer;
}

.noData {
	font: var(--gilroy-Medium-24-140p);
}

@media screen and (min-width: 768px) {
	.adaptive__songTitle,
	.adaptive__songTable {
		display: flex;
	}

	.adaptive__item {
		display: none;
	}

	.adaptive__menuMoreWrapper {
		display: none;
	}

	.menuMoreWrapper {
		display: flex;
		align-items: center;
		height: 48px;
		cursor: pointer;
	}

	.menuMoreWrapperActive {
		position: relative;
		display: flex;
		align-items: center;
		height: 48px;

		background-color: var(--color-black);
	}
}

@media screen and (min-width: 1280px) {
	.song__table > *:nth-child(6) {
		width: 5%;
	}
}
