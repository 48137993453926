.Page {
	display: grid;
	width: 100%;
	height: 100%;
	grid-template-areas: 'wiz' 'form';
	grid-template-rows: 60px auto;
	grid-template-columns: auto;
}

.specialSuccess_header {
	color: black;
	font: var(--gilroy-Medium-24-140p);
	margin-bottom: 16px;
}

.specialSuccess_body {
	color: black;
	font: var(--gilroy-Medium-16-140);
}

.Wizard {
	grid-area: wiz;
}

.Container {
	overflow: auto;
	height: 100%;
	padding-bottom: 50px;
	grid-area: form;
}

.Controls {
	margin-top: 50px;
	margin-bottom: 80px;
}

.Declined {
	max-width: 60%;
	margin: 100px auto 0;
}

.Declined ul {
	list-style: disc;
}

.Declined li {
	margin-bottom: 10px;
}

.contractList {
	margin-top: 10px;
}

@media screen and (min-width: 768px) {
	.specialSuccess_header {
		font: var(--gilroy-Medium-40);
	}

	.specialSuccess_body {
		font: var(--gilroy-Medium-24);
	}
}
