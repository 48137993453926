.repertoire {
	width: 100vw;
	top: 56px;
	letter-spacing: 1px;
	background: var(--color-white100);
	overflow-x: hidden;
}

.headerText {
	margin-top: 68px;
	padding: 16px;
	font: var(--gilroy-Medium-24-140p);
}

.repertoire__header {
	display: flex;
	width: 100%;
	overflow-x: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.repertoire__header::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.repertoire__header {
	-ms-overflow-style: none;
	/* IE and Edge */
	scrollbar-width: none;
	/* Firefox */
}

.repertoire__headerButton {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: nowrap;
	padding: 13px 15px !important;
	border: none;
	text-align: center;
	cursor: pointer;
}
.repertoire__headerButtonRepertoires {
	display: inline-block;
	margin-left: 8px;
	font: var(--gilroy-Bold-12-15);
	border: 1px solid var(--color-dark-grey100);
	box-sizing: border-box;
	border-radius: 25px;
	padding: 3px 8px 2px 7px;
	color: var(--color-dark-grey);
	margin: 0;
}

.repertoire__main {
	padding: 16px;
}

@media screen and (min-width: 768px) {
	.Input input {
		font: var(--gilroy-Medium-18);
	}

	.Input {
		max-width: 416px;
		height: 60px;
	}

	.repertoire {
		top: 0;
	}

	.headerText {
		display: none;
	}

	.repertoire__header {
		overflow-x: auto;
		width: 100% !important;
		margin-top: 0px;
	}

	.repertoire__main {
		padding: 30px;
	}
}

@media screen and (min-width: 1024px) {
	.repertoire {
		width: calc(100vw - 167px);
	}
}
