.generalContainer {
	position: relative;
}

.outletsBlock {
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-top: 40px;
}

.outletsTitle {
	font: var(--gilroy-Medium-24);
	line-height: 28px;
	letter-spacing: 0.96px;
}

.outletsContainer {
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin-top: 16px;
	margin-bottom: 32px;
}

.outletsForm {
	margin-top: -18px;
	height: 155px;
}

.addOutlet {
	display: flex;
	align-items: center;
	cursor: pointer;
	background: transparent;
	border: none;
}

.addOutletTitle {
	margin-left: 8px;
	font: var(--gilroy-Medium-18-22);
	text-decoration: underline;
}

.outletsBtns {
	width: 24px;
	cursor: pointer;
}

.outlet, .outletId {
	position: relative;
}

.outletId,
.outletId > div {
	width: 100%;
}

.deleteBtn {
	margin-left: auto;
	margin-right: 12px;
	background: transparent;
	border: none;
	cursor: pointer;
}

.errorHelper {
	position: absolute;
	bottom: -15px;
	left: 0px;
	color: var(--color-red);
	font: var(--gilroy-Medium-12-12);
	padding: 0 4px;
}

.disabled {
	opacity: 0.5;
	cursor: not-allowed;
}