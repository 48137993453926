/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 09/12/2020, 18:26
 */

.Page {
	height: 100%;
	padding: 30px;
	background-color: var(--color-white100);
}

