/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/11/2020, 15:02
 */

.Bullet {
	font: var(--gilroy-Medium-24);
	display: flex;
	align-items: center;
}

/*.Bullet svg {*/
/*    margin-right: 20px;*/
/*}*/

/*.Bullet:global.green svg path {*/
/*    stroke: var(--color-light-green);*/
/*    fill: var(--color-light-green);*/
/*}*/


.Rect {
	width: 10px;
	height: 10px;
	margin-right: 10px;
	background-color: var(--color-red100);
}

.Bullet:global.outline > div {
	border: 1px solid var(--color-black);
	background-color: transparent;
}
