.btn {
	border: none;
	background-color: var(--color-btn-grey);
	padding: 18px 33px;
	/* width: 100%; */
	width: 100%;
	height: 60px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 16px;
	cursor: pointer;
}

.img {
	width: 20px;
	height: 20px;
	fill: var(--color-white);
	margin-right: 16px;
}
.text {
	font: var(--gilroy-Medium-18-140);
	letter-spacing: 1px;
	pointer-events: none;
	color: var(--color-white100);
}

@media screen and (min-width: 768px) {
	.btn {
		width: 30vw;
		margin-bottom: 0;
	}
	.img {
		width: 24px;
		height: 24px;
	}
}

@media screen and (min-width: 1024px) {
	.btn {
		width: 25vw;
		margin-bottom: 0;
	}
}
