.repertoire__mainStatsItems {
	display: grid;
	grid-template-columns: repeat(2, 2fr);
	column-gap: 16px;
	row-gap: 16px;
	margin: 9px 0;
	cursor: pointer;
}

.repertoire__mainStatsItem {
	position: relative;
	display: flex;
	background: var(--color-white);
	padding: 24px;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	align-items: center;
	font: var(--gilroy-Medium-14-17);
	color: var(--color-grey100);
	letter-spacing: 0.4px;
	overflow: hidden;
}

.repertoire__mainStatsVerifyItem {
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	align-items: center;
	font: var(--gilroy-Medium-14-17);
	color: var(--color-grey100);
	letter-spacing: 0.4px;
	overflow: hidden;
}

.active {
	border: 2px solid var(--color-green);
}

.stats {
	color: var(--color-dark-grey);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.disputes {
	color: var(--color-red);
}

.disputes__empty {
	color: var(--color-red);
}

.repertoire__mainStatsItem > span:first-child,
.repertoire__mainStatsVerifyItem > span:first-child {
	margin-bottom: 8px;
}

.repertoire__mainStatsItem > span:last-child,
.repertoire__mainStatsVerifyItem > span:last-child {
	font: var(--gilroy-Medium-24-29);
	letter-spacing: 1px;
}

.form__fragment_help_btn {
	position: absolute;
	top: 0;
	right: 8px;
}

.form__fragment_help_btn svg {
	width: 18px;
}

@media screen and (min-width: 720px) {
	.repertoire__mainStatsItems {
		grid-template-columns: repeat(5, 1fr);
		margin: 0;
		margin-bottom: 30px;
		row-gap: 0;
		column-gap: 16px;
	}

	.repertoire__mainStatsItems_recordings {
		grid-template-columns: repeat(4, 1fr);
	}

	.repertoire__mainStatsItem,
	.repertoire__mainStatsVerifyItem {
		font: var(--gilroy-Medium-16-19);
	}

	.repertoire__mainStatsItem > span:last-child,
	.repertoire__mainStatsVerifyItem > span:last-child {
		font: var(--gilroy-Medium-38);
	}
}
