.page {
	min-height: 85vh;
}

.info button {
	display: flex;
	width: 24px;
	margin-left: 0;
}

.titleBlock {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 16px;
}

.title__info {
	display: flex;
	align-items: flex-end;
	text-transform: lowercase;
}

.title__info.info button {
	margin-bottom: 4px;
}

.title {
	display: flex;
	margin-right: 16px;
	font: var(--gilroy-Medium-24-140p);
}

.form {
	position: relative;
	width: 100%;
	padding: 100px 40px 0px 40px;
}

.form__title {
	display: flex;
	justify-content: left;
	align-items: center;
	margin-bottom: 16px;
	font: var(--gilroy-Medium-16-150);
	color: var(--color-dark-grey);
	letter-spacing: 0.15px;
}

.form__section {
	display: grid;
	grid-template-columns: repeat(2, calc(50% - 12px));
	grid-column-gap: 24px;
	grid-row-gap: 24px;
	margin-top: 16px;
}

.input {
	position: relative;
}

.form__input {
	width: 100%;
}

.input_leftCol {
	position: relative;
	min-width: 412px;
}

.input_rightCol {
	position: relative;
	min-width: 412px;
}

.input_leftCol > :first-child,
.input_rightCol > :first-child {
	width: 100%;
}

.Helper {
	position: absolute;
	bottom: -18px;
	left: 0px;
	color: var(--color-red);
	font: var(--gilroy-Medium-12);
	font-size: smaller;
	padding: 0 5px;
}

.narrative label {
	padding: 0px 4px;
}

.lyricWrapper {
	position: relative;
	margin-top: 32px;
}

.form__submit {
	width: 328px;
	height: 47px;
	margin-top: 48px;
	border: none;
	background: var(--color-dark-grey);
	font: var(--gilroy-Medium-18-140);
	color: var(--color-white100);
	cursor: pointer;
}

.checkBoxWrapper {
	margin-top: 32px;
}

.button__wrapper {
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding: 48px 40px 80px;
}

.prev {
	display: inline-block;
	padding: 18px 65px 18.43px 0px;
	font: var(--gilroy-Medium-18-22);
	text-decoration: underline;
	cursor: pointer;
}

.next {
	max-width: 328px;
	width: 25.625vw;
	height: 60px;
	background: var(--color-dark-grey);
	border: none;
	color: var(--color-white);
	letter-spacing: 1px;
	font: var(--gilroy-Medium-18-140);
	cursor: pointer;
}

.next.disabled {
	background: var(--color-grey50);
	cursor: inherit;
}

.lyricInfo {
	margin-bottom: 14px;
}

.lyricInfo button {
	margin-left: 16px;
}
