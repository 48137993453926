.Checkbox {
	font: var(--gilroy-Medium-18-140);
	display: flex;
	align-items: center;
	box-sizing: border-box;
	max-width: 100%;
	margin-bottom: 10px;
	user-select: none;
	color: inherit;
	border: none;
	outline: none;
	background-color: transparent;
}

.Checkbox input[type='checkbox'] {
	display: none;
}

.Checkbox label {
	position: relative;
	display: flex;
	align-items: center;
	max-width: 100%;
	min-height: 26px;
	cursor: pointer;
}

.Checkbox label :global .mark,
.Checkbox label :global .checkedNotAll {
	display: flex;
	align-items: center;
	padding-right: 48px;
}

.Checkbox:global.empty label :global .mark,
.Checkbox:global.empty label :global .checkedNotAll {
	padding-right: 26px;
}

.Checkbox:global.rtl label {
	flex-direction: row-reverse;
}

.Checkbox:global.rtl label .mark,
.Checkbox:global.rtl label .checkedNotAll {
	padding-right: 0;
	padding-left: 48px;
}

.Checkbox:global.rtl label .mark::before,
.Checkbox:global.rtl label .mark::after,
.Checkbox:global.rtl label .checkedNotAll::before,
.Checkbox:global.rtl label .checkedNotAll::after {
	right: 0;
	left: unset;
}

.Checkbox:global.rtl label .mark::after,
.Checkbox:global.rtl label .checkedNotAll::after {
	right: 7px;
}

.Checkbox :global .mark::before,
.Checkbox :global .mark::after,
.Checkbox :global .checkedNotAll::before,
.Checkbox :global .checkedNotAll::after {
	position: absolute;
	display: inline-block;
	content: '';
}

.Checkbox :global .mark::before,
.Checkbox :global .checkedNotAll::before {
	top: 0;
	left: 0;
	width: 24px;
	height: 24px;
	border: 1px solid;
}

.Checkbox :global .checkedNotAll::before {
	background-color: var(--color-dark-grey);
}

.Checkbox :global .mark::after {
	top: 7px;
	left: 7px;
	width: 11px;
	height: 6px;
	transform: rotate(-45deg);
	border-bottom: 2px solid;
	border-left: 2px solid;
}

.Checkbox :global .checkedNotAll::after {
	top: 7px;
	left: 7px;
	width: 11px;
	height: 6px;
	border-bottom: 2px solid;
	color: var(--color-white);
}

.Checkbox :global .hidden::before {
	top: 0;
	left: 0;
	width: 24px;
	height: 24px;
	border: 1px solid;
	visibility: hidden;
}

.Checkbox :global .hidden::after {
	top: 7px;
	left: 7px;
	width: 11px;
	height: 6px;
	transform: rotate(-45deg);
	border-bottom: 2px solid;
	border-left: 2px solid;
	visibility: hidden;
}

.Checkbox :global .headermark::before {
	top: unset;
}

.Checkbox :global .headermark::after {
	top: unset;
}

.Checkbox :global input[type='checkbox'] + label .mark::after {
	content: none;
}

.Checkbox :global input[type='checkbox']:disabled + label .mark::before {
	border: 1px solid var(--color-grey);
}

.Checkbox :global input[type='checkbox']:checked + label .mark::before {
	background-color: var(--color-dark-grey);
}

.Checkbox
	:global
	input[type='checkbox']:checked:disabled
	+ label
	.mark::before {
	background-color: var(--color-grey);
	border: none;
}

.Checkbox :global input[type='checkbox']:checked + label .mark::after {
	content: '';
	color: var(--color-white);
}

.Checkbox :global input[type='checkbox']:checked:disabled + label .mark::after {
	content: '';
	color: var(--color-grey100);
}

.checkbox_disabled_wrapper {
	display: flex;
}

.checkbox_disabled {
	width: 24px;
	height: 24px;
	margin-right: 24px;
}
