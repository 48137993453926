.Lang {
	grid-area: lang;
}

.textEditor {
	height: 150px;
	font: var(--gilroy-Medium-18) !important;
}

.articleNameInput {
	width: 100%;
	max-width: 100%;
	color: var(--color-dark-grey);
	padding: 8px 4px;
	border: none;
}

.articleNameInput::placeholder {
	color: #9197a3 !important;
}

.component {
	margin-bottom: 20px;
	max-width: 680px;
}

.componentRichText {
	composes: component;
	position: relative;
	z-index: 0;
}
.wrapper {
	padding: 30px 40px;
}

.createArticle__header {
	display: flex;
	justify-content: space-between;
	font: var(--gilroy-Medium-24);
	color: var(--color-dark-grey);
	font-weight: 500;
}

.createArticle__name {
	composes: createArticle__header;
	width: 100%;
}

.notify__confirm {
	display: flex;
}

.notify__confirm_question {
	font: var(--gilroy-Medium-18-22);
}

.activeBtnConfirmQuestion {
	margin-top: 20px;
	max-width: 100%;
}

.activeBtnConfirmQuestion:not(:last-child) {
	margin-right: 8px;
}

.notify__confirm_question_wrapper {
	max-width: 100%;
	margin-top: auto;
}

.inactiveBtn:hover {
	color: var(--color-white) !important;
	background-color: var(--color-grey) !important;
	border: none !important;
}

.DropZone__wrapper {
	margin-top: 32px;
	width: 282px;
	height: 282px;
	position: relative;
}

.DropZone {
	width: 282px;
	height: 282px;
	margin: 0;
	padding: 0;
	border: none;
	background-image: url("data:image/svg+xml;utf8,<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' style='fill: none; stroke: black; stroke-width: 1; stroke-dasharray: 8 8'/></svg>");
}

.DropZone > div {
	width: 100%;
}

.DropZone > div > div {
	margin: 0 15px 0 19px;
	display: flex;
	flex-direction: column;
	text-align: center;
	font: var(--gilroy-Medium-18-22);
}

.DropZone > div > div > img {
	width: 54.47px;
	margin-bottom: 33.06px;
	margin-right: 0;
}

.DropZone span {
	display: block;
}

.loaderWrapper {
	position: absolute;
	bottom: 50%;
	right: 42%;
}

.activeBtn,
.inactiveBtn {
	margin-top: 20px;
	text-transform: lowercase;
	max-width: 400px;
}
