.helper {
	position: relative;
	padding: 24px;
	margin-bottom: 24px;
	background: var(--color-light-green);
	font: var(--gilroy-Medium-16-140);
	letter-spacing: 1px;
}

.helper img {
	position: absolute;
	width: 16px;
	height: 16px;
	top: 8px;
	right: 8px;
}

.error {
	position: relative;
	color: var(--color-red100);
	padding: 0px 5px;
}

.errorText {
	position: absolute;
	font: var(--gilroy-Medium-12);
	font-size: smaller;
}

.dataError {
	display: flex;
	justify-content: center;
	width: 100%;
	margin-top: 50px;
	font: var(--gilroy-Medium-18);
	line-height: 24px;
	color: var(--color-dark-grey);
}

.loader {
	margin-top: 30px;
}

.statistic__songItemLogo {
	position: absolute;
	top: 16px;
	left: 16px;
	width: 22px;
	height: 22px;
}

/* error */
.statistic__songItemError {
	color: var(--color-red);
}

.statistic__songItemError .statistic__songItemLogo path {
	fill: var(--color-red);
}

.statisticByTerritories__mainContent {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

/* empty */

.statisticByDate__mainContentEmpty {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 215px;
}

.inputsWrapper {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}

.inputTrack {
	width: 100%;
	max-width: 150px;
	margin: auto 12px 0px !important;
}

.inputOutlets {
	width: 100%;
	max-width: 150px;
	margin-top: auto;
}

.inputDate {
	min-width: 150px;
}

.inputStartDate {
	composes: inputDate;
	margin: 0 12px 0 0;
}

.topTracksTable {
	width: 100%;
	border-spacing: 0px 2px;
}

.topTracksTable__body {
	background-color: white;
}

.topTracksTable__header > tr > td {
	font: var(--gilroy-Medium-18);
	color: var(--color-grey100);
	text-transform: lowercase;
	margin-bottom: 16px;
	padding: 10px;
}

.topTracksTable__body > tr > td {
	font: var(--gilroy-Medium-16-19);
	padding: 15px 10px;
}

.topTracksTable__body > tr > *:first-child {
	width: 40 px;
}

.topTracksTable__item {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	position: relative;
}

.topTracksTable__fullName {
	position: absolute;
	left: 0px;
	top: 30px;
	padding: 3px;
	background: var(--color-dark-grey);
	color: var(--color-grey100);
	opacity: 0;
	display: none;
	z-index: 1;
	transition: 0.1s ease;
}

.topTracksTable__itemLink:hover .topTracksTable__fullName {
	border: 5px red solid;
	color: red;
	background-color: aquamarine;
	opacity: 1;
	display: block;
}

.topTracksTable__body > tr > *:first-child {
	width: 40px;
}
.topTracksTable__body > tr > *:nth-child(2) {
	max-width: 25vw;
}
.topTracksTable__body > tr > *:nth-child(3) {
	max-width: 20vw;
}
.topTracksTable__body > tr > *:nth-child(4) {
	max-width: 28vw;
}

.topTracksTable__body > tr > *:nth-child(6) {
	max-width: 8vw;
}

.topTracksTitle {
	margin: 40px 0 14px 0;
	font: var(--gilroy-Medium-24-140p);
}

.tableRow:hover {
	cursor: pointer;
}

.statistic__noData {
	margin: 150px 0px;
	text-align: center;
	font: var(--gilroy-Medium-24);
	color: var(---color-dark-grey100);
	text-align: center;
}

@media screen and (min-width: 800px) {
	.inputTrack {
		max-width: 180px;
	}

	.inputOutlets {
		max-width: 180px;
	}
}

@media screen and (min-width: 900px) {
	.inputTrack {
		max-width: 200px;
	}

	.inputOutlets {
		max-width: 200px;
	}
}

@media screen and (min-width: 1024px) {
	.inputTrack {
		max-width: 220px;
	}

	.inputOutlets {
		max-width: 220px;
	}
}

@media screen and (min-width: 1280px) {
	.inputTrack {
		max-width: 25vw;
	}

	.inputOutlets {
		max-width: 25vw;
	}
}
