.repertoire__mainFilter {
	display: flex;
	flex-grow: 1;
}

.repertoire__mainFilterInput {
	display: flex;
	position: relative;
	width: 100%;
}

.repertoire__mainFilterInput svg {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	pointer-events: none;
}

.repertoire__mainFilterInput input {
	width: 100%;
	padding: 8px 4px 8px 39px;
	border: none;
	border-bottom: 2px solid var(--color-grey);
	font: var(--gilroy-Medium-16-140);
	background: transparent;
	transition: 0.2s;
}

.repertoire__mainFilterInput input:hover {
	border-color: var(--color-black);
}

.repertoire__mainFilterInput input:hover ~ svg path {
	fill: #000;
}

.repertoire__mainFilterInput input:focus {
	border-bottom: 2px solid var(--color-dark-grey);
}

.repertoire__mainFilterInput input:focus ~ svg path {
	fill: var(--color-black);
}

.repertoire__mainFilterInput input:focus-visible {
	outline: none;
}

.repertoire__mainFilterInput input::placeholder {
	color: var(--color-grey100);
}

.repertoire__filterImg {
	cursor: pointer;
}

.inputLowercasePlaceholder::placeholder {
	text-transform: lowercase;
}

.inputFilled input {
	border-bottom: 2px solid var(--color-dark-grey) !important;
}

.inputFilled svg path {
	fill: var(--color-black);
}
