.centeredContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	width: 386px;
	height: 100%;
	margin: 0 auto;
}

.centeredContainer__title {
	margin-bottom: 16px;
}

.centeredContainer__content {
	font-weight: 500;
	letter-spacing: 0.72px;
	margin-bottom: 32px;
}
