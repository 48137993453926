.wrapper {
  display: flex;
  flex-direction: column;
	padding: 16px;
	background-color: var(--color-white);
  flex: 50%;
  min-height: 210px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
}

.heading {
  position: relative;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}

.title {
	font: var(--gilroy-Medium-24-28);
}

.list {
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 16px;
  list-style: none;
}



.buttonInfo {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  color: var(--color-grey);
  z-index: 100000000;
}

.buttonInfo span {
  font: var(--gilroy-Medium-16-24);
  text-transform: capitalize;
  text-decoration: underline;
}

.wrapperInformer {
  position: absolute;
  top: 30px;
  right: 0;
  padding: 10px;
  min-width: 170px;
  background-color: var(--color-white);
  box-shadow: 0 1px 10px rgba(0,0,0,0.08);
  z-index: 100;
}

.wrapperInformerHeading {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  font: var(--gilroy-Medium-12);
  color: var(--color-grey);
}


.listSources {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  list-style: none;
}

.source {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  font: var(--gilroy-Medium-14);
}
