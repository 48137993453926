.wrapper {
	position: fixed;
	top: 0;
	z-index: 11111;
	width: calc(100% - 162px);
	height: 100%;
	background-color: var(--color-white);
}
.main {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	text-align: center;
}
.logo {
	justify-content: flex-start;
	padding-top: 16px;
}
.headImg {
	max-width: 373px;
	display: block;
	position: relative;
}
.imgWrapper {
	position: relative;
}
.faceImgWrapper {
	width: 100px;
	height: 50px;
	position: absolute;
	margin: auto;
	top: 52%;
	left: 53%;
	transform: translate(-50%, -50%);
}
.faceImg {
	width: 63px;
	height: 39px;
	position: absolute;
	top: calc(50% - 15px);
	left: calc(31% - 15px);
}
.title {
	font: var(--gilroy-Medium-32);
}
.description {
	font: var(--gilroy-Medium-18);
	color: var(--color-grey);
	font-size: 18px;
	width: 400px;
	margin: 0 auto;
}
@media screen and (min-width: 1024px) {
	.logo {
		height: 100%;
	}
}
@media screen and (max-width: 1024px) {
	.wrapper {
		width: 100%;
	}
}
@media screen and (max-width: 768px) {
	.wrapper {
		width: 100%;
	}
}