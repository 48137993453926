.container {
    padding: 40px;
}

.wrapperFilters {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  column-gap: 24px;
}

.graphsWrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 32px;
    gap: 23px;
}

.inputOutlets {
	width: 100%;
	max-width: 230px;
	margin-top: auto;
}

.filtersButton {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.filtersButton span {
  font: var(--gilroy-Medium-16);
  line-height: 140%;
  border-bottom: 1px solid var(--color-dark-grey);
}


.datePicker {
  width: 100%;
}
