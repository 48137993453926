.inputPeriodWrapper {
  position: relative;
  width: 100%;
  min-width: 210px;
  max-width: 18vw;
}

.inputPeriod {
  width: 100%;
  min-width: 210px;
  max-width: 18vw;
}

.iconCustomPeriod {
  margin-right: 8px;
}

.selectItem {
  background: var(--color-light-grey) !important;
  transition: background-color 0.25s linear, color 0.25s linear;
}

.selectItem:hover {
  background-color: var(--color-dark-grey100) !important;
  color: var(--color-white) !important;
}

.wrapperCalendar {
  display: flex;
}

.calendar {
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 8px 12px 12px;
  top: 61px;
  background-color: var(--color-white) ;
  border: 1px solid var(--color-light-grey);
  z-index: 1000;
  width: 592px;
  min-height: 455px;
}

.wrapperInputsDate {
  display: flex;
  margin-bottom: 12px;
  gap: 16px;
}

.inputDate {
  max-width: 276px;
  width: 100%;
}

.inputDate > div {
  width: 100% !important;
}

.wrapperMenuItem {
  margin: -8px 0;
}

.wrapperButtonCalendar {
  margin-top: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.buttonsCalendar {
  display: flex;
  gap: 8px;
}

.buttonCalendarCancel {
  background: transparent;
  outline: none;
  border: none;
  padding: 7px 34px 10px;
  border: 1px solid var(--color-btn-grey);
  font: var(--gilroy-Medium-18-140);
  cursor: pointer;
}

.buttonCalendarApply {
  background: transparent;
  outline: none;
  border: none;
  padding: 7px 22px 10px;
  background-color: var(--color-dark-grey100);
  font: var(--gilroy-Medium-18-140);
  color: var(--color-white);
  cursor: pointer;
}

.validateDate {
  margin-right: auto;
  display: inline-block;
  color: var(--color-red);
  font: var(--gilroy-Medium-14);
  letter-spacing: 1px;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}



