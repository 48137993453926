.btnOnly {
	z-index: 999999999999999;
}

.formControl {
  position: relative;
	margin: 0px !important;
	width: 100%;
}

.menuItem {
	position: relative;
	padding: 6px 20px !important;
}

.menuItem__Label {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.notFound {
	text-align: center;
	margin-top: 20px;
	margin-bottom: 20px;
}

.checkedTracks {
	text-align: center;
	margin-top: 20px;
	margin-bottom: 20px;
	color: var(--color-light-green);
}

.btnOnly {
	position: absolute;
	top: 0px;
	right: 0px;
	padding: 10px 16px;
	border: none;
	font: var(--gilroy-Medium-14-17);
	background-color: var(--color-black);
	color: var(--color-white);
  opacity: 0;
  z-index: 3;
  cursor: pointer;
  transition: opacity 0.25s linear;

}

.btnOnly:hover {
  opacity: 1;
}

.wrapper {
	margin-top: 10px;
	height: 300px;
}

.search {
	padding-left: 20px !important;
	padding-right: 20px !important;
	width: 100%;
	position: relative;
}

.search::before {
	content: '';
	position: absolute;
	left: 20px;
	top: 70%;
	transform: translateY(-50%);
	width: 24px;
	height: 24px;
	background-image: url('images/loop.svg');
	background-size: cover;
}

.search input {
	padding: 0 0 0 40px !important;
}

.search label {
	padding-left: 60px !important;
}

.selected_single {
	max-width: calc(100% - 15px);
	overflow: hidden;
	text-overflow: ellipsis;
}

.buttonResetFilter {
  position: absolute;
  right: 30px;
  top: 27px;
  background-color: transparent;
  font: var(--gilroy-Medium-16-19);
  text-decoration: underline;
  color: var(--color-grey);
  cursor: pointer;
  white-space: nowrap;
  text-transform: lowercase;
  border: none;
  outline: none;
}

.validateInput {
  color: var(--color-red);
  font: var(--gilroy-Medium-12);
  letter-spacing: 1px;
}

.noData {
  padding-top: 10px;
  font: var(--gilroy-Medium-16);
  text-align: center;
}

.selectElement {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.selectElementRelease {
  display: flex  !important;
  align-items: center !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  gap: 5px;
}

.titleElement {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.typeRelease {
  color: var(--color-grey);
}

.valueInputText {
  display: inline-block;
  max-width: 210px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}