.container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 24px;
  padding: 12px 16px 0;
  gap: 8px;
}

.trackCover {
  width: 48px;
  height: 48px;
  background-color: var(--color-light-grey);
}

.trackTitle {
  margin-bottom: 2px;
  height: 28px;
  width: 350px;
  background-color: var(--color-light-grey);
}

.performerName {
  height: 18px;
  width: 280px;
  background-color: var(--color-light-grey);
}

