.container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 64px;
	padding: 22px 16px 14px 16px;
	overflow: hidden;
}

.container__user {
	display: flex;
	align-items: center;
	gap: 12px;
	height: 64px;
	overflow: hidden;
}

.userLogo {
	width: 50px;
	min-width: 50px;
	height: 50px;
}

.userLogo div {
	width: 100%;
	height: 100%;
}

.container__userInfo {
	display: flex;
	flex-direction: column;
}

.userTitle {
	max-width: 265px;
	font: var(--gilroy-Bold-24);
	font-weight: 700;
	color: var(--color-white);
	text-transform: lowercase;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.userLogin {
	font: var(--gilroy-Medium-14);
	color: var(--color-grey100);
}

.iconsContainer img {
	width: 20px;
	height: 20px;
	cursor: pointer;
}

.iconsContainer button {
	cursor: pointer;
	border: none;
	background: transparent;
}

.iconsContainer__info {
	margin-right: 12px;
}
