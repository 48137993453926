.Textarea {
	font: var(--gilroy-Medium-16-150);
	width: 100%;
	resize: none;
	padding-top: 16px;
	padding-left: 4px;
	border: none;
	outline: none;
	border-bottom: 1px solid var(--color-grey);
	height: 160px !important;
	color: var(--color-dark-grey);
	overflow: auto !important;
}

.Textarea::placeholder {
	color: var(--color-grey);
}

.TextareaWithError {
	font: var(--gilroy-Medium-16-150);
	width: 100%;
	resize: none;
	padding-top: 24px;
	padding-left: 24px;
	background-color: var(--color-white100);
	border: none;
	outline: none;
	height: 160px !important;
	color: var(--color-dark-grey);
	border: 1px solid var(--color-red);
	overflow: auto !important;
}
