.Input {
	position: relative;
	max-width: 100%;
}

.InputWrapper {
	position: relative;
}

.InputWrapper > div:first-child {
	max-width: 100%;
}

.Icon {
	position: absolute;
	top: 8px;
	right: 0;
	cursor: pointer;
	border: none;
	outline: none;
	background-color: transparent;
}

.Input :global .react-calendar {
	position: absolute;
	z-index: 4;
	top: 43px;
	width: 353px;
	padding-bottom: 16px;
	background-color: var(--color-white);
	border: 1px solid var(--color-light-grey);
}

.Input :global .react-calendar button {
	font: var(--gilroy-Medium-16);
	border: none;
	outline: none;
	background-color: transparent;
}

.Input :global .react-calendar button:hover {
	outline: none;
	opacity: 0.8;
}

.Input :global .react-calendar__navigation {
	display: flex;
	align-items: center;
	height: 40px;
	padding: 16px;
	margin-bottom: 16px;
	font-weight: bold;
	background-color: var(--color-light-grey);
}

.Input :global .react-calendar__navigation__prev2-button,
.Input :global .react-calendar__navigation__next2-button {
	display: none;
}

.Input :global .react-calendar__month-view__weekdays {
	margin-bottom: 30px;
	text-align: center;
	color: var(--color-grey);
}

.Input :global .react-calendar__month-view__weekdays__weekday {
	max-width: 40px !important;
	margin: 0 9px;
	padding: 0;
}

.Input abbr {
	text-decoration: none !important;
}

.Input :global .react-calendar__tile {
	width: 40px;
	max-width: 40px !important;
	height: 40px;
	margin: 0 5px;
	padding: 0;
	cursor: pointer;
}

.Input :global .react-calendar__year-view__months__month {
	width: 85px;
	max-width: 85px !important;
	height: 40px;
	margin: 0 9px;
	padding: 0;
}

.Input :global .react-calendar__century-view__decades__decade {
	width: 40px;
	max-width: 117px !important;
	height: 78px;
	margin: 0 9px;
	padding: 0;
}

.Input :global .react-calendar__decade-view__years__year {
	width: 40px;
	max-width: 65px !important;
	height: 40px;
	margin: 0 9px;
	padding: 0;
}

.Input :global button.react-calendar__month-view__days__day--neighboringMonth {
	color: var(--color-grey100);
}

.Input :global button.react-calendar__tile--now {
	border: 1px solid var(--color-green);
}

.Input :global button.react-calendar__tile--active {
	background-color: var(--color-green);
	color: var(--color-white);
}

.Input :global .react-calendar__navigation__label__labelText {
	position: absolute;
	left: 16px;
	top: 10px;
	cursor: pointer;
}

.Input
	:global
	.react-calendar__navigation
	> button.react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
	position: absolute;
	right: 48px;
}

.Input :global .react-calendar__navigation__arrow {
	font-size: 35px !important;
	cursor: pointer;
}

@media screen and (min-width: 768px) {
	.Input {
		max-width: 416px;
	}
}
