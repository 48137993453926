.save_btn {
	margin-top: 23px;
}

.country {
	max-width: 416px;
	margin-bottom: 43px;
}

.checkbox {
	margin-top: -27px;
	margin-bottom: 26px;
}

.checkboxWithError {
	margin-top: -15px;
	margin-bottom: 26px;
}
