.error_container {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	padding: 10px 40px;
}

.error {
	color: var(--color-red);
	font: var(--gilroy-Medium-12);
	font-size: smaller;
}
