.scope_title {
	text-align: left;
	font: var(--gilroy-Medium-24);
	padding-bottom: 20px;
	padding-top: 10px;
	text-transform: lowercase;
}

.table__body {
	/* align-items: center; */
	padding-left: 20px;
}
.noData {
	text-align: center;
	font: var(--gilroy-Medium-24);
}