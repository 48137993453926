.selectDateBtn {
	display: flex;
	justify-content: end;
	border: none;
	background: transparent;
	font: var(--gilroy-Medium-16-140);
	cursor: pointer;
}

.selectDateBtn img {
	margin-right: 8px;
}

.selectDateBtn span {
	border-bottom: 1px solid var(--color-dark-grey);
}

.disabled {
	cursor: not-allowed;
}
