.link {
	color: var(--color-dark-grey);
	transition: transform 0.2s ease-in-out;
}

.link:hover {
	transform: scale(0.98);
}

.card {
	display: flex;
	flex-direction: column;
	width: 260px;
}

.image {
	position: relative;
	width: 260px;
	height: 260px;
}

.cover {
	width: 100%;
}

.noCover {
	position: absolute;
	top: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 260px;
	height: 260px;
	background: var(--gray-200);
}

.imgNoCover {
	display: block;
	margin: 0 auto;
	width: 48px;
}

.noCoverTitle {
	display: block;
	text-align: center;
	margin-top: 10px;
	font: var(--gilroy-Medium-18);
	color: var(--gray-300);
}

.album {
	display: flex;
	margin-top: 12px;
}

.album span {
	padding: 8px;
	height: 32px;
	font: var(--gilroy-Medium-14);
	background: var(--color-light-grey);
}

.album span:first-child {
	margin-right: 8px;
}

.title {
	margin-top: 8px;
	font: var(--gilroy-Medium-18);
	font-weight: 700;
	line-height: 28px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.performer {
	margin-top: 4px;
	font: var(--gilroy-Medium-14);
	font-weight: 500;
	line-height: 16px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.streams {
	margin-top: 8px;
	font: var(--gilroy-Medium-16);
}

.streams__text {
	color: var(--color-grey);
}
