.CountrySelect {
	position: relative;
	display: flex;
	align-items: flex-end;
}

.CountryCode {
	display: flex;
	justify-content: space-between;
}

.countryError {
	position: absolute;
	bottom: 10px;
	max-width: 416px;
	font: var(--gilroy-Medium-12-12);
	color: var(--color-red);
}
