.Page {
	width: 100%;
	height: 100%;
	background-color: var(--color-white100);
}

.Form {
	overflow-x: hidden;
	height: 100%;
	padding-top: 15px;
}

.Header {
	display: flex;
	align-items: center;
	position: sticky;
	z-index: 100;
	width: 100%;
	padding: 16px 55px;
	background: var(--color-white);
}

.Header img {
	margin-right: 20px;
}

.Header div {
	width: 95%;
	box-sizing: border-box;
	word-wrap: break-word;
}

.Document {
	display: flex;
	overflow: auto;
	flex-direction: column;
	padding-top: 30px;
}

.DocPage {
	margin: 0 auto 30px;
	padding-top: 50px;
	padding-left: 40px;
	background-color: var(--color-white);
	box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
}

.Controls {
	margin-top: 40px;
}

.Checkbox {
	margin-bottom: 24px;
}

.Hash {
	position: absolute;
	top: 5px;
	right: 15px;
	color: var(--color-grey);
}

.btnWrapper {
	position: relative;
}

.Helper {
	position: absolute;
	top: 68px;
	left: 0px;
	color: var(--color-red);
	font: var(--gilroy-Medium-12);
	font-size: smaller;
	padding: 0 5px;
}
