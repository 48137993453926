/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/11/2020, 15:02
 */

.Link {
	font: inherit;
	text-decoration: none;
	color: var(--color-grey100);
}

.Link:global.black {
	color: var(--color-dark-grey);
}

.Link:global.underline {
	text-decoration: underline;
}
