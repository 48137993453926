/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/11/2020, 15:02
 */

.Title {
	margin-bottom: 30px;
	padding-top: 50px;
}

.Block {
	min-height: 50vh;
	/*padding-top: 30px;*/
}

.Repertoire {
	composes: Block;
}

.Contract {
	padding: 20px;
	border-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTAiIGhlaWdodD0iNTAiIHZpZXdCb3g9IjAgMCA1MCA1MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMC41IiB5PSIwLjUiIHdpZHRoPSI0OSIgaGVpZ2h0PSI0OSIgcng9IjEuNSIgZmlsbD0iI0ZBRkFGQSIgc3Ryb2tlPSIjMUUxRTFFIiBzdHJva2UtZGFzaGFycmF5PSIxMCAxMCIvPgo8L3N2Zz4K)
		round;
	border-image-slice: 30% fill;
	border-image-width: 24px 24px;
	composes: Block;
}

.Controls {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
}

.Controls button {
	min-width: unset;
}

.Line {
	position: absolute;
	left: 50%;
	display: none;
	height: 100%;
	border-right: 1px solid var(--color-grey);
}

.Item {
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 10px;
	padding: 30px;
	background-color: var(--color-white);
}

.Item :global .mic {
	width: 30px;
	height: 30px;
	margin-right: 20px;
}

.Item :global .title {
	width: 100%;
}

.Item :global .date {
	width: 100px;
	margin-left: 100px;
}

.Item :global .menu {
	margin-left: 30px;
}
