.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.icon {
  margin-bottom: 8px;
}

.title {
  margin-bottom: 16px;
  font: var(--gilroy-Medium-14-17);
  color: var(--color-grey);
}

.percentage {
  margin-top: auto;
  margin-bottom: 2px;
  font: var(--gilroy-Bold-24);
}

.noData {
  font: var(--gilroy-Medium-18);
}

.counter {
  font: var(--gilroy-Medium-12);
  color: var(--color-grey);
}