/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/11/2020, 15:02
 */

.Page {
	margin-top: 71px;
	margin-right: 181px;
	margin-left: 134px;
}

.Page > section {
	margin-bottom: 40px;
}

.Wizard {
	background-color: var(--color-white);
}

.Label {
	margin-bottom: 34px;
}

.Sub {
	margin-bottom: 40px;
}

.Controls {
	display: flex;
	margin-top: 40px;
}

.Controls button {
	margin-right: 30px;
}

.Accordion :global .checkboxes {
	margin-top: 150px;
}

.Accordion :global .checkbox {
	max-width: 100%;
	margin-bottom: 20px;
}

.Accordion :global .checkbox label {
	max-width: 100%;
}
