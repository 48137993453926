.container__items_all,
.container__items_hidden {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	margin: 32px 0px;
}

.container__item {
	width: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	padding: 16px 8px;
	font: var(--gilroy-Medium-16);
	line-height: 24px;
	letter-spacing: 0.16px;
	color: var(--color-grey100);
	background-color: var(--color-white);
	text-decoration: none;
}

.container__item > *:last-child {
	display: flex;
	align-items: center;
	margin-top: 20px;
	height: 100%;
	font: var(--gilroy-Medium-38);
	letter-spacing: 0.4px;
	text-transform: lowercase;
	text-align: center;
}

.stats {
	color: var(--color-dark-grey);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.disputes {
	color: var(--color-red);
}

.disputes__empty {
	color: var(--color-dark-grey);
}

.preLoader {
	font-size: 0px;
	width: 30px;
	height: 30px;
	animation: loader 1s linear infinite;
}

@keyframes loader {
	100% {
		transform: rotate(360deg);
		transform-origin: center;
	}
}

@media screen and (min-width: 1250px) {
	.container__items_all,
	.container__items_hidden {
		margin: 24px 0 48px;
	}

	.container__items_all {
		grid-template-columns: repeat(6, 1fr);
	}

	.container__items_hidden {
		grid-template-columns: repeat(4, 1fr);
	}

	.container__item {
		min-width: 150px;
		min-height: 136px;
		overflow: hidden;
	}

	.container__item > *:last-child {
		padding: 0;
	}
}
