.TableWrapper {
	letter-spacing: 1px;
	margin-left: 40px;
	margin-right: 2vw;
	margin-bottom: 10px;
}

.row_header {
	padding: 11px 15px 11px 0px !important;
	margin: 0px 0px 10px !important;
	flex-wrap: nowrap !important;
	justify-content: space-between !important;
	color: var(--color-grey100);
	font: var(--gilroy-Medium-14);
	line-height: 16px;
	text-transform: lowercase;
	width: 100%;
}

.RepertoireMainInfoTable__body {
	font: var(--gilroy-Medium-16-19);
}

.RepertoireMainInfoTable__body li {
	background-color: var(--color-white);
	margin: 4px 0px;
	border: 3px solid white;
	transition: 0.2s;
}

.RepertoireMainInfoTable__body ul {
	background-color: var(--color-white100);
	padding: 0;
}

.articlesListItem {
	display: flex;
	justify-content: space-between !important;
	min-width: max-content;
}

.row_data {
	display: block !important;
	margin: 2px 0px !important;
	font: var(--gilroy-Medium-16);
	padding: 17px 15px 7px 0px;
	width: 100%;
}

.row_title {
	margin: 0px !important;
	font: var(--gilroy-Medium-16);
	padding: 0px 0px 6px;
	height: 20px !important;
	justify-content: space-between !important;
}

.shell {
	display: flex;
	align-items: center;
}

.shell a {
	width: 100%;
}

.heading {
	display: -webkit-box;
	max-width: 150px !important;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden !important;
	overflow-wrap: break-word;
}

.search {
	margin-left: 40px;
	margin-right: 40px;
	text-transform: lowercase !important;
}

.search::placeholder {
	text-transform: lowercase !important;
}

.sectionTitleWrapper {
	margin: 24px 40px;
	font: var(--gilroy-Medium-24);
	line-height: 28px;
}

.sectionFilterWrapper {
	display: grid;
	grid-template-columns: auto 216px 200px;
	margin: 0px 2vw 16px 40px;
	grid-gap: 48px;
	padding: 4px 0px;
}

.reportDateWrapper {
	display: flex;
	align-items: center;
	margin-left: auto;
	align-items: center;
}

.sectionTitle {
	font: var(--gilroy-Medium-18);
	font-weight: 500;
	cursor: pointer;
	text-decoration: underline;
}

.createArticleBtn {
	width: 200px;
	height: 36px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	color: var(--color-dark-grey);
	font: var(--gilroy-Medium-16);
	font-weight: 500;
	border: none;
	border-radius: 20px;
	background-color: var(--color-light-green);
	cursor: pointer;
}

.uploadReportBtn {
	cursor: pointer;
	text-decoration: none;
	color: var(--color-dark-grey);
	width: 400px;
	margin: 0px auto;
}

.iconPlus {
	width: 16px;
	margin-right: 8px;
}

.iconEdit {
	height: 24px;
	padding-left: 16px;
}

.iconEditWrapper {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding-top: 4px;
}

.noReports {
	width: 50px;

	margin-bottom: 30px;
}

.modalRecipientsWrapper {
	position: relative;
}

.modalRecipientsAll {
	position: absolute;
	top: 100%;
	left: 0;
	background-color: var(--color-dark-grey);
	color: var(--color-white);
}

.modalRecipientsItemWrapper {
	display: flex;
}

.modalRecipientsItem {
	margin-right: 20px;
}

.itemTextWrapper {
	display: -webkit-box;
	max-width: 40vw;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden !important;
}

.itemTextWrapper ul {
	list-style: disc !important;
	padding-left: 20px;
}

.itemTextWrapper ol {
	padding-left: 20px;
}

.innerBlockText {
	white-space: pre-wrap;
}

.topBtnsWrapper {
	display: flex;
	justify-content: right;
	align-items: center;
}

.rubricSettingsLink {
	margin-right: 16px;
	font: var(--gilroy-Medium-18);
	text-decoration: underline;
	color: var(--color-dark-grey);
}

.createArticleBtnLink {
	display: flex;
	align-items: center;
}

.lang {
	margin-left: 40px;
	margin-bottom: 40px;
}

.modifyArticle {
	text-decoration: none;
	color: unset;
}

.noData {
	margin-top: 20vw;
	text-align: center;
	font: var(--gilroy-Medium-24);
}

.menuMoreWrapper {
	margin-left: auto;
	display: flex;
	align-items: center;
	min-width: fit-content;
	height: 49px;
	cursor: pointer;
	margin: 0px 0px 0px auto !important;
	padding: 0px 10px;
}

.menuMoreWrapperActive {
	position: relative;
	margin-left: auto;
	display: flex;
	align-items: center;
	height: 49px;
	min-width: fit-content;
	margin: 0px 0px 0px auto;
	background-color: var(--color-black) !important;
	padding: 0px 10px;
}

.menuMore {
	position: relative;
	flex-grow: 1;
}

.menuMoreList {
	position: absolute;
	bottom: -120px;
	right: 0;
	background-color: var(--color-black) !important;
	color: var(--color-white);
	z-index: 99999;
	white-space: nowrap;
}

.menuMoreListItem {
	padding: 9px 24px;
	border: none !important;
	background-color: var(--color-black) !important;
	cursor: pointer;
}

.action {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.actionTitle {
	text-decoration: underline;
	margin-left: 8px;
	padding-bottom: 3px;
	padding-top: 12px;
}

.actionTitleError {
	text-transform: capitalize;
	color: var(--color-red);
	margin-left: 8px;
}

.actionTitlePending {
	text-transform: capitalize;
	margin-left: 8px;
}

.row_title_accId {
	position: relative;
	cursor: pointer;
}

.row_title_accId :nth-child(2) {
	z-index: 1;
	position: absolute;
	left: 0;
	top: 100%;
	display: flex;
	flex-wrap: nowrap;
	justify-content: flex-start;
	padding: 3px;
	background: var(--color-dark-grey);
	color: var(--color-grey100);
	opacity: 0;
	visibility: hidden;
	transition: 0.1s ease;
	width: max-content !important;
	height: 30px;
}

.row_title_accId:hover > :nth-child(2) {
	opacity: 1;
	visibility: visible;
}

.loader {
	position: absolute;
	top: -5px;
	left: 12px;
}

.percentCount {
	position: absolute;
	top: 0;
	left: 50px;
}

.filterWrapper {
	display: flex;
	align-items: inherit;
	width: 504px;
}

.filterIcon {
	margin-left: 8px;
	width: 24px;
	cursor: pointer;
}
.removeIcon {
	margin-left: 4px;
	width: 36px;
	cursor: pointer;
}

.NoDataWrapper {
	text-align: center;
}

.row_header_content {
	display: flex;
	flex-wrap: nowrap;
}

@media screen and (min-width: 1024px) {
	.mainWrapper {
		width: calc(100vw - 169px);
	}
}

@media screen and (min-width: 1190px) {
	.heading {
		max-width: 200px !important;
	}
}

@media screen and (min-width: 1280px) {
	.heading {
		max-width: 25vw !important;
	}
}
