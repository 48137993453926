/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 23/09/2020, 15:24
 */

.Page {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	background-color: var(--color-white100);
}

.Form {
	width: 100%;
	max-width: 416px;
}

.Title {
	margin-bottom: 24px;
}

.Button {
	margin-top: 20px;
}

.Controls {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	margin-top: 23px;
}

.detailsWrapper {
  display: flex;
  align-items: baseline;
  cursor: pointer;
}

.detailsHeader {
  margin-right: 10px;
}

.detailsText {
  margin-top: 20px;
  max-width: 50vw;
  word-wrap: break-word;
}
