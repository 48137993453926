.container {
	display: flex;
	align-items: center;
	width: 100%;
	padding: 12px;
	font: var(--gilroy-Medium-16-24);
	color: var(--color-error900);
	background: var(--color-error100);
	text-transform: lowercase;
}

.container img {
	margin-right: 12px;
}
