/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/11/2020, 15:02
 */

.Form {
	width: calc(100% - 32px);
	/* width: 415px; */
	align-items: flex-start;
}

.subTitle {
  margin-bottom: 15px;
  font: var(--gilroy-Medium-16);
}

.Button {
	margin-top: 15px;
	max-width: 100%;
}

.Label {
	/* font: var(--form-title-font); */
	font: var(--gilroy-Medium-24-140p);
	margin-top: 54px;
	margin-bottom: 45px;
	padding-top: 16px;
}

.Fields {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	max-height: 60vh;
}

.Controls {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	max-height: 10vh;
}

.Controls a {
	height: 60px;
	margin-top: 20px;
}

.Helper {
  position: absolute;
  top: 65px;
  left: 0px;
  color: var(--color-red);
  font: var(--gilroy-Medium-12);
  font-size: smaller;
  padding: 0 5px;
}

.submit {
  position: relative;
	/* margin-top: -20px; */
}

@media screen and (min-width: 320px) {
}

@media screen and (min-width: 768px) {
	.Label {
		font: var(--gilroy-Medium-40);
		margin-top: 0;
		margin-bottom: 15px;
		padding-top: 0;
	}

	.Form {
		max-width: 416px;
	}
}

@media screen and (min-width: 1440px) {
}

@media screen and (min-width: 1920px) {
}

@media screen and (min-width: 3840px) {
}
