.removeBtn {
	width: 52px;
	height: 52px;
	margin-top: -4px;
	outline: none;
	border: none;
	cursor: pointer;
	background: transparent;
}

@media screen and (min-width: 768px) {
	.removeBtn {
		margin-top: 0;
	}
}
