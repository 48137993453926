.table__item {
	display: flex;
	position: relative;
	align-items: center;
	padding: 10px 0;
	cursor: pointer;
}

.table__item span {
	position: absolute;
	left: 40px;
	font: var(--gilroy-Medium-18-24);
}

.table__item_locked {
	composes: table__item;
	cursor: auto;
}

/* .table__item img, .table__item_locked img {
	width: 24px;
} */
