.page {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	margin-bottom: 100px;
}

.page > * {
	margin-bottom: 30px;
}

.Table {
	font: var(--gilroy-Medium-16);
	overflow: auto;
	width: 100%;
	max-height: 100%;
	border-spacing: 0px;
}

.Table td {
	height: 50px;
	padding: 5px;
	border-top: 1px solid #f4f4f4;
	border-left: 1px solid #f4f4f4;
}

.Table th {
	padding: 5px;
	text-align: left;
	border-top: 1px solid var(--color-grey50);
	border-left: 1px solid var(--color-grey50);
	background-color: #d9d9d9;
}

.Table :global .title {
	width: 100%;
	cursor: auto;
}

.Table :global .firstCol {
	min-width: 190px;
}

.Table tr:hover td,
.Table tr:nth-child(odd):hover td {
	background-color: var(--color-light-grey);
}

.loaderWrapper {
	position: relative;
}
.loader {
	margin-top: 30px;
}

.noData {
	text-align: center;
	font: var(--gilroy-Medium-24);
}

.author > * {
	position: relative;
}

.author > * > :first-child {
	display: inline-block;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.author > * > :nth-child(2) {
	position: absolute;
	left: 0;
	top: 100%;
	padding: 3px;
	background: var(--color-dark-grey);
	color: var(--color-grey100);
	opacity: 0;
	visibility: hidden;
	z-index: 1;
	transition: 0.1s ease;
}

.author > *:hover > :nth-child(2) {
	opacity: 1;
	visibility: visible;
}

.chevronContainer {
	position: relative;
}

.chevronRow {
	display: flex;
	white-space: nowrap;
	overflow: hidden;
	margin-right: 10px;
}

.outlets {
	display: flex;
	flex-direction: column;
	margin-top: 10px;
	margin-left: 18px;
}

.outlets div {
	display: flex;
	align-items: center;
	height: 40px;
}

.flexAlign {
	display: flex;
	align-items: flex-start;
	width: 100%;
	height: 100%;
}
