.mainWrapper {
	padding: 25px 40px;
}

.mainWrapper ul ,.mainWrapper li {
	padding:0;
	margin: 0;
}

.title {
	font: var(--gilroy-Medium-24-140p);
	margin-bottom: 24px;
}

.info {
	margin-bottom: 48px;
}

.infoNames {
	color:  var(--color-grey);
	font:var(--gilroy-Medium-18-20)
	
}

.infoValues {
	/* color: var(--color-grey); */
	font: var(--gilroy-Medium-16-20)
}

.infoNames li, .infoValues li {
	height: 36px;
}

.infoNames li:not(:last-child),
.infoValues li:not(:last-child) {
	margin-bottom: 8px;
}


.infoNameCol,
.infoValuesCol {
	min-width: 328px;
}

.rightsTableTitle , .rightsTableValues {
margin-left:0 !important;
margin-right: 0 !important;
}
.rightsTable {
	padding: 14px 0px;
}

.rightsTableValues {
	background-color: var(--color-white);
	font: var(--gilroy-Medium-16-20);
}

.rightsTableTitle {		
	color: var(--color-grey);
	font:var(--gilroy-Medium-18-20);
	margin-bottom: 14px;
	text-align: center;
}

.rightsTableValues li {
	height: 48px;
	padding-top: 18px;
	text-align: center;

}

.rightsTableTitle :first-child, .rightsTableValuesLeftSide li{
	text-align: left;
	padding-left: 24px !important;
}

.rightsTableValuesLeftSide {
 
}

.productsTable {
	margin-top: 24px;
}

.expandBtn {
	width: 100%;
	height: 44px;
	text-align: center;
	background-color: var(--color-light-grey);
	margin-left:0 !important;
	margin-right:0 !important;
	margin-top: 20px;
	flex-grow: 1;
	justify-content: center !important;
	align-items: center !important;
	cursor: pointer;
}

.expandBtn span {

}