.sign-invoice__wrapper {
	margin: 80px 2vw 30px 40px;
}

.sign-invoice__document {
	margin: 0 2vw 80px 55px;
}
.document-preview {
	margin: 0 0 80px 0;
}

.sign-invoice__heading .Label {
	display: flex;
}

.sign-invoice__list {
	margin: 48px 0;
}

.tooltip__parent {
	margin: 0 0 -2px 8px;
	cursor: pointer;
}

.tooltip__body {
	max-width: 420px;
}

.error_message {
	margin: 6px 0;
	color: var(--color-red);
	font: var(--gilroy-Medium-14);
}
