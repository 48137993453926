.songContracts__empty {
	padding-top: 256px;
	padding-bottom: 272px;
	display: flex;
	flex-direction: column;
	align-items: center;
	letter-spacing: 1px;
}
.songContracts__empty span {
	font: var(--gilroy-Medium-24-140p);
	margin-top: 32px;
}
.songContracts__empty a {
	text-decoration: underline;
}

.songContracts__content {
	display: flex;
	flex-direction: column;
	letter-spacing: 1px;
}
.songContent__item {
	margin-bottom: 40px;
}
.songContent__header {
	display: flex;
	justify-content: space-between;
}
.songContent__title {
	font: var(--gilroy-Medium-24-140p);
}
.songContent__headerTitle {
	text-decoration: underline;
}
.songContent__body {
}
div.songContentBody__tableHead {
	padding-top: 16px;
	padding-bottom: 16px;
	color: var(--color-grey100);
	font: var(--gilroy-Medium-18);
}
.song__table {
	display: flex;
	padding: 11px 24px;
}

.song__table > *:first-child {
	flex-grow: 1;
}
.song__table > *:nth-child(2) {
	margin-left: 6px;
	margin-right: 34px;
	width: 82px;
	text-align: left;
}
.song__table > *:nth-child(3) {
	margin-right: 6px;
	margin-left: 32px;
	width: 82px;
	text-align: left;
}
.song__tableElement {
	display: flex;
	flex-direction: column;
}
.sonsContentBody__tableBody > li {
	align-items: center;
	margin-bottom: 2px;
	background: var(--color-white);
}
.sonsContentBody__tableBody > li:last-child {
	margin-bottom: 0px;
}

.song__tableTitle {
	font: var(--gilroy-Bold-16-19);
	text-decoration: underline;
}
.song__tableText {
	font: var(--gilroy-Medium-16-19);
}
.song__tableDate {
	font: var(--gilroy-Medium-16-140);
}

.mm {
	position: absolute;
	top: 20px;
	left: 50vw;
	z-index: 111111111;
	border: none;
	background: darkblue;
	color: white;
	padding: 5px 10px;
}
