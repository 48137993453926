.codeInput {
	position: relative;
	max-width: 235px;
}

.resendBtn {
	position: absolute;
	cursor: pointer;
	background-color: transparent;
	font: var(--gilroy-Medium-14);
	border: none;
	border-bottom: 2px solid var(--color-grey);
}
