.mainWrapper {
    padding: 60px 30px;
}

.title, .titleComment, .textRow, .search__wrapper {
        margin-left: 0px !important;
        margin-right: 0px !important;
}

.search__wrapper {
    margin-top: 20px;
}

.title {
    margin-top: 56px;
    margin-bottom: 40px;
    font: var(--gilroy-Medium-24);
}

.titleComment {
    font: var(--gilroy-Medium-16);
    margin: 0px 0px 28px 0px !important;
    padding: 0px !important;
    color: var(--color-grey)
}



.columnLeft,
.columnRight {
    padding: 0px !important;
    font: var(--gilroy-Medium-14);
}


.columnLeft:hover,
.columnRight:hover {
    text-decoration: line-through;
}

.columnLeft {
    /* padding-left: 140px!important ; */
    padding-right: 5vw !important;
}

.columnRight {
    /* padding-left: 140px!important ; */
    padding-left: 5vw !important;
}

.textRow {
    padding: 12px 0px !important;
}

.section__wrapper {
    margin-bottom: 48px;
}

.mainWrapper a {
    text-decoration: none;
    color: var(--color-dark-gray);
}

.wayTitle {
    color: var(--color-grey100)
}

.articlesList {
    letter-spacing: 1px;
    line-height: 16.98px;
}

@media screen and (min-width:768px) {
    .mainWrapper {
        padding: 60px 83px;
    }
           .search__wrapper {
               margin-top: 0px;
           }
}

@media screen and (min-width:1280px) {
    .mainWrapper {
        padding: 60px 104px;
    }
}