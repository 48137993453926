.body {
	letter-spacing: 1px;
}

/*  */
/* head */

div.table__header {
	padding-top: 0px;
	padding-bottom: 16px;
	color: var(--color-grey100);
	font: var(--gilroy-Medium-18);
}
div.table__header > span {
	margin-top: 0px !important;
}

/* main */

.main {
	margin-bottom: 8px;
}

.table {
	display: flex;
	padding: 0.764vw 0.764vw 0.764vw 1.667vw;
}

.table > *:first-child {
	/* width: 22.917vw; */
	width: 42.917vw;
	margin-right: 4.306vw;
}
.table > *:nth-child(2) {
	/* width: 28.194vw; */
	width: 8.194vw;
	margin-right: 0.694vw;
	margin-top: 8px;
}
.table > *:nth-child(3) {
	width: 3.889vw;
	margin-right: 3.333vw;
	margin-top: 8px;
}
.table > *:nth-child(4) {
	width: 5.556vw;
	margin-top: 8px;
}

.play {
	position: absolute;
	width: 25px;
	top: 50%;
	transform: translateY(-50%);
	right: 9.653vw;
}

.delete {
	position: absolute;
	text-decoration: underline;
	top: 50%;
	transform: translateY(-50%);
	right: 1.528vw;
	background: none !important;
	border: none;
	font: var(--gilroy-Medium-16-19);
	cursor: pointer;
}

.table__item {
	position: relative;
	background: var(--color-white);
	margin-bottom: 2px;
	font: var(--gilroy-Medium-16-19);
}

.table__item:last-child {
	margin-bottom: 0px;
}

.mm {
	position: absolute;
	top: 20px;
	left: 50vw;
	z-index: 111111111;
	border: none;
	background: darkblue;
	color: white;
	padding: 5px 10px;
}
.noData {
	text-align: center;
	font: var(--gilroy-Medium-24);
}
