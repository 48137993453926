/* MAIN */
/*  */

.modal {
	width: 100vw;
	height: 100vh;
	background: rgba(30, 30, 30, 0.8);
	position: absolute;
	top: 0;
	left: 0;
	z-index: 140000000;
	display: flex;
	justify-content: flex-end;
	visibility: hidden;
	opacity: 0;
}
.modal.active {
	visibility: visible;
	opacity: 1;
}
.container {
	position: relative;
	width: 38.194vw; /* 550px */
	background-color: var(--color-dark-grey);
	overflow-y: auto;
	color: white;
	display: flex;
	flex-direction: column;
}

.modalFooter__title,
.modalHeader__title {
	font: var(--gilroy-Medium-32-140p);
	letter-spacing: 1px;
	text-transform: lowercase;
}

.modal__icon {
	margin-right: 16px;
}
/*  */
/* HEADER */
/*  */

.modalHeader {
	padding: 1.042vw 2.083vw 1.111vw 2.083vw; /* 15px 30px 24px 30px*/
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.modalHeader svg {
	font-size: 42px;
}

/*  */
/* BODY */
/*  */

.modalBody {
	flex-grow: 1;
	padding: 0 30px;
	letter-spacing: 1px;
}
.modalBody__createNew {
	padding: 12px 0;
	display: flex;
	align-items: center;
	font: var(--gilroy-Medium-18);
	text-decoration: underline;
}
.modalBody__list {
}
.bodyList__item {
	display: flex;
	align-items: center;
	font: var(--gilroy-Medium-16-19);
	padding: 15px 0 14px;
}
/*         */
/* FOOTER */
/*       */

.modalFooter {
	padding: 18px 32px 40px 31px;
	opacity: 0;
	visibility: hidden;
	background-color: #000000;
}
.modalFooterActive {
	opacity: 1;
	visibility: visible;
}
.footerTitle {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
}
.footerTitle__textContainer {
	display: flex;
}

.footerTitle__count {
	margin-left: 1.111vw; /* 16px */
	margin-top: 12px;
	background: var(--color-red);
	border-radius: 50%;
	padding: 0.278vw 0.576vw;
	font: var(--gilroy-Medium-14-20);
	height: 1.667vw;
	width: 1.667vw;
	display: flex;
	align-items: center;
	justify-content: center;
}
.footerTitle__reset {
	font: var(--gilroy-Medium-18);
	margin-bottom: 8px;
	text-decoration: underline;
}

.footer__list {
	margin-top: 8px;
	margin-bottom: 16px;
	font: var(--gilroy-Medium-16-19);
}
.footerList__item {
	padding: 15px 0;
	display: flex;
	align-items: center;
}
.footerNext__button {
	background: var(--color-white);
	border: 2px solid #fff;
	width: 100%;
	height: 56px;
	font: var(--gilroy-Medium-18);
	letter-spacing: 1px;
	transition: 0.2s;
}
.footerNext__button:hover {
	background: #000;
	color: white;
}
