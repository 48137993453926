/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 28/01/2021, 10:48
 */
.Input {
	/*display: flex;*/
	/*margin-top: 15px;*/
	/*margin-bottom: 30px;*/
	position: relative;
	max-width: 100%;
}

.Input > div:first-child {
	/*width: 118px;*/
}

.Input > div + div {
	/*width: 100%;*/
	/*margin-left: 8px;*/
}

.Icon {
	position: absolute;
	top: 5px;
	right: 0;
	cursor: pointer;
	border: none;
	outline: none;
	background-color: transparent;
}

.Input :global .react-calendar {
	position: absolute;
	z-index: 1;
	top: 43px;
	width: 416px;
	height: 372px;
	border: none;
	background-color: var(--color-white);
}

.Input :global .react-calendar button {
	font: var(--gilroy-Medium-18);
	border: none;
	outline: none;
	background-color: transparent;
}

.Input :global .react-calendar button:hover {
	border-radius: 50%;
	outline: none;
	background-color: var(--color-light-grey);
}

.Input :global .react-calendar__navigation {
	font-weight: bold;
	padding: 18px 0;
}

.Input :global .react-calendar__navigation__prev2-button,
.Input :global .react-calendar__navigation__next2-button {
	display: none;
}

.Input :global .react-calendar__month-view__weekdays {
	margin-bottom: 30px;
	padding-bottom: 13px;
	text-align: center;
	border-bottom: 1px solid var(--color-dark-grey);
}

.Input :global .react-calendar__month-view__weekdays__weekday {
	max-width: 40px !important;
	margin: 0 9px;
	padding: 0;
}

.Input abbr {
	text-decoration: none;
}

.Input :global .react-calendar__tile {
	width: 40px;
	max-width: 40px !important;
	height: 40px;
	margin: 0 9px;
	padding: 0;
	cursor: pointer;
}

.Input :global .react-calendar__year-view__months__month {
	width: 85px;
	max-width: 85px !important;
	height: 40px;
	margin: 0 9px;
	padding: 0;
}

.Input :global .react-calendar__century-view__decades__decade {
	width: 40px;
	max-width: 117px !important;
	height: 78px;
	margin: 0 9px;
	padding: 0;
}

.Input :global .react-calendar__decade-view__years__year {
	width: 40px;
	max-width: 65px !important;
	height: 40px;
	margin: 0 9px;
	padding: 0;
}

.Input :global button.react-calendar__month-view__days__day--neighboringMonth {
	color: var(--color-grey100);
}

.Input :global .react-calendar__month-view__days__day--weekend {
	color: var(--color-red);
}

.Input :global button.react-calendar__tile--now,
.Input :global button.react-calendar__tile--active {
	border: 1px solid var(--color-dark-grey);
}

.Input :global .react-calendar__navigation__label__labelText {
	cursor: pointer;
}

.Input :global .react-calendar__navigation__arrow {
	font-size: 35px !important;
	cursor: pointer;
}

@media screen and (min-width: 768px) {
	.Icon {
		top: 9px;
	}

	.Input {
		max-width: 416px;
	}
}
