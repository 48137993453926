.Page {
	width: 100%;
	height: 100%;
}

.PageWithWizard {
	width: 100%;
	height: calc(100% - 65px);
}

.Wizard {
	width: 100%;
	height: 65px;
}

.Form {
	overflow: auto;
	max-height: calc(100vh - 140px);
	padding: 50px 30px 100px 15px;
}

.Summary {
	padding-top: 50px;
	padding-bottom: 100px;
	background-color: var(--color-light-green);
}

.Summary :global .content {
	padding-right: 30px;
	padding-left: 74px;
}

.Header {
	margin-bottom: 50px;
}

.Controls {
	display: flex;
	margin-top: 50px;
}

.Field {
	margin-bottom: 30px;
}

.Bullet {
	margin-bottom: 25px;
}

.BulletSelected {
	margin-bottom: 25px;
}

.BulletSelected > div {
	width: 10px;
	height: 10px;
	margin-right: 10px;
	background-color: var(--color-grey);
}

.BulletSelected > span {
	color: var(--color-grey);
}

.infoButton {
	display: block;
	width: calc(100% - 35px);
}

.infoButton svg {
	vertical-align: bottom;
}
