.autoGenres_wrapper {
	position: relative;
}

.autoTag {
	position: absolute;
	top: 22px;
	right: 50px;
	color: black;
	font-size: 17px;
}

/* .autoTag {
	position: absolute;
	top: 23px;
	right: 50px;
	display: none;
	color: black;
	font-size: 17px;
}

.autoGenres_wrapper:focus-within .autoTag {
	display: block;
}
 */
