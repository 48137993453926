.scroll {
  position: relative;
  padding: 20px 0;
  width: 64px;
  height: 64px;
  
  

  border-radius: 40% 0;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  
}

/* .scroll::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 40px;
} */

.scrollSvgPathBackground {
  position: absolute;
    top: 5px;
    left: 5px;
  
    fill: transparent;
    stroke: var(--color-light-grey);
    stroke-width: 5px;
    /* transition: 0.2s; */
    stroke-linecap: square;
}

.scrollSvgPath {
  position: absolute;
  top: 5px;
  left: 5px;
  
  fill: transparent;
  stroke: var(--color-light-green);
  stroke-width: 5px;
  transition: 0.2s;
  stroke-linecap: square;
}



