/* MAIN */
/*  */

.modal {
	width: 100vw;
	height: 100vh;
	background: rgba(30, 30, 30, 0.8);
	position: absolute;
	top: 0;
	left: 0;
	z-index: 140000000;
	display: flex;
	justify-content: flex-end;
	visibility: hidden;
	opacity: 0;
}
.modal.active {
	visibility: visible;
	opacity: 1;
}
.container {
	position: relative;
	width: 38.194vw; /* 550px */
	background-color: var(--color-dark-grey);
	overflow-y: auto;
	color: white;
	display: flex;
	flex-direction: column;
}

.modalFooter__title,
.modalHeader__title {
	font: var(--gilroy-Medium-32-140p);
	letter-spacing: 1px;
	text-transform: lowercase;
}

.modal__icon {
	margin-right: 16px;
}
/*  */
/* HEADER */
/*  */

.modalHeader {
	padding: 1.042vw 2.083vw 1.111vw 2.083vw; /* 15px 30px 24px 30px*/
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.modalHeader svg {
	font-size: 42px;
}

/*  */
/* BODY */
/*  */

.modalBody {
	flex-grow: 1;

	letter-spacing: 1px;
}

.input {
	position: relative;
	margin: 0 30px;
}

.input img {
	position: absolute;
	left: 3px;
	top: 50%;
	transform: translate(0, -50%);
}
.input input {
	width: 100%;
	padding: 10px 10px 12px 39px;
	border: none;
	border-bottom: 2px solid #888;
	color: #d7d7d7;
	background: transparent;
}
.input input::placeholder {
	font: var(--gilroy-Medium-18);
	color: #d7d7d7;
}

.modalBody__list {
	margin-top: 29px;
}
.list__item {
	display: flex;
	flex-direction: column;
	position: relative;
	align-items: flex-start;
	padding: 7px 40px 6px 62px;
	font: var(--gilroy-Medium-16-19);
	letter-spacing: 1px;
}
.list__item:hover {
	cursor: pointer;
	background: #323232;
	text-decoration: line-through;
}
.list__item img {
	position: absolute;
	left: 30px;
	top: 9px;
}
.list__author {
	color: #888888;
	font: var(--gilroy-Medium-14);
}
/*         */
/* FOOTER */
/*       */

.modalFooter {
	display: none;
	position: sticky;
	bottom: 0;
	padding: 18px 32px 40px 31px;
	opacity: 0;
	visibility: hidden;
	background-color: #000000;
}
.modalFooterActive {
	display: block;
	opacity: 1;
	visibility: visible;
}
.footerTitle {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
}
.footerTitle__textContainer {
	display: flex;
}

.footerTitle__count {
	margin-left: 1.111vw; /* 16px */
	margin-top: 12px;
	background: var(--color-red);
	border-radius: 50%;
	padding: 0.278vw 0.576vw;
	font: var(--gilroy-Medium-14-20);
	height: 1.667vw;
	width: 1.667vw;
	display: flex;
	align-items: center;
	justify-content: center;
}
.footerTitle__reset {
	cursor: pointer;
	font: var(--gilroy-Medium-18);
	margin-bottom: 8px;
	text-decoration: underline;
}

.footer__list {
	margin-top: 8px;
	margin-bottom: 16px;
	font: var(--gilroy-Medium-16-19);
	max-height: calc(50vh - (71px + 105px));
	overflow-y: auto;
}

/* scroll */

.footer__list::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}
.footer__list::-webkit-scrollbar-thumb {
	background: #888888;
	border-radius: 30px;
}
.footer__list::-webkit-scrollbar-thumb:hover {
	background: #b3afb3;
}
.footer__list::-webkit-scrollbar-thumb:active {
	background: #555;
}
.footer__list::-webkit-scrollbar-track {
	background: transparent;
}

.footerList__item {
	padding: 15px 0;
	display: flex;
	align-items: center;
}
.footerNext__button {
	margin-top: 16px;
	background: var(--color-white);
	border: 2px solid #fff;
	width: 100%;
	height: 56px;
	font: var(--gilroy-Medium-18);
	letter-spacing: 1px;
	transition: 0.2s;
}
.footerNext__button:hover {
	cursor: pointer;
	background: #000;
	color: white;
}
