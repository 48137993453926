.modalBackground {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 100000;
	width: 100vw;
	height: 100vh;
	background: rgba(30, 30, 30, 0.8);
}

.modal {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: var(--color-white);
	width: 516px;
	padding: 40px 30px;
	gap: 32px;
	position: relative;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
	text-align: center;
}

.closeIcon {
	position: absolute;
	top: 16px;
	right: 16px;
	cursor: pointer;
	font-size: 24px;
}

.modalTitle {
	font: var(--gilroy-Medium-32-140p);
	letter-spacing: 1px;
	color: var(--color-dark-grey100);
}

.modalSubtitle {
	font: var(--gilroy-Medium-16-150);
	letter-spacing: 0.16px;
	color: var(--color-dark-grey100);
}

.buttonContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 16px;
}

.modalButton {
	display: flex;
	width: 100%;
	height: 60px;
	padding: 8px;
	justify-content: center;
	align-items: center;
	background-color: var(--color-dark-grey100);
	color: var(--color-white);
	border: none;
	padding: 10px 20px;
	border: 1px solid var(--color-dark-grey100);
	cursor: pointer;
	font: var(--gilroy-Medium-16-150);
	letter-spacing: 1px;
}

.modalButton:hover {
	background-color: var(--color-white);
	color: var(--color-black);
}
