.preview {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 282px;
	height: 282px;
	border: 1px dashed var(--color-black);
}

.preview img {
	margin: 3px;
	max-height: 282px;
	max-width: 282px;
	transition: filter 0.3s ease;
}

.preview:hover img,
.preview .delete:hover ~ img {
  filter: brightness(60%);
}

.preview .delete {
	position: absolute;
	font: var(--gilroy-Medium-18-22);
	color: var(--color-white);
	cursor: pointer;
	border: none;
	border-bottom: 2px solid var(--color-white);
	background: transparent;
	opacity: 0;
	transition: opacity 0.3s ease;
}

.preview:hover .delete {
	opacity: 1;
}

.delete {
	position: absolute;
	font: var(--gilroy-Medium-18-22);
	color: var(--color-white);
	cursor: pointer;
	border: none;
	border-bottom: 2px solid var(--color-white);
	background: transparent;
}

.preLoader {
	background-image: url('images/loading_circle.svg');
	background-size: 70%;
	background-position: center;
	background-repeat: no-repeat;
	width: 70%;
	height: 70%;
	object-fit: cover;
	animation: loader 1s linear infinite;
}

@keyframes loader {
	100% {
		transform: rotate(360deg);
	}
}
