/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/11/2020, 15:02
 */

.Page {
	display: grid;
	width: 100%;
	height: 100%;
	background-color: var(--color-white100);
	grid-template-areas: "wiz" "page";
	grid-template-rows: 48px auto;
	grid-template-columns: auto;
}

.Page:global.no-wiz {
	grid-template-rows: 0px auto;
}

.Wizard {
	background-color: var(--color-white);
	grid-area: wiz;
}

.Step {
	overflow: auto;
	height: 100%;
	grid-area: page;
}
