/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 22/05/2020, 09:50
 */


.Page {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	padding: 0px 16px;
	background-color: var(--color-white100);
}

.Title {
	margin-bottom:32px;
}
.Title > div:first-child {
	margin-bottom: 16px;
	font: var(--gilroy-Medium-28);
	line-height: 28px;
} 

.Title>div:nth-child(2) {
	font: var(--gilroy-Medium-18);
	line-height: 24px;
	letter-spacing: 0.72px;
}

.Form {
	max-width: 500px;
}

.Controls button {
	max-width: 100%;
}

@media screen and (min-width: 768px) {

	.Controls button {
			max-width: 416px;
		}

	.Title > div:first-child {
		font: var(--gilroy-Medium-40);
		line-height: 40px;
	} 

}