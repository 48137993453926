/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 03/03/2021, 16:02
 */

.Dropzone {
	display: flex;
	justify-content: center;
	box-sizing: border-box;
	width: auto;
	height: 120px;
	padding: 0 25px;
	border: 1px dashed var(--color-dark-grey);
	border-radius: 2px;
	background-color: var(--color-white);
}

.Area {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}

.Area img {
	margin-right: 24px;
}

.Area span {
	cursor: pointer;
	text-decoration: underline;
}

.Text {
	font: var(--gilroy-Medium-18);
}

.Uploaded {
	/*display: flex;*/
	/*align-items: center;*/
}

.Uploaded :global .size {
	margin-left: 33px;
	color: var(--color-grey);
}

.Uploaded :global .filename {
	font-size: 16px;
	line-height: 19px;
	max-width: 306px;
	margin-bottom: 8px;
	word-break: break-all;
	text-decoration-line: underline;
}

.Error {
	padding: 24px;
	border-style: solid;
	border-radius: 2px;
	background: var(--color-light-grey);
	composes: Dropzone;
}

.Error :global .icon {
	width: 50px;
}

.Error :global .header {
	font: var(--gilroy-Medium-18);
	margin-bottom: 10px;
}

.Error :global .link {
	cursor: pointer;
	text-decoration: underline;
}

.Loading {
	align-items: center;
	justify-content: center;
	composes: Dropzone;
}

.Line {
	display: flex;
	align-items: center;
}

.Remove {
	margin-left: 10px;
	cursor: pointer;
}

.Active {
	border-color: var(--color-light-green);
	background-color: var(--color-light-grey);
}

.Dropzone > div {
	outline: none;
}

.Dropzone :global .moderation-error-header {
	color: var(--color-red);
}
