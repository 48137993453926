.PhoneInput {
	display: flex;
}

.PhoneInput > div:first-child {
	width: 118px;
}

.PhoneInput > div + div {
	width: 100%;
	margin-left: 8px;
}

.PhoneInput input[type='number']::-webkit-outer-spin-button,
.PhoneInput input[type='number']::-webkit-inner-spin-button {
	margin: 0;
	-webkit-appearance: none;
}

.disabled input, .codeDisabled {
	opacity: 0.35;
}

@media screen and (min-width: 768px) {
	.PhoneInput {
		max-width: 416px;
	}
}
