.notifyItem {
	position: relative;
	padding: 16px 50px 16px 73px;
	border-bottom: 1px solid #888888;
	cursor: pointer;
}

.notifyItem:last-child {
	border: none;
}

.notifyItem p {
	margin: 0;
}

.notifyItem__text {
	word-wrap: break-word;
}

.notifyItem p.notifyItem__text {
	font: var(--gilroy-Bold-16-20);
}

.notifyItem p.notifyItem__date {
	margin-top: 0.556vw;
	font: var(--gilroy-Medium-16-20);
	color: var(--color-grey);
}

.notifyItem img {
	position: absolute;
	top: 1.389vw;
	left: 1.736vw;
}

.notifyItem span {
	position: absolute;
	top: 1.597vw;
	right: 2.083vw;
}

.notifyItem__dots {
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: var(--color-red);
}
