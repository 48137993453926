.modal {
	position: fixed;
	width: 100%;
	height: 100%;
}

.overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: var(--color-dark-grey);
}

.container {
	width: 519px;
	margin-right: 0 !important;
	padding-right: 0 !important;
}

.user__wrapper :global .another-lang {
	opacity: 0.5 !important;
}

.user {
	height: 100vh;
	overflow: auto;
	color: var(--color-dark-grey);
	background-color: var(--color-white);
}

.user__wrapper {
	display: flex;
	flex-direction: column;
	position: relative;
	padding: 24px;
	height: 100%;
}

.user__header {
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
}

.user__header button {
	border: none;
	background: transparent;
	cursor: pointer;
}

.user__title {
	font: var(--gilroy-Medium-24-28);
	color: var(--color-dark-grey);
}

.user__top_wrapper {
	display: flex;
	justify-content: space-between;
	margin-bottom: 40px;
}

.confirmFields {
	display: flex;
	flex-direction: column;
	margin-top: 32px;
}

.fieldInput {
	position: relative;
}

.fieldInput div {
	max-width: 100%;
}

.codeInput {
	position: relative;
	max-width: 235px;
}

.disabled input {
	opacity: 0.45;
}

.resendBtn {
	position: absolute;
	cursor: pointer;
	background-color: transparent;
	font: var(--gilroy-Medium-14);
	border: none;
	border-bottom: 2px solid var(--color-grey);
}

.note {
	width: 100%;
	padding: 16px 20px;
	font: var(--gilroy-Medium-14);
	background-color: var(--color-light-grey);
}

.saveBtn {
	width: 100%;
	max-width: 100%;
	height: 60px;
	margin-top: auto;
}

.phoneBlock {
	width: 100%;
}

.phoneInput {
	position: relative;
}

.phoneInputContainer {
	position: relative;
	width: 100%;
}

/* .phoneInputContainer > div > div > div {
	margin-bottom: 0 !important;
} */
