.toastWrapper,
.toastWrapperClose {
	position: absolute;
	right: 16px;
	bottom: 16px;
	z-index: 9999999990;
	display: flex;
	box-sizing: border-box;
	width: 361px;
	padding: 16px 24px;
	flex-direction: column;
	align-items: flex-start;
	gap: 8px;
	background: var(--color-dark-grey100);
	color: var(--color-white);
}

.toastWrapper {
	animation: slideIn 1s linear;
}

.toastWrapperClose {
	opacity: 0;
	animation: fadeOut 1s ease-out;
}

@keyframes slideIn {
	0% {
		opacity: 0;
		transform: translateY(32px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.toastTitle {
	font: var(--gilroy-Medium-18-140);
}

.toastText {
	font: var(--gilroy-Medium-14);
}

.toastClose {
	color: var(--color-light-green);
	font: var(--gilroy-Bold-12-15);
	cursor: pointer;
}
