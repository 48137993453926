.container {
	position: relative;
	padding: 24px;
	background-color: var(--color-light-green);
}
.close__btn {
	position: absolute;
	right: 12px;
	top: 12px;
	background-color: inherit;
	border: none;
	cursor: pointer;
}

.close__btn img {
width: 24px;
height: 24px;
}

.title {
  	font: var(--gilroy-Medium-18);
	line-height: 22px;
	letter-spacing: 0.72px;
	color: var(--color-dark-grey);
	margin-bottom: 8px;
	/* text-transform: lowercase; */
}
.text {
  font: var(--gilroy-Medium-14);
	line-height: 22px;
	letter-spacing: 0.14px;
	color: var(--color-dark-grey);
}

@media screen and (min-width: 768px) {
	.container {
		margin-top: 0;
	}
	.btn {
		width: 328px;
		margin-bottom: 0;
	}
	.img {
		width: 24px;
		height: 24px;
	}

		.title {
			font: var(--gilroy-Medium-24-140p);

		}
	
		.text {
			font: var(--gilroy-Medium-16-140);

		}
	
}