.form {
	position: relative;
	margin-top: 72px;
	width: 100%;
	padding: 40px 40px 60px 24px;
	background: var(--color-white);
}

.formTitleWrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 16px;
}

.form__title {
	display: flex;
	justify-content: left;
	align-items: center;
	font: var(--gilroy-Medium-24);
	color: var(--color-dark-grey);
	letter-spacing: 0.15px;
}

.helpInfo {
	margin-bottom: 16px;
}

.form__section {
	display: grid;
	grid-template-columns: repeat(2, calc(50% - 12px));
	grid-column-gap: 24px;
	grid-row-gap: 24px;
}

.input {
	position: relative;
}

.form__input {
	width: 100%;
}

.input_leftCol {
	position: relative;
}

.input_rightCol {
	position: relative;
}

.input_leftCol > :first-child,
.input_rightCol > :first-child {
	width: 100%;
}

.Helper {
	position: absolute;
	bottom: -18px;
	left: 0px;
	color: var(--color-red);
	font: var(--gilroy-Medium-12);
	font-size: smaller;
	padding: 0 5px;
}

.narrative label {
	padding: 0px 4px;
}

.lyricWrapper {
	position: relative;
	margin-top: 32px;
	margin-bottom: 32px;
	min-height: 136px;
}

.form__submit {
	width: 328px;
	height: 48px;
	border: none;
	background: var(--color-dark-grey);
	font: var(--gilroy-Medium-18-140);
	color: var(--color-white100);
	cursor: pointer;
}

.checkBoxWrapper {
	margin-top: 32px;
}
