.artist {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.photo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  flex-shrink: 0;
  background-color: var(--color-white100);
  overflow: hidden;
}

.name {
  margin-bottom: 4px;
  max-width: 185px;
  font: var(--gilroy-Medium-16-24);
  color: var(--color-dark-grey);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}

.name:hover {
  text-decoration: underline;
}

.streams {
  font: var(--gilroy-Medium-14);
  color: #888888;
}