.MainWrapper {
  width: 100vw;
  height: calc(100vh - 64px);
  display:flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font: var(--gilroy-Medium-18);
  text-align: center;
  line-height: 24px;
  letter-spacing: 0.72px;
}


.TextWrapper {
  width:462px;
}

.HelpWrapper {
  display: flex;
  padding:8px 0px;
  margin: 8px 0 32px;
  text-decoration: underline;
  color: var(--color-grey);
}

.InfoBtn {
  margin-right: 8px;
}

.InfoBtn svg {
    width: 24px;
    height: 24px;
}

.SubmitBtn {
  width: 412px;
}

@media screen and (min-width:1024px) {
  .MainWrapper {
      width: calc(100vw - 162px);
    
  }
}
