/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 02/03/2021, 20:39
 */

.Table {
	width: 100%;
	margin-top: 32px;
	margin-bottom: 40px;
	border-bottom: 1px solid var(--color-grey100);
}

.withoutBorder {
	border-bottom: 0px solid transparent;
}

.Table table {
	width: 100%;
	margin-top: 16px;
	margin-bottom: 32px;
}

.Table table th {
	/*background-color: var(--color-light-grey);*/
}

.Table table tr + tr td {
	/*background-color: var(--color-white);*/
	padding-top: 8px;
}

