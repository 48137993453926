.topTracksTable__header > tr > td {
	font: var(--gilroy-Medium-14);
	color: var(--color-grey100);
	text-transform: lowercase;
	margin-bottom: 16px;
	padding: 8px;
}

.topTracksTitleMain {
	margin: 40px 0 16px 0;
	font: var(--gilroy-Medium-32);
}

.topTracksTable {
	width: 100%;
}

.topTracksTable tr {
	cursor: pointer;
}

.topTracksTable td {
	text-align: left !important;
}

.topTracksTable__itemNumber {
	color: var(--color-grey);
}

.topTracksTable__body {
	background-color: white;
}

.topTracksTable__header > tr > td {
	font: var(--gilroy-Medium-14);
	color: var(--color-grey100);
	text-transform: lowercase;
	margin-bottom: 16px;
	padding: 8px;
}

.topTracksTable__body > tr > td {
	font: var(--gilroy-Medium-16-19);
	padding: 15px 10px;
}

.topTracksTable__body > tr > *:first-child {
	width: 40 px;
}

.topTracksTable__item {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	position: relative;
}

.topTracksTable__fullName {
	position: absolute;
	left: 0px;
	top: 30px;
	padding: 3px;
	background: var(--color-dark-grey);
	color: var(--color-grey100);
	opacity: 0;
	display: none;
	z-index: 1;
	transition: 0.1s ease;
}

.topTracksTable__itemLink:hover .topTracksTable__fullName {
	border: 5px red solid;
	color: red;
	background-color: aquamarine;
	opacity: 1;
	display: block;
}

.topTracksTable {
	border-spacing: 0px 2px;
}

.topTracksTable__body > tr > *:first-child {
	width: 40px;
}

.topTracksTable__body > tr > *:nth-child(2) {
	max-width: 25vw;
}

.topTracksTable__body > tr > *:nth-child(3) {
	max-width: 20vw;
}

.topTracksTable__body > tr > *:nth-child(4) {
	max-width: 28vw;
}

.topTracksTable__body > tr > *:nth-child(6) {
	max-width: 8vw;
}
