.authorRole_wrapper .outlets_wrapper {
	position: relative;
}

.autoTag {
	position: absolute;
	top: 33px;
	right: 50px;
	color: black;
	font-size: 17px;
}

.textFiledInputError input,
.textFiledInputError path {
	color: var(--color-red) !important;
	fill: var(--color-red) !important;
}