.tableBody {
	font: var(--gilroy-Medium-16-150);
}

.tableBody li {
	box-sizing: border-box;
	background-color: var(--color-white);
	margin: 2px 0px;
	transition: 0.2s;
}

.tableBody ul {
	background-color: var(--color-white100);
	padding: 0;
}

.articlesListItem {
	cursor: pointer;
}

.rowData {
	display: block !important;
	margin: 2px 0px !important;
	font: var(--gilroy-Medium-16-150);
	padding: 14px 0px;
}

.rowTitle {
	flex-wrap: nowrap !important;
	margin: 0px !important;
	font: var(--gilroy-Medium-16-150);
}

.rowTitleNumber {
	display: flex;
	color: var(--color-grey);
}

.modifyArticle {
	text-decoration: none;
	color: unset;
}

.overflow, .innerBlockText {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}


