.create__container {
	display: flex;
	justify-content: flex-end;
}

.create {
	display: flex;
	align-items: center;
	font: var(--gilroy-Medium-18);
	text-decoration: underline;
	letter-spacing: inherit;
}
.create > img {
	margin-right: 8px;
}

div.table__header {
	padding-top: 21px;
	padding-bottom: 16px;
	color: var(--color-grey100);
	font: var(--gilroy-Medium-18);
}

.body {
	letter-spacing: 1px;
}
.main {
	margin-bottom: 8px;
}

.table {
	display: flex;
	padding: 14.6px 15px 14.4px 24px;
}

.table > *:first-child {
	width: 55.417vw;
	margin-right: 0.694vw;
}

.table > *:nth-child(2) {
	width: 13.75vw;
	margin-right: 0.694vw;
}

.table > *:nth-child(3) {
	width: 11.319vw;
}

.table img {
	position: absolute;
}

.play {
	top: 50%;
	transform: translateY(-50%);
	right: 24px;
}
.nota {
	top: 50%;
	transform: translateY(-50%);
	left: 25px;
}

.table__item {
	position: relative;
	background: var(--color-white);
	margin-bottom: 2px;
	font: var(--gilroy-Medium-16-19);
}

.table__item:last-child {
	margin-bottom: 0px;
}
