.statisticHeader {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 16px;
}

.btnsContainer {
	display: flex;
}

.statistic__chartModeBtn {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 164px;
	height: 40px;
	cursor: pointer;
	font: var(--gilroy-Medium-18-140);
	color: var(--color-grey);
	border: none;
  background-color: var(--color-light-grey);
}

.statistic__chartModeBtnActive {
	background-color: var(--color-dark-grey100);
	color: var(--color-white);
}
