.Page {
	display: grid;
	width: 100%;
	height: 100%;
	grid-template-areas: 'wiz' 'container';
	grid-template-rows: 60px auto;
	grid-template-columns: 100%;
}

.Wizard {
	grid-area: wiz;
}

.Container {
	grid-area: container;
}

.Row {
	height: 100%;
}

.Right {
	padding-top: 50px;
	padding-bottom: 50px;
	background-color: var(--color-light-green);
	height: 100%;
}

.Right label {
	font: var(--gilroy-Medium-24);
}

.Right > div {
	padding: 0px 30px;
}

.Right ul {
	margin-left: 32px;
}

.Right li + li {
	margin-top: 16px;
}

.Left {
	overflow: auto;
	max-height: calc(100vh - 200px);
}

.Form {
	padding-top: 40px;
}

.Header {
	margin-bottom: 24px;
}

.FormLine {
	margin-bottom: 32px;
}

.Subheader {
	margin-bottom: 16px;
}

.Controls {
	margin-top: 50px;
}

.Radio {
	margin-top: 32px;
	margin-bottom: 24px;
}

.uploadBlock {
	max-width: 416px;
}

.infoButton {
	display: block;
	width: calc(100% - 35px);
}

.infoButton svg {
	vertical-align: bottom;
}
