.RepertoireSongInfo__mainInfoTable {
	letter-spacing: 1px;
	margin-left: 40px;
	margin-right: 2vw;
	margin-bottom: 30px;
}

.row_header {
	padding: 0px !important;
	margin: 0px 0px 10px !important;
	margin-top: 30px !important;
	flex-wrap: nowrap !important;
	color: var(--color-grey100);
	font: var(--gilroy-Medium-18);
}

.RepertoireMainInfoTable__body {
	position: relative;
	font: var(--gilroy-Medium-16-19);
}

.RepertoireMainInfoTable__body li {
	background-color: var(--color-white);
	margin: 2px 0px;
	border: 3px solid white;
	transition: 0.2s;
}

.RepertoireMainInfoTable__body ul {
	background-color: var(--color-white100);
	padding: 0;
}

.row_data {
	display: block !important;
	margin: 2px 0px !important;
	font: var(--gilroy-Medium-18);
	padding: 11px 0px;
}

.row_title {
	margin: 0px !important;
	font: var(--gilroy-Medium-18);
	flex-wrap: nowrap !important;
	justify-content: space-between !important;
}

.row_title_number {
	display: flex;
}

.search {
	margin-left: 40px;
	margin-right: 40px;
}

.sectionTitleWrapper {
	display: flex;
	margin: 24px 40px;
	justify-content: space-between;
}

.sectionTitle {
	font: var(--gilroy-Medium-24);
}

.notifyBtn {
	display: flex;
	align-items: center;
	padding: 10px 24px;
	font: var(--gilroy-Medium-16);
	border: none;
	border-radius: 20px;
	background-color: var(--color-light-green);
	cursor: pointer;
}

.iconPlus {
	width: 16px;

	margin-right: 8px;
}

.notificationListItem {
	cursor: pointer;
}

.modalRecipientsWrapper {
	position: relative;
}

.modalRecipientsAll {
	position: absolute;
	top: 100%;
	left: 0;
	background-color: var(--color-dark-grey);
	color: var(--color-white);
}

.modalRecipientsItemWrapper {
	display: flex;
}

.modalRecipientsItem {
	margin-right: 20px;
}

.itemText {
	display: -webkit-box;
	max-width: 40vw;

	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.itemText ul {
	list-style: disc !important;
	padding-left: 20px;
}

.itemText ol {
	padding-left: 20px;
}

.mainWrapper {
	margin-bottom: 30px;
}

.rubricWrapper {
	display: flex;
	justify-content: space-between;
}

.rubricEditor {
	display: flex;
}

.rubricSave {
	margin-left: 15px;
}

.rubricEditor input {
	padding: 0px 0px 10px 16px;
}

.rubricEditor input:focus {
	border-bottom: 2px solid var(--color-dark-grey);
}

.save {
	margin-left: 15px;
}

.lang {
	margin-left: 40px;
	margin-bottom: 40px;
}

.table {
	display: flex;
	flex-direction: column;
	margin-top: 10px;
}

.table__item {
	margin-bottom: 2px;
	width: 100%;
	background: var(--color-white);
	font: var(--gilroy-Medium-14-17);
	cursor: grab;
}

.ActionsWrapper {
	display: flex;
	justify-content: space-between;
	width: fit-content;
}

.ActionsWrapper > img:first-child {
	padding-right: 24px;
	cursor: pointer;
}

.ActionsWrapper > img:last-child {
	padding-right: 9px;
	cursor: pointer;
}

.noData {
	margin-top: 150px;
	text-align: center;
	font: var(--gilroy-Medium-24);
}
