/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 11/12/2020, 16:05
 */

.Component {
	position: relative;
}

.List {
	position: absolute;
	z-index: 99999;
	top: -16px;
	margin: 0;

}

.List li {
	display: flex;
	align-items: center;
	min-width: 416px;
	min-height: 48px;
	padding: 0 10px;
	cursor: pointer;
	background-color: var(--color-light-grey);
}

.List li:hover {
	color: var(--color-white);
	background-color: var(--color-dark-grey);
}

.Dropdown {
	position: relative;
}

.Skeleton {
  margin-top: -25px;
}

.Icon {
	position: absolute;
	top: 9px;
	right: 0;
	cursor: pointer;
	border: none;
	outline: none;
	background-color: transparent;
}