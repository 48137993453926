.cmoContainer {
	display: flex;
	align-items: center;
	margin-left: 16px;
	position: relative;
}

.cmoBtn {
	font: var(--gilroy-Medium-18-140);
	color: var(--color-dark-grey);
	background: transparent;
	border: none;
	border-bottom: 1px solid var(--color-dark-grey);
	cursor: pointer;
}

.green {
	color: var(--color-green);
	border-bottom: 1px solid var(--color-green);
}

.disabled {
	opacity: 0.5;
	cursor: default;
}

.preparing {
	position: absolute;
	top: 100%;
	color: var(--color-dark-grey);
	opacity: 0.5;
	text-transform: lowercase;
}

.clearBtn {
	margin: 4px 0px 0px 16px;
	background: transparent;
	border: none;
	cursor: pointer;
}

.clearBtn img {
	width: 24px;
}
