.wrapper {
  display: flex;
}

.photo {
  width: 100px;
  height: 100px;
  margin-right: 16px;
  background-color:var(--color-light-grey);
}

.title {
  margin-bottom: 4px;
  width: 250px;
  height: 35px;
  background-color:var(--color-light-grey);
}

.attr {
  margin-bottom: 10px;
  width: 100px;
  height: 25px;
  background-color:var(--color-light-grey);
}

.list {
  width: 150px;
  height: 25px;
  background-color:var(--color-light-grey);
}