.page {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
}

.page {
	margin-bottom: 30px;
}

.Table {
	font: var(--gilroy-Medium-16);
	overflow: auto;
	width: 100%;
	max-height: 100%;
	border-spacing: 0px;
}

.Table td {
	height: 50px;
	padding: 5px;
	border-top: 1px solid #f4f4f4;
	border-left: 1px solid #f4f4f4;
}

.Table th {
	padding: 5px;
	border-top: 1px solid var(--color-grey50);
	border-left: 1px solid var(--color-grey50);
	background-color: #d9d9d9;
}

.header {
	font-size: 14px;
	height: 130px;

	text-align: center;
}

.date {
	width: 200px;
}
.errors {
	width: 200px;
}

.outlets {
	min-width: 190px;
}

.rotate_270 {
	transform: rotate(-90deg) translateX(-35px);
	transform-origin: 50% 50%;
}

.rotate {
	width: 50px;
	min-width: 50px;
	max-width: 50px;
	height: 130px;
	padding-top: 30px;
	text-align: start;
}

.Table tr:hover td,
.Table tr:nth-child(odd):hover td {
	background-color: var(--color-light-grey);
}

.Table tr:nth-child(odd) td {
	background-color: var(--color-white100);
}

.page__searchAndFilterWrapper {
	display: flex;
}

.page__input {
	display: block;
	width: 100%;
}
.page__checkbox {
	margin: 10px;
}

.loaderWrapper {
	position: relative;
}
.loader {
	margin-top: 30px;
}

.noData {
	text-align: center;
	font: var(--gilroy-Medium-24);
}

.signUpTitle {
	cursor: pointer;
}

.signUpTitle :hover {
	text-decoration: underline;
}

.inputsWrapper {
	display: flex;
	justify-content: space-between;

	padding: 5px 10px;
	flex-grow: 1;
}

.inputTrack {
	width: 100%;
	margin: auto 12px 0 !important;
}

.inputOutlets {
	width: 100%;
	margin-top: auto;
}

.inputEndDate {
	min-width: 150px;
}
.inputStartDate {
	composes: inputEndDate;
	margin: 0px 12px;
}

.tdStatus {
	text-align: center;
	padding-top: 15px !important;
}

.viewIssue {
	background: none !important;
	border: none;
	padding-top: 10px !important;
	font: var(--gilroy-Medium-16-19);
	text-decoration: underline;
	cursor: pointer;
}
.filterIcon {
	margin: 0 55px 0 8px;
	width: 24px;
	padding-top: 25px;
	cursor: pointer;
}

@media screen and (min-width: 1024px) {
	.filterIcon {
		margin-right: 48px;
	}
}
