.wrapperTabs {
  margin-bottom: 32px;
  display: flex;
  background-color: var(--color-light-grey);
}

.tab {
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 11px 30px 13px;
  font: var(--gilroy-Bold-16-19);
  color: var(--color-dark-grey);
  cursor: pointer;
  background-color: var(--color-light-grey);
}

.active {
  background-color: var(--color-white100);
}

.active span {
  position: relative;
}

.active span::after {
  content: '';
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 1px;
  transform: translate(-50%, calc(-50% + 1px));
  background-color: var(--color-dark-grey);
}