.auto__wrapper {
	position: relative;
	display: flex;
	align-items: center;
}

.auto {
	display: flex;
	font: var(--gilroy-Medium-16-140);
	color: var(--color-dark-grey);
	cursor: pointer;
}

.auto svg {
	margin-right: 21px;
	pointer-events: none;
}

.auto__check path {
	fill: var(--color-dark-grey);
}

.auto__noCheck path {
	fill: var(--color-grey100);
}

.auto span {
	pointer-events: none;
}
