.releaseDateCheckbox {
	height: auto !important;
	margin-top: 24px;
}

.checkboxOutletsContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 24px;
}
