.create__container {
	display: flex;
	justify-content: flex-end;
}

.create {
	display: flex;
	align-items: center;
	font: var(--gilroy-Medium-18);
	text-decoration: underline;
	letter-spacing: inherit;
	background: none !important;
	border: none;
	cursor: pointer;
}

.create > img {
	margin-right: 8px;
}

div.table__header {
	padding-top: 21px;
	padding-bottom: 16px;
	color: var(--color-grey100);
	font: var(--gilroy-Medium-18);
}

.body {
	letter-spacing: 1px;
}

.main {
	margin-bottom: 8px;
}

.table {
	text-decoration: none;
	color: var(--color-black);
	display: flex;
	padding: 14.5px 135px 14.5px 72px;
}

.table > *:first-child {
	width: 37.569vw;
	margin-right: 0.694vw;
}

.table > *:nth-child(2) {
	width: 28.958vw;
	margin-right: 0.694vw;
}

.table img {
	position: absolute;
}

.eye {
	top: 50%;
	transform: translateY(-50%);
	right: 24px;
}

.nota {
	top: 13px;
	left: 25px;
}

.table__item {
	position: relative;
	background: var(--color-white);
	margin-bottom: 2px;
	font: var(--gilroy-Medium-16-19);
}

.table__item:last-child {
	margin-bottom: 0px;
}

.item__authors {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
