.Header {
	width: 100%;
	max-width: 415px;
	white-space: nowrap;
}

.Header span {
	font: var(--gilroy-Medium-40);
}

.Label {
	max-width: 415px;
}

.Button {
	max-width: 415px;
	margin-top: 50px;
}
