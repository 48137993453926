.modal {
	position: fixed;
	width: 100%;
	height: 100%;
}

.overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: var(--color-dark-grey);
}

.contentWrapper {
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;
	top: calc(50vh - 184px);
	left: calc(10vw);
	width: 80vw;
	padding: 16px 30px 40px;
	color: var(--color-dark-grey100);
	background-color: var(--color-white);
}

.confirmBtn {
	width: 100%;
	height: 60px;
	margin-top: 32px;
	margin-left: auto;
	margin-right: auto;
	color: var(--color-white100);
	background-color: var(--color-dark-grey);
	border: 2px solid var(--color-white100);
	font: var(--gilroy-Medium-18);
	cursor: pointer;
}

.twoBtnsWrapper {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-top: 32px;
}

.btn {
	width: 100%;
	height: 60px;
	margin-bottom: 24px;
	color: var(--color-white100);
	background-color: var(--color-dark-grey);
	border: 2px solid var(--color-white100);
	font: var(--gilroy-Medium-18);
	cursor: pointer;
}

.close_button {
	margin: 0 0 0 auto;
}

.close_button button {
	border: none;
	outline: none;
	cursor: pointer;
	background: transparent;
}

.close_button svg {
	transform: scale(0.7);
}

.declineBtn {
	composes: btn;
	margin-bottom: 0px;
}

.confirmBtn:hover,
.btn:hover {
	color: var(--color-dark-grey);
	background-color: var(--color-white100);
	border: 2px solid var(--color-dark-grey);
}

.content {
	text-align: center;
}

.title {
	font: var(--gilroy-Medium-24-140p);
	margin-bottom: 16px;
}

.paragraph {
	font: var(--gilroy-Medium-16-140);
}

@media screen and (min-width: 768px) {
	.twoBtnsWrapper {
		flex-direction: row;
		justify-content: space-between;
		height: 60px;
	}

	.btn {
		width: 48%;
		height: 60px;
		color: var(--color-white100);
		background-color: var(--color-dark-grey);
		border: 2px solid var(--color-white100);
		font: var(--gilroy-Medium-18);
		cursor: pointer;
	}

	.contentWrapper {
		top: calc(50vh - 184px);
		left: calc(50vw - 258px);
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 516px;
	}

	.confirmBtn {
		width: 416px;
	}

	.title {
		font: var(--gilroy-Medium-32-140p);
	}
}
