/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 23/09/2020, 15:24
 */

.Page {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	background-color: var(--color-white100);
}

.Form {
	width: 100%;
	max-width: 416px;
}

.Title {
	margin-bottom: 24px;
}

.link {
	text-decoration: underline;
	cursor: pointer;
}
