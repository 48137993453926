/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 20/10/2020, 13:34
 */

.Modal {
	position: fixed;
	width: 100%;
	height: 100%;
}

.Overlay {
	position: absolute;
	/*opacity: 0.5;*/
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: var(--color-dark-grey);
}

.LeftSide {
	overflow: auto;
	max-height: 100vh;
	color: var(--color-dark-grey);
	background-color: var(--color-white100);
}

.CloseButton {
	position: fixed;
	top: 40px;
	right: 40px;
}

.Content {
	display: flex;
	overflow: auto;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	/*padding: 117px 104px;*/
	min-height: 100vh;
	margin: 117px 104px;
}

.Form {
	max-width: 416px;
	min-height: 50vh;

}

.Header {
	margin-bottom: 24px;
}

.Description {
	margin-top: 24px;
}

.Controls {
	margin-top: 48px;
}

.Title {
	font: var(--gilroy-Medium-32);
	margin-bottom: 50px;
}

.Paragraph {
	font: var(--gilroy-Medium-18);
}

.errorSygnType {
  color: var(--color-red);
    font: var(--gilroy-Medium-12-20);
}


