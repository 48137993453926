.page {
	width: 100%;
}

.helpInfo {
	margin-top: 16px;
	margin-bottom: 32px;
}

.controls {
	margin-top: 50px;
}

.submit__page {
	display: flex;
	justify-content: space-between;
}

.prev_btn {
	display: flex;
	justify-content: center;
	align-items: center;
	width: fit-content;
	height: 60px;
	background: transparent;
	border: none;
	outline: none;
	font: var(--gilroy-Medium-18-22);
	text-decoration: underline;
	cursor: pointer;
}

.submit__page button {
	max-width: 250px;
	height: 48px;
	min-height: 48px;
}

.сheckbox {
	margin-bottom: 48px;
}

.сheckbox label {
	font: var(--gilroy-Medium-18);
}

.Table {
	margin-top: 40px;
	border-bottom: 0px solid transparent;
}

.Table :global .title {
	width: 40%;
	min-width: 40%;
	max-width: 40%;
	color: var(--color-grey);
	font: var(--gilroy-Medium-18);
}

.Table :global .value {
	font: var(--gilroy-Medium-18);
	max-width: 500px;
}

.Roles {
	display: flex;
	flex-wrap: wrap;
	max-width: 500px;
}

.Roles li {
	margin-right: 40px;
	margin-bottom: 0px;
}

.Bullet {
	font-size: 12px;
}

.radio {
	margin-top: 3px;
	margin-bottom: 31px;
}

.radio label {
	font: var(--gilroy-Medium-18-140);
}
