/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 03/12/2020, 14:24
 */

.Page {
	display: flex;
	/*align-items: center;*/
	justify-content: center;
	min-height: 100%;
	padding-top: 60px;
	padding-left: 15px;
	padding-right: 15px;
	background-color: var(--color-white100);
}
