/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/11/2020, 15:02
 */

.LoadingScreen {
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 99%;
	height: 110px;
}

.LoadingScreenNonMargin {
	composes: LoadingScreen;
	height: 100%;
}

.LoadingCube {
	width: 73px;
	height: 73px;
	/*margin: 0 auto;*/
	/*position: absolute;*/
	/*left: 50%;*/
	/*top: 45%;*/
	/*transform: translate(-50%, -50%) rotateZ(45deg);*/
	transform: rotateZ(45deg);
}

.Cube {
	position: relative;
	float: left;
	width: 50%;
	height: 50%;
	transform: rotateZ(45deg) scale(1.1);
}

.Cube:before {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	content: "";
	transform-origin: 100% 100%;
	animation: cssload-fold-thecube 2.76s infinite linear both;
	background-color: var(--color-light-green);
}

.LoadingCube .C2 {
	transform: scale(1.1) rotateZ(90deg);
}

.LoadingCube .C3 {
	transform: scale(1.1) rotateZ(180deg);
}

.LoadingCube .C4 {
	transform: scale(1.1) rotateZ(270deg);
}

.LoadingCube .C2:before {
	animation-delay: 0.35s;
}

.LoadingCube .C3:before {
	animation-delay: 0.69s;
}

.LoadingCube .C4:before {
	animation-delay: 1.04s;
}

@keyframes cssload-fold-thecube {
	0%, 10% {
		transform: perspective(136px) rotateX(-180deg);
		opacity: 0;
	}
	25%, 75% {
		transform: perspective(136px) rotateX(0deg);
		opacity: 1;
	}
	90%, 100% {
		transform: perspective(136px) rotateY(180deg);
		opacity: 0;
	}
}
