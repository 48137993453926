.tableContainer {
	width: 100%;
	max-width: 100%;
	overflow-x: scroll;
}

.table {
	font: var(--gilroy-Medium-16);
	overflow: auto;
	width: 100%;
	max-height: 100%;
	border-spacing: 0px;
}

.table td {
	max-width: 230px;
	height: 50px;
	padding: 5px;
	border-top: 1px solid #f4f4f4;
	border-left: 1px solid #f4f4f4;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.table th {
	padding: 5px;
	border-top: 1px solid var(--color-grey50);
	border-left: 1px solid var(--color-grey50);
	background-color: #d9d9d9;
}

.table tr:global.second th {
	font-size: 14px;
	line-height: 100%;
	width: 50px;
	min-width: 50px;
	max-width: 50px;
	height: 160px;
	padding-top: 30px;
	text-align: start;
}

.table :global .title {
	width: 100%;
	cursor: auto;
}

.table :global .mark {
	width: 24px;
	text-align: center;
}

.table :global .date {
	width: 200px;
}

.firstCol {
	box-sizing: border-box;
	width: 230px;
	min-width: 190px;
	max-width: 230px;
}

.table :global .rotate-270 {
	transform: rotate(-90deg) translateX(-25px);
	transform-origin: 50% 50%;
}

.table tr:hover td,
.table tr:nth-child(odd):hover td {
	background-color: var(--color-light-grey);
}

.table tr:nth-child(odd) td {
	background-color: var(--color-white100);
}

.page__searchAndFilterWrapper {
	display: flex;
}

.inputWrapper {
	padding: 5px 0px 5px 5px;
	flex-grow: 1;
}

.page__input {
	display: block;
	width: 100%;
}

.page__checkbox {
	margin: 10px;
}

.loaderWrapper {
	position: relative;
}

.loader {
	margin-top: 30px;
}

.noData {
	text-align: center;
	font: var(--gilroy-Medium-24);
}

.overflow {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.signUpTitle {
	cursor: pointer;
	max-width: 40vw;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.signUpTitle :hover {
	text-decoration: underline;
}

.filterIcon {
	margin: 0 55px 0 8px;
	width: 24px;
	cursor: pointer;
}

.checkboxCol {
	width: 40px;
}

.checkboxCol > div {
	margin-bottom: 0;
	justify-content: center;
}

@media screen and (min-width: 1024px) {
	.filterIcon {
		margin-right: 48px;
	}
}

@media screen and (min-width: 1440px) {
	.tableContainer {
		overflow-x: hidden;
	}
}

@media screen and (min-width: 2000px) {
	.table :global .rotate-270 {
		transform: rotate(-90deg) translateX(-10px);
	}
}
