.Page {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	justify-content: center;
	margin-bottom: 100px;
}

.Page section {
	width: 100%;
}

.grid {
	margin-top: 40px;
}

.Page__title {
	display: flex;
	width: 100%;
	justify-content: space-between;
}

.HeaderLine {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin-top: 40px;
	margin-bottom: 24px;
}

.Title {
	margin-bottom: 20px;
}

.Field {
	margin-bottom: 20px;
}

.Field :global .title {
	margin-bottom: 10px;
}
