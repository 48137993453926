.Form {
	width: 100%;
	padding: 40px 16px;
}

.Input {
	max-width: 100%;
}

.verifySms__header {
	color: var(--color-dark-grey);
	font: var(--gilroy-Medium-24-140p);
	margin-bottom: 8px;
}

.verifySms__subheader {
	color: var(--color-grey);
	font: var(--gilroy-Medium-18-24);
}

.Title {
	margin-bottom: 48px;
}

.Button_send {
	text-transform: lowercase;
}

.Button_resend {
	margin-top: 8px;
	text-transform: lowercase;
	text-decoration: none !important;
	width: fit-content;
	height: fit-content;
	min-height: fit-content !important;
	border-bottom: 2px solid var(--color-grey) !important;
}

.Fields {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	max-height: 60vh;
}

.Fields > div:first-child {
	margin-bottom: 7px;
	max-width: 100%;
}

.Controls {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: flex-start;
	max-height: 10vh;
	margin-top: 24px;
}

.Controls button {
	max-width: 100%;
}

.phone {
	position: relative;
}

.resendBtn {
	position: absolute;
	bottom: 20px;
	cursor: pointer;
	background-color: transparent;
	font: var(--gilroy-Medium-14);
	border: none;
	border-bottom: 2px solid var(--color-grey);
}

.codeInput {
	position: relative;
}

.disabled input {
	opacity: 0.35;
}

@media screen and (min-width: 768px) {
	.Button_resend {
		margin-top: 26px;
	}

	.Form {
		max-width: 500px;
		padding: 0;
	}

	.Input {
		max-width: 500px;
	}

	.Fields > div:first-child {
		max-width: 100%;
	}

	.Controls button {
		max-width: 500px;
	}

	.Controls {
		align-items: center;
		flex-direction: column;
		justify-content: space-between;
		max-height: 10vh;
	}

	.Title {
		margin-bottom: 48px;
	}

	.verifySms__header {
		font: var(--gilroy-Medium-40);
	}
}
