.modal {
	width: 0;
	height: 100%;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 140000000;
	display: flex;
	justify-content: flex-end;
	visibility: hidden;
	overflow: hidden !important;
	overflow-y: hidden !important;
	opacity: 0;
	transition: width 300ms ease-in;
}

.modal.active {
	width: 100%;
	visibility: visible;
	opacity: 1;
}

.container {
	position: relative;
	height: 100vh;
	background-color: var(--color-white);
	overflow-y: scroll;
	overflow-x: hidden;
	color: var(--color-dark-grey);
	display: flex;
	flex-direction: column;
	padding: 32px 24px 24px;
}

.modalFooter__title,
.modalHeader__title {
	font: var(--gilroy-Medium-32);
	font-weight: 500;
	line-height: 40px;
	letter-spacing: 1px;
	text-transform: lowercase;
}

.modal__icon {
	margin-right: 16px;
}

.modalHeader {
	display: flex;
	flex-direction: column;
	padding-bottom: 40px;
}

.modalHeader__titleWrapper {
	display: flex;
	align-items: center;
}

.modalHeader__clear {
	font: var(--gilroy-Medium-18);
	margin: 0 0 0 24px;
	text-decoration: underline;
	color: var(--color-grey);
	cursor: pointer;
	white-space: nowrap;
	text-transform: lowercase;
}

.modalHeader__clear:hover {
	color: var(--color-dark-grey);
}

.modalHeader__icon {
	margin: 0 0 0 auto;
	font-size: 24px;
}

.modalBody {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.modalBody * {
	letter-spacing: 1px;
}

.setting {
	display: flex;
	flex-direction: column;
	gap: 40px;
}

.setting__title {
	margin-bottom: 15px;
	font: var(--gilroy-Medium-24);
	line-height: 34px;
}

.Overlay {
	position: absolute;
	opacity: 0;
	top: 0;
	left: 0;
	width: 0;
	height: 0;
	background-color: var(--color-dark-grey);
	transition: opacity 300ms ease-in;
}

.OverlayActive {
	z-index: 130000000;
	width: 100vw;
	height: 100vh;
	opacity: 0.5;
}

.error {
	color: var(--color-red);
	font: var(--gilroy-Medium-12);
}

@media screen and (min-width: 795px) {
	.setting__list {
		overflow-y: none;
	}
}
