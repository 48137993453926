.RepertoireSongInfo__mainInfoTable {
	letter-spacing: 1px;
	width: 100% !important;
	margin-left: 9px;
	margin-right: 2vw;
	margin-bottom: 10px;
}

.row_header {
	padding: 0px !important;
	margin: 0px 0px 10px !important;
	flex-wrap: nowrap !important;
	color: var(--color-grey100);
	font: var(--gilroy-Medium-14);
}

.row_data {
	display: block !important;
}

.RepertoireMainInfoTable__bodyTitle {
	margin: 16px 0px 15px 15px;
	display: block;
	font: var(--gilroy-Medium-18);
}

.RepertoireMainInfoTable__body {
	font: var(--gilroy-Medium-16-19);
}

.RepertoireMainInfoTable__body li {
	background-color: var(--color-white);
	margin: 2px 0px;
	border: 3px solid white;
	transition: 0.2s;
}

.RepertoireMainInfoTable__body ul {
	background-color: var(--color-white100);
	padding: 0;
}

.row_data {
	flex-wrap: nowrap !important;
	margin: 2px 0px !important;
	font: var(--gilroy-Medium-18);
	padding: 11px 0px;
}

.row_title {
	margin: 0px !important;
	font: var(--gilroy-Medium-18);
	padding: 0px 0px 6px;
}

.loader {
	margin-top: 30px;
}
