.loader {
	margin-top: 200px !important;
}

.form {
	display: flex;
	flex-direction: column;
}

.input {
	position: relative;
}

.input,
.input > div {
	width: 100%;
}

.pseudonym {
	margin-bottom: 22px;
}

.errorHelper {
	position: absolute;
	bottom: -15px;
	left: 0px;
	color: var(--color-red);
	font: var(--gilroy-Medium-12-12);
	padding: 0 4px;
}

.submitButton {
	position: sticky;
	bottom: 0;
	margin: auto 0 0;
}

.submitButton:hover {
	background-color: var(--color-white) !important;
}

.tip {
	z-index: 100;
	font: var(--gilroy-Medium-12);
	position: absolute;
	bottom: 60px;
	width: 220px !important;
	padding: 16px;
	color: var(--color-white);
	background-color: var(--color-dark-grey);
}

.tip:after {
	z-index: 101;
	position: absolute;
	bottom: -10px;
	left: 40%;
	width: 0;
	height: 0;
	content: '';
	border-width: 20px 20px 0 20px;
	border-style: solid;
	border-color: var(--color-dark-grey) transparent transparent transparent;
}


.info {
	position: absolute;
	top: 35px;
	left: 100px;
	transition-duration: 0.1s;
	transform: translate(0px, 0px);
}

.infoMts {
	position: absolute;
	top: 30px;
	left: 100px;
	transition-duration: 0.1s;
	transform: translate(0px, 0px);
}

.info svg,
.infoMts svg,
.infoActive svg,
.infoActiveMts svg {
	width: 16px;
	height: 16px;
}

.infoActive {
	position: absolute;
	top: 12px;
	left: 81px;
	transition-duration: 0.12s;
	transform: translate(-5px, 0px);
}

.infoActiveMts {
	position: absolute;
	top: 7px;
	left: 81px;
	transition-duration: 0.12s;
	transform: translate(-5px, 0px);
}

.infoActive svg circle,
.infoActiveMts svg circle {
	stroke: var(--color-grey100) !important;
}

.infoActive svg path,
.infoActiveMts svg path {
	fill: var(--color-grey100) !important;
}
