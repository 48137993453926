.Dropdown {
	font: var(--gilroy-Medium-16);
	line-height: 24px;
	position: relative;
	width: 100%;
	width: 105px;
	height: 60px;
	margin-bottom: 25px;
}

.List {
	position: absolute;
	z-index: 1;
	bottom: auto;
	left: 0;
	overflow: auto;
	width: 100%;
	min-width: 342px;
	max-width: 416px;
	max-height: 220px;
	margin: 0;
}

.Item {
	display: flex;
	align-items: center;
	width: 100%;
	height: 42px;
	padding: 0 8px;
	cursor: pointer;
	background-color: var(--color-white);
}

.Item:hover {
	background-color: var(--color-light-grey);
}

.noHover:hover {
	background-color: initial; 
	cursor: default;
}
.Value *,
.Item * {
	margin-right: 8px;
}

.Value {
	display: flex;
	border-bottom: 2px solid var(--color-dark-grey);
	composes: Item;
	padding: 0 4px;
}

input {
	font: var(--gilroy-Medium-18);
	background-color: transparent;
	border: none;
	border-bottom: 2px solid var(--color-grey);
	outline: none;
}

input:focus {
	border: none;
}

.inputWrapper {
	position: relative;
}

.maskPlus {
	position: absolute;
	top: 10px;
	left: 5px;
}

.Helper {
	font: var(--gilroy-Medium-12);
	font-size: smaller;
	padding: 0 5px;
}

.Error .Helper {
	color: var(--color-red);
}

.Error .Value {
	color: var(--color-red);
	border-color: var(--color-red);
}

.Placeholder {
	color: var(--color-grey100);
	composes: Value;
}

.Chevron {
	position: absolute;
	right: 18px;
	bottom: 50%;
	cursor: pointer;
}

.disabled {
	opacity: 0.5;
	cursor: default;
}

.phoneDisabled {
	opacity: 0.35;
	cursor: default;
}

@media screen and (min-width: 768px) {
	.Dropdown {
		font: var(--gilroy-Medium-18);
		line-height: 24px;
	}
}
