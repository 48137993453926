.Overlay {
	position: absolute;
	opacity: 0;
	top: 0;
	left: 0;
	width: 0;
	height: 0;
	background-color: var(--color-dark-grey);
	transition: opacity 300ms ease-in;
}

.OverlayActive {
	z-index: 1288;
	width: 100vw;
	height: 100vh;
	opacity: 0.5;
}

.modal {
	width: 0;
	height: 100%;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 1299;
	display: flex;
	justify-content: flex-end;
	visibility: hidden;
	overflow: hidden !important;
	overflow-y: hidden !important;
	opacity: 0;
	transition: width 300ms ease-in;
}

.modal.active {
	width: 100%;
	visibility: visible;
	opacity: 1;
}

.container {
	position: relative;
	width: 580px;
	min-height: 600px;
	height: 100vh;
	background-color: var(--color-white);
	overflow-y: scroll;
	color: var(--color-dark-grey);
	display: flex;
	flex-direction: column;
	padding: 20px 24px;
}

.modalFooter__title,
.modalHeader__title {
	font: var(--gilroy-Medium-30);
	font-weight: 500;
	line-height: 40px;
	letter-spacing: 1px;
}

.modalHeader_clear {
	font: var(--gilroy-Medium-18);
	font-weight: 500;
	letter-spacing: 1px;
	text-decoration: underline;
	cursor: pointer;
	color: var(--color-grey);
	margin: 0 0 0 24px;
}

.modalHeader_clear:hover {
	color: var(--color-dark-grey);
}

.modal__icon {
	margin-right: 16px;
}

.modalHeader {
	display: flex;
	flex-direction: column;
	padding-bottom: 23px;
}

.modalHeader__titleWrapper {
	display: flex;
	align-items: center;
}

.modalHeader svg {
	font-size: 24px;
}

.modalHeader__icon {
	margin: 0 0 0 auto;
}

.modalBody {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
}

.modalBody * {
	letter-spacing: 1px;
}

.bodyHeader {
	display: flex;
	justify-content: space-between;
	margin-bottom: 40px;
}

.bodyHeaderTitle {
	font: var(--gilroy-Medium-24);
}

.bodyHeader button {
	display: flex;
	align-items: center;
	font: var(--gilroy-Medium-18-140);
	color: var(--color-dark-grey);
	background: transparent;
	border: none;
	cursor: pointer;
}

.addCmoImg {
	width: 24px;
	margin-right: 8px;
}

.addCmoBtn {
	border-bottom: 1px solid var(--color-dark-grey);
}

.title {
	margin-top: 32px;
	margin-bottom: 8px;
	color: var(--color-dark-grey);
	font: var(--gilroy-Medium-18-140);
	font-weight: 700;
}

.disabled {
	cursor: default !important;
	opacity: 0.5;
}
