.rowHeader {
	padding: 0px !important;
	margin: 0px 0px 8px !important;
	flex-wrap: nowrap !important;
	color: var(----color-grey100);
	font: var(--gilroy-Medium-14);
	text-transform: lowercase;
}

.rowHeader div {
	color: var(--color-grey);
}
