.RadioGroup:global.horizontal {
	height: 60px;
	margin-bottom: 25px;
}

.RadioGroup {
	display: grid;
	grid-column-gap: 24px;
	padding: 9px 0;
}

.radioItem {
	display: grid;
	grid-column-gap: 24px;
	padding: 9px 0;
}

.Input__date {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 24px;
	padding: 9px 0;
}

.RadioGroup:global.horizontal {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	max-width: 416px;
	max-height: 60px;
}

.RadioGroup > div:last-child {
	margin-right: 0;
}

.radioBtnItem {
	margin-bottom: 0px;
}

.input {
	position: relative;
	margin-top: 15px;
}

.Helper {
	position: absolute;
	top: 70px;
	left: 0px;
	color: var(--color-red);
	font: var(--gilroy-Medium-12);
	font-size: smaller;
	padding: 0 5px;
}

.dropdown {
	margin-top: 16px !important;
	margin-bottom: 16px !important;
	z-index: 3;
}
