.errors {
	display: flex;
	flex-direction: column;
	margin-top: 10px;
}

.helper {
	color: var(--color-red);
	font: var(--gilroy-Medium-12);
	font-size: smaller;
}
