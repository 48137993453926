.autoGenres_wrapper {
	position: relative;
}

.autoTag {
	position: absolute;
	top: 33px;
	right: 50px;
	color: black;
	font: var(--gilroy-Medium-16);
}
