.banner {
	position: relative;
	width: 100%;
	padding: 24px;
	margin-bottom: 24px;
	background: var(--color-light-green);
	font: var(--gilroy-Medium-16-140);
	letter-spacing: 1px;
}

.banner img {
	position: absolute;
	width: 16px;
	height: 16px;
	top: 12px;
	right: 12px;
	cursor: pointer;
}

.title {
	margin-bottom: 8px;
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: 28px;
	letter-spacing: 0.96px;
}

.text {
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 16px;
}
