.container {
	min-height: 748px;
	padding: 40px 30px 68px 30px;
}

.title {
	font: var(--gilroy-Medium-24-28);
	margin-bottom: 7px;
}

.radioGroup {
	margin-bottom: 22px;
}

.outletsBlock {
	display: flex;
	flex-direction: column;
	gap: 40px;
}

.releaseTitle {
	margin-bottom: 16px;
	font: var(--gilroy-Bold-18);
	color: var(--color-dark-grey);
}

.table {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-column-gap: 40px;
	grid-row-gap: 10px;
	font: var(--gilroy-Medium-16-20);
	color: var(--color-dark-grey);
}

.outletCheckbox {
	font: var(--gilroy-Medium-18-24);
}

.reasonWrapper {
	position: relative;
	margin-top: 40px;
}

.reasonWrapper label {
	font: var(--gilroy-Medium-18-22);
}

.reasonsRadioGroup > div {
	padding: 6px 0px;
}

.helper {
	position: absolute;
	bottom: 0px;
	left: 34px;
	color: var(--color-red);
	font: var(--gilroy-Medium-12);
	font-size: smaller;
}
