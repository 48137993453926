.flex {
	display: flex;
	flex-direction: column;
}

.item {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 30px;
}

.input {
	position: relative;
	display: flex;
	align-items: flex-end;
	margin-bottom: 24px;
}

.input > :first-child {
	width: 100%;
}

.helper {
	position: absolute;
	bottom: -18px;
	left: 0px;
	color: var(--color-red);
	font: var(--gilroy-Medium-12-12);
	padding: 0 4px;
}