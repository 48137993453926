.issue_wrapper {
	list-style: none;
	padding-left: 0px;
	padding-right: 0px;
	margin-left: 0px;
	margin-right: 0px;
	/* 	border: 2px red solid; */
}

.issue_element {
	display: flex;
	/* width: 100vw; */
	/* 	justify-content: flex-start; */
	align-self: center;
	padding: 16px 0px 16px 10px;
}

.issue_element svg {
	margin-top: 5px;
}

.text_left {
	text-align: left;
	padding-left: 25px !important;
}

.track {
	text-align: left;
	padding-left: 50px !important;
}

ul,
li,
span {
	padding: 0px;
	margin: 0px;
}
