.Page {
	display: grid;
	width: 100%;
	height: 100%;
	grid-template-areas: 'wiz' 'form';
	grid-template-rows: 60px auto;
	grid-template-columns: auto;
}

.Wizard {
	grid-area: wiz;
}

.Form {
	height: 100%;
	padding-top: 50px;
	padding-bottom: 50px;
	grid-area: form;
}

.Header {
	margin-bottom: 43px;
}

.Header :global .sub {
	margin-top: 10px;
}

.List {
	margin: 32px 0 0;
}

.Controls {
	margin-top: 50px;
}

.List li {
	margin-bottom: 32px;
}
