.table__item {
  display: flex;
  position: relative;
  align-items: center;
  padding: 10px 0;
  cursor: pointer;
}

.table__item img {
  padding: 3px;
}

.table__item span {
  position: absolute;
  left: 34px;
}

.table__item_locked {
  composes: table__item;
  cursor: auto;
}