/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/11/2020, 15:02
 */

.Page {
	margin-top: 71px;
	margin-right: 181px;
	margin-left: 134px;
}

.Page > section {
	margin-bottom: 40px;
}

.Label {
	margin-bottom: 34px;
}


.Controls {
	display: flex;
	margin-top: 40px;
	padding-bottom: 80px;
}

.Controls button {
	margin-right: 30px;
}
