.Title {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: flex-start;
}

.invoiceNumber {
	width: 60%;
	word-wrap: break-word;
	font: var(--gilroy-Medium-24);
}

.Param {
	color: var(--color-grey);
}

.Status {
	margin-top: 4px;
	margin-bottom: 5px;
	color: var(--color-dark-grey);
	composes: Param;
}

.Date {
	composes: Param;
}

.Download {
	margin-left: 24px;
}

.Download img {
	margin-right: 8px;
}

.Download a {
	display: flex;
	align-items: center;
	color: var(--color-dark-grey);
}

.Files {
	margin-top: 24px;
	margin-left: -10px;
}

.Uploaded {
	margin-bottom: 24px;
}

.Uploaded :global .size {
	width: 50px;
	color: var(--color-grey);
}

.Uploaded :global .filename {
	font-size: 16px;
	line-height: 19px;
	width: 318px;
	min-width: 318px;
	max-width: 318px;
	margin-bottom: 8px;
	text-decoration-line: underline;
}

.uploadBlock {
	max-width: 416px;
}

.Line {
	display: flex;
	align-items: center;
}

.Remove {
	margin-left: 10px;
	cursor: pointer;
}

.Status:global.signed > .status-title {
	color: var(--color-light-green);
}

.Item a {
	width: fit-content;
}

.Error {
	color: var(--color-red);
	composes: Status;
}

.Error :global .status-error ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.Error :global .status-error > div {
	display: flex;
	margin-top: 20px;
}

@media screen and (min-width: 1440px) {
	.invoiceNumber {
		width: auto;
		max-width: 80%;
	}
}
