.repertoireSong__titleWrapper {
	background: var(--color-white);
	padding: 12px 30px;
	display: flex;
	align-items: center;
	color: var(--color-dark-grey);
}

.repertoireSong__header {
	height: 48px;
	background: var(--color-light-grey);
}

.repertoireSong__header > button {
	padding: 15px 36px;
	font: var(--gilroy-Bold-16-19);
}

.repertoireSong__title {
	font: var(--gilroy-Medium-40);
	letter-spacing: 1px;
	margin-left: 16px;
}

.repertoireSong__iswc {
	margin-left: auto;
	text-transform: uppercase;
	padding-right: 83px;
	font: var(--gilroy-Medium-18);
}

.repertoireSong__main {
	padding: 24px 30px;
}

.headerButton {
	cursor: pointer;
}
