.Page {
	display: grid;
	width: 100%;
	height: 100%;
	grid-template-areas: 'wiz' 'form';
	grid-template-rows: 60px auto;
	grid-template-columns: auto;
}

.Wizard {
	grid-area: wiz;
}

.Form {
	overflow: auto;
	overflow-x: hidden;
	height: 100%;
	padding-top: 50px;
	padding-bottom: 50px;
	grid-area: form;
}

.Header {
	margin-bottom: 43px;
}

.Header :global .subheader {
	margin-top: 32px;
}

.Controls {
	margin-top: 50px;
}

.checkbox {
	margin-top: -27px;
	margin-bottom: 27px;
}

.checkboxWithError {
	margin-top: -15px;
	margin-bottom: 27px;
}

.CountryInput input,
.CountryInput {
	max-width: 100%;
}

.CountryInput {
	margin-bottom: 38px;
}

@media screen and (min-width: 768px) {
	.CountryInput input,
	.CountryInput {
		max-width: 416px;
	}

	.CountryInput {
		margin-bottom: 43px;
	}
}
