.emptyPlaylistWrapper {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	height: 70vh;
	width: 100%;
	padding: 0 14px 14px;
}

.emptyPlaylistIcon {
	width: 40px;
	height: 40px;
}

.emptyPlaylistTitle {
	font: var(--gilroy-Medium-24);
	color: var(--color-dark-grey100);
	margin-bottom: 8px;
}

.emptyPlaylistDescription {
	font: var(--gilroy-Medium-14);
	color: var(--color-grey100);
	max-width: 300px;
	text-align: center;
}
