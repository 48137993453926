.info__content {
	padding: 20px 24px;
	background-color: var(--color-white);
}

.info__table {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-column-gap: 24px;
	grid-row-gap: 24px;
}

.info__item {
	display: flex;
	flex-direction: column;
}

.info__item > *:first-child {
	color: var(--color-grey100);
	font: var(--gilroy-Medium-12-12);
	margin-bottom: 6px;
}

.info__item > *:last-child {
	font: var(--gilroy-Medium-16-24);
}

.item__ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.info__logo {
	position: relative;
}

.info__load {
	position: absolute;
	opacity: 0;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	background: transparent;
	color: rgb(254, 254, 254);
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	font: var(--gilroy-Medium-18);
	transition: 0.2s;
}

.info__logo > .info__load {
	background: rgba(0, 0, 0, 0.4);
	opacity: 1;
}

.info__load > *:first-child {
	text-decoration: underline;
}
