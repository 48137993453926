.add__button {
	display: flex;
	margin-top: 24px;
	padding: 6px 24px 6px 20px;
	align-items: center;
	border-radius: 50px;
	background: var(--color-light-green);
	font: var(--gilroy-Medium-16-150);
	color: var(--color-btn-grey);
	cursor: pointer;
	border: none;
}
