.Logo {
	position: fixed;
	z-index: 99999;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	height: 51px;
	margin-bottom: auto;
	padding-left: 16px;	
	background-color: var(--color-logo);	
	grid-area: logo;
}

.Commit {
	color: var(--color-grey);
}

.Image {
	height: 23px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

@media screen and (min-width: 768px) {
	.Image {
		width: 120px;
		height: 40.73px;
	}

	.Logo {
		height: 80px;
		padding-left: 30px;
	}
}

@media screen and (min-width: 768px) {
	.Logo {
		padding-left: 20px;
	}
}

@media screen and (min-width: 1024px) {
	.Logo {
		position: unset;
		padding-left: 0;
		width: 162px;
	}
}

@media screen and (min-width: 3840px) {
	.Image {
		width: 216px;
		height: 66px;
	}
}
