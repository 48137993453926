.edit {
	width: 24px;
	height: 24px;
}

.repertoire__songItemsWrapper {
	display: flex;
	flex-direction: column;
}

.repertoire__songItemsWrapper a {
	color: unset;
	text-decoration: none;
}

.draft_moderation {
	color: var(--color-grey100);
}

.song__table {
	display: flex;
	align-items: center;
	padding: 14px 15px 14px 64px;
}

.song__table > * {
	position: relative;
}

.song__table > * > :first-child {
	display: inline-block;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.song__table > * > :nth-child(2) {
	position: absolute;
	left: 0;
	top: 100%;
	padding: 3px;
	background: var(--color-dark-grey);
	color: var(--color-grey100);
	opacity: 0;
	visibility: hidden;
	z-index: 1;
	transition: 0.1s ease;
}

.song__table > *:hover > :nth-child(2) {
	opacity: 1;
	visibility: visible;
}

.song__table > *:first-child {
	width: 18.281vw;
	margin-right: 2.344vw;
}

.song__table > *:nth-child(2) {
	width: 15.547vw;
	margin-right: 2.344vw;
}

.song__table > *:nth-child(3) {
	width: 17.735vw;
	margin-right: 2.344vw;
}

.song__table > *:nth-child(4) {
	width: 8.594vw;
	margin-right: 2.344vw;
}

.song__table > *:nth-child(5) {
	margin-left: auto;
}

.repertoire__songItemsWrapper .itemWrapper {
	position: relative;
	font: var(--gilroy-Medium-16-19);
	background-color: var(--color-white);
}

.repertoire__songItemsWrapper li:last-child {
	margin-bottom: 0;
}

.repertoire__songTitle {
	font: var(--gilroy-Medium-18);
	color: var(--color-grey100);
	text-transform: lowercase;
	margin-bottom: 16px;
	padding-top: 0;
	padding-bottom: 0;
}

.loader {
	margin-top: 30px;
}

.repertoire__songItemLogo {
	position: absolute;
	top: 16px;
	left: 16px;
	width: 22px;
	height: 22px;
}

.repertoire__songItemError {
	color: var(--color-red);
}

.repertoire__songItemError .repertoire__songItemLogo path {
	fill: var(--color-red);
}

.repertoire__mainContent {
	margin-top: 30px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.repertoire__mainContentEmpty {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 215px;
}

.repertoire__mainContentEmpty > *:first-child {
	margin-bottom: 32px;
}

.repertoire__mainContentEmpty > *:last-child {
	color: var(--color-dark-grey);
	font: var(--gilroy-Medium-24-140p);
	letter-spacing: 1px;
}

.repertoire__mainContentEmpty > *:last-child a {
	color: var(--color-dark-grey);
	text-decoration: underline;
	cursor: pointer;
}

.itemWrapper {
	display: flex;
	justify-content: space-between;
	border-bottom: 2px solid var(--color-white100);
}

.itemLink {
	flex-grow: 1;
}

.disabled {
	cursor: not-allowed;
}

.menuMore {
	position: relative;
	padding: 0px 14px 0px 14px;
}

.menuMoreList {
	position: absolute;
	bottom: -37px;
	right: 0;
	background-color: var(--color-black);
	color: var(--color-white);
	z-index: 99999;
}

.menuMoreListItem {
	padding: 9px 24px;
}

.menuMoreWrapper {
	display: flex;
	align-self: flex-start;
	align-items: center;
	min-width: 49px;
	height: 48px;
	cursor: pointer;
}

.menuMoreWrapperActive {
	position: relative;
	display: flex;
	align-self: flex-start;
	align-items: center;
	height: 48px;

	background-color: var(--color-black);
}

.adaptive__songTitle,
.adaptive__songTable {
	display: none;
}

.adaptive__item {
	display: block;
	width: 100%;
}

.adaptive__songItemLogo {
	width: 22px;
	height: 22px;
}

.adaptive__firstRow {
	display: flex;
	align-items: center !important;
	padding-bottom: 8px;
}

.adaptive__itemTitle {
	height: 27px;
	color: var(--color-grey100);
	font: var(--gilroy-Medium-14-20);
}

.itemInactive {
	color: var(--color-grey100);
	display: block;
	width: 100%;
}

/*=======================*/
.adaptive__itemText {
	position: relative;
	height: 27px;
	/* border: 1px solid green; */
	font: var(--gilroy-Medium-14-20);
}

.adaptive__itemText :first-child {
	display: inline-block;
	max-width: 40vw;
	white-space: nowrap;
	/* Текст не переносится */
	overflow: hidden;
	/* Обрезаем всё за пределами блока */
	text-overflow: ellipsis;
	/* Добавляем многоточие */
}

.adaptive__itemText :nth-child(2) {
	position: absolute;
	left: 0;
	top: 100%;
	padding: 3px;
	background: var(--color-dark-grey);
	color: var(--color-grey100);
	opacity: 0;
	visibility: hidden;
	z-index: 1;
	transition: 0.1s ease;
}

.adaptive__itemText:hover > :nth-child(2) {
	opacity: 1;
	visibility: visible;
}

.adaptive__menuMoreWrapper {
	display: flex;
	min-width: 49px;
	height: 48px;
}

.adaptive__itemText_firstLine {
	position: relative;
}

.adaptive__itemText_firstLine :first-child {
	display: -webkit-box;
	max-width: 40vw;
	/* max-width: 200px; */
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.adaptive__itemText_firstLine :nth-child(2) {
	position: absolute;
	left: 0;
	top: 100%;
	padding: 3px;
	background: var(--color-dark-grey);
	color: var(--color-grey100);
	opacity: 0;
	visibility: hidden;
	z-index: 1;
	transition: 0.1s ease;
}

.adaptive__itemText_firstLine:hover > :nth-child(2) {
	opacity: 1;
	visibility: visible;
}

@media screen and (min-width: 768px) {
	.adaptive__songTitle,
	.adaptive__songTable {
		display: flex;
		align-items: center;
	}

	.adaptive__item {
		display: none;
	}

	.adaptive__menuMoreWrapper {
		display: none;
	}

	.menuMoreWrapper {
		display: flex;
		align-items: center;
		height: 48px;
		cursor: pointer;
	}

	.menuMoreWrapperActive {
		position: relative;
		display: flex;
		align-items: center;
		height: 48px;
		background-color: var(--color-black);
	}
}
