.noData {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 100%;
	font: var(--gilroy-Medium-18);
	line-height: 24px;
	color: var(--color-dark-grey);
	text-transform: lowercase;
}

.noData > div:nth-child(2) {
	margin-top: 8px;
	color: var(--color-grey);
	font: var(--gilroy-Medium-14);
	line-height: 16px;
}

.text {
	display: flex;
}

.text span {
	text-align: center;
	font: var(--gilroy-Medium-18);
	font-weight: 500;
	line-height: 24px;
}

.text span h4 {
	font: var(--gilroy-Medium-32);
	font-weight: 500;
	line-height: 40px;
}
