.RepertoireSongInfo__mainInfoTable {
	letter-spacing: 1px;

	margin-left: 40px;
	margin-right: 2vw;
	margin-bottom: 10px;
}

.mainWrapper {
	margin-top: 94px;
}
.helper {
	position: relative;
	margin: 30px 2vw 30px 40px;
	padding: 24px;

	background: var(--color-light-green);
	font: var(--gilroy-Medium-16-140);
	letter-spacing: 1px;
}

.row_header {
	padding: 0px !important;
	margin: 0px 0px 10px !important;
	flex-wrap: nowrap !important;
	color: var(--color-grey100);
	font: var(--gilroy-Medium-18);
	text-transform: lowercase;
}

.RepertoireMainInfoTable__body {
	font: var(--gilroy-Medium-16-19);
}

.RepertoireMainInfoTable__body li {
	background-color: var(--color-white);
	margin: 4px 0px;
	border: 3px solid white;
	transition: 0.2s;
}

.RepertoireMainInfoTable__body ul {
	background-color: var(--color-white100);
	padding: 0;
}

.row_data {
	display: block !important;

	margin: 2px 0px !important;
	font: var(--gilroy-Medium-18);
	/* padding: 5px 0px 2px 0px; */
	padding: 11px 0px 9px 0px;
}

.row_title {
	margin: 0px !important;
	font: var(--gilroy-Medium-18);
	/* padding: 0px 0px 6px; */
}

.row_title_number {
	display: flex;
}

.sectionTitleWrapper {
	display: flex;

	margin: 24px 40px;

	justify-content: space-between;
}

.sectionTitle {
	font: var(--gilroy-Medium-24);
}

.modalRecipientsWrapper {
	position: relative;
}

.modalRecipientsAll {
	position: absolute;
	top: 100%;
	left: 0;

	background-color: var(--color-dark-grey);
	color: var(--color-white);
}

.modalRecipientsItemWrapper {
	display: flex;
}

.modalRecipientsItem {
	margin-right: 20px;
}

.infoWrapper {
	display: flex;
	margin: 30px 2vw 30px 40px;
}

.infoTitle {
	margin-bottom: 8px;
}

.infoRoyalty {
	position: relative;
	text-align: center;
	padding-top: 30px;
	padding-bottom: 32px;
	width: calc(100% / 2 - 15px);
	margin-right: 30px;

	background-color: var(--color-white);
}
.infoNextReport {
	text-align: center;
	padding-top: 30px;
	padding-bottom: 32px;
	background-color: var(--color-white);
	width: calc(100% / 2 - 15px);
}

.infoTitle {
	font: var(--gilroy-Medium-16-19);
	color: var(--color-grey100);
}
.infoValue {
	font: var(--gilroy-Medium-48);
	color: var(--color-dark-grey);
}

.action {
	display: flex;
	/* align-items: center; */
	cursor: pointer;
}

.actionTitle {
	text-decoration: underline;
	margin-left: 8px;
}

.noData {
	margin-top: 150px;
	align-items: center;

	text-align: center;
	font: var(--gilroy-Medium-24);
}

.shell {
	display: flex;
	align-items: center;
}

.heading {
	display: -webkit-box;
	/* max-width: 150px !important; */
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden !important;
	/* white-space: pre-wrap; */
	overflow-wrap: break-word;
}

.form__fragment_help_btn {
	position: absolute;
	top: 12px;
	right: 12px;
}

@media screen and (min-width: 768px) {
	.mainWrapper {
		margin-top: 0;
	}
}
