/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/11/2020, 15:02
 */

.Title {
	margin-bottom: 30px;
	padding-top: 50px;
}

.ListItem {
	width: 100%;
	height: 100px;
	margin-bottom: 10px;
	padding: 30px;
	cursor: pointer;
	background-color: var(--color-white);
}

.ListItem:hover,
.Selected {
	color: var(--color-white);
	background-color: var(--color-dark-grey);
}

.InfoBlock {
	margin-bottom: 30px;
}

.InfoFieldTitle {
	font: var(--gilroy-Medium-20);
	font-size: 16px;
	font-weight: 500;
	line-height: 150%;
	margin-bottom: 10px;
	letter-spacing: 0.4px;
}

.InfoField {
	font: var(--gilroy-Medium-24-140);
}
