.wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 17px 0 17px 8px;
  border-bottom: 1px solid var(--color-light-grey);
}

.wrapper:last-child {
  border-bottom: none;
}

.content {
  display: flex;
  align-items: center;
  width: 100%;
}

.number {
  display: inline-block;
  padding-left: 8px;
  width: 22px;
  height: 24px;
  font: var(--gilroy-Medium-16-24);
	color: var(--color-grey)
}

.cover {
  margin-right: 8px;
  width: 40px;
  height: 40px;
  overflow: hidden;
}

.cover img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.info {
  margin-top: -4px;
  max-width: 20vw;
}

.title {
  margin: 0;
	font: var(--gilroy-Medium-16-24);
  color: var(--color-dark-grey);
  line-height: 1.5;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
  cursor: pointer;
}

.title:hover {
  text-decoration: underline;
}

.description {
  font: var(--gilroy-Medium-12-12);
	color: var(--color-grey);
}

.streams {
  margin-left: auto;
  font: var(--gilroy-Medium-16-24);
  color: var(--color-dark-grey);
}