.settings {
	width: 100%;
	top: 56px;
	letter-spacing: 1px;
	background: var(--color-white100);
}

.header {
	display: flex;
	align-items: center;
	height: 48px;
	background-color: var(--color-light-grey);
}

.header > button {
	text-transform: lowercase;
	cursor: pointer;
	padding: 14px 24px !important;
}

.header > button:first-child {
	padding-left: 30px;
}

.headerButton {
	padding: 25px 0;
	border: none;
	text-align: center;
}

.headerButtonRepertoires {
	display: inline-block;
	margin-left: 8px;
	font: var(--gilroy-Bold-12-15);
	border: 1px solid var(--color-dark-grey100);
	box-sizing: border-box;
	border-radius: 25px;
	padding: 3px 8px 2px 7px;
	color: var(--color-dark-grey);
	margin: 0;
}

.main {
	padding: 40px 30px;
}

.notReady {
	display: inline-block;
	border: 1px red solid;
	border-radius: 4px;
	width: 8px;
	height: 8px;
	background-color: red;
	margin-left: 8px;
}

.info {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	height: 56px;
	padding-left: 30px;
	font: var(--gilroy-Medium-18);
	background-color: var(--color-white);
}

.attentionIcon {
	margin-right: 24px;
}

@media screen and (min-width: 768px) {
	.Input input {
		font: var(--gilroy-Medium-18);
	}

	.Input {
		max-width: 416px;
		height: 60px;
	}

	.repertoire {
		top: 0;
	}
}

.Red_color {
	color: var(--color-red);
}
