/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/11/2020, 15:02
 */

.Footer {
	display: flex;
	overflow: hidden;
	align-items: center;
	justify-content: flex-end;
	box-sizing: border-box;
	height: 100%;
	padding: 20px;
	color: var(--color-white);
	background-color: var(--color-dark-grey);
	grid-area: footer;
}

.Footer > div {
	margin-right: 5vw;

}

@media screen  and (min-width: 320px) {

}

@media screen  and (min-width: 768px) {

}

@media screen  and (min-width: 1440px) {

}

@media screen  and (min-width: 1920px) {

}

@media screen  and (min-width: 3840px) {

}

