.statisticByDateTabSection .MuiCalendarPicker-root .PrivatePickersFadeTransitionGroup-root {
  background-color: var(--color-white) !important;
}

.statisticByDateTabSection .MuiCalendarPicker-viewTransitionContainer .PrivatePickersSlideTransition-root {
  background-color: var(--color-white) !important;
  min-height: 230px !important;
}

.statisticByDateTabSection .MuiPaper-root .MuiCalendarPicker-root > div:first-child {
  margin: 0 !important; 
  padding-top: 16px !important;
  padding-bottom: 16px !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
  max-height: 40px;
  min-height: 40px;
  background-color: var(--color-light-grey) !important;
}

.statisticByDateTabSection .MuiPaper-root .MuiCalendarPicker-root > div:first-child .PrivatePickersFadeTransitionGroup-root{
background-color: var(--color-light-grey) !important;
}

.statisticByDateTabSection .MuiPaper-root .MuiCalendarPicker-root {
overflow-y: hidden !important;
border: 1px solid var(--color-light-grey);
}

.statisticByDateTabSection [role="dialog"] {
  width: 276px !important;
  max-height: 330px !important;
}

.statisticByDateTabSection [role="dialog"] .MuiPaper-root {
  box-shadow: none !important;  
  margin-top: 10px !important;
  margin-left: 18px !important; 
}

.statisticByDateTabSection [role="dialog"] .MuiCalendarPicker-root {
  margin: 0 !important; 
  width: 276px !important;
  max-height: 330px !important;
}

.statisticByDateTabSection [role="dialog"] .MuiCalendarPicker-root > div:first-child > div:first-child {
  width: 276px !important;
  max-height: 330px !important;
}




 