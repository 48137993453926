.chart_container svg rect:hover,
.chart_container svg .clickable_text:hover {
	cursor: pointer;
}

.chart_container .hint {
	background: var(--color-dark-grey);
	padding: 8px;
	color: var(--color-white);
	font: var(--gilroy-Medium-12);
	margin-bottom: 10px;
	transform: translate(-5px, -15px);
}

.chart_container .hint_total {
	background: var(--color-white);
	color: var(--color-dark-grey);
	font: var(--gilroy-Medium-14);
	padding: 8px 12px 28px;
	transform: translate(0, 14px);
	box-shadow: 0px 1.85px 6.25px 0px #00000030, 0px 0.5px 1.75px 0px #0000000a;
	gap: 8px;
	display: flex;
	flex-direction: column;
}

.hint_total h4 {
	font: var(--gilroy-Medium-14);
}

.hint_total p {
	color: var(--color-grey);
	font: var(--gilroy-Medium-12);
}

.hint_total p span {
	font-weight: 700;
}
