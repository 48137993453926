.container {
	margin-bottom: 40px;
}

.title {
	display: inline-block;
	font: var(--gilroy-Medium-24-140p);
	letter-spacing: 1px;
}

.distributions {
	display: flex;
	align-items: center;
	height: 84px;
	margin-top: 24px;
	padding: 20px 24px;
	background-color: var(--color-white);
}

.distributions > *:first-child {
	min-width: 18.75vw;
	margin-right: 24px;
}

.item {
	display: flex;
	flex-direction: column;
	max-width: 240px;
	height: 38px;
}

.item__title {
	font: var(--gilroy-Medium-12-15);
	color: var(--color-grey100);
	margin-bottom: 4px;
}

.item__text {
	font: var(--gilroy-Medium-16-19);
}

.item__textLh {
	font: var(--gilroy-Medium-16-140);
}

.distributionOutlets {
	margin-top: 24px;
}
