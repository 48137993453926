.page {
	display: grid;
	width: 100%;
	height: 100%;
	background-color: var(--color-white100);
	grid-template-areas: 'wiz' 'page';
	grid-template-rows: 48px auto;
	grid-template-columns: auto;
}

.wizard {
	background-color: var(--color-white);
	grid-area: wiz;
}

.step {
	overflow: auto;
	height: 100%;
	grid-area: page;
}
