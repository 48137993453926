.Form {
	width: 100%;
	padding: 40px 16px;
}

.Input {
	max-width: 100%;
}

.disabled input {
	opacity: 0.35;
}

.Title__wrapper {
	margin-bottom: 40px;
}

.Title__header {
	margin-bottom: 8px;
	font: var(--gilroy-Medium-28);
	line-height: 28px;
}

.Title__subheader {
	font: var(--gilroy-Medium-18);
	color: var(--color-grey);
	line-height: 24px;
	letter-spacing: 0.72px;
}

.Title__action {
	margin-top: 24px;
	font: var(--gilroy-Medium-24);
	line-height: 28px;
	letter-spacing: 0.72px;
}

.Button_resend {
	font: var(--gilroy-Medium-18);
	line-height: 24px;
	letter-spacing: 0.72px;
	margin-top: 8px;
}

.noCode {
	display: flex;
	justify-content: center !important;
	margin-top: 8px;
}

.Fields {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	max-height: 60vh;
}

.Fields > div:first-child {
	margin-bottom: 16px;
	max-width: 100%;
}

.Controls {
	width: 100%;
	margin-top: 50px;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: flex-start;
	max-height: 10vh;
}

.Controls button {
	max-width: 100%;
}

.Controls a {
	justify-content: flex-start;
	max-width: 100%;
	line-height: 24px;
	letter-spacing: 0.72px;
	text-transform: lowercase;
}

.codeInput {
	position: relative;
}

.resendBtn {
	position: absolute;
	bottom: 20px;
	cursor: pointer;
	background-color: transparent;
	font: var(--gilroy-Medium-14);
	border: none;
	border-bottom: 2px solid var(--color-grey);
}

@media screen and (min-width: 768px) {
	.Title__header {
		font: var(--gilroy-Medium-40);
		line-height: 40px;
	}

	.Title__subtitle {
		font: var(--gilroy-Medium-18);
		line-height: 24px;
		letter-spacing: 0.72px;
	}

	.Controls {
		align-items: flex-start;
		flex-direction: column;
		justify-content: space-between;
		max-height: 10vh;
	}

	.Controls a {
		justify-content: flex-start;
	}

	.Fields > div:first-child {
		max-width: 100%;
	}
}
