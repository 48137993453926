.dropdownContainer {
	max-width: 120px;
}

.PhoneInput {
	display: flex;
}

.PhoneInput > div + div {
	width: 100%;
	margin-left: 8px;
}

.PhoneInput input[type='number']::-webkit-outer-spin-button,
.PhoneInput input[type='number']::-webkit-inner-spin-button {
	margin: 0;
	-webkit-appearance: none;
}

.disabled input,
.codeDisabled {
	opacity: 0.5;
}

.inputWidth {
	width: 100%;
	max-width: 100%;
}
