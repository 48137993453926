.playerWrapper {
	position: absolute;
	bottom: 0px;
	right: 0px;
	z-index: 99999999999;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	gap: 32px;
	box-sizing: border-box;
	padding: 12px 40px;
	height: auto;
	min-height: 56px;
	width: 100%;
	background-color: var(--color-dark-grey100);
	color: white;
}

.playerNavigationWrapper {
	display: flex;
	align-items: center;
}

.playerTitle:not(:last-child) {
	margin-right: 15px;
}

.play {
	margin-right: 29px;
	cursor: pointer;
}

.volumeBtn {
	position: relative;
	margin-right: 29px;
	cursor: pointer;
}

.volumeSliderBox {
	position: absolute;
	bottom: 43px;
	left: 0px;
	height: 100px;
	width: 24px;
	background-color: #1e1e1e;
	opacity: 0.8;
	border-radius: 2px;
}

.volumeSlider {
	margin-bottom: 9px;
}

.slider {
	height: 100%;
	position: relative;
	width: 320px;
	margin-right: 16px;
	margin-top: 4px;
}

.playBar {
	display: flex;
	align-items: center;
	margin-right: 29px;
	color: white;
}

.playTime {
	width: 50px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.playTime:not(:last-child) {
	margin-right: 16px;
}

.playBar:not(:last-child) {
	margin-right: 16px;
}

.close {
	cursor: pointer;
}

@media screen and (min-width: 860px) {
	.playerWrapper {
		flex-wrap: nowrap;
	}
}

@media screen and (min-width: 1024px) {
	.playerWrapper {
		width: calc(100% - 160px);
	}
}

@media screen and (max-width: 680px) {
	.playerWrapper {
    padding: 12px;
	}

	.slider {
		width: 120px;
	}
}
