.header {
	width: -webkit-fill-available;
	width: -moz-available;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-top: 20px;
	padding: 9px 30px 9px 24px;
	color: var(--color-dark-grey);
	font: var(--gilroy-Medium-24);
	letter-spacing: 1px;
}

.header img {
	width: 24px;
	margin-right: 8px;
}

.headerTitle__type {
	text-transform: lowercase;
	margin-right: 16px;
}

.headerTitle__name {
	font: var(--gilroy-Medium-32);
	font-weight: 500;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

@media screen and (min-width: 768px) {
	.header {
		margin-top: 0;
	}
}
