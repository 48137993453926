.autoGenres_wrapper {
	position: relative;
}

.autoTag {
	position: absolute;
	top: 22px;
	right: 50px;
	color: black;
	font-size: 17px;
}
