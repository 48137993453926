/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 02/10/2020, 16:10
 */

.Condition {
	display: flex;
	align-items: flex-start;
	margin-bottom: 38px;
}

.Title {
	width: 40%;
}

.Condition button {
	font-size: inherit;
	display: flex;
	align-items: center;
	align-self: center;
	margin-left: 14px;
	cursor: pointer;
	border: none;
	background-color: #0000;
}

.Condition img {
	margin-right: 5px;
}

.Checkbox {
	font: var(--gilroy-Bold-18);
	margin: 0;
}

.Fields :global .line {
	display: flex;
	align-items: flex-start;
	margin-bottom: 18px;
}

.Fields ul {
	display: flex;
	flex-wrap: wrap;
	max-width: 400px;
	margin: -8px 0 0 10px;
	padding: 0;
}

.Fields ul > li {
	margin: 3px 0 4px 8px;
}

.Fields ul > li:last-child {
	margin-right: auto;
}

.checkGreen {
	margin-right: 20px !important;
}

.editBtn {
	font: var(--gilroy-Medium-18-140);
	padding-top: 4px;
}
