.helper {
	position: relative;
	padding: 24px;
	margin-bottom: 24px;
	background: var(--color-light-green);
	font: var(--gilroy-Medium-16-140);
	letter-spacing: 1px;
}
.helper img {
	position: absolute;
	width: 16px;
	height: 16px;
	top: 8px;
	right: 8px;
}

.loader {
	margin-top: 30px;
}
/* svg */

/* error */

/* .repertoire__songItemError {
	color: var(--color-red);
}

.repertoire__songItemError .repertoire__songItemLogo path {
	fill: var(--color-red);
} */

.statistic__mainContent {
	position: relative;
	padding: 16px;
	margin: 30px 0 32px;
	display: flex;
	flex-direction: column;
	min-height: 540px;
	background-color: var(--color-white);
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
}

/* empty */

.repertoire__mainContentEmpty {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 100px;
	margin-bottom: 100px;
}

.repertoire__mainContentEmpty > *:first-child {
	margin-bottom: 32px;
}
.repertoire__mainContentEmpty > *:last-child {
	color: var(--color-dark-grey);
	font: var(--gilroy-Medium-24-140p);
	letter-spacing: 1px;
}
.repertoire__mainContentEmpty > *:last-child a {
	color: var(--color-dark-grey);
	text-decoration: underline;
	cursor: pointer;
}

/* ==================================== */
.Helper {
	position: absolute;
	top: 55px;
	left: 0px;
	color: var(--color-red);
	font: var(--gilroy-Medium-12);
	font-size: smaller;
	padding: 0 5px;
}

.select {
	width: 100%;
}

.statistic__mainContentHeader {
	display: flex;
	/* justify-content: space-between; */
	gap: 20px;
	align-items: center;
	margin-bottom: 16px;
	background-color: var(--color-white);
}

.statistic__mainContentButtons {
	display: flex;
	margin-left: auto;
}

.statistic__mainContentHeaderInfo_title {
	font: var(--gilroy-Medium-24-140p);
}

.statistic__mainContentHeaderInfo_amount {
	font: var(--gilroy-Medium-16-20);
	color: var(--color-grey);
}

.statistic__mainContentButtons {
	display: flex;
	justify-content: center;
	flex-direction: row;
	align-items: center;
}

.titleContainer {
	position: relative;
	display: flex;
	align-items: center;
}

.titleContainer .tooltip {
	display: none;
	z-index: 10;
}

.titleContainer:hover .tooltip {
	display: block;
}

.question {
	margin-left: 4px;
}

.question:hover {
	cursor: pointer;
}

.tooltip {
	position: absolute;
	top: 28px;
	left: -20px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: center;
	width: max-content;
	height: 28px;
	padding: 8px 12px;
	border-radius: 8px;
	color: var(--color-white);
	background-color: #171a21;
	font: var(--gilroy-Medium-12-12);
}

.tooltip:after {
	z-index: 1000000;
	position: absolute;
	top: -5px;
	left: 107px;
	width: 0;
	height: 0;
	content: '';
	transform: rotate(180deg) translateY(20%);
	border-width: 7px 7px 0 7px;
	border-style: solid;
	border-color: var(--color-dark-grey) transparent transparent transparent;
}

.statistic__mainContentButtonsFirst {
	height: 40px;
	padding: 8px 10px 10px;
	background-color: var(--color-white);
	color: var(--color-dark-grey100);
	border: 1px var(--color-dark-grey100) solid;
	cursor: pointer;
}

.statistic__mainContentButtonsSecond {
	margin-left: 0;
	border-left: 0;
	height: 40px;
	padding: 8px 10px 10px;
	color: var(---color-dark-grey100);
	background-color: var(--color-white);
	border: 1px var(--color-dark-grey100) solid;
	cursor: pointer;
}

.statistic__mainContentButtonsActive {
	background-color: var(--color-dark-grey100);
	color: var(--color-white);
}

.crosshairData__wrapper {
	display: flex;
	flex-direction: column;
	min-width: 300px;
	background-color: white;
	color: black;
	box-shadow: 0px 0.5px 1.75px rgba(0, 0, 0, 0.039),
		0px 1.85px 6.25px rgba(0, 0, 0, 0.19);
	padding: 8px 12px;
}

.crosshairData__title {
	font: var(--gilroy-Medium-16-19);
	margin-bottom: 8px;
}

.crosshairData__itemStatistic {
	font: var(--gilroy-Bold-12-15);
	color: rgba(0, 0, 0, 0.6);
}

.crosshairData__itemTitle {
	font: var(--gilroy-Medium-12-15);
	margin-bottom: 8px;
}

.crosshairData__item {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 8px;
}

.crosshairData__itemColor {
	width: 12px;
	height: 4px;
	margin-right: 4px;
}

.crosshairData__legend {
	display: flex;
	flex-wrap: wrap;
	gap: 14px;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	padding-left: 10px;
	padding-bottom: 24px;
	background-color: var(--color-white);
}

.crosshairData__legendItem {
	position: relative;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.crosshairData__legendItemColor {
	width: 12px;
	height: 4px;
	margin-right: 4px;
}

.crosshairData__legendItemTitle {
	font: var(--gilroy-Medium-12-15);
}

.statistic__XYPlot {
	padding-left: 50px;
}

.statistic__noData {
	z-index: 1;
	position: absolute;
	width: 200px;
	padding: 5px;
	padding-left: auto;
	padding-right: auto;
	top: 110px;
	left: calc(50% - 100px);
	font: var(--gilroy-Bold-24);
	color: var(---color-dark-grey100);
	background-color: rgba(255, 255, 255, 0.7);
	text-align: center;
}

.buttonInfo {
	position: relative;
	display: flex;
	align-items: center;
	gap: 8px;
	cursor: pointer;
	color: var(--color-grey);
	z-index: 100000000;
}

.buttonInfo span {
	font: var(--gilroy-Medium-16-24);
	text-transform: capitalize;
	text-decoration: underline;
}

.wrapperInformer {
	position: absolute;
	top: 50px;
	right: 15px;
	padding: 10px;
	min-width: 170px;
	background-color: var(--color-white);
	box-shadow: 0 1px 10px rgba(0, 0, 0, 0.08);
	z-index: 100;
}

.wrapperInformerHeading {
	margin-bottom: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
	font: var(--gilroy-Medium-12);
	color: var(--color-grey);
}

.listSources {
	padding: 0;
	margin: 0;
	display: flex;
	flex-direction: column;
	gap: 8px;
	list-style: none;
}

.source {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 10px;
	font: var(--gilroy-Medium-14);
}
