.mainWrapper {
	padding: 60px 30px;
}

.title {
	margin-top: 80px;
	margin-bottom: 40px;
	font: var(--gilroy-Medium-24);
}

.subTitle__wrapper {
	font: var(--gilroy-Medium-20-140p);
	margin: 36px 0px 12px 0px !important;
}

.subTitle__wrapper:hover,
.columnLeft:hover,
.columnRight:hover {
	text-decoration: line-through;
}

.columnLeft,
.columnRight {
	padding: 0px !important;
	font: var(--gilroy-Medium-14);
}

.columnLeft {
	/* padding-left: 140px!important ; */
	padding-right: 5vw !important;
}

.columnRight {
	/* padding-left: 140px!important ; */
	padding-left: 5vw !important;
}

.textRow {
	padding: 12px 0px !important;
	margin: 0px !important;
}

.section__wrapper {
	margin-bottom: 48px;
}

.articlesList {
	letter-spacing: 1px;
	line-height: 16.98px;
}

@media screen and (min-width: 768px) {
	.mainWrapper {
		padding: 60px 83px;
	}
}

@media screen and (min-width: 1280px) {
	.mainWrapper {
		padding: 60px 104px;
	}
}

.mainWrapper a {
	text-decoration: none;
	color: var(--color-dark-gray);
}
