.info__logo {
	position: relative;
}

.info__logo > .info__load {
	background: rgba(0, 0, 0, 0.4);
	opacity: 1;
}

.cover {
	width: 100%;
	max-width: 240px;
}

.noCover {
	width: 240px;
	height: 240px;
	background-color: var(--color-white);
}

.imgNoCover {
	display: block;
	margin: 0 auto;
	padding-top: 60px;
}

.noImgTitle {
	display: block;
	text-align: center;
	margin-top: 30px;
	font: var(--gilroy-Medium-18);
}

.preLoader {
	background-image: url('images/loading_circle.svg');
	background-size: 70%;
	background-position: center;
	background-repeat: no-repeat;
	width: 70%;
	height: 70%;
	object-fit: cover;
	animation: loader 1s linear infinite;
}

preview img.loader {
	width: 0;
	height: 0;
}

.preview {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 240px;
	height: 240px;
	border: 1px solid var(--color-black);
}

.preview img {
	max-height: 100%;
	max-width: 100%;
}

@keyframes loader {
	100% {
		transform: rotate(360deg);
	}
}

@media screen and (min-width: 860px) {
	.noCover,
	.preview {
		position: absolute;
		top: 0;
		right: 30px;
	}
}

@media screen and (min-width: 1024px) {
	.noCover,
	.preview {
		width: 214px;
		height: 214px;
	}
}
