.container {
	padding: 0 15px;
	margin-left: 'auto';
	text-overflow: 'initial';
}

.repertoire__playLogo {
	width: initial !important;
}

.repertoire__playLogo_inactive {
	width: initial !important;
	opacity: 0.3;
}
