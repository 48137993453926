/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 18/11/2020, 15:02
 */

.DNDUpload {
	font: var(--gilroy-Medium-18-22);
	margin-top: 20px;
}

.Drop {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	min-height: 300px;
	margin-bottom: 50px;
	padding: 20px;
	border: 1px dashed var(--color-grey);
	border-radius: 2px;
	background-color: var(--color-white);
}

.Controls {
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
}
