.subheader {
	margin-top: 8px;
	margin-bottom: 24px;
}

.reportsBlock {
	display: flex;
	flex-direction: column;
	gap: 7px;
	margin-bottom: 16px;
}

.uploadBlock {
	width: 100%;
	max-width: 768px;
	margin-bottom: 48px;
}

.uploadBlock section {
	width: 100%;
	max-width: 768px;
}

.filesBlock {
	position: relative;
	margin-top: 24px;
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.helper {
	color: var(--color-red);
	font: var(--gilroy-Medium-12);
	font-size: smaller;
}
