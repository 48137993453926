.mainWrapper {
	padding: 100px 40px 48px 46px;
}

.sectionTitleWrapper {
	box-sizing: border-box;
	width: 100%;
}

.lang {
	margin-top: 26px;
	margin-bottom: 40px;
}

.RepertoireSongInfo__mainInfoTable {
	letter-spacing: 1px;
	margin-bottom: 30px;
}

.sectionTitle {
	font: var(--gilroy-Medium-24);
}

.modalRecipientsWrapper {
	position: relative;
}

.modalRecipientsAll {
	position: absolute;
	top: 100%;
	left: 0;

	background-color: var(--color-dark-grey);
	color: var(--color-white);
}

.modalRecipientsItemWrapper {
	display: flex;
}

.modalRecipientsItem {
	margin-right: 20px;
}

.itemTextWrapper {
	display: -webkit-box;
	max-width: 40vw;

	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden !important;
}

.itemTextWrapper ul {
	list-style: disc !important;
	padding-left: 20px;
}

.itemTextWrapper ol {
	padding-left: 20px;
}

.innerBlockText {
	white-space: pre-wrap;
}

.noData {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-top: 223px;
	
}

.noData__title {
	width: 100%;
	text-align: center;
	font: var(--gilroy-Medium-32);
	margin-bottom: 8px;
}

.noData__text {
	width: 100%;
	text-align: center;
	font: var(--gilroy-Medium-18-140);
	margin-bottom: 32px;
}

@media screen and (min-width: 768px) {
	.mainWrapper {
		padding: 26px 40px 48px 46px;
	}

	.sectionTitleWrapper {
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
	}
}
