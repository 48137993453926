.field {
	overflow-wrap: break-word;
	margin-bottom: 20px;
}

.field {
	font: var(--gilroy-Medium-16-20)
}

.field :global .title {
	margin-bottom: 10px;
}
