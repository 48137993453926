.Bg {
	height: 100%;
	display: grid;
	grid-template-columns: 100%;
	align-self: center;
	height: fit-content;
}

.Bg_dgtal {
	display:flex;
	height: 100%;
	justify-content: center;
	background-color: var(--color-light-grey);
	align-items: center;
	/* align-self: center; */
	/* height: fit-content; */
}

.Hero {
	padding: 0 16px;
	margin-bottom: 40px;
}

.Hero_header {
	padding-bottom: 24px;
	/*align-self: center;*/
	justify-self: center;
	font: var(--gilroy-Medium-32);
	line-height: 40px;
}

.Hero_header_wrapper, .Hero_header_body {
padding-left: 0px !important;
padding-right: 0px !important;
}

.HeroBox {
	display: flex;
	align-self: center;
	margin-bottom: 16px;
	letter-spacing: 0.64px;
	font: var(--gilroy-Medium-16);
	line-height: 20px;

}

.HeroBox>div {
	display: inline-flex;
	flex-direction: column;
	justify-content: center;
}


.Hero_text {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.svg_branding {
	margin-right: 20px;
	fill: var(--color-light-green);
	width: 22px;
	height: 22px;
}

.Hero_box img {
	margin-right: 20px;
}

.Form :global .label {
	/* 	height: 68px; */
	margin-bottom: 20px;
}

.Form :global .content {
	display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: space-between;
	width: 85%;
}

.Form :global .login {
	color: var(--color-dark-grey);
	background-color: var(--color-white100);
	padding-top: 24px;
}

.Form :global .register {
	/* width: 100%; */
	background-color: var(--color-light-green);
	padding-top: 24px;
}

.Form :global .register,
.Form :global .login {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: space-between;
	width: 85%;
	padding-bottom: 32px;
	/*width: 416px;*/
}

.formRow {
	padding: 0px;

}

.formRow button{
	max-width: 100%;
}

.Bottom_text {
	position: relative;
	padding: 24px 40px !important;
	background-color: var(--color-light-green);
	font: var(--gilroy-Medium-24);
}
.Bottom_text_title {
	font-weight: 700 !important;
	font: var(--gilroy-Medium-16);
	line-height: 22px;
	margin-bottom: 4px;
	letter-spacing: 1px;
}

.Bottom_text_body {
	margin-bottom: 24px;
	font: var(--gilroy-Medium-14);
	line-height: 22px;
	letter-spacing: 0.14px;

}

.Bottom_text > .container {
	padding-top: 20px;
}

.Bottom_text :global .bar {
	content: '';
	position: absolute;
	background-color: var(--color-dark-grey100);
}

.Bottom_text a {
	font-weight: bold;
}

.formCol {
	display: flex;
	justify-content: center;
		color: var(--color-dark-grey);
			background-color: var(--color-white100);
			padding: 50px 45px 60px !important;



}


.formLoginCol, .formRegisterCol {
	composes: formCol;
}

.formRegisterCol {
		background-color: var(--color-light-green);
}

.formLoginColWrapper, .formRegisterColWrapper {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content:flex-end;
	width: 342px;
}




.formRegisterColWrapper {
		background-color: var(--color-light-green);
			font: var(--gilroy-Medium-24);
}




/*  LOGIN */
.Forgot {
	font: var(--gilroy-Medium-14-140);
	margin-top: 60px;
	text-align: center;
}

.form_wrapper {
	max-width: 100%;
}

.form_wrapper > div {
	max-width: 100%;
}

.form_wrapper > button {
	max-width: 100%;
}


.submit {
	margin-top: 0px;
}

.register_title {
	margin-bottom: 20px;
}

.register_button {
	margin-top: 50px;
	font: var(--gilroy-Medium-18);
	line-height: 22px;
	letter-spacing: 0.72px;
	text-align: center;
}

.register_button a{
	font-weight: 700;
 }

.LoginWrapper {
	display: flex;
	flex-direction: column;

	max-width: 100%;
	padding: 40px 16px 40px;
	justify-content: flex-start;
	background-color: var(--color-light-grey);
	height: fit-content;
	text-transform: lowercase;
}
.LoginWrapper_dgtal {
	box-sizing: content-box;
	display: flex;
	flex-direction: column;
	width: 490px;
	max-width: 100%;
	padding: 40px 16px 40px;
	justify-content: flex-start;
	background-color: var(--color-light-grey);
	height: fit-content;
	text-transform: lowercase;
}

.submit > div {
	max-width: 100%;
}

.submit > button {
	max-width: 100%;
}

.JoinWrapper {
/* display: none; */
flex-direction: column;
max-width: 100%;
justify-content: center;
margin-top: 40px;
text-transform: lowercase;
}


@media screen and (min-width: 768px) {
	.JoinWrapper {
		display: flex;
		margin-top: 0px;
	}
	.Bg {
		grid-template-columns: 50% 50%;
			height: 100%;
	}
	.LoginWrapper {
		padding: 0 80px;
		justify-content: center;
		height:auto;
	}
	.Hero {
		padding: 0 56px;
		margin-bottom: 120px;
	}
		
	.Hero_header {
		padding-bottom: 40px;
		font: var(--gilroy-Medium-48);
		line-height: 56px;
	}
	.HeroBox {
		margin-bottom: 17px;
		letter-spacing: 0.72px;
		font: var(--gilroy-Medium-18);
		line-height: 24px;
	}

	.register_button {
		font: var(--gilroy-Medium-24);
		margin-top: 50px;
		line-height: 28px;
		letter-spacing: 0.96px;
	}
	.Bottom_text_title {
		font: var(--gilroy-Medium-18);
		line-height: 24px;
		}
		.Bottom_text_body {
			font: var(--gilroy-Medium-16);
			line-height: 24px;
			letter-spacing: 0.16px;
			}

			.formRow {
				padding-left:56px;
				padding-right:56px;
			}
}