.container {
	display: flex;
	padding: 0 30px;
	align-items: center;
	gap: 8px;
	align-self: stretch;
	width: 100%;
	height: 80px;
	border-top: 1px solid var(--color-grey50);
}

.prev_btn {
	display: flex;
	justify-content: center;
	align-items: center;
	width: fit-content;
	height: 60px;
	background: transparent;
	border: none;
	outline: none;
	font: var(--gilroy-Medium-18-22);
	text-decoration: underline;
	cursor: pointer;
}

.next_btn {
	width: 255px;
	height: 48px;
	background: var(--color-dark-grey);
	border: none;
	color: var(--color-white);
	cursor: pointer;
	font: var(--gilroy-Medium-18-140);
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0.72px;
	text-transform: lowercase;
}

.disabled {
	background: var(--color-grey50);
	cursor: initial;
}
