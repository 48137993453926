.releaseDateBlock {
	position: relative;
	margin-left: 36px;
}

.releaseDateBlockHelper,
.releaseOutletsBlockHelper {
	position: absolute;
	color: var(--color-red);
	font: var(--gilroy-Medium-12);
	font-size: smaller;
	padding: 0 5px;
}

.releaseOutletsBlockHelper {
	top: -14px;
}

.outletsDataBar {
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin-top: 12px;
}

.error_container {
	position: relative;
}
