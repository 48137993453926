.LoginPage {
	height: 100%;
	background-color: var(--color-white100);
}

.Login {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	grid-area: login;
}

.Register {
	display: none;
	align-items: center;
	justify-content: center;
	background-color: var(--color-light-green);
	grid-area: register;
}

.Register :global .form {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	height: 373px;
	padding-bottom: 50px;
	width: 312px;
}

.Register button {
	margin-top: 48px;
}

@media screen and (min-width: 768px) {
	.Login {
		align-items: center;
	}

	.Register {
		display: flex;
	}
}
